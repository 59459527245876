import config from '@config'
import { useQuery } from '@tanstack/react-query'
import { Link } from '@utils/cook/types'

const ALLOWED_TYPES_OF_SPORT = ['soccer'] as const

export type AllowedTypeOfSport = (typeof ALLOWED_TYPES_OF_SPORT)[number]

interface UseEntityRelatedMatchesProps {
  seasonId: string
  typeOfSport: AllowedTypeOfSport
}

interface EntityRelatedMatchesItem {
  matchId: string
  typeOfSport: AllowedTypeOfSport
  link?: Link
}

export type EntityRelatedMatchesAPIResponse = {
  items: EntityRelatedMatchesItem[]
}

const {
  sports: { baseUrlV2: sportsBaseUrlV2, relatedMatchesSeasonUrlTemplateV2 },
} = config

const validSeasonId = (seasonId: UseEntityRelatedMatchesProps['seasonId']) =>
  !!seasonId && seasonId !== '0'

const validTypeOfSport = (
  typeOfSport: UseEntityRelatedMatchesProps['typeOfSport']
) => ALLOWED_TYPES_OF_SPORT.includes(typeOfSport)

const fetchSportsEntityRelatedMatchesData = async ({
  typeOfSport,
  seasonId,
}: UseEntityRelatedMatchesProps) => {
  const requestUrl = `${sportsBaseUrlV2}${relatedMatchesSeasonUrlTemplateV2
    .replace('{SPORT}', typeOfSport)
    .replace('{API_VERSION}', 'v0')
    .replace('{SEASON_ID}', seasonId)}`

  const response = await fetch(requestUrl)

  if (!response.ok) {
    throw new Error('Error fetching League Related Matches.')
  }

  return response.json() as Promise<EntityRelatedMatchesAPIResponse>
}

const useEntityRelatedMatches = ({
  seasonId,
  typeOfSport,
}: UseEntityRelatedMatchesProps) => {
  const isEnabled = validSeasonId(seasonId) && validTypeOfSport(typeOfSport)

  const { data, error, isSuccess } = useQuery({
    queryKey: ['sportsEntityRelatedMatches', typeOfSport, 'season', seasonId],
    queryFn: () =>
      fetchSportsEntityRelatedMatchesData({ typeOfSport, seasonId }),
    enabled: isEnabled,
  })

  return {
    data,
    error,
    isSuccess,
    isEnabled,
  }
}

export default useEntityRelatedMatches
