import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

export interface TitleProps {
  children: string
}

const Wrapper = styled.div`
  display: inline-block;
`
const StyledTitle = styled.span`
  ${({
    theme: {
      colors: { staticWhite, textDefault },
      typography: {
        headings: {
          xsmall: { bundledCSS: xsmallHeadingCSS },
        },
      },
      utils: { ellipsis },
      spacing: { spacing4, spacing8 },
    },
  }) => css`
    ${ellipsis('100%', 4)};
    ${xsmallHeadingCSS};
    color: ${textDefault};
    background-color: ${staticWhite};
    padding: 0 ${spacing8} ${spacing4};
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    display: inline;
  `}
`

const Title: FunctionComponent<TitleProps> = ({ children }) => {
  return (
    <Wrapper>
      <StyledTitle>{children}</StyledTitle>
    </Wrapper>
  )
}

export default Title
