import { FunctionComponent, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useQueryClient } from '@tanstack/react-query'
import config from '@config'

const {
  urlParams: { clientOnlyURLParams, clientOnlyURLParamsCommonKey },
} = config

const clientOnlyURLParamsNames = clientOnlyURLParams.map(
  (urlParam) => urlParam.name
)

const UrlParamManager: FunctionComponent = () => {
  const router = useRouter()
  const queryClient = useQueryClient()

  useEffect(() => {
    router.query = {
      ...router.query,
      ...Array.from(
        new URLSearchParams(window.location.search).entries()
      ).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
    }
  }, [router])

  useEffect(() => {
    const routerQuery = router.query
    const toBeInvalidated: string[] = []

    clientOnlyURLParamsNames.forEach((queryParamName) => {
      const queryParamValue = routerQuery[queryParamName]

      if (
        queryParamValue !==
        queryClient.getQueryData([clientOnlyURLParamsCommonKey, queryParamName])
      ) {
        toBeInvalidated.push(queryParamName)

        if (typeof queryParamValue === 'undefined') {
          queryClient.removeQueries({
            queryKey: [clientOnlyURLParamsCommonKey, queryParamName],
            exact: true,
          })
        } else {
          queryClient.setQueryData(
            [clientOnlyURLParamsCommonKey, queryParamName],
            queryParamValue
          )
        }
      }
    })

    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === clientOnlyURLParamsCommonKey &&
        toBeInvalidated.includes(query.queryKey[1] as string),
    })
  }, [queryClient, router.query])

  return null
}

export default UrlParamManager
