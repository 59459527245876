import Script from 'next/script'
import { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import config from '@config'
import { useQueryClient } from '@tanstack/react-query'
import useCMPHasUserInteracted from '@hooks/useCMPHasUserInteracted'

const {
  aureus: { clientScript, endpoint, fallbackEndpoint, clientUUID, apiKey },
} = config

const AUREUS_CLIENT_CONFIG = {
  endpoint,
  fallbackEndpoint,
  clientUUID,
  apiKey,
  //   blacklisting
  // - when using this mode subsequent API calls are automatically deduplicated (using blacklist) i.e. it provides
  //   guarantee that every subsequent fetched recommendation would not include previously rendered materials
  // - this works as long as the same `AureusClient` instance is reused
  //! Before enabling this feature, make sure that the `blackListedIds` are properly set in the `fetchBlickBitesRecommendations` function
  autoBlackListingEnabled: false,
}

const AureusManager: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const hasUserInteracted = useCMPHasUserInteracted()

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined)

  const initAureusClient = useCallback(() => {
    window.aureusClient = new window.AureusClient({
      config: AUREUS_CLIENT_CONFIG,
    })

    queryClient.setQueryData(['has-aureus-js-client-script-initialized'], true)
    queryClient.invalidateQueries({
      queryKey: ['has-aureus-js-client-script-initialized'],
      exact: true,
    })

    clearTimeout(timeoutRef.current)
  }, [queryClient])

  const onTimeout = useCallback(() => {
    if (
      !queryClient.getQueryData(['has-aureus-js-client-script-initialized'])
    ) {
      console.warn(
        `Timeout of 3000ms exceeded. Init AureusClient without EventsAPI.`
      )

      initAureusClient()
    }
  }, [initAureusClient, queryClient])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const onAureusJsClientScriptLoaded = useCallback(() => {
    // we use window.dlApi.cmd to create AureusClient instance after Simetra is loaded
    window.dlApi.cmd.push(() => {
      initAureusClient()
    })

    timeoutRef.current = setTimeout(onTimeout, 3000)
  }, [initAureusClient, onTimeout])

  return hasUserInteracted ? (
    <Script
      id="AureusJsClientScript"
      strategy="afterInteractive"
      defer={true}
      src={clientScript}
      onLoad={onAureusJsClientScriptLoaded}
    />
  ) : null
}

export default AureusManager
