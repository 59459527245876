import config from '@config'
import {
  getAureusBatchRecommendations,
  GetAureusBatchRecommendationsResponse,
} from '@utils/aureus'
import {
  BlickBitesAd,
  BlickBitesData,
  BlickBitesItem,
} from '@widgets/Video/BlickBites'
import { isValidAd } from '@utils/ads'
import { InfiniteData } from '@tanstack/react-query'

export type FetchBlickBites = (options?: {
  id?: string
  before?: number
  blackListedIds?: string[]
  deboostedIds?: string[]
}) => Promise<BlickBitesData | undefined>

type FetchSharedBlickBite = (id: string) => Promise<Response>

type FetchBlickBitesRecommendations = (args: {
  before?: number
  blackListedIds?: string[]
  deboostedIds?: string[]
}) => GetAureusBatchRecommendationsResponse<BlickBitesItem>

const {
  publication: { publication },
  blickBites: {
    blickBitesApiUrl,
    urlHashParam,
    aureusQueryName,
    aureusQuerySection,
    aureusResultsLimit,
  },
} = config

const isInServerBundle = typeof window === 'undefined'

const fetchBlickBitesRecommendations: FetchBlickBitesRecommendations = ({
  before,
  blackListedIds = [],
  deboostedIds = [],
} = {}) => {
  const query = {
    // polaris-bites
    [aureusQueryName]: {
      resultsLimit: aureusResultsLimit,
      pinningOffset: before ? before * aureusResultsLimit : undefined,
      context: {
        section: aureusQuerySection, // polaris-bites-stg
        contentBlacklist: blackListedIds?.join(','),
        currentSession: {
          deboosted: deboostedIds,
        },
      },
    },
  }

  return getAureusBatchRecommendations<BlickBitesItem>(query)
}

const fetchSharedBlickBite: FetchSharedBlickBite = (id) =>
  fetch(
    `${blickBitesApiUrl}?id=${decodeURIComponent(id)}&pub=${publication}&size=1`,
    isInServerBundle ? { headers: { 'User-Agent': 'next' } } : {}
  )

const fetchBlickBites: FetchBlickBites = async ({
  id,
  before,
  blackListedIds,
  deboostedIds,
} = {}) => {
  try {
    // load shared blick bite if id is provided and this is a first fetch
    const shouldFetchSharedBlickBite = id && !before

    const [sharedBlickBiteResponse, blickBitesRecommendationsResponse] =
      await Promise.allSettled([
        shouldFetchSharedBlickBite ? fetchSharedBlickBite(id) : undefined,
        fetchBlickBitesRecommendations({
          blackListedIds: shouldFetchSharedBlickBite ? [id] : blackListedIds,
          deboostedIds,
          before,
        }),
      ])

    const sharedBlickBite =
      sharedBlickBiteResponse.status === 'fulfilled' &&
      sharedBlickBiteResponse.value
        ? (await sharedBlickBiteResponse.value.json())?.content[0]
        : null

    const blickBitesData =
      (blickBitesRecommendationsResponse.status === 'fulfilled' &&
        blickBitesRecommendationsResponse.value.results[aureusQueryName]
          ?.recommendations) ||
      []

    return {
      content: sharedBlickBite
        ? [sharedBlickBite, ...blickBitesData]
        : blickBitesData,
      previousCursor: before ? before + 1 : 1,
    } as BlickBitesData
  } catch (err) {
    console.error('Error fetching Blick Bites', err)

    return {
      content: [],
      previousCursor: undefined,
    }
  }
}

const getBiteIdFromHash = (path: string): string => {
  if (path.includes('#')) {
    const [, hash] = path.split('#')
    const params = new URLSearchParams(hash)
    return params.get(urlHashParam) ?? ''
  }

  return ''
}

const hashWithoutBiteId = (hash: string): string => {
  const params = new URLSearchParams(hash)
  params.delete(urlHashParam)
  return params.toString()
}

const hashWithBiteId = (hash: string, bid: string): string => {
  const cleanedUpHash = hashWithoutBiteId(hash)
  const params = new URLSearchParams(cleanedUpHash)
  params.set(urlHashParam, bid)
  return params.toString()
}

const pathWithBiteId = (path: string, bid: string): string => {
  if (path.includes('#')) {
    const [beforeHash, hash] = path.split('#')
    return `${beforeHash}#${hashWithBiteId(hash, bid)}`
  } else {
    return `${path}#${hashWithBiteId('', bid)}`
  }
}

const pathWithoutBiteId = (path: string): string => {
  if (path.includes('#')) {
    const [beforeHash, hash] = path.split('#')
    const cleanedUpHash = hashWithoutBiteId(hash)
    return `${beforeHash}${cleanedUpHash ? `#${cleanedUpHash}` : cleanedUpHash}`
  } else {
    return path
  }
}

const transformBites = (
  data: InfiniteData<BlickBitesData | undefined, unknown>,
  adData: BlickBitesAd
): (BlickBitesAd | BlickBitesItem)[] => {
  const numberOfBitesBeforeAd = 5
  const parsedBlickBites =
    data?.pages.flatMap((page) => page?.content || []) ??
    ([] as BlickBitesItem[])

  const parsedBlickBitesWithAds = parsedBlickBites.flatMap((item, index) => {
    return (index + 1) % numberOfBitesBeforeAd === 0 ? [item, adData] : [item]
  }) as (BlickBitesAd | BlickBitesItem)[]

  return parsedBlickBitesWithAds
}

const isBlickBitesAd = (
  item: BlickBitesItem | BlickBitesAd
): item is BlickBitesAd => isValidAd(item)

export {
  fetchBlickBites,
  getBiteIdFromHash,
  pathWithBiteId,
  pathWithoutBiteId,
  isBlickBitesAd,
  transformBites,
}
