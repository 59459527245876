import { FunctionComponent, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { type Image } from '@utils/cook/types'
import { desktopCSS, layout } from '@measures/responsive'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'

import SportsPartner from '@components/SportsPartner'
import FollowYourEntity from '@components/EntityHeader/FollowYourEntity'
import EntityImage from '@components/EntityHeader/Image'
import BackgroundImage from '@components/EntityHeader/BackgroundImage'
import Title from '@components/EntityHeader/Title'
import Subtitle from '@components/EntityHeader/Subtitle'
import CollapsibleEntityHeader from '@components/EntityHeader/CollapsibleEntityHeader'
import { TabsWrapper } from './LayoutTabs'

const {
  header: {
    mobileAndTablet: { offsetFirstRow: headerOffsetFirstRowMobileAndTablet },
  },
} = layout

export interface EntityHeaderAPIProps {
  title: string
  isFollowingEnabled: boolean
  subtitle?: string
  sportradarId?: string
  backgroundImage: Image<'16_9' | '1_1'>
  image: Image<'free'>
  partner?: {
    name: string
    logo: Image<'free'>
    title: string
    url: string
  }
}

const EntityHeaderWrapper = styled.div`
  ${({
    theme: {
      measures: { stretchWidthOnMobile },
      spacing: { spacing20 },
    },
  }) => css`
    position: relative;
    overflow: hidden;

    background: linear-gradient(
      180deg,
      rgba(18, 16, 16, 0.64) 0%,
      rgba(18, 16, 16, 0) 34.06%
    );

    ${desktopCSS(css`
      height: 183px;
    `)}

    ${stretchWidthOnMobile()};

    + ${TabsWrapper} {
      margin-top: -${spacing20};
    }
  `}
`

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;

  padding-top: ${headerOffsetFirstRowMobileAndTablet}px;

  ${desktopCSS(css`
    padding-top: 0;
    align-items: center;
  `)}
`

const GridWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing24 },
    },
  }) => css`
    padding: ${spacing24};

    display: grid;
    grid-auto-rows: min-content;
    row-gap: ${spacing8};

    ${desktopCSS(css`
      padding: 0 ${spacing24};
      column-gap: ${spacing24};
      grid-template-columns: auto minmax(0, 1fr);
      align-items: center;
    `)}
  `}
`

const ImageWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    display: flex;
    gap: ${spacing12};
    justify-content: center;

    ${desktopCSS(css`
      grid-row: span 2;
    `)}
  `}
`

const TitleWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    display: grid;

    ${desktopCSS(css`
      grid-template-columns: auto auto minmax(0, 1fr);
      align-items: center;

      & > *:not(:first-child) {
        margin-left: ${spacing24};
      }
    `)}
  `}
`

const StyledSportsPartner = styled(SportsPartner)`
  border: 0 none;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(14px);

  ${desktopCSS(css`
    position: absolute;
    bottom: 0;
    right: 0;

    border-top-left-radius: 16px;
    border-top-width: 1px;
    width: auto;
    min-width: 300px;
  `)}
`

export const FollowYourEntityMobilePlaceholder = styled.div`
  width: 24px;

  ${desktopCSS(css`
    display: none;
  `)}
`

const EntityHeader: FunctionComponent<EntityHeaderAPIProps> = (props) => {
  const {
    title,
    subtitle,
    backgroundImage,
    image,
    partner,
    isFollowingEnabled,
  } = props

  const [
    shouldShowCollapsibleEntityHeader,
    setShouldShowCollapsibleEntityHeader,
  ] = useState(false)

  const onEntityHeaderInViewChangeHandler = useCallback(
    (inView: boolean) => {
      setShouldShowCollapsibleEntityHeader(!inView)
    },
    [setShouldShowCollapsibleEntityHeader]
  )

  const { ref } = useInView({
    rootMargin: '-118px 0px 0px 0px',
    onChange: onEntityHeaderInViewChangeHandler,
  })

  return (
    <>
      <CollapsibleEntityHeader
        backgroundImage={backgroundImage}
        image={image}
        isFollowingEnabled={isFollowingEnabled}
        shouldShowCollapsibleEntityHeader={shouldShowCollapsibleEntityHeader}
      />
      <EntityHeaderWrapper ref={ref}>
        <BackgroundImage image={backgroundImage}>
          <ContentWrapper>
            <GridWrapper>
              <ImageWrapper>
                {isFollowingEnabled && <FollowYourEntityMobilePlaceholder />}
                <EntityImage image={image} />
                {isFollowingEnabled && <FollowYourEntity variant="mobile" />}
              </ImageWrapper>
              <TitleWrapper>
                <Title>{title}</Title>
                {isFollowingEnabled && <FollowYourEntity variant="desktop" />}
              </TitleWrapper>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </GridWrapper>
            <StyledSportsPartner partner={partner} />
          </ContentWrapper>
        </BackgroundImage>
      </EntityHeaderWrapper>
    </>
  )
}

const widget = {
  kind: ['entity-header'],
  component: EntityHeader,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
