import { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { SubscriptionData } from '@components/AuthManager'

export type SubscriptionStatus =
  | 'subscribed'
  | 'notSubscribed'
  | 'notSpecified' // In case of error on the subscription checker
  | undefined // When the subscription data is not fetched yet

export type UseSubscriptionStatus = () => {
  subscriptionStatus: SubscriptionStatus
  getCurrentSubscriptionStatus: () => SubscriptionStatus
}

type GetSubscriptionStatus = (queryClient: QueryClient) => SubscriptionStatus

const getSubscriptionStatus: GetSubscriptionStatus = (queryClient) =>
  queryClient.getQueryData<SubscriptionData>(['subscription-data'])?.user_status

const useSubscriptionStatus: UseSubscriptionStatus = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const getCurrentSubscriptionStatus = useCallback(
    () => getSubscriptionStatus(queryClient),
    [queryClient]
  )

  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus>(() => getSubscriptionStatus(queryClient))

  const updateSubscriptionStatus = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'subscription-data'
      ) {
        setSubscriptionStatus(getSubscriptionStatus(queryClient))
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateSubscriptionStatus)

    setSubscriptionStatus(getSubscriptionStatus(queryClient))

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, queryClient, updateSubscriptionStatus])

  return useMemo(
    () => ({ subscriptionStatus, getCurrentSubscriptionStatus }),
    [getCurrentSubscriptionStatus, subscriptionStatus]
  )
}

export { getSubscriptionStatus }

export default useSubscriptionStatus
