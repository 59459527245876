import { useChatbotOnboarding } from '@hooks/useChatbot/useChatbotOnboarding'
import useInViewChange from '@hooks/useInViewChange'
import { FunctionComponent, RefObject, useCallback } from 'react'
import styled from 'styled-components'

const StyledOnboardingTrigger = styled.div`
  position: absolute;
  bottom: -21px;
`

const OnboardingFeedbackTrigger: FunctionComponent<{
  feedbackRef: RefObject<HTMLDivElement | null>
}> = ({ feedbackRef }) => {
  const { setTooltipPointingElement } = useChatbotOnboarding()
  const onInViewChangeHandler = useCallback(
    (inView: any) => {
      if (inView && feedbackRef.current) {
        setTooltipPointingElement(feedbackRef.current, 3)
      }
    },
    [setTooltipPointingElement, feedbackRef]
  )
  const viewportRef = useInViewChange({
    delay: 100,
    threshold: 1,
    onInViewChange: onInViewChangeHandler,
  })

  return <StyledOnboardingTrigger ref={viewportRef} />
}

export default OnboardingFeedbackTrigger
