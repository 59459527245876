import {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { Link } from '@utils/cook/types'
import { WidgetKind } from '@utils/registry'
import { TeaserStandardVerticalAPIProps } from '@widgets/TeaserStandardVertical'

import {
  desktopCSS,
  mobileCSS,
  mobileAndTabletCSS,
  tabletCSS,
} from '@measures/responsive'
import useViewportType from '@hooks/useViewport/useViewportType'

import JSONRenderer from '@components/JSONRenderer'
import SectionHeader from '@components/SectionHeader'
import Swiper, { SwiperClass, ArrowsVisibilityType } from '@components/Swiper'
import NavigationArrows from '@components/Swiper/NavigationArrows'
import AdSlide from '@components/Swiper/AdSlide'
import { JSONWidgetType as AdRingierJSONWidgetType } from '@widgets/AdRingier'
import { getSlidesWithAds, isAdSlide } from '@components/Carousel/utils'
import { Logo } from '@utils/cook/types'

export type CarouselItem = TeaserStandardVerticalAPIProps & {
  kind: WidgetKind
}

export type CarouselAd = AdRingierJSONWidgetType

export interface CarouselWidgetAPIProps {
  title: string
  accentColor: string
  items: CarouselItem[]
  link?: Link
  adSlideIndex?: number
  ad?: CarouselAd
  belongsTo: string
  logo?: Logo
  componentId?: string
}

const Separator = styled.div<{ position: 'top' | 'bottom' }>`
  ${({
    theme: {
      colors: { strokeWeak },
      measures: { outerPadding },
    },
    position,
  }) => css`
    display: block;

    width: 100%;
    height: 1px;
    background-color: ${strokeWeak};

    ${position === 'top' &&
    css`
      ${mobileAndTabletCSS(css`
        display: none;
      `)}
    `}

    ${position === 'bottom' &&
    css`
      ${mobileCSS(css`
        width: calc(100% + ${outerPadding.mobile});
      `)}

      ${tabletCSS(css`
        width: calc(100% + ${outerPadding.tablet});
      `)}

      ${desktopCSS(css`
        width: calc(100% + ${outerPadding.desktop});
      `)}
    `}
  `}
`

const CarouselWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    width: 100%;
    height: 100%;

    display: grid;
    grid-row-gap: ${spacing16};
  `}
`

const StyledNavigationArrows = styled(NavigationArrows)<{ itemsCount: number }>`
  ${({ itemsCount }) => css`
    ${itemsCount <= 1 &&
    mobileCSS(css`
      display: none;
    `)};
    ${itemsCount <= 2 &&
    tabletCSS(css`
      display: none;
    `)};
    ${itemsCount <= 3 &&
    desktopCSS(css`
      display: none;
    `)}
  `}
`

const StyledSwiper = styled(Swiper)`
  ${({
    theme: {
      measures: { outerPadding },
    },
  }) => css`
    ${mobileCSS(css`
      margin-right: -${outerPadding.mobile}; // + stretch to right for mobile
      width: auto;

      > .swiper-items-container > *:last-child {
        margin-right: ${outerPadding.mobile};
        scroll-margin-right: ${outerPadding.mobile};
      }
    `)}
  `}
`

const SwiperSlide = styled.div`
  width: 300px;
`

const Carousel: FunctionComponent<CarouselWidgetAPIProps> = ({
  title,
  accentColor,
  link,
  ad,
  adSlideIndex,
  items,
  logo,
  componentId,
}) => {
  const viewportType = useViewportType()
  const isDesktop = viewportType === 'desktop'

  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>()

  const itemsWithAds = useMemo(
    () =>
      adSlideIndex && ad ? getSlidesWithAds(items, adSlideIndex, ad) : items,
    [items, ad, adSlideIndex]
  )

  const onInit = useCallback(
    (swiper: any) => {
      if (swiperRef) {
        swiperRef.current = swiper
      }
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  if (!title || componentId === 'carousel.promotionmitbox') {
    return null
  }

  return (
    <>
      <Separator position="top" />
      <CarouselWrapper>
        <SectionHeader
          title={title}
          accentColor={accentColor}
          link={link}
          logo={logo}
          isSubheader={true}>
          <StyledNavigationArrows
            swiperRef={swiperRef}
            arrowsVisibility={arrowsVisibility}
            itemsCount={itemsWithAds?.length ?? 0}
          />
        </SectionHeader>
        <StyledSwiper
          initialSlide={0}
          spaceTopBottom={0}
          slidesPerGroup={isDesktop ? 3 : 1}
          spaceBetween={isDesktop ? 32 : 16}
          onInit={onInit}
          onArrowsVisibility={onArrowsVisibility}
          slides={itemsWithAds?.map((item, index) =>
            isAdSlide(item) ? (
              <SwiperSlide key={`carousel-slide-${index}`}>
                <AdSlide {...item} containerId={`carousel-slide-${index}`} />
              </SwiperSlide>
            ) : (
              <SwiperSlide key={item.articleId}>
                <JSONRenderer>{item}</JSONRenderer>
              </SwiperSlide>
            )
          )}
        />
      </CarouselWrapper>
      <Separator position="bottom" />
    </>
  )
}

const widget = {
  kind: ['carousel'],
  component: Carousel,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
