import styled, { css } from 'styled-components'

import { desktopCSS } from '@measures/responsive'

const Subtitle = styled.div`
  ${({
    theme: {
      colors: { staticWhite },
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: xsmallBodycopyCSS },
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
      utils: { ellipsis },
    },
  }) => css`
    color: ${staticWhite};

    ${xsmallBodycopyCSS};
    text-align: center;

    ${ellipsis('100%', 1)};

    ${desktopCSS(css`
      ${small2BodycopyCSS};
      text-align: left;
    `)}
  `}
`

export default Subtitle
