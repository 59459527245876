import { FunctionComponent, useMemo } from 'react'
import config from '@config'
import SchemaHTMLElement from '@components/SchemaHTMLElement'
import { AuthorForPersonSchema } from '@components/Schema/types'
import OrganizationSchema from '@components/Schema/OrganizationSchema'
import EducationalOccupationalCredentialSchema from '@components/Schema/EducationalOccupationalCredentialSchema'
import { getPreparedAssociations } from '@components/Schema/utils'

const {
  schema: {
    personSchemaUrl,
    organizationId: blickId,
    organizationSchemaUrl,
    educationalOccupationalCredentialSchemaUrl,
  },
  backend: { imageUrl },
} = config

interface PersonSchemaProps extends AuthorForPersonSchema {
  src?: string
  socialMediaLinks?: string[]
}

const PersonSchema: FunctionComponent<PersonSchemaProps> = (props) => {
  const {
    url,
    name,
    src,
    description,
    socialMediaLinks,
    role,
    knowsAbout,
    awards,
    associations,
    education,
  } = props

  const preparedAwards = useMemo(
    () => awards?.map((award) => award.name),
    [awards]
  )

  const preparedAssociationsIds = useMemo(
    () =>
      associations?.map(({ organizationId, organizationName }) => ({
        '@id': `${organizationSchemaUrl}${organizationId || organizationName || ''}`,
      })),
    [associations]
  )
  const preparedAssociations = useMemo(
    () => getPreparedAssociations(associations),
    [associations]
  )

  const preparedCredentials = useMemo(
    () =>
      education?.map((educationItem) => ({
        '@id': `${educationalOccupationalCredentialSchemaUrl}${educationItem.name}`,
      })),
    [education]
  )

  const preparedKnowsAbout = useMemo(
    () => knowsAbout?.map((knowsAboutItem) => knowsAboutItem.topic).join(', '),
    [knowsAbout]
  )

  return (
    <>
      {!!preparedAssociations?.length &&
        preparedAssociations.map((association) => {
          return (
            <OrganizationSchema key={`${association.id}`} {...association} />
          )
        })}
      {!!education?.length &&
        education.map((educationItem) => (
          <EducationalOccupationalCredentialSchema
            key={educationItem.name}
            {...educationItem}
          />
        ))}
      <SchemaHTMLElement
        scriptKey={`person-schema-${url}`}
        schemaObject={{
          '@type': 'Person',
          id: `${personSchemaUrl}${url || ''}`,
          name,
          ...(role ? { jobTitle: role } : {}),
          ...(src ? { image: `${imageUrl}${src}` } : {}),
          ...(description ? { description } : {}),
          ...(preparedKnowsAbout?.length
            ? { knowsAbout: preparedKnowsAbout }
            : {}),
          worksFor: {
            '@id': blickId,
          },
          ...(preparedAssociationsIds?.length
            ? { memberOf: preparedAssociationsIds }
            : {}),
          ...(preparedCredentials?.length
            ? { hasCredential: preparedCredentials }
            : {}),
          ...(preparedAwards?.length ? { award: preparedAwards } : {}),
          ...(socialMediaLinks?.length ? { sameAs: socialMediaLinks } : {}),
        }}
      />
    </>
  )
}

export default PersonSchema
