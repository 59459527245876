import useHasInvertedColors from '@hooks/useHasInvertedColors'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface PromoLabelProps {
  className?: string
  children: ReactNode
}

export interface InvertedColorsStyledProps {
  hasInvertedColors: boolean
}

const StyledPromoLabel = styled.div<InvertedColorsStyledProps>`
  ${({
    theme: {
      spacing: { spacing8 },
      colors: { textWeak, staticNeutral400 },
      typography: {
        catchword: {
          small1: { bundledCSS: small1CatchwordCSS },
        },
      },
    },
    hasInvertedColors,
  }) => css`
    display: inline-block;
    ${small1CatchwordCSS};
    color: ${hasInvertedColors ? staticNeutral400 : textWeak};
    text-transform: uppercase;
    margin-bottom: ${spacing8};
  `}
`

const PromoLabel: FunctionComponent<PromoLabelProps> = ({ children }) => {
  const hasInvertedColors = useHasInvertedColors()

  return (
    <StyledPromoLabel hasInvertedColors={hasInvertedColors}>
      {children}
    </StyledPromoLabel>
  )
}

export default PromoLabel
