import { ConfigFn } from '@config/types'

export type FeatureFlagsEntry = {
  description: string
  values: { name: string | number | boolean; description?: string }[]
  canBeEnabledFromGrowthbook?: boolean
}

/*
 * When setting global window variables to check against in an optimize A/B test,
 * use the windowKey below as the window property. Then set your variantKey with its
 * value(s) and copy it in the array below. The ABTestManager will update, when the
 * Optimize script tries to set the property, the relevant field of ReactQuery.
 */
const config = (({ publication }) =>
  ({
    dashboardPath:
      publication === 'romandie'
        ? '/fr/test_section/dashboard/'
        : '/Test_Section/dashboard/',
    windowKey: 'blick_abTest',
    localStorageUpdateKey: 'localStorageUpdateKey',
    featureFlagsEntries: {
      showPianoRelatedLogs: {
        description: 'Show console logs for Piano. (Used by developers only)',
        values: [{ name: 'show', description: 'Show Logs' }],
      },
      enableCommentButtonPolarisColors: {
        description:
          'Change the commenting button colors to match the Polaris design',
        values: [{ name: 'enable', description: 'Enable Polaris Colors' }],
      },
      pianoDebugMode: {
        description: 'Enable Piano debug mode',
        values: [
          {
            name: 'active',
            description:
              'Enable Piano verbose logging - Note: requires reload of the page. (Used by developers only)',
          },
        ],
      },
      showReactQueryDevtoolsOnProd: {
        description:
          'Show React Query Devtools on production builds of React. (Used by developers only)',
        values: [{ name: 'show', description: 'Show Devtools on Production.' }],
      },
      showThemeSwitcher: {
        description:
          'Show the Theme Switcher (auto | force light | force dark)',
        values: [{ name: 'show', description: 'Show the Theme Switcher.' }],
      },
      forceTheme: {
        description:
          'Force the Theme (force auto | force dark - force light is currently the default)',
        values: [
          {
            name: 'force auto',
            description: 'Force the auto theme.',
          },
          { name: 'force dark', description: 'Force the dark theme.' },
        ],
      },
      showCMPLogs: {
        description: 'Show console logs for CMP. (Used by developers only)',
        values: [{ name: 'show', description: 'Show Logs' }],
      },
      showAdLogs: {
        description: 'Show console logs for Ads. (Used by developers only)',
        values: [{ name: 'show', description: 'Show Logs' }],
      },
      showCUI: {
        description: 'Show the Chatbot UI',
        values: [{ name: 'show', description: 'Show CUI' }],
      },
      showVideoPlayerLogs: {
        description:
          'Show console logs for Video Player. (Used by developers only)',
        values: [{ name: 'show', description: 'Show Logs' }],
      },
      showOneSignalLogs: {
        description:
          'Show console logs for OneSignal. (Used by developers only)',
        values: [{ name: 'show', description: 'Show Logs' }],
      },
      enableGrowthBookDevMode: {
        description: 'Enable GrowthBook DevMode. (Used by developers only)',
        values: [{ name: 'enable', description: 'Enable GrowthBook DevMode' }],
      },
      showTrackingDebugView: {
        description: 'Show Tracking debug view',
        values: [
          {
            name: 'show',
            description:
              'Show Tracking debug view (Reload is needed after activation)',
          },
        ],
      },
      containerPiano1: {
        description:
          'First Piano container: it can be either a widget or a Piano template',
        values: [],
      },
      containerPiano2: {
        description:
          'Second Piano container: it can be either a widget or a Piano template',
        values: [],
      },
      showGeolocation: {
        description:
          'Show an indicator that tells whether the user is in Switzerland or not ( Used to test geoblocked video)',
        values: [{ name: 'show', description: 'Show geolocation' }],
      },
      showMiddleToi: {
        description: 'Show Middle Toi',
        values: [{ name: 'show', description: 'Show Middle Toi' }],
        canBeEnabledFromGrowthbook: true,
      },
    } as const satisfies Record<string, FeatureFlagsEntry>,
  }) as const) satisfies ConfigFn

export default config
