import {
  ArticleSchemaImage,
  FilteredArticleSchemaImage,
  ImageProps,
  TeaserImageProps,
} from '@components/Schema/types'
import {
  BLICK_LOGO_INFO,
  IMAGE_CONFIG,
  SCHEMAORG_IMAGE_RATIOS,
} from '@components/Schema/constants'
import config from '@config'

const {
  backend: { imageUrl },
} = config

const createImage = ({
  url,
  ...restData
}: {
  url: string
  width?: number
  height?: number
  caption?: string
}) => {
  const filteredData = Object.fromEntries(
    Object.entries(restData).filter(([, value]) => value)
  )
  return {
    '@type': 'ImageObject',
    url,
    contentUrl: url,
    ...filteredData,
    '@id': url,
  }
}

const defaultLogo = createImage(BLICK_LOGO_INFO)

const _createCommonParams = ({
  size = '',
  ratio,
  isRetina,
  pixelDensity,
}: {
  size: any
  ratio: Record<string, any>
  isRetina: boolean
  pixelDensity: number
}) => {
  const { name: ratioName = '' } = ratio
  const selectedSize =
    size[isRetina ? 'retina' : 'default'] || (isRetina ? size + 'R' : size)

  // For native apps only
  // Fix for blurry images of high density devices
  // As the Akamai Image Manager only supports integers (no floats)
  // for the imdensity parameter, we have to round here
  if (pixelDensity) {
    return (
      'imwidth=' +
      IMAGE_CONFIG.widths[size] +
      '&imdensity=' +
      Math.round(pixelDensity) +
      '&ratio=' +
      ratioName.toUpperCase()
    )
  }

  // get default or retina width
  const width =
    IMAGE_CONFIG.widths[selectedSize] ||
    IMAGE_CONFIG.widths[selectedSize.slice(0, -1)] * 2

  return 'imwidth=' + width + '&ratio=' + ratioName.toUpperCase()
}

const createResponsiveImageSrcSet = ({
  src,
  crops = {},
  options = {},
  isExternal = false,
}: {
  src: string
  crops: Record<string, any>
  options: Record<string, any>
  isExternal: boolean
}) => {
  if (src) {
    const [defaultImageURL] = createSrcArray({
      src,
      crops,
      options,
      isExternal,
    } as any)

    if (options.asArrayOfURISource) {
      return [
        {
          uri: defaultImageURL,
          scale: 1,
        },
      ]
    }

    return defaultImageURL
  }
}

const getImageProps = ({
  element,
  customRatio,
  isMainImage,
}: ImageProps): ArticleSchemaImage[] => {
  const src = element?.image?.src

  // We want to show only the main image (due to Google AMP rules)
  if (!src) {
    return [{} as ArticleSchemaImage]
  }

  const cSrc = Array.isArray(src) ? src[0] : src
  const alt = element?.image?.alt
  const crops = element?.image?.crops
  const ratios: any = isMainImage
    ? SCHEMAORG_IMAGE_RATIOS.map((ratio) => ratio.name)
    : [customRatio || 'free']

  return ratios.map((ratio: any) => {
    const width = crops?.[ratio]?.width
    const height = crops?.[ratio]?.height
    return {
      url:
        createResponsiveImageSrcSet({
          src: cSrc,
          crops: crops,
          options: {
            ratio: { name: ratio },
            size: IMAGE_CONFIG.sizes.XXL,
          },
        } as any) ?? '',
      alt,
      width,
      height,
    }
  })
}

const _createURL = (
  src: string,
  cropParams: string,
  commonParams: string,
  isExternal: boolean
) => {
  if (isExternal) {
    return src
  }
  const path = `${src}?${commonParams}${cropParams}`

  // Disabling token generation for the sake of performance
  // const tokenParam =
  //   edgeAuth.options.tokenName + '=' + edgeAuth.generateURLToken(path)

  return `${imageUrl}${path}`
}

const createCropParams = ({
  crops,
  ratio,
}: {
  crops: Record<string, any>
  ratio: Record<string, any>
}) => {
  const crop = crops[ratio.name] || crops || {} // free crops can come in from API as crops: { free: {}} or crops: {}
  const { x = 0, y = 0, width, height } = crop

  return width
    ? '&x=' + x + '&y=' + y + '&width=' + width + '&height=' + height
    : ''
}

export const createSrcArray = ({
  src,
  crops = {},
  options = { ratio: {} },
  isExternal,
}: {
  src: string
  crops: Record<string, any>
  options: { ratio: Record<string, any> }
  isExternal: boolean
}) => {
  const cropParams = createCropParams({
    crops,
    ...options,
  })
  const defaultCommonParams = _createCommonParams(options as any)
  const retinaCommonParams = _createCommonParams({
    ...options,
    isRetina: true,
  } as any)

  return [
    _createURL(src, cropParams, defaultCommonParams, isExternal),
    _createURL(src, cropParams, retinaCommonParams, isExternal),
  ]
}

const getTeaserImageProps = ({
  teaserImage,
}: TeaserImageProps): Array<ArticleSchemaImage> => {
  const { src, alt, crops } = teaserImage

  return (SCHEMAORG_IMAGE_RATIOS as any).map((ratio: any) => {
    const crop = crops?.[ratio.name] || crops || {} // free crops can come in from API as crops: { free: {}} or crops: {}
    let { width, height } = crop

    // If width is > then 2000 -> downscale the width to 2000 and calculate the height accordingly
    if (width && height) {
      if (width > IMAGE_CONFIG.widths.XXL) {
        height = Math.round(height / (width / IMAGE_CONFIG.widths.XXL))
        width = IMAGE_CONFIG.widths.XXL
      }
    }

    return {
      url:
        createResponsiveImageSrcSet({
          src: src,
          crops: crops,
          options: {
            ratio: { name: ratio.name },
            size: IMAGE_CONFIG.sizes.XXL,
          },
        } as any) ?? '',
      alt,
      width,
      height,
    }
  })
}

const getPreparedImages = (teaserImage?: TeaserImageProps['teaserImage']) => {
  const imageSet = teaserImage
    ? getTeaserImageProps({
        teaserImage,
      })
    : []

  return imageSet
    .filter((image) => !!image.url)
    .map((image) => ({
      ...image,
      caption: image.alt,
      url: image.url || '',
    }))
}

const getPrimaryImageSchema = ({
  teaserImage,
  isArticle,
}: {
  teaserImage?: TeaserImageProps['teaserImage']
  isArticle?: boolean
}) => {
  if (!isArticle || !teaserImage) {
    return defaultLogo
  }
  const { src, crops } = teaserImage || {}
  const crop = crops?.['16_9'] || crops || {}
  let { width, height } = crop

  if (width && height) {
    if (width > IMAGE_CONFIG.widths.XXL) {
      height = Math.round(height / (width / IMAGE_CONFIG.widths.XXL))
      width = IMAGE_CONFIG.widths.XXL
    }
  }

  const url =
    (createResponsiveImageSrcSet({
      src: src,
      crops: crops,
      options: {
        ratio: { name: '16_9' },
        size: IMAGE_CONFIG.sizes.XXL,
      },
    } as any) as string) || ''

  return createImage({ url, width, height })
}

const getImageSet = (image?: TeaserImageProps['teaserImage']) => {
  if (image) {
    const imageSet = getTeaserImageProps({
      teaserImage: image,
    })

    const filteredImageSet = imageSet.filter(
      ({ url }) => !!url
    ) as FilteredArticleSchemaImage[]

    return filteredImageSet.map((image) =>
      createImage({
        url: image.url,
        width: image.width,
        height: image.height,
        caption: image.alt,
      })
    )
  }
  return defaultLogo
}

export {
  createCropParams,
  createImage,
  defaultLogo,
  getImageProps,
  getTeaserImageProps,
  getPreparedImages,
  getPrimaryImageSchema,
  getImageSet,
}
