import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import config from '@config'
import { printCSS } from '@utils/style'

const { mailtoAddress } = config.feedback

interface Props {
  articleUrl: string
  articleTitle: string
}

const StyledText = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: xsmallHeadingCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    ${xsmallHeadingCSS};

    color: ${primary01};
    ${printCSS(css`
      display: none;
    `)}
  `}
`

const StyledLink = styled.span`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
    },
  }) => css`
    color: ${blickRed};
    text-decoration: underline;
    appearance: none;

    &:hover {
      cursor: pointer;
    }
  `}
`

const Feedback: FunctionComponent<Props> = ({ articleUrl, articleTitle }) => {
  const handleClick = useCallback(() => {
    const mailtoSubject = encodeURIComponent(
      translate('feedback.mailtoSubject', {
        articleTitle,
      })
    )
    const mailtoBody = encodeURIComponent(
      translate('feedback.mailtoBody', {
        articleUrl,
      })
    )

    const mailto = `mailto:${mailtoAddress}?subject=${mailtoSubject}&body=${mailtoBody}`

    window.open(mailto, '_self')
  }, [articleTitle, articleUrl])

  return (
    <StyledText>
      {translate('feedback.foundMistake')}{' '}
      <StyledLink onClick={handleClick}>
        {translate('feedback.reportNow')}
      </StyledLink>
    </StyledText>
  )
}

export default Feedback
