import config from '@config'
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { getLocalStorageItem, setLocalStorageItem } from '@utils/localStorage'
import styled, { css, useTheme } from 'styled-components'
import SvgIcon from '@components/SvgIcon'
import useTracking from '@hooks/useTracking'
import JSONRenderer from '@components/JSONRenderer'
import { AnswerFeedbackProps } from '../types'
import { getAnswerText, getEntryPoint, trackHandler } from '../utils'
import translate from '@i18n'
import { useChatbotOnboardingInProcess } from '@hooks/useChatbot/useChatbotOnboarding'
import OnboardingFeedbackTrigger from './OnboardingFeedbackTrigger'
import useUser from '@hooks/useUser'

const {
  usersnap: {
    cuiAnswerWidgetKey,
    rateLimitLocalStorageKey,
    rateLimitPerMinute,
  },
} = config

const StyledAnswerFeedback = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02 },
        tertiary: { grey400 },
      },
      spacing: { spacing24 },
    },
  }) => css`
    margin-top: ${spacing24};
    padding-top: ${spacing24};
    border-top: 1px solid ${grey400};
    position: relative;
    background-color: ${primary02};
  `}
`

const StyledAnswerFeedbackQuestionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

const StyledRatingQuestion = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: bodyCopyXXSmallCSS },
        },
      },
      spacing: { spacing24 },
      color: {
        tertiary: { grey800 },
      },
    },
  }) => css`
    color: ${grey800};
    margin-right: ${spacing24};
    ${bodyCopyXXSmallCSS}
  `}
`

const StyledIconsWrapper = styled.div<{
  isRatingEnabled: boolean
}>`
  ${({
    isRatingEnabled,
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    display: flex;
    gap: ${spacing24};
    opacity: 1;
    overflow: hidden;
    min-width: 160px;

    ${isRatingEnabled &&
    css`
      animation: fadeIn 0.5s ease-in-out forwards;
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `}
  `}
`

const StyledRatingButton = styled.button<{
  isRatingSelected: boolean
}>`
  ${({
    isRatingSelected,
    theme: {
      color: {
        primary: { blickRed },
        tertiary: { grey200, grey400, grey500, grey800 },
      },
      spacing: { spacing8 },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: bodyCopyXXSmallCSS },
        },
      },
    },
  }) => css`
    display: flex;
    align-items: center;
    gap: ${spacing8};
    cursor: pointer;
    border: none;
    padding: 0;
    height: 40px;
    background-color: transparent;
    color: ${grey800};
    ${bodyCopyXXSmallCSS}

    &:disabled {
      cursor: not-allowed;
      color: ${isRatingSelected ? grey800 : grey500};
    }
    &:disabled > div {
      background-color: ${isRatingSelected ? blickRed : grey200};
    }
    @media (hover: hover) {
      &:hover:not([disabled]) > div {
        background-color: ${isRatingSelected ? blickRed : grey400};
      }
    }
  `}
`

const StyledButtonIconWrapper = styled.div<{
  isRatingSelected: boolean
}>`
  ${({
    isRatingSelected,
    theme: {
      color: {
        primary: { blickRed },
        tertiary: { grey300 },
      },
    },
  }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${isRatingSelected ? blickRed : grey300};
  `}
`

const StyledFeedbackWrapper = styled.div<{ isRatingVisible: boolean }>`
  ${({
    isRatingVisible,
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing16};
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    ${isRatingVisible &&
    css`
      height: auto;
      animation: fadeIn 1s ease-in-out forwards;
      @keyframes fadeIn {
        100% {
          opacity: 1;
        }
      }
    `};
  `}
`

const StyledFeedbackDivider = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    padding-top: ${spacing24};
  `}
`

const UsersnapRating = {
  Positive: 1,
  Neutral: 0,
  Negative: -1,
} as const

type UsersnapRating = (typeof UsersnapRating)[keyof typeof UsersnapRating]

const getMinuteTimestamp = () => {
  const now = new Date()
  now.setSeconds(0, 0) // Set seconds and milliseconds to zero
  return now.getTime()
}

const getFeedbackCount = (): number => {
  const timestampedCount =
    getLocalStorageItem<string>(`usersnapFeedbackCount`)?.toString() ?? ''
  const timestampCount = timestampedCount.split('.')

  // If the timestamp is not within ttl minute, reset the count
  if (
    timestampCount.length !== 2 ||
    parseInt(timestampCount[0], 10) !== getMinuteTimestamp()
  ) {
    setFeedbackCount(0)
    return 0
  }

  const number = parseInt(timestampCount[1], 10)
  return Math.max(Math.min(isNaN(number) ? 0 : number, rateLimitPerMinute), 0)
}

const setFeedbackCount = (count: number) => {
  setLocalStorageItem(
    rateLimitLocalStorageKey,
    `${getMinuteTimestamp()}.${count}`
  )
}

const AnswerFeedback: FunctionComponent<AnswerFeedbackProps> = ({
  message,
}) => {
  const { content, conversationId, traceId, questionData } = message
  const [feedbackGiven, setFeedbackGiven] = useState<boolean>(false)
  const [isRatingEnabled, setIsRatingEnabled] = useState<boolean>(true)
  const [isFeedbackLimitReached, setIsFeedbackLimitReached] =
    useState<boolean>(false)
  const theme = useTheme()
  const handleTracking = useTracking(trackHandler)
  const answer = useMemo(() => getAnswerText(content), [content])
  const feedbackRef = useRef<HTMLDivElement>(null)
  const onboardingInProcess = useChatbotOnboardingInProcess()

  useEffect(() => {
    setIsFeedbackLimitReached(getFeedbackCount() === rateLimitPerMinute)
  }, [])

  const [rating, setRating] = useState<UsersnapRating>(UsersnapRating.Neutral)
  const user = useUser()

  const ratingCallback = useMemo(
    () => (rating: UsersnapRating) => () => {
      const nextCount = getFeedbackCount() + 1

      handleTracking({
        event: 'chatbot_answer_feedback',
        messageId: traceId,
        conversationId,
        isCached: questionData?.isCached,
        isSuggestedQuestion: questionData?.isSuggestedQuestion,
        rating: rating === UsersnapRating.Positive ? 'positive' : 'negative',
      })

      setRating(rating)
      setFeedbackGiven(false)
      setFeedbackCount(nextCount)
      setIsRatingEnabled(false)
    },
    [
      setRating,
      setFeedbackGiven,
      conversationId,
      handleTracking,
      questionData,
      traceId,
    ]
  )

  const ratingIconNotSelectedColor = useMemo(
    () =>
      rating === UsersnapRating.Neutral
        ? theme.color.primary.primary01
        : theme.color.tertiary.grey700,
    [rating, theme.color.primary.primary01, theme.color.tertiary.grey700]
  )

  const isPositiveRatingSelected = useMemo(
    () => rating === UsersnapRating.Positive,
    [rating]
  )

  const isNegativeRatingSelected = useMemo(
    () => rating === UsersnapRating.Negative,
    [rating]
  )

  const textRatingsData = useMemo(
    () => ({
      kind: ['widget', 'tool', 'usersnap'],
      id: cuiAnswerWidgetKey,
      custom: {
        rating: rating === UsersnapRating.Positive ? 'positive' : 'negative',
        ...(questionData?.text && { question: questionData?.text }),
        ...(answer && { answer }),
        ...(traceId && { messageId: traceId }),
        ...(conversationId && { conversationId }),
        ...(questionData?.context && { context: questionData?.context }),
        entryPoint: getEntryPoint(
          questionData?.isSuggestedQuestion,
          questionData?.isCached
        ),
        time: new Date().getTime(),
        userType: user?.email.endsWith('@ringier.ch') ? 'internal' : 'external',
      } satisfies Record<string, string | number | boolean>,
    }),
    [rating, questionData, answer, traceId, conversationId, user]
  )

  return (
    <StyledAnswerFeedback ref={feedbackRef}>
      {!isFeedbackLimitReached && (
        <StyledAnswerFeedbackQuestionButtons>
          <StyledRatingQuestion>
            {translate('chatbot.answerFeedback.text')}
          </StyledRatingQuestion>
          <StyledIconsWrapper isRatingEnabled={isRatingEnabled}>
            <StyledRatingButton
              disabled={!isRatingEnabled}
              onClick={ratingCallback(UsersnapRating.Positive)}
              isRatingSelected={isPositiveRatingSelected}>
              <StyledButtonIconWrapper
                isRatingSelected={isPositiveRatingSelected}>
                <SvgIcon
                  iconName="thumbs-up"
                  color={
                    isPositiveRatingSelected
                      ? theme.color.primary.primary02
                      : ratingIconNotSelectedColor
                  }
                  size={20}
                />
              </StyledButtonIconWrapper>
              {translate('yes')}
            </StyledRatingButton>
            <StyledRatingButton
              disabled={!isRatingEnabled}
              onClick={ratingCallback(UsersnapRating.Negative)}
              isRatingSelected={isNegativeRatingSelected}>
              <StyledButtonIconWrapper
                isRatingSelected={isNegativeRatingSelected}>
                <SvgIcon
                  iconName="thumbs-down"
                  color={
                    isNegativeRatingSelected
                      ? theme.color.primary.primary02
                      : ratingIconNotSelectedColor
                  }
                  size={20}
                />
              </StyledButtonIconWrapper>
              {translate('no')}
            </StyledRatingButton>
          </StyledIconsWrapper>
        </StyledAnswerFeedbackQuestionButtons>
      )}
      <StyledFeedbackWrapper
        isRatingVisible={rating !== UsersnapRating.Neutral && !feedbackGiven}>
        <StyledFeedbackDivider />
        <JSONRenderer>{textRatingsData}</JSONRenderer>
      </StyledFeedbackWrapper>
      {onboardingInProcess && (
        <OnboardingFeedbackTrigger feedbackRef={feedbackRef} />
      )}
    </StyledAnswerFeedback>
  )
}

export default AnswerFeedback
