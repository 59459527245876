import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { StartInfoProps } from '@widgets/StartInfo'
import useSubscribeToSportsEventCache from '@hooks/useSubscribeToSportsEventCache'
import { formatDateByPublication } from './utils'
import { desktopCSS } from '@measures/responsive'

type StartInfoComponentProps = Omit<StartInfoProps, 'typeOfSport'> & {
  typeOfSport: 'soccer'
}

const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing16};
  `}
`

const StyledTitle = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingCSS },
        },
      },
      colors: { strokeSport },
      spacing: { spacing2, spacing8 },
    },
  }) => css`
    ${smallHeadingCSS};
    text-transform: uppercase;
    border-left: ${spacing2} solid ${strokeSport};
    padding-left: ${spacing8};
  `}
`

const StyledContentWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing4, spacing16 },
      colors: { strokeWeak },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing4};
    border-bottom: 1px solid ${strokeWeak};
    padding-bottom: ${spacing16};
  `}
`
const StyledDay = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          xsmall2: { bundledCSS: xsmall2SubheadCSS },
        },
      },
      colors: { textWeak },
    },
  }) => css`
    ${xsmall2SubheadCSS};
    color: ${textWeak};
    text-transform: capitalize;
  `}
`

const StyledDate = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadCSS },
          large1: { bundledCSS: large1HeadingCSS },
        },
      },
      colors: { textDefault },
    },
  }) => css`
    ${small1SubheadCSS};
    ${desktopCSS(css`
      ${large1HeadingCSS};
    `)};
    color: ${textDefault};
  `}
`

const StartInfoComponent: FunctionComponent<StartInfoComponentProps> = ({
  title,
  typeOfSport,
  matchId,
}) => {
  const existingSportsEventData = useSubscribeToSportsEventCache({
    matchId,
    typeOfSport,
  })

  if (!existingSportsEventData?.formattedData.startDate) {
    return null
  }

  const { dayOfWeek, date } = formatDateByPublication(
    existingSportsEventData.formattedData.startDate
  )

  return (
    <Wrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledContentWrapper>
        <StyledDay>{dayOfWeek}</StyledDay>
        <StyledDate>{date}</StyledDate>
      </StyledContentWrapper>
    </Wrapper>
  )
}

export default StartInfoComponent
