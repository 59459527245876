import {
  EnrichedLiveEventTransformed,
  EnrichedLiveEventTransformedFormula1,
  EnrichedLiveEventTransformedHockey,
  EnrichedLiveEventTransformedSki,
  EnrichedLiveEventTransformedSoccer,
  EnrichedLiveEventTransformedTennis,
} from '@utils/sport'

import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { LiveEventTransformedFormula1 } from '@utils/formatters/formula1'
import { LiveEventTransformedHockey } from '@utils/formatters/hockey'
import { LiveEventTransformedSki } from '@utils/formatters/ski'
import { LiveEventTransformedSoccer } from '@utils/formatters/soccer'
import { LiveEventTransformedTennis } from '@utils/formatters/tennis'

export type TransformedDataSoccer = {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
  homeTeam: LiveEventTransformedSoccer['teams'][number]
  homeTeamScore: LiveEventTransformedSoccer['teams'][number]['score']
  awayTeam: LiveEventTransformedSoccer['teams'][number]
  awayTeamScore: LiveEventTransformedSoccer['teams'][number]['score']
  isLive: LiveEventTransformedSoccer['isLive']
  statusTitle: LiveEventTransformedSoccer['statusTitle']
  statusTitleRelatedMatches: LiveEventTransformedSoccer['statusTitleRelatedMatches']
  scoreExplanation: LiveEventTransformedSoccer['scoreExplanation']
  hasEnded: boolean
  isScoreAvailable: boolean
  tournamentName: LiveEventTransformedSoccer['tournamentName']
}

export type TransformedDataHockey = {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
  homeTeam: LiveEventTransformedHockey['teams'][number]
  homeTeamScore: LiveEventTransformedHockey['teams'][number]['score']
  awayTeam: LiveEventTransformedHockey['teams'][number]
  awayTeamScore: LiveEventTransformedHockey['teams'][number]['score']
  isLive: LiveEventTransformedHockey['isLive']
  statusTitle: LiveEventTransformedHockey['statusTitle']
  statusTitleRelatedMatches: LiveEventTransformedSoccer['statusTitleRelatedMatches']
  scoreExplanation: LiveEventTransformedHockey['scoreExplanation']
  hasEnded: boolean
  isScoreAvailable: boolean
  tournamentName: LiveEventTransformedHockey['tournamentName']
}

export type TransformedDataTennis = {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.TENNIS
  hasStarted: boolean
  isCancelled: boolean
  homePlayer: LiveEventTransformedTennis['competitors'][number]
  awayPlayer: LiveEventTransformedTennis['competitors'][number]
  statusTitle: LiveEventTransformedTennis['statusTitle']
  isLive: boolean
  hasEnded: boolean
  periodScores: LiveEventTransformedTennis['periodScores']
  gameState: LiveEventTransformedTennis['gameState']
}

export type TransformedDataFormula1 = {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1
  hasStarted: boolean
  competitors: LiveEventTransformedFormula1['competitors']
  raceName: LiveEventTransformedFormula1['raceGPName']
  isCancelled: LiveEventTransformedFormula1['isCancelled']
  statusTitle: LiveEventTransformedFormula1['statusTitle']
  hasEnded: boolean
  statusInfo: LiveEventTransformedFormula1['statusInfo']
  raceType: LiveEventTransformedFormula1['raceTypeName']
  isLive: LiveEventTransformedFormula1['isLive']
}

export type TransformedDataSki = {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.SKI
  hasEnded: boolean
  hasStarted: boolean
  competitors: LiveEventTransformedSki['competitors']
  raceName: LiveEventTransformedSki['raceTypeName']
  isCancelled: LiveEventTransformedSki['isCancelled']
  isLive: LiveEventTransformedSki['isLive']
  statusTitle: LiveEventTransformedSki['statusTitle']
  isBetweenRuns: LiveEventTransformedSki['isBetweenRuns']
  statusInfo: LiveEventTransformedSki['statusInfo']
  raceLocation: LiveEventTransformedSki['raceGPName']
}

export type TransformedData =
  | TransformedDataSoccer
  | TransformedDataHockey
  | TransformedDataTennis
  | TransformedDataFormula1
  | TransformedDataSki

export type TransformDataForSportsComponents = <
  T extends EnrichedLiveEventTransformed,
>(
  data: T
) => T extends EnrichedLiveEventTransformedSoccer
  ? TransformedDataSoccer | undefined
  : T extends EnrichedLiveEventTransformedTennis
    ? TransformedDataTennis | undefined
    : T extends EnrichedLiveEventTransformedHockey
      ? TransformedDataHockey | undefined
      : T extends EnrichedLiveEventTransformedFormula1
        ? TransformedDataFormula1
        : T extends EnrichedLiveEventTransformedSki
          ? TransformedDataSki
          : TransformedData | undefined

//@ts-expect-error
const transformDataForSportsComponents: TransformDataForSportsComponents = (
  data
) => {
  switch (data.sportsEventType) {
    case LIVE_EVENT_DATA_SPORT_TYPES.SOCCER: {
      const {
        hasPenalties,
        isLive,
        statusTitle,
        statusTitleRelatedMatches,
        teams,
        isScheduled,
        scoreExplanation,
        tournamentName,
      } = data.formattedData

      const [homeTeam, awayTeam] = teams && teams.length ? teams : [null, null]

      const isScoreAvailable = !isScheduled
      const hasEnded = !isLive && !isScheduled

      if (!homeTeam || !awayTeam) {
        return
      }

      const awayTeamScore = hasPenalties
        ? awayTeam.scorePenalties
        : awayTeam.scoreBeforePenalties
      const homeTeamScore = hasPenalties
        ? homeTeam.scorePenalties
        : homeTeam.scoreBeforePenalties

      return {
        typeOfSport: data.sportsEventType,
        homeTeam,
        homeTeamScore,
        awayTeam,
        awayTeamScore,
        isLive,
        hasEnded,
        isScoreAvailable,
        statusTitle,
        statusTitleRelatedMatches,
        scoreExplanation,
        tournamentName,
      }
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY: {
      const {
        isLive,
        statusTitle,
        statusTitleRelatedMatches,
        teams,
        isScheduled,
        scoreExplanation,
        tournamentName,
      } = data.formattedData

      const [homeTeam, awayTeam] = teams && teams.length ? teams : [null, null]

      const isScoreAvailable = !isScheduled
      const hasEnded = !isLive && !isScheduled

      if (!homeTeam || !awayTeam) {
        return
      }

      const awayTeamScore = awayTeam.score
      const homeTeamScore = homeTeam.score

      return {
        typeOfSport: data.sportsEventType,
        homeTeam,
        homeTeamScore,
        awayTeam,
        awayTeamScore,
        isLive,
        hasEnded,
        isScoreAvailable,
        statusTitle,
        statusTitleRelatedMatches,
        scoreExplanation,
        tournamentName,
      }
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.TENNIS: {
      const {
        isScheduled,
        isCancelled,
        isLive,
        statusTitle,
        competitors,
        periodScores,
        gameState,
      } = data.formattedData

      const [homePlayer, awayPlayer] =
        competitors && competitors.length ? competitors : [null, null]

      const hasEnded = !isScheduled && !isLive
      const hasStarted = !isScheduled

      if (!homePlayer || !awayPlayer) {
        return
      }

      return {
        typeOfSport: data.sportsEventType,
        hasStarted,
        isCancelled,
        homePlayer,
        awayPlayer,
        statusTitle,
        isLive,
        hasEnded,
        periodScores,
        gameState,
      }
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1: {
      const {
        willStart,
        isCancelled,
        isLive,
        raceTypeName: raceType,
        raceGPName: raceName,
        statusTitle,
        statusInfo,
        competitors,
      } = data.formattedData

      const hasEnded = !willStart && !isLive
      const hasStarted = !willStart

      return {
        typeOfSport: data.sportsEventType,
        hasStarted,
        competitors,
        raceName,
        isCancelled,
        statusTitle,
        hasEnded,
        statusInfo,
        raceType,
        isLive,
      }
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.SKI: {
      const {
        isPending,
        isBetweenRuns,
        isCancelled,
        isLive,
        raceTypeName: raceName,
        raceGPName: raceLocation,
        statusTitle,
        statusInfo,
        competitors,
      } = data.formattedData

      const hasEnded = !isPending && !isLive
      const hasStarted = !isPending

      return {
        typeOfSport: data.sportsEventType,
        hasEnded,
        hasStarted,
        competitors,
        raceName,
        isCancelled,
        isLive,
        statusTitle,
        isBetweenRuns,
        statusInfo,
        raceLocation,
      }
    }

    default: {
      return
    }
  }
}

export { transformDataForSportsComponents }
