import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import { StadiumInfoProps } from './types'
import StadiumInfoComponent from '@components/StadiumInfo'
import { validMatchId } from '@hooks/useSportsEvent'

const StadiumInfo: FunctionComponent<StadiumInfoProps> = ({
  title,
  typeOfSport,
  matchId,
}) => {
  if (!validMatchId(matchId)) {
    return null
  }
  if (typeOfSport !== 'soccer') {
    return null
  }

  return (
    <StadiumInfoComponent
      title={title}
      typeOfSport={typeOfSport}
      matchId={matchId}
    />
  )
}

const widget = {
  kind: ['stadium-info'],
  component: StadiumInfo,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
