import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface SponsoredLabelProps {
  children: ReactNode
  className?: string
}

const StyledSponsoredLabel = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
      colors: { staticWhite },
      typography: {
        catchword: {
          small1: { bundledCSS: small1CatchwordCSS },
        },
      },
    },
  }) => css`
    display: inline-flex;
    column-gap: ${spacing8};
    ${small1CatchwordCSS};
    color: ${staticWhite};
    text-transform: uppercase;
  `}
`

const SponsoredLabel: FunctionComponent<SponsoredLabelProps> = ({
  children,
  className,
}) => {
  return (
    <StyledSponsoredLabel className={className}>
      {children}
    </StyledSponsoredLabel>
  )
}

export default SponsoredLabel
