import {
  SkeletonSeedValues,
  SkeletonView,
} from '@components/Summary/SummaryLoadingSkeleton'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { AnswerLoadingProps } from '../types'
import WidgetButton from '../buttons/WidgetButton'
import QuestionTitle from '../messages/QuestionTitle'

const SkeletonLoaderButtonsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing16};
  `}
`

const StyledSkeletonViewWrapper = styled.div`
  width: 221px;
`

const StyledQuestionTitle = styled.div`
  ${({
    theme: {
      spacing: { spacing40 },
    },
  }) => css`
    margin-top: ${spacing40};
  `}
`
const QuestionLoading: FunctionComponent<AnswerLoadingProps> = ({
  title,
  buttonsCount = 3,
}) => {
  return (
    <>
      {title && (
        <StyledQuestionTitle>
          <QuestionTitle title={title} />
        </StyledQuestionTitle>
      )}

      <SkeletonLoaderButtonsWrapper>
        {Array.from(Array(buttonsCount), (_, index) => (
          <WidgetButton key={`question-loading-${index}`}>
            <StyledSkeletonViewWrapper>
              <SkeletonView
                seed={[
                  {
                    type: SkeletonSeedValues.ParagraphNatural,
                    multiplier: 2,
                  },
                ]}
              />
            </StyledSkeletonViewWrapper>
          </WidgetButton>
        ))}
      </SkeletonLoaderButtonsWrapper>
    </>
  )
}

export default QuestionLoading
