import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SharingAndCommentWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  return <Wrapper>{children}</Wrapper>
}

export default SharingAndCommentWrapper
