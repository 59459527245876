import { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import CueLiveContentContext from '@contexts/cueLiveContent'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import {
  InfiniteData,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {
  CueLiveAdPost,
  CueLiveData,
  CueLivePost,
  fetchCueLive,
} from '@utils/cueLive'
import PostsContainer from '@components/CueLive/PostsContainer'
import config from '@config'
import MoreButtonSegment from '@components/CueLive/MoreButtonSegment'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import translate from '@i18n'
import useViewportTracking from '@hooks/useViewportTracking'
import EndIndicator from '@components/CueLive/EndIndicator'
import usePageMetadata from '@hooks/usePageMetadata'
import EmptyTickerIndicator from '@components/CueLive/EmptyTickerIndicator'
import CueLiveWrapper from '@components/CueLive'

interface CueLiveProps {
  cueLiveId: string
  adOffset: number
  adStepSize: number
  accentColor?: string
}

const {
  cueLive: { autoUpdateItemCount, fetchingStep, defaultRefetchInterval },
} = config

const getCombinedPostsPreviousCursor = (
  refreshingData: UseQueryResult<CueLiveData>['data'],
  nonRefreshingData: InfiniteData<CueLiveData, unknown> | undefined
): string =>
  nonRefreshingData
    ? (nonRefreshingData.pages[nonRefreshingData.pages.length - 1]
        .previousCursor ?? '')
    : (refreshingData?.previousCursor ?? '')

const loadMoreItemsText = translate('cueLive.loadMoreEntries')

const endIndicatorText = translate('cueLive.endIndicator')

const emptyTickerText = translate('cueLive.emptyTickerText')

const CueLive: FunctionComponent<CueLiveProps> = ({
  cueLiveId,
  adOffset,
  adStepSize,
  accentColor,
}) => {
  const queryClient = useQueryClient()
  const { livetickerAd } = usePageMetadata()

  const activelyUpdatedItemCount = useRef<number>(10)
  const adPosts = useRef<CueLiveAdPost[]>([])

  const getIsFirstFetch = useCallback(
    () =>
      queryClient.getQueryState(['cue-live', cueLiveId, 'refreshing'])
        ?.dataUpdateCount === 1,
    [cueLiveId, queryClient]
  )

  const getAdInjectedPosts = useCallback<
    (posts: CueLivePost[]) => (CueLivePost | CueLiveAdPost)[]
  >(
    (posts) => {
      if (livetickerAd?.isAdDisabled === true || posts.length <= adOffset) {
        return posts
      }

      let postsWithExistingAds = [...posts, ...adPosts.current].sort(
        (postA, postB) => postB.publishedAt - postA.publishedAt
      )

      if (getIsFirstFetch() && postsWithExistingAds[adOffset].type !== 'ad') {
        if (posts.length > adOffset) {
          const adPostPublishedAt = posts?.[adOffset - 1]?.publishedAt - 100
          if (
            adPosts.current.findIndex(
              (adPost) => adPost.publishedAt === adPostPublishedAt
            ) === -1
          ) {
            adPosts.current.push({
              id: `ad-${adPosts.current.length + 1}`,
              type: 'ad',
              publishedAt: adPostPublishedAt,
            })
          }
        }
      }

      postsWithExistingAds = [...posts, ...adPosts.current].sort(
        (postA, postB) => postB.publishedAt - postA.publishedAt
      )

      let postsWithExistingAdsReversed = [...posts, ...adPosts.current].sort(
        (postA, postB) => postA.publishedAt - postB.publishedAt
      )

      let lastAdIndex =
        postsWithExistingAdsReversed.length -
        1 -
        postsWithExistingAdsReversed.findIndex((post) => post.type === 'ad')

      while (postsWithExistingAds?.[lastAdIndex + adStepSize]) {
        adPosts.current.push({
          id: `ad-${adPosts.current.length + 1}`,
          type: 'ad',
          publishedAt:
            postsWithExistingAds[lastAdIndex + adStepSize].publishedAt - 100,
        })

        postsWithExistingAds = [...posts, ...adPosts.current].sort(
          (postA, postB) => postB.publishedAt - postA.publishedAt
        )

        postsWithExistingAdsReversed = [...posts, ...adPosts.current].sort(
          (postA, postB) => postA.publishedAt - postB.publishedAt
        )

        lastAdIndex =
          postsWithExistingAdsReversed.length -
          1 -
          postsWithExistingAdsReversed.findIndex((post) => post.type === 'ad')
      }

      const finalPosts = [...posts, ...adPosts.current].sort(
        (postA, postB) => postB.publishedAt - postA.publishedAt
      )

      return finalPosts
    },
    [adOffset, adStepSize, getIsFirstFetch, livetickerAd?.isAdDisabled]
  )

  const combinedPostsPreviousCursor = useRef<string>(
    queryClient.getQueryData<CueLiveData>(['cue-live', cueLiveId, 'refreshing'])
      ?.previousCursor ?? ''
  )

  const fetchCueLiveEntries = useCallback(
    () =>
      fetchCueLive({
        cueLiveId,
        ...(activelyUpdatedItemCount.current > 0
          ? { options: { limit: activelyUpdatedItemCount.current } }
          : {}),
      }),
    [cueLiveId]
  )

  const fetchNonRefreshingCueLiveEntries = useCallback(
    () =>
      fetchCueLive({
        cueLiveId,
        options: {
          limit: fetchingStep,
          before: combinedPostsPreviousCursor.current,
        },
      }),
    [cueLiveId]
  )

  const { data: refreshingData, refetch: refetchRefreshingData } = useQuery({
    queryKey: ['cue-live', cueLiveId, 'refreshing'],
    queryFn: () => fetchCueLiveEntries(),
    refetchInterval: (query) =>
      (query.state.data?.refreshRate ?? defaultRefetchInterval) * 1000,
  })

  const { data: nonRefreshingData, fetchNextPage: fetchNextNonRefreshingPage } =
    useInfiniteQuery({
      queryKey: ['cue-live', cueLiveId, 'nonRefreshing'],
      queryFn: () => fetchNonRefreshingCueLiveEntries(),
      //! We do not use page params, but we have to set "getNextPageParam" and "initialPageParam",
      //! otherwise react-query will not fetch the next page.
      getNextPageParam: () => combinedPostsPreviousCursor.current,
      initialPageParam: combinedPostsPreviousCursor.current,
      enabled: false,
    })

  const onLoadMoreItemsClickTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'element_click',
      element: 'cue_live_button',
      button_label: loadMoreItemsText,
    }),
    []
  )

  const onLoadMoreItemsImpressionTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'element_impression',
      element: 'cue_live_button',
    }),
    []
  )

  const trackOnLoadMoreItemsClick = useTracking(onLoadMoreItemsClickTrackingFn)
  const trackOnLoadMoreItemsImpression = useTracking(
    onLoadMoreItemsImpressionTrackingFn
  )

  const viewportRef = useViewportTracking({
    onImpression: trackOnLoadMoreItemsImpression,
    track: true,
  })

  const onLoadMoreItems = useCallback(async () => {
    trackOnLoadMoreItemsClick()
    if (activelyUpdatedItemCount.current >= autoUpdateItemCount) {
      fetchNextNonRefreshingPage()
    } else {
      activelyUpdatedItemCount.current += fetchingStep
      refetchRefreshingData()
    }
  }, [
    fetchNextNonRefreshingPage,
    refetchRefreshingData,
    trackOnLoadMoreItemsClick,
  ])

  useEffect(() => {
    combinedPostsPreviousCursor.current = getCombinedPostsPreviousCursor(
      refreshingData,
      nonRefreshingData
    )
  }, [refreshingData, nonRefreshingData])

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ['cue-live', cueLiveId],
      })
    }
  }, [cueLiveId, queryClient])

  if (!refreshingData) {
    return null
  }

  const showMoreButtonSegment = !!getCombinedPostsPreviousCursor(
    refreshingData,
    nonRefreshingData
  )

  const { entries: refreshingPosts, sticky: refreshingPinnedPosts } =
    refreshingData

  const nonRefreshingPosts =
    nonRefreshingData?.pages.reduce((acc, group) => {
      acc.push(...group.entries)
      return acc
    }, [] as CueLivePost[]) ?? []

  const showEmptyTickerSegment = [
    refreshingPosts,
    refreshingPinnedPosts,
    nonRefreshingPosts,
  ].every((posts) => !posts?.length)

  return (
    <CueLiveWrapper>
      <CueLiveContentContext.Provider value={true}>
        <PostsContainer isPinned={true} accentColor={accentColor}>
          {refreshingPinnedPosts}
        </PostsContainer>
        <PostsContainer accentColor={accentColor}>
          {getAdInjectedPosts([...refreshingPosts, ...nonRefreshingPosts])}
        </PostsContainer>
        {showEmptyTickerSegment && (
          <EmptyTickerIndicator accentColor={accentColor}>
            {emptyTickerText}
          </EmptyTickerIndicator>
        )}
        {!showMoreButtonSegment && (
          <EndIndicator accentColor={accentColor}>
            {endIndicatorText}
          </EndIndicator>
        )}
        {showMoreButtonSegment && (
          <MoreButtonSegment
            accentColor={accentColor}
            onLoadMoreItems={onLoadMoreItems}
            viewportRef={viewportRef}>
            {loadMoreItemsText}
          </MoreButtonSegment>
        )}
      </CueLiveContentContext.Provider>
    </CueLiveWrapper>
  )
}

const widget = {
  kind: ['widget', 'ticker', 'cue-live'],
  component: CueLive,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
