import { QueryClient } from '@tanstack/react-query'
import { CMPCookieCategories } from '@components/CMPManager/types'
import { setCookieValue } from '@utils/cookie'
import config from '@config'
import { calculateSSOTrackingSource } from '@utils/sso'

export type GetLoginUrlOptions = {
  queryClient: QueryClient
  loginCase?: string
  sourcePrefix?: string
  fullSource?: string
  omitPublicationInSource?: boolean
}

export type GetLoginUrl = (options: GetLoginUrlOptions) => string

const {
  tracking: { GACookieName, GA4CookieNameSegment, GA4CookieName },
  auth: {
    domain,
    audience,
    accountPath,
    clientID,
    lang,
    scope,
    state,
    responseType,
    authURL,
    loginPath,
    logoutPath,
    cookieDomain,
    loginCookie,
    logoutCookie,
  },
} = config

const loginStaticParams = {
  audience,
  scope,
  response_type: responseType,
  client_id: clientID,
  redirect_uri: `${authURL}${loginPath}`,
  lang,
  state,
}

const stringifiedLoginStaticParams = (
  Object.keys(loginStaticParams) as (keyof typeof loginStaticParams)[]
).reduce((acc, paramKey) => {
  return `${acc}${acc ? '&' : '?'}${paramKey}=${encodeURIComponent(
    loginStaticParams[paramKey]
  )}`
}, '')

const getClientIdFromCookieName = (cookieName: string) => {
  try {
    const cookie = document?.cookie
      ?.split(';')
      .find((cookieValue) => cookieValue.includes(`${cookieName}=`))
      ?.split('=')
    return cookie?.[1]
  } catch {}
}

const getLoginUrl: GetLoginUrl = ({
  queryClient,
  sourcePrefix,
  fullSource,
  loginCase,
  omitPublicationInSource,
}) => {
  const extraParams = {
    source:
      fullSource ??
      calculateSSOTrackingSource(
        {
          prefix: sourcePrefix ?? '',
          omitPublication: omitPublicationInSource,
        },
        queryClient
      ),
    ...(loginCase ? { login_case: loginCase } : {}),
  } satisfies Record<string, Parameters<typeof encodeURIComponent>[0]>

  const cookieCategories =
    queryClient.getQueryData<CMPCookieCategories>(['cmp-cookie-categories']) ??
    {}

  const hasOneLogOptOut = !Object.entries(cookieCategories).some(
    (cookieCategory) => cookieCategory[0] === 'C0002' && cookieCategory[1]
  )

  const gacid = getClientIdFromCookieName(GACookieName)
  const ga4sid = getClientIdFromCookieName(GA4CookieName)

  const params: Record<string, string> = {
    ...(hasOneLogOptOut ? { ext_tracking_consent: 'false' } : {}),
    ...(gacid ? { gacid } : {}),
    ...(ga4sid ? { ga4_sid: GA4CookieNameSegment, ga4_value: ga4sid } : {}),
    ...extraParams,
  }

  const appendedParams = params
    ? Object.keys(params).reduce((acc, paramKey) => {
        return `${acc}&${paramKey}=${encodeURIComponent(params[paramKey])}`
      }, '')
    : ''

  return `https://${domain}/authorize${stringifiedLoginStaticParams}${appendedParams}`
}

const getLogoutUrl = (): string => {
  const params = {
    client_id: clientID,
    returnTo: `${authURL}${logoutPath}`,
  }

  const appendedParams = params
    ? (Object.keys(params) as (keyof typeof params)[]).reduce(
        (acc, paramKey) => {
          return `${acc}${acc ? '&' : '?'}${paramKey}=${encodeURIComponent(
            params[paramKey]
          )}`
        },
        ''
      )
    : ''

  return `https://${domain}/v2/logout${appendedParams}`
}

const getAccountUrl = (): string => {
  const params = {
    state,
  }

  const appendedParams = params
    ? (Object.keys(params) as (keyof typeof params)[]).reduce(
        (acc, paramKey) => {
          return `${acc}${acc ? '&' : '?'}${paramKey}=${encodeURIComponent(
            params[paramKey]
          )}`
        },
        ''
      )
    : ''

  return `${authURL}${accountPath}${appendedParams}`
}

const setLoginCookie = () => {
  setCookieValue({
    name: loginCookie,
    value: window.location.href,
    domain: cookieDomain,
  })
}

const setLogoutCookie = () => {
  const logoutRedirect = window.location.href

  setCookieValue({
    name: logoutCookie,
    value: logoutRedirect,
    domain: cookieDomain,
  })
}

export {
  getLoginUrl,
  getLogoutUrl,
  getAccountUrl,
  setLoginCookie,
  setLogoutCookie,
}
