import { ConfigFn } from '@config/types'

const config = (({ publication, sportApiUrl, sportApiUrlV2 }) =>
  ({
    baseUrl: sportApiUrl,
    eventUrlTemplate: `/${
      publication === 'romandie' ? 'fr' : 'de'
    }/live-event/{SPORT}/{EVENT_ID}`,
    standingsUrlTemplate: `/${
      publication === 'romandie' ? 'fr' : 'de'
    }/standings/{SPORT}/{EVENT_ID}`,

    // SDP V2
    baseUrlV2: sportApiUrlV2,
    eventUrlTemplateV2: `/{SPORT}/{API_VERSION}/live-event/{EVENT_ID}/${
      publication === 'romandie' ? 'fr' : 'de'
    }`,
    standingsUrlTemplateV2: `/{SPORT}/{API_VERSION}/standings/{EVENT_ID}/${
      publication === 'romandie' ? 'fr' : 'de'
    }`,
    relatedMatchesSeasonUrlTemplateV2: `/{SPORT}/{API_VERSION}/related-matches/season/{SEASON_ID}/${
      publication === 'romandie' ? 'fr' : 'de'
    }`,
  }) as const) satisfies ConfigFn

export default config
