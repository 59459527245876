import config from '@config'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css, useTheme } from 'styled-components'
import IconButton from '@components/Buttons/IconButton'
import Popover from '@components/Popover'
import Facebook from '@components/Social/Facebook'
import Twitter from '@components/Social/Twitter'
import Whatsapp from '@components/Social/Whatsapp'
import Email from '@components/Social/Email'
import Copy from '@components/Social/Copy'
import translate from '@i18n'
import { getUTMParameters } from '@components/Social/utils'
import useIsDarkModeInSocialButton from '@hooks/useIsDarkModeInSocialButton'
import useIsInRecipeContent from '@hooks/useIsInRecipeContent'
import Print from './Print'
import usePageMetadata from '@hooks/usePageMetadata'
import useOnScroll from '@hooks/useOnScroll'
import { isMobileUserAgent } from '@measures/responsive'

const {
  backend: { baseUrl, rootUrl },
} = config

const popoverContentWidth = 244
const shareButtonSize = 40

interface ShareCTAButtonProps {
  title: string
  articleId: string
}

const StyledIconButton = styled(IconButton)<{
  isDarkMode: boolean
  isActive: boolean
}>`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02 },
        tertiary: { grey300, grey400, grey500, grey800 },
      },
    },
    isDarkMode,
    isActive,
  }) => css`
    border-radius: 50%;
    background-color: ${isDarkMode ? grey800 : grey300};

    > svg {
      fill: ${isDarkMode ? primary02 : primary01};
    }

    &:hover {
      background-color: ${isDarkMode ? grey500 : grey400};
    }

    ${isActive &&
    css`
      background-color: ${isDarkMode ? grey500 : grey400};
    `}
  `}
`

const Wrapper = styled.div`
  position: relative;
`

const StyledPopover = styled(Popover)`
  ${({ position }) => css`
    ${position === 'top' &&
    css`
      bottom: 16px;
      right: initial;
      left: -36px;

      &:after {
        left: 8px;
      }
    `}
    ${position === 'bottom' &&
    css`
      bottom: initial;
      right: initial;
      left: -36px;
      top: 18px;

      &:after {
        left: 8px;
      }
    `}
  `}
`

const PopoverContent = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    width: ${popoverContentWidth}px;
    padding: ${spacing16};
    box-sizing: border-box;
  `}
`

const PopoverHeader = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing16};
  `}
`

const PopoverTitle = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xxsmall: { bundledCSS: xxsmallHeadingsCSS },
        },
      },
      color: {
        primary: { primary01 },
      },
    },
  }) => css`
    ${xxsmallHeadingsCSS};
    color: ${primary01};
  `}
`

const PopoverBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ShareCTAButton: FunctionComponent<ShareCTAButtonProps> = ({ title }) => {
  const theme = useTheme()
  const { url } = usePageMetadata()
  const isInRecipe = useIsInRecipeContent()
  const isDarkMode = useIsDarkModeInSocialButton()
  const windowRef = useRef<typeof window | null>(null)
  const [shouldTrackOnScroll, setShouldTrackOnScroll] = useState(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [tooltipPosition, setTooltipPosition] = useState<'top' | 'bottom'>(
    'bottom'
  )
  const shareUrl = `${baseUrl}${rootUrl}${url?.slice(1)}`

  const togglePopover = useCallback(() => {
    setIsPopoverOpen((prevValue) => !prevValue)
  }, [])

  const onShareButtonClick = useCallback(async () => {
    if (navigator.share && isMobileUserAgent()) {
      try {
        const utmSuffix = getUTMParameters({
          utmSource: 'native-share',
          fullSuffix: true,
        })

        await navigator.share({
          title,
          text: `${translate('shareMessage')}\n\n${title}\n\n`,
          url: `${shareUrl}${utmSuffix}`,
        })
      } catch (err) {
        console.error('Error sharing Social tooltip:', err)
      }
    } else {
      togglePopover()
    }
  }, [shareUrl, title, togglePopover])

  const handleScroll = useCallback(() => {
    const percentage = 55
    if (wrapperRef.current && tooltipPosition) {
      const { top } = wrapperRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      const calculatedItemPosition = windowHeight - Math.floor(top)
      const reachedPercentage = Math.floor(
        (calculatedItemPosition / windowHeight) * 100
      )
      if (
        reachedPercentage < percentage &&
        tooltipPosition === 'bottom' &&
        calculatedItemPosition < 300
      ) {
        setTooltipPosition('top')
      } else if (reachedPercentage > percentage && tooltipPosition === 'top') {
        setTooltipPosition('bottom')
      }
    }
  }, [tooltipPosition])

  useOnScroll({
    track: shouldTrackOnScroll,
    delay: 60,
    elementRef: windowRef,
    onScroll: handleScroll,
  })

  useEffect(() => {
    windowRef.current = window
    if (!shouldTrackOnScroll) {
      setShouldTrackOnScroll(true)
    }
    handleScroll()
  }, [handleScroll, shouldTrackOnScroll])

  return (
    <Wrapper ref={wrapperRef}>
      <StyledIconButton
        ariaLabel="Share"
        buttonSize={shareButtonSize}
        onClick={onShareButtonClick}
        isDarkMode={isDarkMode}
        iconName="share"
        iconSize={20}
        isActive={isPopoverOpen}
      />

      {isPopoverOpen && (
        <StyledPopover
          position={isInRecipe ? 'top' : tooltipPosition}
          trackClickOutside={true}
          wrapperRef={wrapperRef}
          onClickOutside={togglePopover}>
          <PopoverContent>
            <PopoverHeader>
              <PopoverTitle>{translate('social.share')}</PopoverTitle>
              <IconButton
                ariaLabel="Close"
                buttonSize={16}
                onClick={togglePopover}
                iconName="xmark-large"
                iconSize={16}
                iconColor={theme.color.primary.primary01}
              />
            </PopoverHeader>
            <PopoverBody>
              <Facebook shareUrl={shareUrl} label="Facebook" />
              <Twitter shareUrl={shareUrl} shareTitle={title} label="X" />
              <Whatsapp
                shareUrl={shareUrl}
                shareTitle={title}
                label="WhatsApp"
              />
              <Email shareUrl={shareUrl} shareTitle={title} label="E-Mail" />
              <Copy shareUrl={shareUrl} label={translate('social.copy')} />
              {isInRecipe && <Print label="Print" />}
            </PopoverBody>
          </PopoverContent>
        </StyledPopover>
      )}
    </Wrapper>
  )
}

export default ShareCTAButton
