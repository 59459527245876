import { FunctionComponent } from 'react'
import useScoreboard from '@hooks/useScoreboard'
import styled, { css } from 'styled-components'
import ScoreboardTeamLogo from '@components/Scoreboard/ScoreboardTeamLogo'
import useViewportType from '@hooks/useViewport/useViewportType'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { desktopCSS } from '@measures/responsive'

const Divider = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey1000: grey1000Color },
      },
      spacing: { spacing12, spacing16 },
    },
  }) => css`
    border-bottom: 1px solid ${grey1000Color};
    margin-bottom: ${spacing16};
    margin-top: ${spacing12};
  `}
`

const StyledScoreboardMatchResult = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    width: 100%;
    margin-top: ${spacing8};
  `}
`

const StyledCompetitionLocation = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
      typography: {
        catchword: {
          medium2: { bundledCSS: medium2CatchwordCSS },
        },
      },
      spacing: { spacing8 },
    },
  }) => css`
    align-items: center;
    display: flex;
    text-transform: uppercase;
    color: ${primary01Color};
    ${medium2CatchwordCSS};
    > div {
      margin-left: ${spacing8};
    }
  `}
`

const StyledCompetitionOverview = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingsCSS },
        },
      },
      color: {
        primary: { primary01: primary01Color },
      },
    },
  }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      color: ${primary01Color};
      ${smallHeadingsCSS};
    }
  `}
`

const StyledCompetitor = styled.div``

const StyledCompetitorTeamname = styled.span`
  ${({
    theme: {
      color: {
        tertiary: { grey700: grey700Color },
      },
      spacing: { spacing4 },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodycopyCSS },
        },
      },
      utils: { ellipsis },
    },
  }) => css`
    margin-left: ${spacing4};
    grid-area: competitorTeam;
    ${ellipsis('100%', 2)};
    color: ${grey700Color};
    ${xxsmallBodycopyCSS};
  `}
`

const StyledCompetitorWrapper = styled.div<{ isSki: boolean }>`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    display: grid;
    grid-auto-flow: dense;
    place-items: center start;
    grid-template:
      'competitorPosition competitorIcon competitorName competitorTime'
      '. . competitorTeam competitorTime' / auto 24px 1fr auto;
    column-gap: 4px;
    margin-top: ${spacing12};
  `}
`

const StyledCompetitorPosition = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
      typography: {
        catchword: {
          medium2: { bundledCSS: medium2CatchwordCSS },
        },
      },
    },
  }) => css`
    min-width: 14px;
    grid-area: competitorPosition;
    color: ${primary01Color};
    ${medium2CatchwordCSS};
  `}
`

const StyledCompetitorName = styled.span`
  ${({
    theme: {
      spacing: { spacing4 },
      color: {
        primary: { primary01: primary01Color },
      },
      typography: {
        headings: {
          xxsmall: { bundledCSS: xxsmallHeadingsCSS },
          xsmall: { bundledCSS: xsmallHeadingsCSS },
        },
      },
    },
  }) => css`
    grid-area: competitorName;
    margin-left: ${spacing4};
    color: ${primary01Color};
    ${xxsmallHeadingsCSS};
    ${desktopCSS(css`
      ${xsmallHeadingsCSS};
    `)}
  `}
`

const StyledCompetitorTime = styled.span`
  ${({
    theme: {
      color: {
        tertiary: { grey900: grey900Color },
      },
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: xsmallBodycopyCSS },
        },
      },
    },
  }) => css`
    grid-area: competitorTime;
    place-self: center end;
    color: ${grey900Color};
    ${xsmallBodycopyCSS};
  `}
`

const StyledCompetitorsDivider = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey300: grey300Color },
      },
      spacing: { spacing12 },
    },
  }) => css`
    border-top: 1px solid ${grey300Color};
    grid-column: 1 / -1;
    margin-top: ${spacing12};
  `}
`

const { SKI } = LIVE_EVENT_DATA_SPORT_TYPES

const ScoreboardMatchResultSkiOrFormula1: FunctionComponent = () => {
  const viewportType = useViewportType()
  const isMobile = viewportType === 'mobile'

  const { formattedData, sportsEventType } = useScoreboard<
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.SKI
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1
  >()

  const { isLive, competitors, countryLogoSrc, raceTypeName, raceGPName } =
    formattedData ?? {}

  const isSki = sportsEventType === SKI

  const shouldShowDivider = isSki
    ? !formattedData.isPending
    : !formattedData.willStart

  return (
    <StyledScoreboardMatchResult>
      <StyledCompetitionOverview>
        <span>
          {isSki
            ? isLive || competitors.length
              ? raceTypeName
              : formattedData.genderType
            : raceGPName}
        </span>
        <StyledCompetitionLocation>
          {isSki ? raceGPName : formattedData.raceLocation}
          {!!countryLogoSrc && (
            <ScoreboardTeamLogo
              name={isSki ? raceGPName : formattedData.raceLocation}
              size={isMobile ? 32 : 40}
              url={countryLogoSrc}
            />
          )}
        </StyledCompetitionLocation>
      </StyledCompetitionOverview>
      {shouldShowDivider && <Divider />}
      {competitors.map((competitor, index) => (
        <>
          <StyledCompetitor key={competitor.position}>
            <StyledCompetitorWrapper isSki={isSki}>
              <StyledCompetitorPosition>
                {competitor.position ?? index + 1}.
              </StyledCompetitorPosition>
              {!!competitor.logo && (
                <ScoreboardTeamLogo
                  name={competitor.name}
                  size={24}
                  url={competitor.logo}
                />
              )}
              <StyledCompetitorName>{competitor.name}</StyledCompetitorName>
              <StyledCompetitorTime>{competitor.time}</StyledCompetitorTime>
              {competitor.teamname && (
                <StyledCompetitorTeamname>
                  {competitor.teamname}
                </StyledCompetitorTeamname>
              )}
            </StyledCompetitorWrapper>
          </StyledCompetitor>
          {index !== competitors.length - 1 && <StyledCompetitorsDivider />}
        </>
      ))}
    </StyledScoreboardMatchResult>
  )
}

export default ScoreboardMatchResultSkiOrFormula1
