import JSONRenderer from '@components/JSONRenderer'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import {
  desktopCSS,
  getCurrentViewportType,
  mobileAndTabletCSS,
  mobileCSS,
} from '@measures/responsive'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import {
  FunctionComponent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import {
  LayoutTabsProps,
  ScrollableTabProps,
  ScrollableTabsProps,
} from './types'
import useScrollArrow from '@hooks/useScrollArrow'
import { scrollToEnd, scrollToStart } from '@components/LayoutTabs/utils'
import ArrowFader from '@components/LayoutTabs/ArrowFader'
import useViewportType from '@hooks/useViewport/useViewportType'
import useViewportDimensions from '@hooks/useViewport/useViewportDimensions'
import { ALLOWED_TAB_TYPES } from './utils'
import useIsInSportArticle from '@hooks/useIsInSportArticle'
import useGetPageIdentity from '@hooks/useGetPageIdentity'
import usePageMetadata from '@hooks/usePageMetadata'

type StyledTabProps = {
  hasScoreboard: boolean
  isEntity: boolean
  shouldTabsBeSticky: boolean
  tabsNumber: number
}

export const TabsWrapper = styled.div<Omit<StyledTabProps, 'tabsNumber'>>`
  ${({
    theme: {
      color: {
        tertiary: { grey200 },
      },
      measures: { stretchWidthOnMobile },
      spacing: { spacing16 },
    },
    hasScoreboard,
    isEntity,
    shouldTabsBeSticky,
  }) => {
    const stickyTabsMobile = css`
      ${mobileCSS(css`
        top: ${hasScoreboard ? '96px' : isEntity ? '112px' : '48px'};
        position: sticky;
        z-index: 1;
      `)}
    `

    return css`
      position: relative;
      background-color: ${grey200};
      padding: 0 ${spacing16};
      box-sizing: border-box;
      overflow: hidden;
      ${stretchWidthOnMobile()}
      ${shouldTabsBeSticky && stickyTabsMobile}
    `
  }}
`

const TabsContent = styled.div`
  scroll-margin-top: 156px;
`

const StyledTabContent = styled.div<{ isVisible: boolean }>`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
    isVisible,
  }) => css`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: ${spacing16};
    ${desktopCSS(css`
      grid-row-gap: ${spacing24};
    `)};
    align-items: flex-start;
    ${!isVisible &&
    css`
      display: none;
    `}
  `}
`

const StyledSideAdWrapper = styled.div`
  ${({}) => css`
    ${mobileAndTabletCSS(css`
      display: none;
    `)};

    ${desktopCSS(css`
      width: 100%;
      height: 100%;
      min-height: max(100%, 600px);
    `)};
  `}
`

const StyledTabContentWithSideAdWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24, spacing32 },
    },
  }) => css`
    display: grid;
    align-items: flex-start;

    ${mobileAndTabletCSS(css`
      grid-template-columns: minmax(0, 1fr);
      grid-row-gap: ${spacing16};
    `)};

    ${desktopCSS(css`
      grid-template-areas: 'tabContent sideAdDesktop';
      grid-template-columns: 632px minmax(0, 1fr);
      grid-column-gap: ${spacing32};
      grid-row-gap: ${spacing24};

      > ${StyledSideAdWrapper} {
        grid-area: sideAdDesktop;
      }

      > *:not(StyledSideAdWrapper) {
        grid-area: tabContent;
      }
    `)};
  `}
`

const TabContent: FunctionComponent<
  | {
      children: ReactNode
      isVisible: boolean
      isEntity: boolean
      isSingleTab?: false
    }
  | {
      children: ReactNode
      isEntity: boolean
      isSingleTab: true
    }
> = (props) => {
  const { children, isEntity, isSingleTab } = props
  const modifiedTabContent = useMemo(() => {
    if (!children) {
      return null
    }

    if (!Array.isArray(children)) {
      return null
    }

    if (!isEntity) {
      return <JSONRenderer>{children}</JSONRenderer>
    }

    const sideAdDesktopIndex = children.findIndex(
      (item) =>
        item.kind?.[0] === 'ad-ringier' && item.placement === 'entitySideAd'
    )

    const sideAdDesktop =
      sideAdDesktopIndex === -1 ? null : (
        <JSONRenderer>{children[sideAdDesktopIndex]}</JSONRenderer>
      )

    const restOfChildren = (
      <JSONRenderer>
        {sideAdDesktopIndex === -1
          ? children
          : children.filter((_, index) => index !== sideAdDesktopIndex)}
      </JSONRenderer>
    )

    return (
      <StyledTabContentWithSideAdWrapper>
        <StyledSideAdWrapper>{sideAdDesktop}</StyledSideAdWrapper>
        {restOfChildren}
      </StyledTabContentWithSideAdWrapper>
    )
  }, [children, isEntity])

  if (isSingleTab) {
    return modifiedTabContent
  }

  return (
    <StyledTabContent isVisible={props.isVisible}>
      {modifiedTabContent}
    </StyledTabContent>
  )
}

const ScrollableContainer = styled.div<Pick<StyledTabProps, 'tabsNumber'>>`
  ${({
    tabsNumber,
    theme: {
      spacing: { spacing12, spacing24 },
    },
  }) => css`
    height: 100%;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-gap: ${spacing24};
    ${mobileCSS(css`
      grid-gap: ${spacing12};
    `)}
    grid-template-columns: repeat(${tabsNumber}, max-content);
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`

interface TabProps {
  isActive: boolean
  isSportArticle: boolean
  accentColor?: string
}

const StyledButton = styled.button<TabProps>`
  ${({
    theme: {
      typography: {
        subheads: {
          medium1: { bundledCSS: subheadingMedium1CSS },
          medium2: { bundledCSS: subheadingMedium2CSS },
        },
      },
      color: {
        tertiary: { grey800 },
        primary: { primary01, blickRed },
        secondary: { green },
      },
      spacing: { spacing12 },
    },
    isActive,
    isSportArticle,
    accentColor,
  }) => css`
    ${isActive ? subheadingMedium1CSS : subheadingMedium2CSS};
    color: ${isActive ? primary01 : grey800};
    overflow: hidden;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    padding: ${spacing12} 0;
    margin: 0;
    display: grid;
    align-content: center;
    justify-content: center;
    background-color: transparent;
    border-bottom: 3px solid transparent;
    ${isActive &&
    css`
      border-bottom-color: ${accentColor ??
      (isSportArticle ? green : blickRed)};
    `}
    ${!isActive &&
    css`
      cursor: pointer;
    `};
  `}
`

const ScrollableTab: FunctionComponent<ScrollableTabProps> = ({
  tab,
  tabIndex,
  isActive,
  updateActiveTab,
  tabsContentRef,
  accentColor,
}) => {
  const onClick = useCallback<TrackingFnType>(
    () => ({
      event: 'layout_tabs',
      eventCategory: 'layout_tabs',
      eventAction: 'tab_click',
      eventLabel: `${tab}`,
    }),
    [tab]
  )

  const trackedOnClick = useTracking(onClick)
  const isSportArticle = useIsInSportArticle()

  return (
    <StyledButton
      key={tab}
      isActive={isActive}
      isSportArticle={isSportArticle}
      accentColor={accentColor}
      {...(isActive
        ? {}
        : {
            onClick: () => {
              updateActiveTab(tabIndex)
              const isMobile = getCurrentViewportType() === 'mobile'
              if (isMobile) {
                if (tabsContentRef.current && document.scrollingElement) {
                  const shouldScroll =
                    document.scrollingElement.scrollTop >
                    tabsContentRef.current?.offsetTop - 100
                  if (shouldScroll)
                    tabsContentRef.current?.scrollIntoView({
                      behavior: 'smooth',
                    })
                }
              }
              trackedOnClick()
            },
          })}>
      {tab}
    </StyledButton>
  )
}

const ScrollableTabs: FunctionComponent<ScrollableTabsProps> = ({
  tabs,
  activeTabIndex,
  updateActiveTab,
  tabsContentRef,
  accentColor,
}) => {
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false)
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false)
  const scrollableContainerEl = useRef<HTMLDivElement>(null)
  const viewportType = useViewportType()
  const { width: viewportWidth } = useViewportDimensions()
  const { hasScoreboard, isEntity } = useGetPageIdentity()
  const { typeOfSport } = usePageMetadata()

  useEffect(() => {
    if (scrollableContainerEl?.current) {
      const isOverflowing =
        scrollableContainerEl.current.clientWidth <
        scrollableContainerEl.current.scrollWidth
      setShowRightArrow(isOverflowing)
    }
  }, [viewportWidth, viewportType])

  useScrollArrow({
    elementRef: scrollableContainerEl,
    setShowLeftArrow,
    setShowRightArrow,
  })

  return (
    <TabsWrapper
      hasScoreboard={hasScoreboard}
      isEntity={isEntity}
      shouldTabsBeSticky={isEntity || typeOfSport === 'soccer'}>
      {showLeftArrow && (
        <ArrowFader.Left
          scrollToStart={() =>
            scrollToStart({ containerRef: scrollableContainerEl })
          }
        />
      )}
      <ScrollableContainer tabsNumber={tabs.length} ref={scrollableContainerEl}>
        {tabs.map((tab, index) => {
          return (
            <ScrollableTab
              key={tab}
              tab={tab}
              tabIndex={index}
              isActive={activeTabIndex === index}
              updateActiveTab={updateActiveTab}
              tabsContentRef={tabsContentRef}
              accentColor={accentColor}
            />
          )
        })}
      </ScrollableContainer>
      {showRightArrow && (
        <ArrowFader.Right
          scrollToEnd={() =>
            scrollToEnd({ containerRef: scrollableContainerEl })
          }
        />
      )}
    </TabsWrapper>
  )
}

const LayoutTabs: FunctionComponent<LayoutTabsProps> = ({
  content,
  accentColor,
  initialTabIndex,
}) => {
  const isEntity = useGetPageIdentity().isEntity
  const tabsContentRef = useRef<HTMLDivElement>(null)
  const [activeTabIndex, setActiveTabIndex] = useState<number>(initialTabIndex)

  const updateActiveTab = useCallback((index: any) => {
    setActiveTabIndex(index)
  }, [])

  const allowedContent = content.filter((content) =>
    Object.values(ALLOWED_TAB_TYPES).includes(content.type)
  )

  const tabsInfo = useMemo(
    () => allowedContent.map((allowedContent) => allowedContent.name),
    [allowedContent]
  )

  const tabsContent = useMemo(
    () => allowedContent.map((allowedContent) => allowedContent.content),
    [allowedContent]
  )

  const hasAllowedContent = allowedContent.length > 0

  if (!hasAllowedContent) {
    return null
  }

  const hasOnlyOneAllowedContent = allowedContent.length === 1

  if (hasOnlyOneAllowedContent) {
    return (
      <TabContent isEntity={isEntity} isSingleTab={true}>
        {tabsContent[0]}
      </TabContent>
    )
  }

  return (
    <>
      <ScrollableTabs
        tabs={tabsInfo}
        activeTabIndex={activeTabIndex}
        updateActiveTab={updateActiveTab}
        tabsContentRef={tabsContentRef}
        accentColor={accentColor}
      />
      <TabsContent ref={tabsContentRef}>
        {tabsContent.map((tabContent, index) => (
          <TabContent
            isEntity={isEntity}
            key={tabsInfo[index]}
            isVisible={index === activeTabIndex}>
            {tabContent}
          </TabContent>
        ))}
      </TabsContent>
    </>
  )
}

const MemoizedLayoutTabs = memo(LayoutTabs)

MemoizedLayoutTabs.displayName = 'MemoizedLayoutTabs'

const widget = {
  kind: ['layout', 'tabs2'],
  component: MemoizedLayoutTabs,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
