import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export interface CatchwordProps {
  children?: ReactNode
}

const StyledCatchword = styled.div`
  ${({
    theme: {
      colors: { textBrand },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      spacing: { spacing4 },
      utils: { ellipsis },
    },
  }) => css`
    ${ellipsis('100%', 1)};
    ${small1SubheadingCSS};
    color: ${textBrand};
    margin-bottom: ${spacing4};
  `}
`

const Catchword: FunctionComponent<CatchwordProps> = ({ children }) => {
  return <StyledCatchword>{children}</StyledCatchword>
}

export default Catchword
