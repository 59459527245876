import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { LiveEventDataSportTypes } from '@utils/formatters/liveEvent'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import {
  composeRequestUrlSportsEvent,
  composeRequestUrlSportsEventV2,
  EnrichedLiveEventTransformed,
  EnrichedLiveEventTransformedFormula1,
  EnrichedLiveEventTransformedHockey,
  EnrichedLiveEventTransformedSki,
  EnrichedLiveEventTransformedSoccer,
  EnrichedLiveEventTransformedSoccerHockey,
  EnrichedLiveEventTransformedTennis,
  getSportsEventData,
} from '@utils/sport'

export type UseSportsEventProps =
  | UseSportsEventPropsUnknownSport
  | UseSportsEventPropsFormula1
  | UseSportsEventPropsHockey
  | UseSportsEventPropsSki
  | UseSportsEventPropsSoccer
  | UseSportsEventPropsTennis
  | UseSportsEventPropsSoccerHockey

export interface UseSportsEventPropsCommon {
  matchId: string
  placeholderData?: any
}

export interface UseSportsEventPropsUnknownSport
  extends UseSportsEventPropsCommon {
  typeOfSport: LiveEventDataSportTypes
}

export interface UseSportsEventPropsFormula1 extends UseSportsEventPropsCommon {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1
}

export interface UseSportsEventPropsSoccerHockey
  extends UseSportsEventPropsCommon {
  typeOfSport:
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
}

export interface UseSportsEventPropsHockey extends UseSportsEventPropsCommon {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
}

export interface UseSportsEventPropsSki extends UseSportsEventPropsCommon {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.SKI
}

export interface UseSportsEventPropsSoccer extends UseSportsEventPropsCommon {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
}

export interface UseSportsEventPropsTennis extends UseSportsEventPropsCommon {
  typeOfSport: typeof LIVE_EVENT_DATA_SPORT_TYPES.TENNIS
}

const validMatchId = (matchId: UseSportsEventProps['matchId']) =>
  !!matchId && matchId !== '0'

const validTypeOfSport = (typeOfSport: UseSportsEventProps['typeOfSport']) =>
  Object.values(LIVE_EVENT_DATA_SPORT_TYPES).includes(typeOfSport)

export type UseSportsEventReturnValue<T> = UseQueryResult<
  T extends UseSportsEventPropsSoccer
    ? EnrichedLiveEventTransformedSoccer
    : T extends UseSportsEventPropsTennis
      ? EnrichedLiveEventTransformedTennis
      : T extends UseSportsEventPropsHockey
        ? EnrichedLiveEventTransformedHockey
        : T extends UseSportsEventPropsFormula1
          ? EnrichedLiveEventTransformedFormula1
          : T extends UseSportsEventPropsSki
            ? EnrichedLiveEventTransformedSki
            : T extends UseSportsEventPropsSoccerHockey
              ? EnrichedLiveEventTransformedSoccerHockey
              : EnrichedLiveEventTransformed
>

function useSportsEvent<T extends UseSportsEventProps>({
  matchId,
  typeOfSport,
  placeholderData,
}: T): UseSportsEventReturnValue<T> {
  const validRequestData =
    validMatchId(matchId) && validTypeOfSport(typeOfSport)

  return useQuery({
    queryKey: ['sportsEvent', typeOfSport, matchId],
    queryFn: () => {
      return getSportsEventData({
        expectedTypeOfSport: typeOfSport,
        expectedMatchId: matchId,
      })(
        typeOfSport === 'soccer'
          ? composeRequestUrlSportsEventV2({ typeOfSport, matchId })
          : composeRequestUrlSportsEvent({ typeOfSport, matchId })
      )
    },
    ...(placeholderData ? { placeholderData } : {}),
    enabled: validRequestData,
    refetchInterval: (query) => {
      if (query.state.data?.pollIntervalInMilliseconds) {
        return query.state.data.pollIntervalInMilliseconds > 0
          ? query.state.data.pollIntervalInMilliseconds
          : false
      } else if (query.state.data?.sportsEventCacheDurationInMilliseconds) {
        return query.state.data.sportsEventCacheDurationInMilliseconds > 0
          ? query.state.data.sportsEventCacheDurationInMilliseconds
          : false
      }

      return false
    },
  })
}

export { getSportsEventData, validMatchId, validTypeOfSport }

export default useSportsEvent
