import { ConfigFn } from '@config/types'

const config = (({ publication }) =>
  ({
    common: [
      { name: 'mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-title', content: 'Blick' },
      { name: 'application-name', content: 'Blick.ch' },
      { name: 'msapplication-config', content: 'tile.xml' },
      { name: 'msapplication-navbutton-color', content: '#e20000' },
    ],
    article:
      publication === 'romandie'
        ? ([
            {
              property: 'fb:admins',
              content: '100002246240739',
              key: '100002246240739',
            },
            { property: 'fb:app_id', content: '122941821049269' },
            { property: 'og:locale', content: 'fr_CH' },
            { property: 'twitter:site', content: '@Blick_fr' },
            { property: 'twitter:app:country', content: 'CH' },
            { property: 'twitter:app:name:iphone', content: 'Blick | fr' },
            { property: 'twitter:app:id:iphone', content: '1596317983' },
            { property: 'twitter:app:url:iphone' },
            {
              property: 'twitter:app:name:googleplay',
              content: 'Blick | fr',
            },
            {
              property: 'twitter:app:id:googleplay',
              content: 'ch.blick.news.fr',
            },
            { property: 'al:ios:app_name', content: 'Blick News-App' },
            { property: 'al:ios:app_store_id', content: '1596317983' },
            { property: 'al:android:app_name', content: 'Blick | fr' },
            { property: 'al:android:package', content: 'ch.blick.news.fr' },
          ] as const)
        : ([
            {
              property: 'fb:admins',
              content: '841239291',
              key: '841239291',
            },
            {
              property: 'fb:admins',
              content: '10216067186586936',
              key: '10216067186586936',
            },
            {
              property: 'fb:pages',
              content: '62562538918',
              key: '62562538918',
            },
            {
              property: 'fb:pages',
              content: '87710839627',
              key: '87710839627',
            },
            {
              property: 'fb:pages',
              content: '436544309865663',
              key: '436544309865663',
            },
            {
              property: 'fb:pages',
              content: '1550090251966454',
              key: '1550090251966454',
            },
            { property: 'fb:app_id', content: '198633050385' },
            { property: 'og:locale', content: 'de_CH' },
            { property: 'twitter:site', content: '@Blickch' },
            { property: 'twitter:app:country', content: 'CH' },
            {
              property: 'twitter:app:name:iphone',
              content: 'Blick News & Sport',
            },
            { property: 'twitter:app:id:iphone', content: '375173494' },
            { property: 'twitter:app:url:iphone' },
            {
              property: 'twitter:app:name:googleplay',
              content: 'Blick News & Sport',
            },
            { property: 'twitter:app:id:googleplay', content: 'ch.blick.news' },
            { property: 'al:ios:app_name', content: 'Blick News & Sport' },
            { property: 'al:ios:app_store_id', content: '375173494' },
            { property: 'al:android:app_name', content: 'Blick News & Sport' },
            { property: 'al:android:package', content: 'ch.blick.news' },
          ] as const),
  }) as const) satisfies ConfigFn

export default config
