import {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { WidgetKind } from '@utils/registry'
import { TeaserStandardVerticalVideoAPIProps } from '@widgets/TeaserStandardVerticalVideo'
import { Link } from '@utils/cook/types'

import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import useViewportType from '@hooks/useViewport/useViewportType'

import Swiper, { SwiperClass, ArrowsVisibilityType } from '@components/Swiper'
import SectionHeader from '@components/SectionHeader'
import NavigationArrows from '@components/Swiper/NavigationArrows'
import InvertedColorsContext from '@contexts/invertedColors'
import JSONRenderer from '@components/JSONRenderer'

type VideosCarouselItem = TeaserStandardVerticalVideoAPIProps & {
  kind: WidgetKind
}

export interface VideosCarouselWidgetAPIProps {
  title: string
  accentColor: string
  items: VideosCarouselItem[]
  link?: Link
}

interface FadeGradientProps {
  isHidden: boolean
  itemsCount: number
  position: 'left' | 'right'
}

const VideosCarouselWrapper = styled.div`
  ${({
    theme: {
      colors: { staticBlack },
      spacing: { spacing16, spacing32, spacing56 },
      measures: { stretchBackground },
    },
  }) => css`
    background-color: ${staticBlack};

    ${stretchBackground()};

    padding-top: ${spacing32};
    padding-bottom: ${spacing32};

    display: grid;
    grid-row-gap: ${spacing16};

    ${desktopCSS(css`
      padding: ${spacing56};
      grid-row-gap: ${spacing32};
    `)}
  `}
`

const StyledNavigationArrows = styled(NavigationArrows)<{ itemsCount: number }>`
  ${({ itemsCount }) => css`
    ${itemsCount <= 2 &&
    mobileCSS(css`
      display: none;
    `)}
    ${itemsCount <= 4 &&
    tabletCSS(css`
      display: none;
    `)};
    ${itemsCount <= 6 &&
    desktopCSS(css`
      display: none;
    `)}
  `}
`

const SwiperWrapper = styled.div`
  ${({
    theme: {
      measures: { outerPadding },
    },
  }) => css`
    position: relative;
    overflow: hidden;

    ${mobileCSS(css`
      margin-right: -${outerPadding.mobile};
    `)}
  `}
`

const StyledSwiper = styled(Swiper)`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    > .swiper-items-container {
      grid-gap: ${spacing16};
      grid-template-rows: repeat(3, minmax(0, 1fr));

      ${desktopCSS(css`
        grid-gap: ${spacing24};
        grid-template-rows: repeat(2, minmax(0, 1fr));
      `)}
    }
  `}
`

const SwiperSlide = styled.div`
  ${({
    theme: {
      measures: { outerPadding },
    },
  }) => css`
    width: 227px;

    ${mobileCSS(css`
      &:last-child {
        margin-right: ${outerPadding.mobile};
        scroll-margin-right: ${outerPadding.mobile};
      }
    `)}
  `}
`

const FadeGradient = styled.div<FadeGradientProps>`
  ${({
    theme: {
      colors: { staticBlack },
    },
    isHidden,
    itemsCount,
    position,
  }) => css`
    width: 48px;
    height: 100%;

    position: absolute;
    z-index: 1;
    top: 0;

    pointer-events: none;

    ${isHidden &&
    css`
      opacity: 0;
    `};

    ${desktopCSS(css`
      width: 80px;
    `)};

    ${position === 'left' &&
    css`
      left: 0;
      background: linear-gradient(270deg, rgba(17, 17, 17, 0), ${staticBlack});
    `};

    ${position === 'right' &&
    css`
      right: 0;
      background: linear-gradient(270deg, ${staticBlack}, rgba(17, 17, 17, 0));
    `};

    ${itemsCount <= 2 &&
    mobileCSS(css`
      display: none;
    `)};

    ${itemsCount <= 4 &&
    tabletCSS(css`
      display: none;
    `)};

    ${itemsCount <= 6 &&
    desktopCSS(css`
      display: none;
    `)}
  `}
`

const SLIDES_PER_GROUP_MOBILE = 3
const SLIDES_PER_GROUP_DESKTOP = 8

const VideosCarousel: FunctionComponent<VideosCarouselWidgetAPIProps> = ({
  title,
  accentColor,
  link,
  items,
}) => {
  const viewportType = useViewportType()
  const isDesktop = viewportType === 'desktop'

  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>('next')

  const shouldHideLeftGradient = useMemo(
    () => ['none', 'next', 'both'].includes(arrowsVisibility || ''),
    [arrowsVisibility]
  )

  const shouldHideRightGradient = useMemo(
    () => ['none', 'prev'].includes(arrowsVisibility || ''),
    [arrowsVisibility]
  )

  const onInit = useCallback(
    (swiper: any) => {
      swiperRef.current = swiper
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  if (!title) {
    return null
  }

  return (
    <InvertedColorsContext.Provider value={true}>
      <VideosCarouselWrapper>
        <SectionHeader
          title={title}
          link={link}
          accentColor={accentColor}
          arrowsVisibility={arrowsVisibility}>
          <StyledNavigationArrows
            swiperRef={swiperRef}
            arrowsVisibility={arrowsVisibility}
            itemsCount={items?.length ?? 0}
          />
        </SectionHeader>
        <SwiperWrapper>
          <StyledSwiper
            initialSlide={0}
            slidesPerGroup={
              isDesktop ? SLIDES_PER_GROUP_DESKTOP : SLIDES_PER_GROUP_MOBILE
            }
            spaceTopBottom={0}
            onInit={onInit}
            onArrowsVisibility={onArrowsVisibility}
            slides={items?.map((item) => (
              <SwiperSlide key={item.articleId}>
                <JSONRenderer>{item}</JSONRenderer>
              </SwiperSlide>
            ))}
          />
          <FadeGradient
            isHidden={shouldHideLeftGradient && shouldHideRightGradient}
            position={shouldHideLeftGradient ? 'right' : 'left'}
            itemsCount={items?.length ?? 0}
          />
        </SwiperWrapper>
      </VideosCarouselWrapper>
    </InvertedColorsContext.Provider>
  )
}

const widget = {
  kind: ['videos-carousel'],
  component: VideosCarousel,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
