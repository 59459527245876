import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import useMount from '@hooks/useMount'
import { QueryClient, useQueryClient } from '@tanstack/react-query'

export type UseExecuteBeforeClientNavigation = (
  fn: (queryClient: QueryClient) => void
) => void

const useExecuteBeforeClientNavigation: UseExecuteBeforeClientNavigation = (
  fn
) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const isMounted = useMount()
  const mountAwareFn = useCallback<
    (url: string, { shallow }: { shallow: boolean }) => void
  >(
    (_url, { shallow }) => {
      //! Do not execute this actions when shallow navigation happens
      if (shallow) {
        return
      }

      if (isMounted()) {
        fn(queryClient)
      }
    },
    [fn, isMounted, queryClient]
  )

  useEffect(() => {
    router.events.on('routeChangeStart', mountAwareFn)

    return () => {
      router.events.off('routeChangeStart', mountAwareFn)
    }
  }, [router, mountAwareFn])
}

export default useExecuteBeforeClientNavigation
