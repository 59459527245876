import contextArticleTextbox from '@contexts/articleTextbox'
import useHeightTransition from '@hooks/useHeightTransition'
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useRef,
} from 'react'
import styled from 'styled-components'

const { ArticleTextboxContext } = contextArticleTextbox

const StyledContentWrapper = styled.div`
  overflow: hidden;
  transition: height 0.5s ease-out;
`

const ExpandableContentWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const { isExpanded } = useContext(ArticleTextboxContext)
  const componentRef = useRef<HTMLDivElement | null>(null)

  const getIsComponentExpanded = useCallback(() => isExpanded, [isExpanded])
  useHeightTransition({
    getIsComponentExpanded,
    collapsedHeight: 120,
    componentRef,
  })

  return (
    <StyledContentWrapper ref={componentRef}>{children}</StyledContentWrapper>
  )
}

export default ExpandableContentWrapper
