import config from '@config'
import useABTestValue from '@hooks/useABTestValue'
import useFeatureFlagsSkeleton from '@hooks/useFeatureFlagsSkeleton'

export type UseIsCUIEnabled = () => boolean

const {
  backend: { teamcityEnv },
  publication: { publication },
} = config

const useIsCUIEnabled: UseIsCUIEnabled = () =>
  [
    publication !== 'romandie',
    useFeatureFlagsSkeleton().isChatbotEnabled,
    useABTestValue('showCUI') === 'show' || !['prod'].includes(teamcityEnv),
  ].every(Boolean)

export default useIsCUIEnabled
