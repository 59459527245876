import styled, { css } from 'styled-components'

import { desktopCSS } from '@measures/responsive'

const Title = styled.div`
  ${({
    theme: {
      colors: { staticWhite },
      typography: {
        headings: {
          xlarge: { bundledCSS: xlargeHeadingCSS },
          xxxlarge: { bundledCSS: xxxlargeHeadingCSS },
        },
      },
      utils: { ellipsis },
    },
  }) => css`
    color: ${staticWhite};

    ${xlargeHeadingCSS};
    text-align: center;

    ${ellipsis('100%', 2)};

    ${desktopCSS(css`
      ${xxxlargeHeadingCSS};
      text-align: left;

      ${ellipsis('100%', 1)};
    `)}
  `}
`

export default Title
