import dynamic from 'next/dynamic'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import { CustomProcessNode } from '@components/HTMLView/types'
import styled, { css } from 'styled-components'
import useArticleSchema from '@hooks/useArticleSchema'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

interface TextBodyProps {
  text?: string
}

const HTMLView = dynamic(() => import('@components/HTMLView'))

export const allowedHtmlTags = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'p',
  'ul',
  'ol',
  'li',
  'em',
  'strong',
  'a',
  'table',
  'tbody',
  'tr',
  'td',
  'sub',
  'sup',
  'strike',
  'u',
  'br',
  'i',
]

const ScrollableWrapper = styled.div<{ hasInvertedColors: boolean }>`
  ${({
    theme: {
      color: {
        tertiary: { grey500 },
        primary: { blickRed, primary02, primary01 },
      },
      spacing: { spacing12, spacing16 },
      typography: {
        subheads: {
          medium1: { bundledCSS: medium1SubheadCSS },
        },
        bodycopy: {
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
    },
    hasInvertedColors,
  }) => css`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow-x: auto;
    text-align: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    table {
      background-color: ${primary02};
      border-collapse: collapse;
      width: 100%;
      border-spacing: 0 ${spacing16};

      td {
        text-align: left;
        padding: ${spacing12} 0 ${spacing12} ${spacing16};

        &:has(p) {
          padding: 0 0 0 ${spacing16};
        }
      }

      tr {
        ${medium1SubheadCSS};
        color: ${blickRed};
        white-space: initial;
        border-bottom: 1px solid ${grey500};

        > :first-child {
          padding: 0;
        }
      }

      tr:not(:first-child) {
        background-color: ${primary02};
        padding-left: ${spacing16};
        ${small2BodycopyCSS};
        color: ${hasInvertedColors ? primary02 : primary01};
      }

      tr:not(:first-child) td:first-child {
        ${medium1SubheadCSS};
        padding: 0;
      }
    }
  `}
`

const TextBody: FunctionComponent<TextBodyProps> = ({ text }) => {
  useArticleSchema({ text })

  const hasInvertedColors = useHasInvertedColors()

  if (!text) {
    return null
  }

  const processNodeTable: CustomProcessNode = (
    _processDefaultNode,
    _node,
    children,
    index
  ) => {
    return (
      <ScrollableWrapper key={index} hasInvertedColors={hasInvertedColors}>
        <table>
          <tbody>{children}</tbody>
        </table>
      </ScrollableWrapper>
    )
  }

  const processNodeTbody: CustomProcessNode = (
    _processDefaultNode,
    _node,
    children
  ) => children

  const processNodeH1andH2: CustomProcessNode = (
    _processDefaultNode,
    _node,
    children,
    index
  ) => {
    return <h2 key={index}>{children}</h2>
  }

  const processNodeRuleForTag = {
    table: {
      processNode: processNodeTable,
    },
    tbody: {
      processNode: processNodeTbody,
    },
    h1: {
      processNode: processNodeH1andH2,
    },
    h2: {
      processNode: processNodeH1andH2,
    },
  }

  return (
    <HTMLView
      content={text}
      allowedHtmlTags={allowedHtmlTags}
      processNodeRuleForTag={processNodeRuleForTag}
    />
  )
}

const widget = {
  kind: ['widget', 'text-body'],
  component: TextBody,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
