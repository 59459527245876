import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface EndIndicatorProps {
  accentColor?: string
  children: string
}

const StyledEndIndicator = styled.div<Pick<EndIndicatorProps, 'accentColor'>>`
  ${({
    theme: {
      typography: {
        catchword: {
          medium2: { bundledCSS: medium2Catchword },
        },
      },
      color: {
        primary: { blickRed },
      },
      spacing: { spacing8 },
    },
    accentColor,
  }) => css`
    ${medium2Catchword};
    color: ${accentColor ?? blickRed};
    text-transform: uppercase;

    padding: ${spacing8} 0px;

    border-top: 2px solid ${accentColor ?? blickRed};
    text-align: center;
  `}
`

const EndIndicator: FunctionComponent<EndIndicatorProps> = ({
  accentColor,
  children,
}) => (
  <StyledEndIndicator accentColor={accentColor}>{children}</StyledEndIndicator>
)

export default EndIndicator
