import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import Blink from '@components/Blink'

export interface SummaryDisclaimerProps {
  title?: string
  links: {
    label: string
    link?: string
    target?: string
    onClickHandler?: (label: string, event?: PointerEvent) => void
  }[]
}

const DisclaimerWrapper = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary01 },
        tertiary: { grey800 },
      },
      spacing: { spacing4, spacing8 },
      typography: {
        subheads: {
          xsmall2: { bundledCSS: smallSubHeadings },
        },
      },
    },
  }) => css`
    color: ${grey800};
    display: flex;
    flex-direction: column;
    gap: ${spacing4};
    position: relative;
    display: flex;
    align-items: center;

    ${smallSubHeadings}

    div {
      display: block;
      text-align: center;
      max-width: 600px;
      ${smallSubHeadings}
    }

    span {
      display: inline-flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding-right: ${spacing8};

      ${mobileCSS(css`
        padding-right: ${spacing4};
      `)}

      a {
        color: ${grey800};
        ${smallSubHeadings}

        &:hover {
          color: ${primary01};
        }
      }

      &:not(:last-child)::after {
        content: '';
        border-radius: 50%;
        width: 5px;
        height: 5px;
        background-color: ${grey800};
        margin-left: ${spacing8};

        ${mobileCSS(css`
          width: 3px;
          height: 3px;
          margin-left: ${spacing4};
        `)}
      }
    }
  `}
`

const AnchorItem = styled.a`
  cursor: pointer;
  text-decoration: underline;
`

const SummaryDisclaimer: FunctionComponent<SummaryDisclaimerProps> = ({
  title,
  links,
}) => {
  return (
    <DisclaimerWrapper>
      {title && <div>{title}</div>}
      {links && (
        <div>
          {links.map(({ label, link, target, onClickHandler }, index) => (
            <span key={`disclaimer-item-${index}`}>
              {link ? (
                <Blink
                  target={target || '_self'}
                  href={link}
                  onClick={() => {
                    onClickHandler?.(label, event as PointerEvent)
                  }}>
                  {label}
                </Blink>
              ) : (
                <AnchorItem
                  role="button"
                  onClick={() => {
                    onClickHandler?.(label, event as PointerEvent)
                  }}>
                  {label}
                </AnchorItem>
              )}
            </span>
          ))}
        </div>
      )}
    </DisclaimerWrapper>
  )
}

export default SummaryDisclaimer
