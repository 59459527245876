import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import Blink from '@components/Blink'
import { FooterSkeletonLinkItem } from '@hooks/useFooter'

type DefaultItemProps = FooterSkeletonLinkItem

const StyledDefaultItem = styled(Blink)`
  ${({
    theme: {
      colors: { textDefault },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: bodyCopyXXSmall },
        },
      },
    },
  }) => css`
    ${bodyCopyXXSmall};
    color: ${textDefault};
    display: grid;
    align-items: center;
    min-height: 40px;
    text-decoration: none;
  `}
`

const DefaultItem: FunctionComponent<DefaultItemProps> = ({ text, link }) => {
  return <StyledDefaultItem {...link}>{text}</StyledDefaultItem>
}

export default DefaultItem
