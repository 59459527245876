import { FunctionComponent, Ref, useMemo } from 'react'
import styled, { css, DefaultTheme, useTheme } from 'styled-components'
import {
  CommonCTAProps,
  ButtonProps,
  commonCTAStyles,
  TextWithIconWrapper,
} from './CommonCTA'

type PrimaryCTAButtonColorVariant = 'red' | 'green' | 'white' | 'black' | 'grey'

export type PrimaryCTAButtonProps = CommonCTAProps &
  ButtonProps & {
    colorVariant?: PrimaryCTAButtonColorVariant
    ref?: Ref<HTMLButtonElement>
  }

export const getColorVariantsMapForPrimaryCTAButton = (
  themeColor: DefaultTheme['colors']
): Record<
  PrimaryCTAButtonColorVariant,
  {
    background: {
      default: string
      hover: string
      focus: string
      disabled: string
    }
    text: {
      default: string
      disabled: string
    }
  }
> => ({
  red: {
    background: {
      default: themeColor.fillBrand,
      hover: themeColor.staticRed900,
      focus: themeColor.staticRed900,
      disabled: themeColor.fillDefaultDisabled,
    },
    text: {
      default: themeColor.staticWhite,
      disabled: themeColor.textDefaultDisabled,
    },
  },
  green: {
    background: {
      default: themeColor.fillSport,
      hover: themeColor.staticGreen900,
      focus: themeColor.staticGreen900,
      disabled: themeColor.fillDefaultDisabled,
    },
    text: {
      default: themeColor.staticWhite,
      disabled: themeColor.textDefaultDisabled,
    },
  },
  white: {
    background: {
      default: themeColor.staticWhite,
      hover: themeColor.staticNeutral100,
      focus: themeColor.staticNeutral100,
      disabled: themeColor.staticNeutral200,
    },
    text: {
      default: themeColor.staticBlack,
      disabled: themeColor.staticNeutral400,
    },
  },
  black: {
    background: {
      default: themeColor.fillInverse,
      hover: themeColor.fillInverseHover,
      focus: themeColor.fillInverseHover,
      disabled: themeColor.fillDefaultDisabled,
    },
    text: {
      default: themeColor.textInverse,
      disabled: themeColor.textDefaultDisabled,
    },
  },
  grey: {
    background: {
      default: themeColor.fillStrong,
      hover: themeColor.fillStrongHover,
      focus: themeColor.fillStrongHover,
      disabled: themeColor.fillDefaultDisabled,
    },
    text: {
      default: themeColor.textDefault,
      disabled: themeColor.textDefaultDisabled,
    },
  },
})

export const primaryCTAButtonStyles = css<StyledPrimaryCTAButtonType>`
  ${({ buttonStateColors, disabled }) => css`
    background-color: ${buttonStateColors.background.default};
    color: ${buttonStateColors.text.default};

    & > svg {
      color: ${buttonStateColors.text.default};
      fill: ${buttonStateColors.text.default};
    }

    @media (hover: hover) {
      &:hover:not([disabled]) {
        background-color: ${buttonStateColors.background.hover};
      }
    }

    &:focus-visible {
      background-color: ${buttonStateColors.background.focus};
    }

    ${disabled &&
    css`
      pointer-events: none;
      background-color: ${buttonStateColors.background.disabled};
      color: ${buttonStateColors.text.disabled};

      & > svg {
        color: ${buttonStateColors.text.disabled};
        fill: ${buttonStateColors.text.disabled};
      }
    `};
  `}
`

export type StyledPrimaryCTAButtonType = Required<
  Pick<PrimaryCTAButtonProps, 'size' | 'iconLeft' | 'disabled'>
> & {
  buttonStateColors: ReturnType<
    typeof getColorVariantsMapForPrimaryCTAButton
  >[PrimaryCTAButtonColorVariant]
}

const StyledPrimaryCTAButton = styled.button<StyledPrimaryCTAButtonType>`
  ${commonCTAStyles}
  ${primaryCTAButtonStyles}
`

const PrimaryCTAButton: FunctionComponent<PrimaryCTAButtonProps> = ({
  colorVariant = 'red',
  size = 'large',
  type = 'button',
  disabled = false,
  iconName,
  iconLeft = false,
  className,
  children,
  onClick,
  ref = null,
}) => {
  const theme = useTheme()

  const buttonStateColors = useMemo(
    () => getColorVariantsMapForPrimaryCTAButton(theme.colors)[colorVariant],
    [theme.colors, colorVariant]
  )

  return (
    <StyledPrimaryCTAButton
      ref={ref}
      className={className}
      size={size}
      disabled={disabled}
      type={type}
      onClick={onClick}
      buttonStateColors={buttonStateColors}
      iconLeft={iconLeft}>
      {iconName ? (
        <TextWithIconWrapper iconName={iconName} size={size}>
          {children}
        </TextWithIconWrapper>
      ) : (
        children
      )}
    </StyledPrimaryCTAButton>
  )
}

export default PrimaryCTAButton
