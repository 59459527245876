import { ConfigFn } from '@config/types'

const config = (({ publication }) =>
  ({
    mailtoAddress: `${
      publication === 'romandie' ? 'signalement' : 'fehler'
    }@blick.ch`,
  }) as const) satisfies ConfigFn

export default config
