import { ConfigFn } from '@config/types'

const config = (({ publication }) =>
  ({
    googleNewsUrl:
      publication === 'romandie'
        ? 'https://news.google.com/publications/CAAqBwgKMMSapgswrKW-Aw'
        : 'https://news.google.com/publications/CAAiEO0blL6qrYjj-vkJj2W5ymIqFAgKIhDtG5S-qq2I4_r5CY9lucpi',
  }) as const) satisfies ConfigFn

export default config
