import { useCallback, useEffect, useRef, useState } from 'react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import {
  UseSportsEventProps,
  UseSportsEventReturnValue,
} from '@hooks/useSportsEvent'

const getSportsEvent = <
  T extends Pick<UseSportsEventProps, 'matchId' | 'typeOfSport'>,
>(
  queryClient: QueryClient,
  { matchId, typeOfSport }: T
): UseSportsEventReturnValue<T>['data'] | undefined => {
  return queryClient.getQueryData<UseSportsEventReturnValue<T>['data']>([
    'sportsEvent',
    typeOfSport,
    matchId,
  ])
}

const useSubscribeToSportsEventCache = <
  T extends Pick<UseSportsEventProps, 'typeOfSport' | 'matchId'>,
>(
  props: T
) => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const [existingSportsEventData, setExistingSportsEventData] = useState<
    ReturnType<typeof getSportsEvent<T>>
  >(() => getSportsEvent<T>(queryClient, props))

  const updateExistingSportsEventData = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        JSON.stringify(args.query.queryKey) ===
          JSON.stringify(['sportsEvent', props.typeOfSport, props.matchId])
      ) {
        setExistingSportsEventData(getSportsEvent(queryClient, props))
      }
    },
    [props, queryClient]
  )

  useEffect(() => {
    setExistingSportsEventData(getSportsEvent(queryClient, props))

    unsubscribeFnRef.current = queryCache.subscribe(
      updateExistingSportsEventData
    )

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [props, queryCache, queryClient, updateExistingSportsEventData])

  return existingSportsEventData
}

export { getSportsEvent }
export default useSubscribeToSportsEventCache
