import { BlickPlusSkeleton } from '@hooks/useBlickPlusSkeleton'
import { FeatureFlagsSkeleton } from '@hooks/useFeatureFlagsSkeleton'
import { FooterSkeleton } from '@hooks/useFooter'
import { NavigationSkeleton } from '@hooks/useNavigation'
import { PageMetadata } from '@hooks/usePageMetadata'
import { VideoSkeleton } from '@hooks/useVideoSkeleton'
import { TargetContentType } from './types'

export type PageType = 'section' | 'article' | 'dossier'

export type RSSPathType =
  | 'rss'
  | 'mod_rss'
  | 'msn_rss'
  | 'bplus_feed'
  | 'bplus_feed_mixed'

const RSS_PATHS: RSSPathType[] = [
  'rss',
  'mod_rss',
  'msn_rss',
  'bplus_feed',
  'bplus_feed_mixed',
]

export interface ValidSkeletonData {
  kind: ['skeleton', 'api', 'web']
  skeletonData: [
    {
      adSDK: {
        version: string
        environment: 'staging' | 'prod'
      }
      navigation: NavigationSkeleton
      footer: FooterSkeleton
      video: VideoSkeleton
      disableThirdPartyScripts: boolean
      blickPlus: BlickPlusSkeleton
      featureFlags: FeatureFlagsSkeleton
    },
  ]
}

const isValidSkeletonData = (
  skeletonData: any
): skeletonData is ValidSkeletonData =>
  Object.keys(skeletonData?.skeletonData?.[0]?.navigation || {}).length > 0 &&
  Object.keys(skeletonData?.skeletonData?.[0]?.footer || {}).length > 0 &&
  Object.keys(skeletonData?.skeletonData?.[0]?.video || {}).length > 0

const isValidPageMetadata = (pageMetadata: any): pageMetadata is PageMetadata =>
  !!Object.keys(pageMetadata ?? {})?.length

const isVideoOnlyArticle = (targetContentType?: TargetContentType) =>
  targetContentType === 'video'

// RSS redirects
// /rss => /rss.xml
// /mod_rss => /mod_rss.xml
// /msn_rss => /msn_rss.xml
const getRedirectToRSSInfo = (requestUrl: string) => ({
  shouldRedirect: RSS_PATHS.some((rssPath) =>
    requestUrl.endsWith(`/${rssPath}/`)
  ),
  redirectPath: `${
    requestUrl.endsWith('/') ? requestUrl.slice(0, -1) : requestUrl
  }.xml`,
})

const getRSSContentInfo = (requestUrl: string) => {
  const rssPath: RSSPathType | undefined = RSS_PATHS.find((path) =>
    requestUrl.endsWith(`/${path}.xml`)
  )

  const rssRequestUrl = requestUrl
    ?.replace('mod_rss', 'rss')
    ?.replace('msn_rss', 'rss')
    ?.replace('bplus_feed_mixed', 'blickplus/rss-extended')
    ?.replace('bplus_feed', 'rss')

  return {
    shouldServeRSSCONTENT: !!rssPath,
    rssPath,
    rssRequestUrl,
  }
}

const hasImmersiveHero = (pageMetadata: PageMetadata): boolean =>
  pageMetadata?.hasImmersiveHero?.[0] === true

const extractPageType = (metadata: any): PageType => {
  if (!isValidPageMetadata(metadata)) {
    return 'section'
  }

  if (metadata.context === 'dossier') {
    return 'dossier'
  }

  if (['section', 'article'].includes(metadata.type)) {
    return metadata.type
  }

  return 'section'
}

export {
  extractPageType,
  hasImmersiveHero,
  isValidSkeletonData,
  getRedirectToRSSInfo,
  getRSSContentInfo,
  isVideoOnlyArticle,
}
