import {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  Ref,
  SyntheticEvent,
} from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'

interface InputProps {
  onChange?: (event?: SyntheticEvent<HTMLInputElement>) => void
  onKeyUp?: (event?: SyntheticEvent<HTMLInputElement>) => void
  placeholder?: string
  internalValue?: string
  className?: string
  children?: ReactNode
  ref?: Ref<HTMLInputElement>
}

const StyledWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing16, spacing24 },
      color: {
        tertiary: { grey400 },
      },
    },
  }) => css`
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    padding: ${spacing8} ${spacing16} ${spacing8} ${spacing24};
    border: 1px solid ${grey400};
    border-radius: 74px;

    ${mobileCSS(css`
      width: 100%;
      height: 48px;
    `)}
  `}
`

const StyledInput = styled.input`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
      color: {
        primary: { primary01 },
        tertiary: { grey700 },
      },
    },
  }) => css`
    color: ${primary01};
    flex-grow: 1;
    border: none;
    outline: 0;

    ${small2BodycopyCSS}
    &::placeholder {
      ${small2BodycopyCSS};
      color: ${grey700};
    }
  `}
`

const Input: FunctionComponent<PropsWithChildren<InputProps>> = ({
  internalValue,
  onChange,
  className,
  placeholder,
  children,
  onKeyUp,
  ref = null,
}) => {
  return (
    <StyledWrapper className={className}>
      <StyledInput
        ref={ref}
        type="text"
        onChange={onChange}
        onKeyUp={onKeyUp}
        value={internalValue}
        placeholder={placeholder}
      />
      {children}
    </StyledWrapper>
  )
}

export default Input
