import { FunctionComponent, useEffect } from 'react'
import config from '@config'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { getLocalStorageItem, setLocalStorageItem } from '@utils/localStorage'
import { captureException } from '@utils/sentry'
import { User } from '@hooks/useUser'

export type GetSubscriptionDataToTrackFn = (
  subscriptions: SubscriptionAPIValidResponse['subscriptions']
) => SubscribedSubscriptionData | NotSubscribedSubscriptionData

export type FetchAuthDataFn = () => Promise<{
  metadata: User
  subscriptions: Subscription[] | Record<'error', true>
} | null>

export type Subscription = {
  active: boolean
  type: string
  typeId: string
  typeName: string
  startDate: string
  expirationDate: string
  subscriptionId: string
  isInTrial: boolean
  isPromotion: boolean | null
}

export interface SubscriptionAPIValidResponse {
  subscriptions: Subscription[]
}

export interface BaseSubscriptionData {
  user_status: 'subscribed' | 'notSubscribed' | 'notSpecified'
  conversion_type?: Subscription['type']
}

interface SubscribedSubscriptionData extends BaseSubscriptionData {
  user_status: 'subscribed'
  product_name: Subscription['typeName']
  product_id: Subscription['typeId']
  subscription_valid_until: Subscription['expirationDate']
  is_trial: Subscription['isInTrial']
}

interface NotSpecifiedSubscriptionData extends BaseSubscriptionData {
  user_status: 'notSpecified'
}

interface NotSubscribedSubscriptionData extends BaseSubscriptionData {
  user_status: 'notSubscribed'
}

export type SubscriptionData =
  | SubscribedSubscriptionData
  | NotSpecifiedSubscriptionData
  | NotSubscribedSubscriptionData
  | undefined

export type FetchSubscriptionDataFn = (
  queryClient: QueryClient
) => Promise<SubscriptionData>

const {
  ads: {
    admeira: { storageKey: admeiraStorageKey },
  },
  auth: { authURL, metadataAndSubscriptionsPath },
} = config

const notSubscribedPayload: NotSubscribedSubscriptionData = {
  user_status: 'notSubscribed',
}

const notSpecifiedPayload: NotSpecifiedSubscriptionData = {
  user_status: 'notSpecified',
}

const getSubscriptionDataToTrack: GetSubscriptionDataToTrackFn = (
  subscriptions
) => {
  // Discard inactive subscriptions, they are not relevant for tracking
  const activeSubscriptions = subscriptions.filter(
    (subscription) => subscription.active
  )

  if (activeSubscriptions.length === 0) {
    return notSubscribedPayload
  }

  const { typeName, typeId, expirationDate, isInTrial, type } =
    getMostRecentSubscription(activeSubscriptions)

  // When the user doesn't have any subscriptions or when a user has access due to a Registration Term,
  // the user should not be flagged as 'subscribed' in the user status for tracking
  if (type === 'Registration') {
    return {
      ...notSubscribedPayload,
      conversion_type: type,
    }
  }

  return {
    user_status: 'subscribed',
    product_name: typeName,
    product_id: typeId,
    subscription_valid_until: expirationDate,
    is_trial: isInTrial,
    conversion_type: type,
  }
}

// In case user has more than one active subscription, we should track the one with the most recent startDate
const getMostRecentSubscription = (subscriptions: Subscription[]) => {
  if (subscriptions.length > 1) {
    const sortedSubscriptions = [...subscriptions].sort(
      (subA, subB) =>
        new Date(subB.startDate).getTime() - new Date(subA.startDate).getTime()
    )
    return sortedSubscriptions[0]
  }

  return subscriptions[0]
}

const fetchAuthData: FetchAuthDataFn = async () => {
  try {
    const response = await fetch(`${authURL}${metadataAndSubscriptionsPath}`, {
      credentials: 'include',
    })

    if (!response.ok && response.status !== 401) {
      captureException(`HTTP ${response.status} error fetching user`)
      return null
    }

    const authData = await response.json()

    if (!authData?.metadata) {
      return null
    }

    if (!!authData?.metadata?.admeira_id) {
      setLocalStorageItem(admeiraStorageKey, {
        ...getLocalStorageItem<{ admeiraId: string }>(admeiraStorageKey),
        admeiraId: authData.metadata.admeira_id,
      })
    }

    return {
      metadata: authData.metadata,
      subscriptions: authData.subscriptions,
    } as {
      metadata: User
      subscriptions: Subscription[] | Record<'error', true>
    }
  } catch {
    return null
  }
}

const performUserLoginFlow = async (queryClient: QueryClient) => {
  const authData = await fetchAuthData()

  if (!authData?.metadata) {
    queryClient.setQueryData<User>(['user'], null)
    queryClient.setQueryData<SubscriptionData>(
      ['subscription-data'],
      notSubscribedPayload
    )
  } else {
    queryClient.setQueryData<User>(['user'], authData.metadata)
    const subscriptions = authData.subscriptions
    if ((subscriptions as Record<'error', true>)?.error) {
      queryClient.setQueryData<SubscriptionData>(
        ['subscription-data'],
        notSpecifiedPayload
      )
    } else {
      queryClient.setQueryData<SubscriptionData>(
        ['subscription-data'],
        getSubscriptionDataToTrack(
          subscriptions as SubscriptionAPIValidResponse['subscriptions']
        )
      )
    }
  }

  queryClient.setQueryData<boolean>(['auth-resolved'], true)

  queryClient.invalidateQueries({
    predicate: (query) =>
      ['auth-resolved', 'subscription-data', 'user'].includes(
        query.queryKey[0] as string
      ),
  })
}

const AuthManager: FunctionComponent = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const isAuthResolved = queryClient.getQueryData<boolean>(['auth-resolved'])
    if (!isAuthResolved) {
      performUserLoginFlow(queryClient)
    }
  }, [queryClient])

  return null
}

export default AuthManager
