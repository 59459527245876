import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import StartInfoComponent from '@components/StartInfo'
import { validMatchId } from '@hooks/useSportsEvent'
import { LiveEventDataSportTypes } from '@utils/formatters/liveEvent'

export interface StartInfoProps {
  title: string
  matchId: string
  typeOfSport: LiveEventDataSportTypes
}

const StartInfo: FunctionComponent<StartInfoProps> = ({
  title,
  typeOfSport,
  matchId,
}) => {
  if (!validMatchId(matchId)) {
    return null
  }
  if (typeOfSport !== 'soccer') {
    return null
  }

  return (
    <StartInfoComponent
      title={title}
      typeOfSport={typeOfSport}
      matchId={matchId}
    />
  )
}

const widget = {
  kind: ['start-info'],
  component: StartInfo,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
