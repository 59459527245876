import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

type UseIsContentRestricted = () => boolean

const CONTENT_RESTRICTED_KEY = 'isContentRestricted'

const useIsContentRestricted: UseIsContentRestricted = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const [isContentRestricted, setIsContentRestricted] = useState<boolean>(
    () => !!queryClient.getQueryData<boolean>([CONTENT_RESTRICTED_KEY])
  )

  const updateIsContentRestricted = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === CONTENT_RESTRICTED_KEY
      ) {
        setIsContentRestricted(
          !!queryClient.getQueryData<boolean>([CONTENT_RESTRICTED_KEY])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateIsContentRestricted)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateIsContentRestricted, queryCache])

  return isContentRestricted
}

export default useIsContentRestricted
