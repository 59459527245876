import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
interface CatchwordProps {
  children: ReactNode
}

const StyledCatchword = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
      typography: {
        subheads: {
          small1: { bundledCSS: subheadSmall1 },
        },
      },
      colors: { textBrand },
      utils: { ellipsis },
    },
  }) => {
    return css`
      margin-bottom: ${spacing8};

      //! Needed because "ellipsis()" returns "display: inline-block;"
      //! when the second argument is 1.
      display: block;
      color: ${textBrand};

      ${subheadSmall1};

      ${desktopCSS(css`
        ${ellipsis('100%', 1)};
      `)}

      ${mobileAndTabletCSS(css`
        ${ellipsis('100%', 2)};
      `)}
    `
  }}
`

const Catchword: FunctionComponent<CatchwordProps> = ({ children }) => {
  return <StyledCatchword>{children}</StyledCatchword>
}

export default Catchword
