import { ConfigFn } from '@config/types'

const config = (() => {
  const projectId = '43892'
  const url = `https://api.beyondwords.io/v1/projects/${projectId}/player`
  return {
    projectId,
    sourceUrl: `${url}/by_source_id/`,
    contentUrl: `${url}/by_content_id/`,
    nonBlickPlusSubscriberContentId: 'bb3148ba-857b-4733-8197-a5c4ffe04877',
    informationLink: 'https://www.blick.ch/id19711657.html',
  } as const
}) satisfies ConfigFn

export default config
