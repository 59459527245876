import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import { desktopCSS } from '@measures/responsive'
import { formatNumberByPublication } from './utils'
import { StadiumInfoProps } from '@widgets/StadiumInfo/types'
import useSubscribeToSportsEventCache from '@hooks/useSubscribeToSportsEventCache'

type StadiumInfoComponentProps = Omit<StadiumInfoProps, 'kind'>

const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing16};
  `}
`

const StyledTitle = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingCSS },
        },
      },
      colors: { strokeSport },
      spacing: { spacing2, spacing8 },
    },
  }) => css`
    ${smallHeadingCSS};
    text-transform: uppercase;
    border-left: ${spacing2} solid ${strokeSport};
    padding-left: ${spacing8};
  `}
`

const StyledContentWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing32, spacing16 },
      colors: { strokeWeak },
    },
  }) => css`
    display: flex;
    gap: ${spacing16};
    border-bottom: 1px solid ${strokeWeak};
    padding-bottom: ${spacing16};
    justify-content: space-between;
    ${desktopCSS(css`
      gap: ${spacing32};
    `)};
  `}
`
const StyledCapacityWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing4 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing4};
  `}
`

const StyledItemWrapper = styled(StyledCapacityWrapper)`
  overflow: hidden;
`

const StyledInfoText = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadCSS },
          large1: { bundledCSS: large1HeadingCSS },
        },
      },
      colors: { textDefault },
    },
  }) => css`
    ${small1SubheadCSS};
    ${desktopCSS(css`
      ${large1HeadingCSS};
    `)};
    color: ${textDefault};
    text-wrap: wrap;
  `}
`
const StyledInfoCategory = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          xsmall2: { bundledCSS: xsmall2SubheadCSS },
        },
      },
      colors: { textWeak },
      utils: { ellipsis },
    },
  }) => css`
    ${xsmall2SubheadCSS};
    color: ${textWeak};
    ${ellipsis('100%', 1)};
  `}
`

const StadiumInfoComponent: FunctionComponent<StadiumInfoComponentProps> = ({
  title,
  typeOfSport,
  matchId,
}) => {
  const existingSportsEventData = useSubscribeToSportsEventCache({
    matchId,
    typeOfSport,
  })

  if (!existingSportsEventData?.formattedData.stadium) {
    return null
  }

  const { city, country, name, capacity } =
    existingSportsEventData.formattedData.stadium

  return (
    <Wrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledContentWrapper>
        <StyledItemWrapper>
          <StyledInfoCategory>
            {city}, {country}
          </StyledInfoCategory>
          <StyledInfoText>{name}</StyledInfoText>
        </StyledItemWrapper>
        <StyledCapacityWrapper>
          <StyledInfoCategory>
            {translate('stadiumInfo.capacity')}
          </StyledInfoCategory>
          <StyledInfoText>{formatNumberByPublication(capacity)}</StyledInfoText>
        </StyledCapacityWrapper>
      </StyledContentWrapper>
    </Wrapper>
  )
}

export default StadiumInfoComponent
