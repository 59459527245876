import { FunctionComponent, useState } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import SummaryDisclaimer, {
  type SummaryDisclaimerProps,
} from '@components/Summary/SummaryDisclaimer'
import { trackClickHandler, TrackingClickType } from './utils'
import useTracking from '@hooks/useTracking'
import { intent, TQuestionData, WidgetIdValues } from '@utils/chatbot/types'
import { useQuery } from '@tanstack/react-query'
import { getQuestions, HTTP_METHOD_VALUES } from '@utils/chatbot'
import useIsUserLoggedIn from '@hooks/useIsUserLoggedIn'
import { QuestionContainer } from '../ArticleQuestion/Question'
import useChatbot from '@hooks/useChatbot'
import useAuthentication from '@hooks/useAuthentication'
import ChatbotInput from '@components/Chatbot/ChatbotInput'
import usePageMetadata from '@hooks/usePageMetadata'
import useIsCUIEnabled from '@hooks/useIsCUIEnabled'
import WidgetButton from '@components/Chatbot/buttons/WidgetButton'
import QuestionLoading from '@components/Chatbot/loading/QuestionLoading'
import AnswerMessage from '@components/Chatbot/messages/AnswerMessage'
import { ChatbotMessage } from '@components/Chatbot/types'
import SecondaryCTAButton from '@components/Buttons/SecondaryCTAButton'
import SummaryHeaderWrapper from '@components/Summary/SummaryHeaderWrapper'

const StyledAnswerMessage = styled(AnswerMessage)`
  & > p,
  & > div {
    max-width: 632px;
  }
  & > div:last-child,
  & > div:nth-last-child(2) {
    max-width: unset;
  }
`

const AdditionQuestionsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing24, spacing28 },
      color: {
        tertiary: { grey400 },
      },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing28};
    margin-bottom: ${spacing8};
    padding-top: ${spacing24};
    border-top: 1px solid ${grey400};
  `}
`

const StyledSecondaryButton = styled(SecondaryCTAButton)`
  display: inline-block;
  margin: 0 auto;
  width: 100%;
`

const StyledSummaryDisclaimer = styled(SummaryDisclaimer)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SummaryResultView: FunctionComponent<{
  title: string
  activeIndex?: number
  onCloseHandler: () => void
  disclaimer: SummaryDisclaimerProps
  trackingClickType: TrackingClickType
  answer: ChatbotMessage
  wasOpen: boolean
  afterGenerationDoneCb: () => void
}> = ({
  title,
  answer,
  disclaimer,
  onCloseHandler,
  trackingClickType,
  wasOpen,
  afterGenerationDoneCb,
}) => {
  const isLoggedIn = useIsUserLoggedIn()
  const isChatbotEnabled = useIsCUIEnabled()
  const pageMetadata = usePageMetadata()
  const { onQuestionClick, isLastQuestion } = useChatbot()
  const { login } = useAuthentication()
  const trackClick = useTracking(trackClickHandler)

  const onLogin = () =>
    login({
      prefix: WidgetIdValues.SectionSummary,
      loginCase: 'email_only',
    })

  const [generationfinished, setGenerationFinished] = useState(false)

  const openChatbot = (question: TQuestionData) => {
    if (!isLastQuestion(question.id)) {
      onQuestionClick({
        context: pageMetadata.context,
        isSuggestedQuestion: true,
        id: question.id,
        isCached: false,
        text: question.text,
      })
    }
  }

  const text = answer.content?.map((contentItem) => contentItem.text)?.join(' ')

  const {
    data: nextQuestionData,
    isLoading: nextQuestionIsLoading,
    isError: nextQuestionIsError,
  } = useQuery({
    enabled: !!text && isLoggedIn && isChatbotEnabled,
    queryKey: ['questionSummary.nextQuestion', pageMetadata.context, text],
    queryFn: async () => {
      const data = await getQuestions({
        method: HTTP_METHOD_VALUES.POST,
        body: {
          text,
          context: pageMetadata.context,
        },
      })()

      return data.questions
    },
  })

  const isNextQuestionLoaded =
    nextQuestionData && !nextQuestionIsLoading && !nextQuestionIsError
  return (
    <>
      <SummaryHeaderWrapper
        title={title}
        onCloseButtonClick={onCloseHandler}
        enabled={true}
        showBetaTag={true}
      />

      {answer && (
        <StyledAnswerMessage
          answer={answer}
          onGeneratingAnswerFinished={() => {
            setGenerationFinished(true)
            afterGenerationDoneCb()
          }}
          showUserSnapRating={false}
          onSlideClick={(url) => {
            try {
              return trackClick({
                event: trackingClickType,
                label: 'article_source',
                linkUrl: new URL(url).pathname,
              })
            } finally {
              return trackClick({
                event: trackingClickType,
                label: 'article_source',
                linkUrl: url,
              })
            }
          }}
          skipAnimation={wasOpen}
          intent={intent.news}
        />
      )}
      {generationfinished && (
        <>
          {isChatbotEnabled && (
            <AdditionQuestionsWrapper>
              {(isNextQuestionLoaded && (
                <QuestionContainer>
                  {nextQuestionData.slice(0, 2).map((data) => (
                    <WidgetButton
                      onClick={() => {
                        openChatbot(data)
                      }}
                      key={data.id}
                      buttonText={data.text}></WidgetButton>
                  ))}
                </QuestionContainer>
              )) ||
                (isLoggedIn && <QuestionLoading buttonsCount={2} />)}
              {(isLoggedIn && <ChatbotInput />) || (
                <StyledSecondaryButton size="large" onClick={() => onLogin()}>
                  {translate('chatbot.footer.loginButton')}
                </StyledSecondaryButton>
              )}
            </AdditionQuestionsWrapper>
          )}
          <StyledSummaryDisclaimer {...disclaimer} />
        </>
      )}
    </>
  )
}

export default SummaryResultView
