import config from '@config'
import { AvailableImageCrops, Image } from '@utils/cook/types'
import { CookWidgetJSON } from '@widgets/types'

export interface CueLivePostIcon {
  image: Required<Pick<Image<AvailableImageCrops>, 'src' | 'width' | 'height'>>
  label: string
}

type CueLiveEvent = {
  action?: 'goal'
  kind?: string
}

export type CueLivePost = {
  id: number
  type?: undefined
  eventId: number
  eTag?: string
  values: CookWidgetJSON[]
  publishedAt: number
  icon?: CueLivePostIcon
  event?: CueLiveEvent
}

export type CueLiveAdPost = {
  id: string
  type: 'ad'
  publishedAt: number
}

export type CueLiveTeaserPost = Pick<
  CueLivePost,
  'id' | 'eventId' | 'publishedAt'
> & { title: string }

export type CueLiveSchemaPost = CueLiveTeaserPost & { body: string }

export type CueLiveData = {
  sticky: CueLivePost[]
  entries: CueLivePost[]
  previousCursor?: string
  afterCursor?: string
  newest: string
  oldest: string
  refreshRate: number
}

export type CueLiveTeaserData = Pick<CueLiveData, 'refreshRate'> & {
  entries: CueLiveTeaserPost[]
}

export type CueLiveSchemaData = Pick<CueLiveData, 'refreshRate'> & {
  entries: CueLiveSchemaPost[]
}

export type FetchCueLive = (args: {
  cueLiveId: string
  options?: {
    limit?: number
    before?: string
    after?: string
  }
  isServerRequest?: boolean
}) => Promise<CueLiveData>

export type FetchCueLiveTeaser = (args: {
  cueLiveId: string
  isServerRequest?: boolean
}) => Promise<CueLiveTeaserData>

export type FetchCueLiveSchemaData = (args: {
  cueLiveId: string
  isServerRequest?: boolean
}) => Promise<CueLiveSchemaData>

const {
  backend: { cueLiveEventBaseUrl, cueLiveTeaserEventBaseUrl },
} = config

const fetchCueLive: FetchCueLive = async ({
  cueLiveId,
  options = { limit: 10 },
  isServerRequest,
}) => {
  const requestUrl = new URL(`${cueLiveEventBaseUrl}${cueLiveId}`)
  const requestHeaders: Partial<Record<'User-Agent', 'next'>> = {}

  if (isServerRequest) {
    requestHeaders['User-Agent'] = 'next'
  }

  Object.entries(options ?? {}).forEach(([key, value]) =>
    requestUrl.searchParams.append(key, `${value}`)
  )

  return (await fetch(requestUrl, { headers: requestHeaders })).json()
}

const fetchCueLiveTeaser: FetchCueLiveTeaser = async ({
  cueLiveId,
  isServerRequest,
}) => {
  const requestUrl = new URL(`${cueLiveTeaserEventBaseUrl}${cueLiveId}`)
  const requestHeaders: Partial<Record<'User-Agent', 'next'>> = {}

  if (isServerRequest) {
    requestHeaders['User-Agent'] = 'next'
  }

  return (await fetch(requestUrl, { headers: requestHeaders })).json()
}

const fetchCueLiveSchemaData: FetchCueLiveSchemaData = async ({
  cueLiveId,
  isServerRequest,
}) => {
  const requestUrl = new URL(`${cueLiveTeaserEventBaseUrl}${cueLiveId}`)
  const requestHeaders: Partial<Record<'User-Agent', 'next'>> = {}

  requestUrl.searchParams.append('limit', '10')
  requestUrl.searchParams.append('includeBody', 'true')

  if (isServerRequest) {
    requestHeaders['User-Agent'] = 'next'
  }

  return (await fetch(requestUrl, { headers: requestHeaders })).json()
}

export { fetchCueLive, fetchCueLiveTeaser, fetchCueLiveSchemaData }
