import { ConfigFn } from '@config/types'

const config = (({ plusLinkShareUrl }) =>
  ({
    linkGenerationApiUrl: `${plusLinkShareUrl}/generateLink`,
    linkConsumptionApiUrl: `${plusLinkShareUrl}/consumeLink`,
    getSharesApiUrl: `${plusLinkShareUrl}/shares`,
  }) as const) satisfies ConfigFn

export type configType = ReturnType<typeof config>
export default config
