import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { TransformedDataTennis } from '../utils'

interface TennisProps {
  sportsData: TransformedDataTennis
  className?: string
}

const Wrapper = styled.div<{ hideScoreboard: boolean }>`
  ${({
    hideScoreboard,
    theme: {
      colors: { staticBlack, staticWhite },
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    color: ${staticWhite};
    background-color: ${staticBlack};
    width: 100%;
    box-sizing: border-box;
    padding: ${spacing16};
    display: grid;
    ${desktopCSS(css`
      padding: ${spacing16} ${spacing24};
      min-height: 94px;
    `)};

    ${mobileAndTabletCSS(css`
      min-height: 86px;
    `)};

    ${hideScoreboard &&
    css`
      grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
      grid-column-gap: ${spacing16};
      justify-content: flex-start;
      align-items: center;
    `}
  `}
`

const PlayerName = styled.div<{ type: 'home' | 'away' }>`
  ${({
    type,
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: headingsXSmall },
        },
      },
      utils: { ellipsis },
    },
  }) => css`
    ${headingsXSmall};
    ${ellipsis('100%', 1)};
    ${type === 'away' &&
    css`
      justify-self: flex-end;
    `}
  `}
`

const MiddleInfo = styled.div`
  ${({
    theme: {
      typography: {
        headings: { xlarge: headingXLarge },
      },
    },
  }) => css`
    ${headingXLarge};
    justify-self: center;
  `}
`

const PlayerRow = styled.div<{ hasGameScore: boolean; nrOfScores: number }>`
  ${({
    nrOfScores,
    hasGameScore,
    theme: {
      colors: { staticNeutral700 },
      spacing: { spacing12, spacing4, spacing16 },
    },
  }) => css`
    &:not(:last-child) {
      border-bottom: 1px solid ${staticNeutral700};
      padding-bottom: ${spacing12};
    }
    &:last-child {
      padding-top: ${spacing12};
    }
    display: grid;
    grid-template-columns:
      minmax(0, 1fr) repeat(${nrOfScores}, ${spacing16})
      ${hasGameScore && '20px'};
    grid-column-gap: ${spacing4};
    align-items: center;
    justify-items: center;
  `}
`

const CompetitorName = styled.div<{ isWinner: boolean }>`
  ${({
    isWinner,
    theme: {
      colors: { staticWhite, staticGreen500 },
      typography: {
        headings: {
          xsmall: { bundledCSS: headingsXSmall },
        },
      },
    },
  }) => css`
    ${headingsXSmall};
    justify-self: flex-start;
    color: ${isWinner ? staticGreen500 : staticWhite};
  `}
`

const SetScore = styled.span<{ isWinning: boolean }>`
  ${({
    isWinning,
    theme: {
      colors: { staticWhite, staticNeutral700 },
      typography: {
        catchword: {
          medium1: { bundledCSS: catchwordMedium1 },
        },
      },
    },
  }) => css`
    ${catchwordMedium1};
    color: ${isWinning ? staticWhite : staticNeutral700};
  `}
`

const GameScore = styled.div`
  ${({
    theme: {
      typography: {
        catchword: {
          medium2: { bundledCSS: catchwordMedium2 },
        },
      },
      colors: { staticGreen500 },
    },
  }) => css`
    ${catchwordMedium2};
    color: ${staticGreen500};
    justify-self: flex-end;
  `}
`

const Tennis: FunctionComponent<TennisProps> = ({ className, sportsData }) => {
  const {
    homePlayer,
    awayPlayer,
    hasStarted,
    isCancelled,
    periodScores,
    gameState,
  } = sportsData

  if (!homePlayer || !awayPlayer) {
    return null
  }

  const homePlayerName = homePlayer.name
  const awayPlayerName = awayPlayer.name

  if (!hasStarted || isCancelled) {
    return (
      <Wrapper hideScoreboard={true} className={className}>
        <PlayerName type="home">{homePlayerName}</PlayerName>
        <MiddleInfo>VS</MiddleInfo>
        <PlayerName type="away">{awayPlayerName}</PlayerName>
      </Wrapper>
    )
  }
  return (
    <Wrapper hideScoreboard={false}>
      <PlayerRow hasGameScore={!!gameState} nrOfScores={periodScores.length}>
        <CompetitorName isWinner={!!homePlayer?.isWinner}>
          {homePlayerName}
        </CompetitorName>
        {periodScores.map(({ home_score, number, winningScore }) => (
          <SetScore key={number} isWinning={winningScore === 'home'}>
            {home_score}
          </SetScore>
        ))}
        <GameScore>{gameState?.sdpHomeScore}</GameScore>
      </PlayerRow>
      <PlayerRow hasGameScore={!!gameState} nrOfScores={periodScores.length}>
        <CompetitorName isWinner={!!awayPlayer?.isWinner}>
          {awayPlayerName}
        </CompetitorName>
        {periodScores.map(({ away_score, number, winningScore }) => (
          <SetScore key={number} isWinning={winningScore === 'away'}>
            {away_score}
          </SetScore>
        ))}
        <GameScore>{gameState?.sdpAwayScore}</GameScore>
      </PlayerRow>
    </Wrapper>
  )
}

export default Tennis
