import usePageMetadata from '@hooks/usePageMetadata'
import { FunctionComponent, useMemo } from 'react'
import {
  createItemList,
  getAuthorsId,
  getParsedAuthors,
} from '@components/Schema/utils'
import config from '@config'
import SchemaHTMLElement from '@components/SchemaHTMLElement'
import PersonSchema from '@components/Schema/PersonSchema'

const {
  backend: { baseUrl },
  schema: { organizationId },
} = config

const AuthorSchema: FunctionComponent = () => {
  const pageMetadata = usePageMetadata()
  const { url, lastModifiedDate, publishedDate, authors, authorSchema } =
    pageMetadata
  const preparedAuthors = useMemo(() => getParsedAuthors(authors), [authors])
  const authorsId = useMemo(
    () => getAuthorsId(preparedAuthors),
    [preparedAuthors]
  )
  return (
    <>
      {!!preparedAuthors.length &&
        preparedAuthors?.map((author) => (
          <PersonSchema key={author.name} {...author} />
        ))}
      <SchemaHTMLElement
        scriptKey="author"
        schemaObject={{
          '@type': 'CollectionPage',
          mainEntityOfPage: `${baseUrl}${url}`,
          url: `${baseUrl}${url}`,
          ...(authors?.[0]?.name ? { name: authors?.[0]?.name } : {}),
          dateModified: lastModifiedDate,
          datePublished: publishedDate,
          ...(authorSchema?.links?.length
            ? { mainEntity: createItemList(authorSchema.links) }
            : {}),
          ...(!!authorsId.length ? { author: authorsId } : {}),
          publisher: {
            '@id': organizationId,
          },
        }}
      />
    </>
  )
}

export default AuthorSchema
