import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: grid;
    grid-gap: ${spacing16};
  `}
`

const ArticleHeader: FunctionComponent<{
  children?: ReactNode
}> = ({ children }) => <Wrapper>{children}</Wrapper>

export default ArticleHeader
