import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const ErrorWrapper = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopy },
        },
      },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${small2BodyCopy};
    & div:last-child {
      padding-bottom: 0;
    }
  `}
`

export const ErrorView: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  return <ErrorWrapper>{children}</ErrorWrapper>
}
