import usePageMetadata from '@hooks/usePageMetadata'
import { FunctionComponent, useMemo } from 'react'
import config from '@config'
import { useQueryClient } from '@tanstack/react-query'
import { CueLiveSchemaData } from '@utils/cueLive'
import SchemaHTMLElement from '@components/SchemaHTMLElement'
import { getAuthorsId, getPreparedAuthors } from '@components/Schema/utils'
import PersonSchema from '@components/Schema/PersonSchema'
import BlogPostingSchema from '@components/Schema/BlogPostingSchema'

const {
  backend: { baseUrl },
  schema: { organizationId, blogPostingUrl, liveBlogPostingUrl },
} = config

const CueLiveSchema: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const pageMetadata = usePageMetadata()

  const {
    url,
    lastModifiedDate,
    publishedDate,
    metaTitle,
    metaDescription,
    cueLiveIds,
    adChannel,
    authors,
    id,
  } = pageMetadata

  const preparedAuthors = useMemo(() => getPreparedAuthors(authors), [authors])

  const authorsId = useMemo(
    () => getAuthorsId(preparedAuthors),
    [preparedAuthors]
  )

  const cueLiveSchemaData = cueLiveIds?.[0]
    ? (queryClient.getQueryData<CueLiveSchemaData>([
        'cue-live',
        cueLiveIds?.[0],
        'schemaOrg',
      ]) as CueLiveSchemaData)
    : undefined

  if (!cueLiveSchemaData) {
    return null
  }

  return (
    <>
      {!!preparedAuthors.length &&
        preparedAuthors?.map((author) => (
          <PersonSchema key={author.name} {...author} />
        ))}
      {!!cueLiveSchemaData?.entries?.length &&
        cueLiveSchemaData?.entries?.map(({ id, publishedAt, title, body }) => (
          <BlogPostingSchema
            key={id}
            id={id}
            datePublished={publishedAt}
            articleBody={body}
            headline={title}
          />
        ))}
      <SchemaHTMLElement
        scriptKey="cue-live-schema"
        schemaObject={{
          '@context': 'https://schema.org',
          '@type': 'LiveBlogPosting',
          '@id': `${liveBlogPostingUrl}${id}`,
          isPartOf: {
            '@id': `${baseUrl}${url}`,
          },
          articleSection: adChannel,
          mainEntityOfPage: { '@id': `${baseUrl}${url}` },
          datePublished: publishedDate,
          dateModified: lastModifiedDate,
          headline: metaTitle,
          description: metaDescription,
          publisher: {
            '@id': organizationId,
          },
          ...(authorsId.length ? { author: authorsId } : {}),
          liveBlogUpdate: cueLiveSchemaData.entries.map(({ id }) => ({
            '@id': `${blogPostingUrl}${id}`,
          })),
        }}
      />
    </>
  )
}

export default CueLiveSchema
