import { useChatbotTypingEffect } from '@hooks/useChatbot/useChatbotTypingEffect'
import { FunctionComponent, useEffect } from 'react'
import { TypingEffectWrapperProps } from '../types'

const TypingAnimationWrapper: FunctionComponent<TypingEffectWrapperProps> = ({
  text,
  afterText,
  skipAnimation,
  afterTypingEffectEndCb,
  time = 10,
}) => {
  const { displayText, animationFinished } = useChatbotTypingEffect(
    text,
    time,
    skipAnimation
  )

  useEffect(() => {
    if (animationFinished) afterTypingEffectEndCb?.()
  }, [animationFinished, afterTypingEffectEndCb])

  return (
    <>
      <span translate={`${animationFinished ? 'yes' : 'no'}`}>
        {displayText}
      </span>
      {animationFinished && afterText}
    </>
  )
}

export default TypingAnimationWrapper
