import config from '@config'
import { dayjs } from '@utils/date'

const {
  publication: { publication },
} = config

type formattedDateInfo = {
  dayOfWeek: string
  date: string
}

const formatDateByPublication = (date: string): formattedDateInfo => {
  const dateFormat =
    publication === 'romandie'
      ? 'DD MMMM YYYY [à] HH:mm'
      : 'DD. MMMM YYYY [um] HH:mm [Uhr]'

  const formattedDayOfWeek = dayjs(date).format('dddd')
  const formattedDate = dayjs(date).format(dateFormat)

  return {
    dayOfWeek: formattedDayOfWeek,
    date: formattedDate,
  }
}

export { formatDateByPublication }
