import useABTestValue from '@hooks/useABTestValue'
import useURLParam from '@hooks/useURLParam'

export type UseShowMiddleToi = () => boolean

const useShowMiddleToi: UseShowMiddleToi = () =>
  [
    useURLParam('showMiddleToi') === 'true',
    useABTestValue('showMiddleToi') === 'show',
  ].some(Boolean)

export default useShowMiddleToi
