import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import styled, { css } from 'styled-components'
import config from '@config'
import FeatureFlagEntry from './FeatureFlagEntry'
import { resetLocalStorage } from './utils'
import { desktopCSS } from '@measures/responsive'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'

const {
  abTest: { featureFlagsEntries },
} = config

const DashboardWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing16};

    padding-top: ${spacing16};
    ${desktopCSS(css`
      padding-top: ${spacing24};
    `)}
  `}
`

const DashboardTitle = styled.h1`
  ${({
    theme: {
      colors: { textDefault },
      typography: {
        headings: {
          large: { bundledCSS: largeHeadingCSS },
          xxxlarge: { bundledCSS: xxxlargeHeadingCSS },
        },
      },
    },
  }) => css`
    margin: 0;
    padding: 0;
    color: ${textDefault};

    ${largeHeadingCSS};
    ${desktopCSS(css`
      ${xxxlargeHeadingCSS};
    `)}
  `}
`

const DashboardDescription = styled.p`
  ${({
    theme: {
      colors: { textBrand },
      typography: {
        bodycopy: {
          medium1: { bundledCSS: medium1BodyCopyCSS },
          small1: { bundledCSS: small1BodyCopyCSS },
        },
      },
    },
  }) => css`
    margin: 0;
    color: ${textBrand};

    ${small1BodyCopyCSS};
    ${desktopCSS(css`
      ${medium1BodyCopyCSS};
    `)}
  `}
`

const StyledFeatureFlagsWrapper = styled.ul`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing16};

    list-style: none;
    padding: 0;

    margin: ${spacing16} 0 0 0;
    ${desktopCSS(css`
      margin: ${spacing24} 0 0 0;
    `)}
  `}
`

const FeatureFlagDashboardWidget: FunctionComponent = () => {
  const [updateKey, setUpdateKey] = useState(0)

  const forceUpdate = useCallback(() => {
    setUpdateKey((prevUpdateKey) => prevUpdateKey + 1)
  }, [])

  useEffect(() => {
    addEventListener('focus', forceUpdate)

    return () => {
      removeEventListener('focus', forceUpdate)
    }
  }, [forceUpdate])

  useEffect(() => {
    addEventListener('storage', forceUpdate)

    return () => {
      removeEventListener('storage', forceUpdate)
    }
  }, [forceUpdate])

  return (
    <DashboardWrapper>
      <DashboardTitle>Available Feature Flags</DashboardTitle>
      <DashboardDescription>
        Important: Resetting a variant means that the default behavior is
        exhibited, which means Optimize could still set a variant if an
        experiment is running.
      </DashboardDescription>
      <PrimaryCTAButton
        size="large"
        onClick={() => {
          resetLocalStorage()
          forceUpdate()
        }}>
        Reset to initial settings
      </PrimaryCTAButton>

      <StyledFeatureFlagsWrapper>
        {(
          Object.entries(featureFlagsEntries) as unknown as [
            keyof typeof featureFlagsEntries,
            (typeof featureFlagsEntries)[keyof typeof featureFlagsEntries],
          ][]
        ).map(([featureFlagName, featureFlagInfo]) => (
          <FeatureFlagEntry
            key={featureFlagName}
            name={featureFlagName}
            {...featureFlagInfo}
            updateKey={`${updateKey}`}
          />
        ))}
      </StyledFeatureFlagsWrapper>
    </DashboardWrapper>
  )
}

const widget = {
  kind: ['feature-flag-dashboard'],
  component: FeatureFlagDashboardWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
