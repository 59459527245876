import SchemaHTMLElement from '@components/SchemaHTMLElement'
import { FunctionComponent } from 'react'
import { createOrganizationSchema } from '@components//Schema/utils'
import { OrganizationSchemaProps } from '@components/Schema/types'

const OrganizationSchema: FunctionComponent<OrganizationSchemaProps> = (
  props
) => {
  const { url } = props
  return (
    <SchemaHTMLElement
      scriptKey={`organization-${url}`}
      schemaObject={createOrganizationSchema(props)}
    />
  )
}

export default OrganizationSchema
