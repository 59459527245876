import { ConfigFn } from '@config/types'

export type SlotCodePlaceholderConfig = Record<
  string,
  {
    desktop?:
      | {
          specialStickyVertical?: false
          stretchToFullSectionWidth?: boolean
          stretchToFullSingleColumnArticleWidth?: boolean
          noExtendedPlaceholderOnArticle?: boolean
          height: number
        }
      | {
          specialStickyVertical?: true
          stretchToFullSectionWidth?: false
          stretchToFullSingleColumnArticleWidth?: false
          noExtendedPlaceholderOnArticle?: false
          height?: undefined
        }
    tablet?: {
      noExtendedPlaceholderOnArticle?: boolean
      noStretchOnSection?: boolean
      height: number
    }
    mobile?: {
      noExtendedPlaceholderOnArticle?: boolean
      noStretchOnSection?: boolean
      height: number
    }
  }
>

const config = (({ publication }) =>
  ({
    videoTypeThresholdInSeconds: 90,
    gallery: {
      firstAdAfterXSlides: 2,
      showAdAfterXSlides: 5,
    },
    adPlaceholderTitle: publication === 'romandie' ? 'Publicité' : 'Werbung',
    slotCodePlaceholderConfig: {
      top_ad_1: {
        desktop: {
          stretchToFullSectionWidth: true,
          noExtendedPlaceholderOnArticle: true,
          height: 250,
        },
        tablet: {
          noExtendedPlaceholderOnArticle: true,
          height: 250,
        },
        mobile: {
          noExtendedPlaceholderOnArticle: true,
          height: 250,
        },
      },
      top_ad_2: {
        tablet: {
          noExtendedPlaceholderOnArticle: true,
          height: 480,
        },
        mobile: {
          noExtendedPlaceholderOnArticle: true,
          height: 480,
        },
      },
      index_ad_1: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 600,
        },
        mobile: {
          height: 600,
        },
      },
      index_ad_2: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 600,
        },
        mobile: {
          height: 600,
        },
      },
      index_ad_3: {
        desktop: {
          specialStickyVertical: true,
        },
        tablet: {
          height: 600,
        },
        mobile: {
          height: 600,
        },
      },
      index_special_1: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      index_special_2: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      bottom_ad_1: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 600,
        },
        mobile: {
          height: 600,
        },
      },
      index_teaser_1: {
        desktop: {
          height: 250,
        },
        tablet: {
          noStretchOnSection: true,
          height: 109,
        },
        mobile: {
          noStretchOnSection: true,
          height: 109,
        },
      },
      details_ad_2: {
        desktop: {
          height: 250,
        },
        tablet: {
          height: 600,
        },
        mobile: {
          height: 600,
        },
      },
      details_ad_3: {
        desktop: {
          height: 250,
        },
        tablet: {
          height: 480,
        },
        mobile: {
          height: 480,
        },
      },
      details_ad_4: {
        desktop: {
          stretchToFullSingleColumnArticleWidth: true,
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      details_ad_5: {
        desktop: {
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      'side-right_ad_1': {
        desktop: {
          noExtendedPlaceholderOnArticle: true,
          height: 250,
        },
      },
      'side-right_teaser_1': {
        desktop: {
          noExtendedPlaceholderOnArticle: true,
          height: 250,
        },
      },
      special_ad_1: {
        desktop: {
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      special_ad_2: {
        desktop: {
          stretchToFullSectionWidth: true,
          height: 250,
        },
        tablet: {
          height: 250,
        },
        mobile: {
          height: 250,
        },
      },
      IAV_1: {},
    } satisfies SlotCodePlaceholderConfig,
    admeira: {
      storageKey: 'admeira',
    },
    sdk: {
      fallbackVersion: 'latest',
      fallbackEnvironment: 'prod',
      publisherId: `blick.ch_${publication === 'romandie' ? 'fr' : 'de'}`,
      articleIdKeyName: `articleid${publication === 'romandie' ? '_fr' : ''}`,
      fallbackChannel: 'ROS',
      channelMapping:
        publication === 'romandie'
          ? ({
              News: 'News',
              Suisse: 'News',
              Monde: 'News',
              Opinion: 'Opinion',
              'Tech & Science': 'Tech',
              Sport: 'Sport',
              Lifestyle: 'Life',
              Vidéos: 'Videos',
            } as const)
          : ({
              News: 'News',
              Schweiz: 'News',
              Ausland: 'News',
              Digital: 'Digital',
              People: 'People',
              Sport: 'Sport',
              Meinung: 'Meinung',
              Politik: 'Politik',
              Wirtschaft: 'Wirtschaft',
              Leben: 'Leben',
              Mobil: 'Auto',
              Video: 'Video',
              'Test Section Home': 'Home',
            } as const),
    },
  }) as const) satisfies ConfigFn

export default config
