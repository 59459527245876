import { ConfigFn } from '@config/types'

const config = (({ gamificationTrophyUrl }) =>
  ({
    gamificationTrophies: [
      {
        name: 'EM-Tippspiel',
        url: `${gamificationTrophyUrl}/emtrophy?utm_source=blick&utm_medium=useraccount&utm_campaign=emtrophy`,
      },
    ],
  }) as const) satisfies ConfigFn

export default config
