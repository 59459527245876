import { useCallback, useEffect, useRef, useState } from 'react'
import { getLocalStorageItem, setLocalStorageItem } from '@utils/localStorage'
import { useQueryClient } from '@tanstack/react-query'
import translate from '@i18n'
import { useTheme } from 'styled-components'
import { DefaultTheme } from 'styled-components/dist/types'

export const ONBOARDING_KEY = 'chatbot_onboarding'
export const ONBOARDING_POINTING_ELEMENT_KEY =
  'chatbot_onboarding_pointing_element'
export const ONBOARDING_IN_PROCESS = 'onboarding_in_process'
export const onboardingType = {
  TOOLTIP: 'tooltip',
  HELLO_MESSAGE: 'helloMessage',
} as const

type OnboardingStepData = {
  title?: string
  text: string
  textLogout?: string
  tooltipPointingElement?: HTMLElement
  additionalStyle?: Partial<CSSStyleDeclaration>
  delay: number
}

export type OnboardingStep = {
  step: number
  shouldShowTooltip: boolean
  bypassHelloMessageTypingEffect: boolean
  data?: OnboardingStepData
}

type OnboardingProcessStepTooltip = {
  type: 'tooltip'
  text: string
  textLogoutUser?: string
  pointingElementStyle?: Partial<CSSStyleDeclaration>
  delay?: number
}

type OnboardingProcessStepIntroduce = {
  type: 'helloMessage'
}

type StepData = OnboardingProcessStepTooltip | OnboardingProcessStepIntroduce

const getOnboardingProcessDataGlobal: (theme: DefaultTheme) => StepData[] = (
  theme
) => [
  {
    type: onboardingType.HELLO_MESSAGE,
  },
  {
    type: onboardingType.TOOLTIP,
    text: translate('chatbot.onboarding.tooltip.input'),
    textLogoutUser: translate('chatbot.onboarding.tooltip.input.logout'),
    delay: 3000,
  },
  {
    type: onboardingType.TOOLTIP,
    text: translate('chatbot.onboarding.tooltip.rating'),
    pointingElementStyle: {
      margin: `${theme.spacing.spacing24} -${theme.spacing.spacing16} -${theme.spacing.spacing24} -${theme.spacing.spacing16}`,
      'border-radius': `0px 0px ${theme.spacing.spacing4}px ${theme.spacing.spacing4}px`,
      padding: `${theme.spacing.spacing24} ${theme.spacing.spacing16}`,
    },
  },
]

export const useChatbotOnboarding = () => {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const getOnboardingProcessData = useCallback(
    () => getOnboardingProcessDataGlobal(theme),
    [theme]
  )

  //local storage handlers
  const setOnboardingStepToLocalStorage = useCallback(
    (onboardingStep: number) => {
      setLocalStorageItem(ONBOARDING_KEY, onboardingStep.toString())
    },
    []
  )

  const getOnboardingStepFromLocalStorage = useCallback<
    () => number | null
  >(() => {
    const step = getLocalStorageItem<string | null>(ONBOARDING_KEY)
    return step ? +step : null
  }, [])

  //query client handlers
  const setOnboardingStepToQueryClient = useCallback(
    (data: OnboardingStep | null) => {
      queryClient.setQueryData<OnboardingStep | null>([ONBOARDING_KEY], data)
      queryClient.invalidateQueries({
        queryKey: [ONBOARDING_KEY],
      })
    },
    [queryClient]
  )

  const getTooltipPointingElementQueryClient = useCallback(() => {
    return queryClient.getQueryData<Record<number, Element>>([
      ONBOARDING_POINTING_ELEMENT_KEY,
    ])
  }, [queryClient])

  const setTooltipPointingElementQueryClient = useCallback(
    (element: Element, forStep: number) => {
      let data = getTooltipPointingElementQueryClient()
      if (!data) {
        data = { [forStep]: element }
      } else {
        data[forStep] = element
      }

      queryClient.setQueryData<Record<number, Element>>(
        [ONBOARDING_POINTING_ELEMENT_KEY],
        data
      )
      queryClient.invalidateQueries({
        queryKey: [ONBOARDING_POINTING_ELEMENT_KEY],
      })
    },
    [queryClient, getTooltipPointingElementQueryClient]
  )

  const createDataForOnboardingStep = useCallback(
    (step: number): OnboardingStep | null => {
      const onboardingProcessDataStep = getOnboardingProcessData()[step - 1]
      if (!onboardingProcessDataStep) {
        return null
      }
      const isTooltipType =
        onboardingProcessDataStep.type === onboardingType.TOOLTIP

      return {
        step,
        shouldShowTooltip: isTooltipType,
        bypassHelloMessageTypingEffect:
          onboardingProcessDataStep.type !== onboardingType.HELLO_MESSAGE,
        data: isTooltipType
          ? {
              text: onboardingProcessDataStep.text,
              textLogout: onboardingProcessDataStep.textLogoutUser ?? undefined,
              additionalStyle: onboardingProcessDataStep?.pointingElementStyle
                ? onboardingProcessDataStep?.pointingElementStyle
                : undefined,
              delay: onboardingProcessDataStep?.delay ?? 100,
            }
          : undefined,
      }
    },
    [getOnboardingProcessData]
  )

  const getOnboardingStepDataFromQueryClient = useCallback(() => {
    const onboardingStepData = queryClient.getQueryData<OnboardingStep>([
      ONBOARDING_KEY,
    ])
    let step = getOnboardingStepFromLocalStorage()
    if (!onboardingStepData || !step) {
      if (!step) {
        setOnboardingStepToLocalStorage(1)
        step = 1
      }
      setOnboardingStepToQueryClient(createDataForOnboardingStep(step))
    }
    return queryClient.getQueryData<OnboardingStep>([ONBOARDING_KEY])
  }, [
    queryClient,
    setOnboardingStepToQueryClient,
    createDataForOnboardingStep,
    getOnboardingStepFromLocalStorage,
    setOnboardingStepToLocalStorage,
  ])

  const nextStep = useCallback(() => {
    const step = getOnboardingStepDataFromQueryClient()?.step
    if (!step) return

    const nextStep = step + 1
    const data = createDataForOnboardingStep(nextStep)
    setOnboardingStepToLocalStorage(nextStep)
    setOnboardingStepToQueryClient(data)
    return data
  }, [
    getOnboardingStepDataFromQueryClient,
    createDataForOnboardingStep,
    setOnboardingStepToLocalStorage,
    setOnboardingStepToQueryClient,
  ])

  const setOnboardingInProcessToQueryClient = useCallback(
    (onboardingInProcess: boolean) => {
      queryClient.setQueryData<boolean>(
        [ONBOARDING_IN_PROCESS],
        onboardingInProcess
      )
      queryClient.invalidateQueries({
        queryKey: [ONBOARDING_IN_PROCESS],
      })
    },
    [queryClient]
  )

  const initOnboardingProcess = useCallback(() => {
    let step = getOnboardingStepFromLocalStorage()
    if (!step) {
      step = 1
      setOnboardingStepToLocalStorage(step)
    }
    const data = createDataForOnboardingStep(step)
    setOnboardingStepToQueryClient(data)
    setOnboardingInProcessToQueryClient(!!data)
    return data
  }, [
    getOnboardingStepFromLocalStorage,
    setOnboardingStepToLocalStorage,
    setOnboardingStepToQueryClient,
    createDataForOnboardingStep,
    setOnboardingInProcessToQueryClient,
  ])

  const setTooltipPointingElement = useCallback(
    (element: Element, forStep: number) => {
      const elementFromQueryClient =
        getTooltipPointingElementQueryClient()?.[forStep]
      if (!elementFromQueryClient) {
        setTooltipPointingElementQueryClient(element, forStep)
      }
    },
    [getTooltipPointingElementQueryClient, setTooltipPointingElementQueryClient]
  )

  return { initOnboardingProcess, nextStep, setTooltipPointingElement }
}

export const useChatbotOnboardingInProcess = () => {
  const [onboardingInProcess, setOnboardingInProcess] = useState(false)
  const unsubscribeFnRef = useRef<(() => void) | null>(null)
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()

  const updateOnboardingInProcess = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === ONBOARDING_IN_PROCESS
      ) {
        const onboardingInProcess = queryClient.getQueryData<boolean>([
          ONBOARDING_IN_PROCESS,
        ])
        setOnboardingInProcess(!!onboardingInProcess)
      }
    },
    [queryClient]
  )

  useEffect(() => {
    const onboardingInProcess = queryClient.getQueryData<boolean>([
      ONBOARDING_IN_PROCESS,
    ])
    setOnboardingInProcess(!!onboardingInProcess)

    unsubscribeFnRef.current = queryCache.subscribe((arg: any) => {
      updateOnboardingInProcess(arg)
    })

    return () => {
      unsubscribeFnRef.current?.()
    }
  }, [queryCache, queryClient, updateOnboardingInProcess])

  return onboardingInProcess
}
