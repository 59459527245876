import usePageMetadata from '@hooks/usePageMetadata'
import config from '@config'
import { extractPageType } from '@utils/cook'
import { getPrimaryImageSchema } from '@components/Schema/images'
import { useMemo } from 'react'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const {
  backend: { baseUrl },
  schema: { webSiteId, breadcrumbListSchemaUrl },
} = config

const WebPageSchema = () => {
  const pageMetadata = usePageMetadata()
  const {
    url,
    context,
    metaDescription,
    lastModifiedDate,
    publishedDate,
    title,
    schema,
  } = pageMetadata
  const isHomePage = context === 'home'
  const isArticle = extractPageType(pageMetadata) === 'article'
  const primaryImageOfPage = useMemo(
    () =>
      getPrimaryImageSchema({
        teaserImage: schema?.image,
        isArticle,
      }),
    [isArticle, schema?.image]
  )
  const additionalInfo = {
    primaryImageOfPage: {
      '@id': primaryImageOfPage?.['@id'] || '',
    },
    breadcrumb: {
      '@id': `${breadcrumbListSchemaUrl}${url}`,
    },
  }

  return (
    <>
      {!isHomePage && (
        <SchemaHTMLElement
          scriptKey="webPagePrimaryImage"
          schemaObject={{ ...primaryImageOfPage }}
        />
      )}
      <SchemaHTMLElement
        scriptKey="webPage"
        schemaObject={{
          '@type': 'WebPage',
          id: `${baseUrl}${url}`,
          url: `${baseUrl}${url}`,
          name: title,
          description: metaDescription,
          datePublished: publishedDate,
          dateModified: lastModifiedDate,
          isPartOf: {
            '@id': webSiteId,
          },
          ...(!isHomePage ? additionalInfo : {}),
        }}
      />
    </>
  )
}

export default WebPageSchema
