import styled, { css } from 'styled-components'
import { FunctionComponent, ReactNode } from 'react'
import useIsInHeroModule from '@hooks/useIsInHeroModule'
import {
  IMAGE_HEIGHT,
  IMAGE_HEIGHT_HERO,
} from '@components/TeaserStandardHorizontal/utils'

interface StyledTextWrapperProps {
  isInHeroModule: boolean
}

const StyledTextWrapper = styled.div<StyledTextWrapperProps>`
  ${({
    theme: {
      spacing: { spacing16, spacing2 },
      typography: {
        headings: {
          xsmall: { lineHeight, fontSize },
        },
      },
    },
    isInHeroModule,
  }) => {
    const imageSize = isInHeroModule ? IMAGE_HEIGHT_HERO : IMAGE_HEIGHT
    return css`
      position: absolute;
      bottom: calc(
        ${spacing16} + ((${lineHeight} - 1) * ${fontSize}) + ${spacing2}
      );
      right: ${spacing16};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: calc(100% - ${imageSize}px - 8px - 16px);
    `
  }}
`

interface TextWrapperProps {
  children: ReactNode
}

const TextWrapper: FunctionComponent<TextWrapperProps> = ({ children }) => {
  const isInHeroModule = useIsInHeroModule()
  return (
    <StyledTextWrapper isInHeroModule={isInHeroModule}>
      {children}
    </StyledTextWrapper>
  )
}

export default TextWrapper
