import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import useClientViewportType from '@hooks/useClientViewportType'
import Separator from './Separator'
import useShowMiddleToi from '@hooks/useShowMiddleToi'

interface StyledMobileOnlySeparatorProps {
  shouldBeHidden?: boolean
}

const StyledMobileOnlySeparator = styled(
  Separator
)<StyledMobileOnlySeparatorProps>`
  ${({ shouldBeHidden }) => css`
    ${desktopCSS(css`
      display: none;
    `)};
    ${shouldBeHidden &&
    css`
      display: none;
    `}
  `}
`
interface MobileOnlySeparatorProps {
  variant?: 'middle-toi'
}
const MobileOnlySeparator: FunctionComponent<MobileOnlySeparatorProps> = ({
  variant,
}) => {
  const isMiddleToi = variant === 'middle-toi'
  const shouldBeHidden = [isMiddleToi, useShowMiddleToi()].every(Boolean)
  return useClientViewportType() === 'desktop' ? null : (
    <StyledMobileOnlySeparator shouldBeHidden={shouldBeHidden} />
  )
}

export default MobileOnlySeparator
