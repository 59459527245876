import { FunctionComponent, Ref, useMemo } from 'react'
import styled, { css, DefaultTheme, useTheme } from 'styled-components'
import {
  CommonCTAProps,
  ButtonProps,
  commonCTAStyles,
  TextWithIconWrapper,
} from './CommonCTA'

type SecondaryCTAButtonColorVariant = 'red' | 'green' | 'white' | 'black'

export type SecondaryCTAButtonProps = CommonCTAProps &
  ButtonProps & {
    colorVariant?: SecondaryCTAButtonColorVariant
    ref?: Ref<HTMLButtonElement>
  }

export const getColorVariantsMapForSecondaryCTAButton = (
  themeColor: DefaultTheme['colors']
): Record<
  SecondaryCTAButtonColorVariant,
  {
    border: {
      default: string
      hover: string
      focus: string
      disabled: string
    }
    text: {
      default: string
      hover: string
      focus: string
      disabled: string
    }
  }
> => ({
  red: {
    border: {
      default: themeColor.strokeBrand,
      hover: themeColor.strokeBrandHover,
      focus: themeColor.strokeBrandHover,
      disabled: themeColor.strokeDefaultDisabled,
    },
    text: {
      default: themeColor.textBrand,
      hover: themeColor.staticRed900,
      focus: themeColor.staticRed900,
      disabled: themeColor.textDefaultDisabled,
    },
  },
  green: {
    border: {
      default: themeColor.strokeSport,
      hover: themeColor.staticGreen900,
      focus: themeColor.staticGreen900,
      disabled: themeColor.strokeDefaultDisabled,
    },
    text: {
      default: themeColor.textSport,
      hover: themeColor.staticGreen900,
      focus: themeColor.staticGreen900,
      disabled: themeColor.textDefaultDisabled,
    },
  },
  white: {
    border: {
      default: themeColor.staticWhite,
      hover: themeColor.staticNeutral300,
      focus: themeColor.staticNeutral300,
      disabled: themeColor.staticNeutral300,
    },
    text: {
      default: themeColor.staticWhite,
      hover: themeColor.staticWhite,
      focus: themeColor.staticWhite,
      disabled: themeColor.staticNeutral300,
    },
  },
  black: {
    border: {
      default: themeColor.strokeStrong,
      hover: themeColor.strokeStrongHover,
      focus: themeColor.strokeStrongHover,
      disabled: themeColor.strokeDefaultDisabled,
    },
    text: {
      default: themeColor.textDefault,
      hover: themeColor.textDefaultHover,
      focus: themeColor.textDefaultHover,
      disabled: themeColor.textDefaultDisabled,
    },
  },
})

export type StyledSecondaryCTAButtonType = Required<
  Pick<SecondaryCTAButtonProps, 'size' | 'iconLeft' | 'disabled'>
> & {
  buttonStateColors: ReturnType<
    typeof getColorVariantsMapForSecondaryCTAButton
  >[SecondaryCTAButtonColorVariant]
}

export const secondaryCTAButtonStyles = css<StyledSecondaryCTAButtonType>`
  ${({ buttonStateColors, disabled }) => css`
    background-color: transparent;
    border: 1px solid ${buttonStateColors.border.default};
    color: ${buttonStateColors.text.default};

    & > svg {
      color: ${buttonStateColors.text.default};
      fill: ${buttonStateColors.text.default};
    }

    @media (hover: hover) {
      &:hover:not([disabled]) {
        border: 1px solid ${buttonStateColors.border.hover};
        color: ${buttonStateColors.text.hover};

        & > svg {
          color: ${buttonStateColors.text.hover};
          fill: ${buttonStateColors.text.hover};
        }
      }
    }

    &:focus-visible {
      border: 1px solid ${buttonStateColors.border.focus};
      color: ${buttonStateColors.text.focus};

      & > svg {
        color: ${buttonStateColors.text.focus};
        fill: ${buttonStateColors.text.focus};
      }
    }

    ${disabled &&
    css`
      pointer-events: none;
      border: 1px solid ${buttonStateColors.border.disabled};
      color: ${buttonStateColors.text.disabled};

      & > svg {
        color: ${buttonStateColors.text.disabled};
        fill: ${buttonStateColors.text.disabled};
      }
    `};
  `}
`

const StyledSecondaryCTAButton = styled.button<StyledSecondaryCTAButtonType>`
  ${commonCTAStyles}
  ${secondaryCTAButtonStyles}
`

const SecondaryCTAButton: FunctionComponent<SecondaryCTAButtonProps> = ({
  colorVariant = 'red',
  size = 'large',
  disabled = false,
  type = 'button',
  iconName,
  iconLeft = false,
  className,
  children,
  onClick,
  ref = null,
}) => {
  const theme = useTheme()

  const buttonStateColors = useMemo(
    () => getColorVariantsMapForSecondaryCTAButton(theme.colors)[colorVariant],
    [theme.colors, colorVariant]
  )

  return (
    <StyledSecondaryCTAButton
      ref={ref}
      className={className}
      size={size}
      disabled={disabled}
      type={type}
      onClick={onClick}
      buttonStateColors={buttonStateColors}
      iconLeft={iconLeft}>
      {iconName ? (
        <TextWithIconWrapper iconName={iconName} size={size}>
          {children}
        </TextWithIconWrapper>
      ) : (
        children
      )}
    </StyledSecondaryCTAButton>
  )
}

export default SecondaryCTAButton
