import {
  ArticleSchemaImage,
  Association,
  AuthorForPersonSchema,
  BreadcrumbSchema,
  BreadCrumbsListItem,
  OrganizationSchemaProps,
  Schema,
  SchemaWithContext,
  SoftwareApplicationSchemaProps,
} from '@components/Schema/types'
import androidAppLogo from '@assets/icons/android/android-chrome-192x192.png'
import iosAppLogo from '@assets/icons/apple/apple-touch-icon-180x180.png'
import config from '@config'
import { PageMetadata } from '@hooks/usePageMetadata'
import {
  BLICK_BLUE_SKY_PAGE,
  BLICK_FACEBOOK_PAGE,
  BLICK_INSTAGRAM_PAGE,
  BLICK_TIKTOK_PAGE,
  BLICK_TWITTER_PAGE,
  BLICK_WIKIPEDIA_PAGE,
  BLICK_YOUTUBE_PAGE,
  DEFAULT_AUTHOR,
  ORGANIZATION_DATA,
} from '@components/Schema/constants'
import { Link } from '@utils/cook/types'
import { Author, AuthorProps } from '@widgets/Author/types'
import { defaultLogo } from '@components/Schema/images'

const {
  backend: { baseUrl },
  schema: {
    organizationId,
    organizationSchemaUrl,
    breadcrumbListSchemaUrl,
    personSchemaUrl,
  },
} = config

const withValidSchemaContext = (
  schema: Record<string, unknown>
): SchemaWithContext => ({
  ...schema,
  '@context': 'https://schema.org',
})

const getFilteredData = (data: Record<string, unknown>) =>
  Object.fromEntries(Object.entries(data).filter(([, value]) => value))

const createAuthor = (name = DEFAULT_AUTHOR, link?: Link) => {
  return {
    '@type': 'Person',
    name,
    ...(!!link?.href ? { url: link.href } : {}),
  }
}

const createItemListElement = (url: string, index: number) => ({
  '@type': 'ListItem',
  url: `${baseUrl}${url}`,
  position: index + 1,
})

const createItemList = (urls: string[]) => ({
  '@type': 'ItemList',
  itemListElement: urls.map((url, index) => createItemListElement(url, index)),
})

const createNutritionInformation = (calories?: string) => ({
  nutrition: {
    '@type': 'NutritionInformation',
    calories,
  },
})

const createOrganizationSchema = (
  organizationData: OrganizationSchemaProps
) => {
  const { id, address, ...restData } = organizationData

  const filteredData = getFilteredData(restData)
  return {
    '@type': 'Organization',
    '@id': `${organizationSchemaUrl}${id}`,
    ...filteredData,
    ...(address ? { address: { '@id': address } } : {}),
    '@context': 'https://schema.org',
  }
}

const createStep = (text: string, image?: string) => ({
  '@type': 'HowToStep',
  text,
  image,
})

const createSoftwareApplicationSchema = ({
  operatingSystem,
  description,
  url,
}: SoftwareApplicationSchemaProps) => ({
  '@type': 'SoftwareApplication',
  name: 'Blick',
  description,
  url,
  operatingSystem,
  applicationCategory: 'EntertainmentApplication',
  applicationSubcategory: 'NewsApplication',
  image: {
    '@id': operatingSystem === 'ANDROID' ? androidAppLogo : iosAppLogo,
  },
  aggregateRating: {},
  offers: {
    '@type': 'Offer',
    price: '0.00',
  },
  publisher: {
    '@id': organizationId,
  },
})

const createListItem = (
  position: number,
  url: string,
  name: string,
  isLastItem: boolean
) => ({
  '@type': 'ListItem',
  position,
  name,
  ...(!isLastItem
    ? {
        item: {
          '@id': `${baseUrl}${url}`,
        },
      }
    : {}),
})

const createBreadcrumbList = (
  listItems: ReturnType<typeof createListItem>[],
  url: string
) => ({
  '@id': url,
  '@type': 'BreadcrumbList',
  itemListElement: listItems,
})

const createBreadcrumbDefaultSchema = (
  pageMetadata: PageMetadata
): BreadcrumbSchema | undefined => {
  const { breadcrumbs = [], htmlTitle, url, context } = pageMetadata
  const isHomePage = context === 'home'
  const fullUrl = `${breadcrumbListSchemaUrl}${url}`

  if (isHomePage || breadcrumbs.length === 0 || !htmlTitle) {
    return
  }

  const totalBreadcrumbs = breadcrumbs.length
  const listItems: BreadCrumbsListItem[] = breadcrumbs.map((crumb, index) =>
    createListItem(
      index + 1,
      crumb.href,
      totalBreadcrumbs === index + 1 && htmlTitle ? htmlTitle : crumb.title,
      index + 1 === breadcrumbs.length
    )
  )
  return createBreadcrumbList(listItems, fullUrl)
}

const generateInitialSchema = (pageMetadata: PageMetadata): Schema => ({
  breadcrumb: createBreadcrumbDefaultSchema(pageMetadata),
  blickLogo: defaultLogo,
  organization: createOrganizationSchema(ORGANIZATION_DATA),
})

const getAuthorsId = (authors: AuthorForPersonSchema[]) => {
  return authors.map((author) => ({
    '@id': `${personSchemaUrl}${author.url || author.link?.href}`,
  }))
}

const getImagesId = (images: ArticleSchemaImage[]) => {
  return images.map((image) => ({
    '@id': image.url,
  }))
}

const getParsedAuthors = (authors?: Author[]) => {
  const parsedAuthors: AuthorProps[] = []
  authors?.forEach((author) => {
    if (author.name.includes(',')) {
      const parsedNames = author.name
        .replace(/ (und|et) /, ',')
        .split(',')
        .map((author) => ({ name: author }))
      parsedAuthors.push(...parsedNames)
    } else {
      parsedAuthors.push(author)
    }
  })
  const isValid = parsedAuthors.every(
    (author) =>
      !!author.name.trim()?.[0] &&
      author.name.trim()?.[0] === author.name.trim()?.[0].toUpperCase()
  )
  return isValid ? parsedAuthors : []
}

const getPreparedAuthors = (authors?: AuthorForPersonSchema[]) => {
  return authors
    ? authors
        .filter((author) => !!author.link?.href || !!author.url)
        .map((author) => ({
          ...author,
          src: author.image?.src,
          socialMediaLinks: [
            `${baseUrl}${author.link?.href || author.url}`,
            ...(author?.socialMedia
              ? author.socialMedia.map((socialMediaLink) => socialMediaLink.src)
              : []),
            ...(author?.otherAuthorProfiles
              ? author.otherAuthorProfiles?.map((profile) => profile.url)
              : []),
          ],
        }))
    : []
}

const getPreparedAssociations = (associations?: Association[]) =>
  associations?.map((association) => {
    const {
      organizationKnowledgeGraphId,
      organizationAlternateName,
      organizationUrl,
      organizationWikipedia,
      organizationName,
      organizationId,
    } = association
    const sameAs = [
      ...(organizationKnowledgeGraphId
        ? [
            `https://www.google.com/search?kgmid=/g/${organizationKnowledgeGraphId}`,
          ]
        : []),
      ...(Array.isArray(organizationWikipedia)
        ? organizationWikipedia
        : organizationWikipedia
          ? [organizationWikipedia]
          : []),
    ]
    return {
      id: organizationId || organizationName || '',
      name: organizationName,
      url: organizationUrl,
      alternateName: organizationAlternateName,
      sameAs: sameAs,
    }
  })

const getSameAsInfo = () => ({
  sameAs: [
    BLICK_FACEBOOK_PAGE,
    BLICK_TWITTER_PAGE,
    BLICK_INSTAGRAM_PAGE,
    BLICK_TIKTOK_PAGE,
    BLICK_YOUTUBE_PAGE,
    BLICK_BLUE_SKY_PAGE,
    BLICK_WIKIPEDIA_PAGE,
  ],
})

export {
  createAuthor,
  createItemListElement,
  createItemList,
  createNutritionInformation,
  createOrganizationSchema,
  createStep,
  createSoftwareApplicationSchema,
  generateInitialSchema,
  getAuthorsId,
  getImagesId,
  getParsedAuthors,
  getPreparedAuthors,
  getPreparedAssociations,
  getSameAsInfo,
  withValidSchemaContext,
}
