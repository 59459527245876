import { useEffect, useRef, useState } from 'react'

export const useChatbotTypingEffect = (
  originalText?: string,
  time = 10,
  skipAnimation = false
) => {
  const [displayText, setDisplayText] = useState('')
  const [animationFinished, setAnimationFinished] = useState(false)
  const index = useRef(0)

  useEffect(() => {
    let typingInterval: NodeJS.Timeout | string | number | null = null
    setDisplayText('')
    if (!originalText) return
    if (skipAnimation) {
      setAnimationFinished(true)
      setDisplayText(originalText)
    } else {
      index.current = 0
      typingInterval = setInterval(() => {
        if (index.current < originalText.length) {
          setDisplayText((prevText) => {
            return (
              prevText +
              originalText.charAt(index.current - (index.current === 0 ? 0 : 1))
            )
          })
          index.current++
        } else {
          if (typingInterval) {
            clearInterval(typingInterval)
          }
          setAnimationFinished(true)
          setDisplayText(originalText)
        }
      }, time)
    }

    return () => {
      if (typingInterval) {
        clearInterval(typingInterval)
      }
    }
  }, [originalText, time, skipAnimation])

  return { displayText, animationFinished }
}
