import { RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'

type UpdateNodeHeightType = (value: number | string) => void

export type UseHeightTransitionType = ({
  getIsComponentExpanded,
  collapsedHeight,
}: {
  getIsComponentExpanded: () => boolean
  collapsedHeight?: number
  componentRef: RefObject<HTMLDivElement | null>
}) => {
  showMoreButton: boolean
}

const useHeightTransition: UseHeightTransitionType = ({
  getIsComponentExpanded,
  collapsedHeight = 0,
  componentRef,
}) => {
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false)
  const updateNodeHeight: UpdateNodeHeightType = useCallback(
    (value) => {
      if (componentRef.current) {
        componentRef.current.style.height =
          typeof value === 'number' ? `${value}px` : value
      }
    },
    [componentRef]
  )

  useEffect(() => {
    const scrollHeight = componentRef.current?.scrollHeight
    updateNodeHeight(scrollHeight || collapsedHeight)
    /* this setTimeout in order to set the height to auto after the animation, if the text was collapsed before
       and if the text was expanded, we need setTimeout to fix the animation, since it does not work with auto height*/
    const timeout = setTimeout(
      () =>
        updateNodeHeight(getIsComponentExpanded() ? 'auto' : collapsedHeight),
      getIsComponentExpanded() ? 700 : 0
    )
    return () => clearTimeout(timeout)
  }, [getIsComponentExpanded, collapsedHeight, componentRef, updateNodeHeight])

  useEffect(() => {
    const resizeHandler = debounce(() => {
      if (componentRef.current) {
        const scrollHeight = componentRef.current.scrollHeight
        const textTooLong = scrollHeight > collapsedHeight
        setShowMoreButton(textTooLong)
      }
    }, 100)
    const resizeObserver = new ResizeObserver(resizeHandler)

    if (componentRef.current) {
      resizeObserver.observe(componentRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [collapsedHeight, componentRef])

  return useMemo(() => ({ showMoreButton }), [showMoreButton])
}

export default useHeightTransition
