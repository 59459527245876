import { Image } from '@utils/cook/types'

export type TQuestionData = { id: string; text: string; isCached: boolean }

export type TAnswerData = { text: string; link?: string; category?: string }

export type TSourceData = {
  url: string
  title: string
  isPlus: boolean
  image: Image<'1_1' | '3_2'>
}

export type TLinkData = Record<string, TSourceData>

export type TQuestionResponse = {
  spanId: string
  traceId: string
  version: string
  questions: TQuestionData[]
}

export const intent = {
  news: 'NEWS',
  generalKnowledge: 'GENERALKNOWLEDGE',
  FAREWELL: 'FAREWELL',
  FORGET: 'FORGET',
  GREETING: 'GREETING',
} as const
export type IntentType = (typeof intent)[keyof typeof intent]

export type TAnswerResponse = {
  id?: string
  spanId: string
  traceId: string
  version: string
  content: TAnswerData[]
  links: TLinkData
  conversationId: string
  intent?: IntentType
  newsCategory?: string
  no_answer?: boolean
  type?: string
}

export const WidgetIdValues = {
  ArticleQuestion: 'articleQuestion',
  SectionSummary: 'dailyBriefing',
  QuestionWidget: 'questionWidget',
} as const

export type WidgetId = (typeof WidgetIdValues)[keyof typeof WidgetIdValues]
