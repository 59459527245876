import { ConfigFn } from '@config/types'

const config = (() =>
  ({
    timeFormat: 'mm:ss',
    timeInputFormat: 'seconds',
    dateFormat: 'YYYY-MM-DDTHH:mm:ss.sssZ',
  }) as const) satisfies ConfigFn

export default config
