import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import config from '@config'
import translate from '@i18n'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import SummaryContainer from '@components/Summary/SummaryContainer'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import { ErrorView } from '@components/Summary/SummaryError'
import {
  SkeletonSeedValues,
  SkeletonView,
} from '@components/Summary/SummaryLoadingSkeleton'
import SummaryRequestView from './SummaryRequestView'
import SummaryResultView from './SummaryResultView'
import { trackClickHandler } from './utils'

import { getAnswer, getQuestions } from '@utils/chatbot'
import { SectionSummaryProps } from './types'
import { mobileCSS } from '@measures/responsive'
import { skipToken, useQuery } from '@tanstack/react-query'
import { intent, WidgetIdValues } from '@utils/chatbot/types'
import useModalOverlayPortal from '@hooks/useModalOverlayPortal'
import UserSnapInlineForm from '@components/UserSnap/InlineForm'
import { ChatbotMessage } from '@components/Chatbot/types'
import AnswerMessage from '@components/Chatbot/messages/AnswerMessage'
import SummaryHeaderWrapper from '@components/Summary/SummaryHeaderWrapper'

const {
  sectionSummary: { moreInfoLink, userSnapFeedbackKey },
} = config

const SummaryWrapper = styled.div`
  width: 100%;
  min-height: 211.19px;

  ${mobileCSS(css`
    min-height: 254.38px;
  `)}
`

const StyledSkeletonViewWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    max-width: 326px;
    padding: ${spacing24} 0 ${spacing24} 0;
    display: flex;
    flex-direction: column;
    gap: ${spacing16};

    & > div:last-child {
      width: 183px;
    }
  `}
`

const SectionSummary: FunctionComponent<SectionSummaryProps> = ({
  title = '',
  isEnabled = false,
}) => {
  const [enabled, setEnabled] = useState<boolean>(isEnabled)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { portalNode, enterModalOverlay, exitModalOverlay, InPortal } =
    useModalOverlayPortal()
  const trackWidgetImpression = useTracking(() => ({
    event: 'home_summary_impression',
  }))
  const trackClick = useTracking(trackClickHandler)
  const [wasOpen, setWasOpen] = useState(false)

  const viewportRef = useViewportTracking({
    track: true,
    onImpression: trackWidgetImpression,
  })

  const trackingOnClick = useCallback<TrackingFnType<{ label: string }>>(
    ({ extraData: { label } }) => ({
      event: 'home_summary_click',
      button_label: label,
    }),
    []
  )
  const handleTrackingOnClick = useTracking(trackingOnClick)

  const disclaimer = {
    title: translate('sectionSummary.disclaimerText'),
    links: [
      {
        label: translate('sectionSummary.disclaimerMoreInfo'),
        link: moreInfoLink,
        target: '_blank',
        onClickHandler: (label: string) => handleTrackingOnClick({ label }),
      },
      {
        label: translate('sectionSummary.disclaimerFeedback'),
        target: '_blank',
        onClickHandler: (label: string) => {
          event?.preventDefault()
          handleTrackingOnClick({ label })
          setIsModalVisible(true)
          enterModalOverlay()
        },
      },
    ],
  }

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['sectionSummary.home'],
    queryFn: enabled
      ? async () => {
          const { questions } = await getQuestions({
            widgetId: WidgetIdValues.SectionSummary,
            context: 'home',
          })()

          if (questions.length === 0) {
            throw new Error('No data found')
          }

          const questionId = questions[0].id

          const data = await getAnswer({
            widgetId: WidgetIdValues.SectionSummary,
            context: 'home',
            questionId: questionId,
          })()

          return data
        }
      : skipToken,
  })

  const processedDataForAnswer = useMemo<ChatbotMessage>(
    () => ({
      content: data?.content,
      type: 'answer',
      links: data?.links,
      spanId: data?.spanId,
    }),
    [data]
  )

  const isSummariesLoaded =
    !!(data?.content && data?.content?.length > 0) && enabled && !isError

  const onCloseWidget = useCallback(() => {
    setEnabled(false)
  }, [setEnabled])

  const onShowResults = useCallback(() => {
    trackClick({ event: 'home_summary_click' })
    setEnabled(true)
  }, [trackClick, setEnabled])

  return (
    <SummaryWrapper id="section-summary">
      <SummaryContainer ref={viewportRef}>
        {!enabled && (
          <SummaryRequestView
            title={title}
            text={translate('sectionSummary.request.text')}
            onPrimaryClick={onShowResults}
          />
        )}

        <>
          {portalNode && isModalVisible && (
            <InPortal node={portalNode}>
              <UserSnapInlineForm
                setIsVisible={(isVisible) => {
                  if (!isVisible) {
                    exitModalOverlay()
                  }
                  enterModalOverlay()
                }}
                projectApiKey={userSnapFeedbackKey}
              />
            </InPortal>
          )}
        </>

        {isSummariesLoaded && (
          <>
            <SummaryResultView
              title={title}
              trackingClickType="home_summary_click"
              answer={processedDataForAnswer}
              disclaimer={disclaimer}
              onCloseHandler={onCloseWidget}
              wasOpen={wasOpen}
              afterGenerationDoneCb={() => {
                setWasOpen(true)
              }}
            />
          </>
        )}

        {isLoading && (
          <>
            <SummaryHeaderWrapper
              title={title}
              enabled={enabled}
              showBetaTag={true}
            />
            <StyledSkeletonViewWrapper>
              <SkeletonView
                seed={[{ type: SkeletonSeedValues.Image, multiplier: 1 }]}
              />
              <SkeletonView
                seed={[{ type: SkeletonSeedValues.Paragraph, multiplier: 1 }]}
              />
            </StyledSkeletonViewWrapper>
          </>
        )}
        {isError && enabled && (
          <ErrorView>
            <SummaryHeaderWrapper
              title={title}
              enabled={enabled}
              showBetaTag={true}
            />
            <AnswerMessage
              answer={{
                type: 'answerError',
                content: [
                  {
                    category: translate('chatbot.answerMessage.errorTitle'),
                    text: translate('sectionSummary.request.error'),
                  },
                ],
              }}
              onReloadAnswer={() => refetch()}
              intent={intent.generalKnowledge}
            />
          </ErrorView>
        )}
      </SummaryContainer>
    </SummaryWrapper>
  )
}

const widget = {
  kind: ['section-summary'],
  component: SectionSummary,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
