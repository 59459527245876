import { ConfigFn } from '@config/types'

const config = (() =>
  ({
    clientOnlyURLParamsCommonKey: 'url-param',
    clientOnlyURLParams: [
      { name: 'admforce' },
      { name: 'token' },
      { name: 'layout' },
      { name: 'q' },
      { name: 'mlp' },
      { name: 'sharingPlus' },
      { name: 'blickmk', includeInDataLayer: true },
      { name: 'ifbanner', includeInDataLayer: true },
      { name: 'eaq' },
      { name: 'cui' },
      { name: 'showMiddleToi' },
    ],
  }) as const) satisfies ConfigFn

export default config
