import { ConfigFn } from '@config/types'

const config = (({
  publication,
  deploymentEnv,
  staticAssetPageUrl,
  staticPageUrlSuffix,
}) =>
  ({
    projectId: `${deploymentEnv === 'prod' ? '' : 'stage-'}${
      publication === 'romandie' ? 'epqqqrlhaarjsnb' : 'znbofpciiqccdkq'
    }`,

    formioBaseUrl: 'https://{{projectId}}.form.io',
    formioWidgetUrl: `${staticAssetPageUrl}/formio${staticPageUrlSuffix}`,
  }) as const) satisfies ConfigFn

export default config
