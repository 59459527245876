import { ConfigFn } from '@config/types'

const config = (({ deploymentEnv, searchUrl, publication }) =>
  ({
    urlHashParam: 'bid',
    utmContent: 'blick-bites',
    blickBitesApiUrl: `${searchUrl}/bites`,
    localStorageHideOnboardingOverlayKey: 'blickBitesHideOnboardingOverlay',
    aureusQueryName: 'polaris-bites',
    aureusQuerySection:
      publication === 'romandie'
        ? `polaris-romandie-bites-${deploymentEnv === 'prod' ? 'prod' : 'stg'}`
        : `polaris-bites-${deploymentEnv === 'prod' ? 'prod' : 'stg'}`,
    aureusResultsLimit: 5,
  }) as const) satisfies ConfigFn

export default config
