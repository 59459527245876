import { ArrowsVisibilityType, SwiperClass } from '@hooks/useSwiper'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { ChatbotArticleSliderProps } from './types'
import useViewportType from '@hooks/useViewport/useViewportType'
import SummaryPill from '@components/Summary/SummaryPill'
import SvgIcon from '@components/SvgIcon'
import useChatbot from '@hooks/useChatbot'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'

import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'
import Blink from '@components/Blink'
import NavigationArrows from '@components/Swiper/NavigationArrows'
import Swiper from '@components/Swiper'

export const SliderWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing8 },
    },
  }) => css`
    position: relative;
    margin-top: ${spacing8};
    margin-bottom: ${spacing16};
  `}
`

export const StyledSwiper = styled(Swiper)`
  ${({
    theme: {
      spacing: { spacing8, spacing16, spacing24, spacing32 },
    },
  }) => css`
    width: auto;
    height: auto;
    overflow: visible;

    margin: 0;
    margin-left: -${spacing24};
    margin-right: -${spacing16};
    padding-left: ${spacing8};

    & > div {
      gap: ${spacing16};
    }

    ${desktopCSS(css`
      margin-right: -${spacing16};
      margin-left: -${spacing32};
    `)}

    > .swiper-items-container {
      scroll-snap-type: none; // Important otherwise the gap between the items is not respected
    }

    > .swiper-items-container > *:first-child {
      margin-left: ${spacing16};
      scroll-margin-left: ${spacing16};

      ${desktopCSS(css`
        margin-left: ${spacing24};
        scroll-margin-left: ${spacing24};
      `)}
    }

    > .swiper-items-container > *:last-child {
      margin-right: ${spacing16};
      scroll-margin-right: ${spacing16};

      ${desktopCSS(css`
        margin-right: ${spacing24};
        scroll-margin-right: ${spacing24};
      `)}
    }
  `}
`
export const StyledSwiperHeader = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing16 },
    },
  }) => css`
    margin-top: ${spacing16};
    margin-bottom: ${spacing8};
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  `}
`

export const StyledSwiperHeadline = styled.h1`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadings },
        },
      },
    },
  }) => css`
    display: flex;
    align-items: center;
    margin: 0;
    ${smallHeadings};
    text-transform: uppercase;
  `}
`

export const StyledSwiperText = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodyCopy },
        },
      },
    },
  }) => css`
    ${xxsmallBodyCopy}
  `}
`

export const StyledNavigationArrows = styled(NavigationArrows)<{
  itemsCount: number
}>`
  ${({ itemsCount }) => css`
    display: flex;
    align-items: center;

    ${itemsCount <= 1 &&
    mobileCSS(css`
      display: none;
    `)};
    ${itemsCount <= 2 &&
    tabletCSS(css`
      display: none;
    `)};
    ${itemsCount <= 4 &&
    desktopCSS(css`
      display: none;
    `)}
  `}
`

export const SwiperSlide = styled(Blink)`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02 },
        tertiary: { grey400, grey600 },
      },
      spacing: { spacing8 },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodyCopy },
        },
      },
    },
  }) => css`
    display: flex;
    gap: ${spacing8};
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    box-sizing: border-box;
    width: 160px;
    height: auto;
    color: ${primary01};
    background-color: ${primary02};
    border: 1px solid ${grey400};
    border-radius: 4px;
    padding: ${spacing8};

    ${xxsmallBodyCopy}

    &:hover {
      text-decoration: none;
      border-color: ${grey600};
    }
  `}
`

interface GradientProps {
  pullRight?: boolean
}

export const SliderGradient = styled.div<GradientProps>`
  ${({
    pullRight = false,
    theme: {
      color: {
        tertiary: { grey100 },
      },
      spacing: { spacing16, spacing24 },
      animation: {
        transition: { fast: fastTransition },
      },
    },
  }) => css`
    position: absolute;
    top: 0;
    right: ${pullRight ? 0 : 'auto'};
    margin-left: -${spacing16};

    ${desktopCSS(css`
      margin-left: -${spacing24};
    `)}

    height: 100%;
    width: ${spacing16};

    transition: all ${fastTransition} ease-in-out;

    opacity: 1;

    background: linear-gradient(
      ${pullRight ? '270deg' : '90deg'},
      ${grey100} 0%,
      rgba(255, 255, 255, 0) 100%
    );

    ${pullRight &&
    css`
      margin-right: -${spacing16};

      ${desktopCSS(css`
        margin-right: -${spacing24};
      `)}
    `}
  `}
`

const StyledPlusIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => {
    return css`
      vertical-align: text-bottom;
      margin-right: ${spacing8};
    `
  }}
`

const StyledSwiperSlideContent = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: ${spacing8};
    gap: ${spacing8};
    background: linear-gradient(
      184.15deg,
      rgba(17, 17, 17, 0) 12.29%,
      rgba(17, 17, 17, 0.6) 49.76%
    );
    max-height: 90px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
  `}
`
const StyledSwiperSlideText = styled.span`
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const StyledSummaryPill = styled(SummaryPill)`
  ${({
    theme: {
      color: { primary: primary02 },
      typography: {
        labels: { label3 },
      },
    },
  }) => css`
    background: ${primary02} !important;
    background-color: ${primary02};
    ${label3}
    width: 16px;
    height: 16px;
    & span {
      background: ${primary02};
      background-color: ${primary02};
      ${label3}
      width: 16px;
      height: 16px;
    }
  `}
`

const StyledImageWrapper = styled.div`
  ${({}) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 168px;
    height: 112px;
  `}
`

const StyledSwiperSlide = styled(SwiperSlide)<{ showPill: boolean }>`
  ${({
    showPill,
    theme: {
      color: {
        primary: { primary01, primary02 },
      },
      typography: {
        headings: {
          xxsmall: { bundledCSS: xxsmallHeadings },
        },
        labels: {
          label3: { bundledCSS: label3Labels },
        },
      },
    },
  }) => css`
    width: 168px;
    height: 112px;

    color: ${primary02};

    ${xxsmallHeadings};
    border: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    position: relative;
    ${showPill &&
    `span:first-child {
      background-color: ${primary02};
      color: ${primary01};
      width: 16px;
      height: 16px;
      ${label3Labels}
    }`}

    &:hover {
      text-decoration: none;
      border-color: transparent;
    }
  `}
`

const ChatbotArticleSlider = ({
  links,
  activeIndex,
  showPill = true,
  title,
  onSlideClick,
}: ChatbotArticleSliderProps) => {
  const theme = useTheme()
  const { SCROLL_CONTAINER_CLASS_NAME } = useChatbot()
  const swiperHeaderRef = useRef<HTMLDivElement>(null)
  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>('none')
  const linksCount = Object.keys(links ?? {}).length
  const viewportType = useViewportType()
  const isDesktop = viewportType === 'desktop'

  const onInit = useCallback(
    (swiper: SwiperClass) => {
      if (swiperRef) {
        swiperRef.current = swiper
      }
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  useEffect(() => {
    if (swiperHeaderRef.current && activeIndex !== null && activeIndex >= 0) {
      const container = document.querySelector(
        `.${SCROLL_CONTAINER_CLASS_NAME}`
      )
      const scrollTop = container?.scrollTop || 0
      const top =
        swiperHeaderRef.current?.getBoundingClientRect().top + scrollTop - 200

      container?.scrollTo({ top, behavior: 'smooth' })
      swiperRef.current?.slideTo(activeIndex)
    }
  }, [activeIndex, SCROLL_CONTAINER_CLASS_NAME])

  return (
    <>
      <StyledSwiperHeader ref={swiperHeaderRef}>
        <StyledSwiperHeadline>{title}</StyledSwiperHeadline>

        {arrowsVisibility !== 'none' && (
          <StyledNavigationArrows
            swiperRef={swiperRef}
            arrowsVisibility={arrowsVisibility}
            itemsCount={linksCount}
          />
        )}
      </StyledSwiperHeader>
      <StyledSwiper
        initialSlide={0}
        spaceTopBottom={0}
        slidesPerGroup={isDesktop ? 4 : 2}
        spaceBetween={8}
        onInit={onInit}
        onArrowsVisibility={onArrowsVisibility}
        slides={Object.keys(links).map((key) => {
          const item = links && links[key]
          const url = new URL(item.url)
          return (
            <StyledSwiperSlide
              href={url.pathname}
              key={`source-item-${item.url}-${key}`}
              onClick={() => onSlideClick?.(item.url)}
              showPill={showPill}>
              <StyledSwiperSlideContent>
                {showPill && (
                  <StyledSummaryPill
                    isActive={activeIndex === parseInt(key) - 1}>
                    {key}
                  </StyledSummaryPill>
                )}
                <StyledSwiperSlideText>
                  {item.isPlus && (
                    <StyledPlusIcon
                      iconName="blick-plus-logo"
                      size={19}
                      color={theme.color.primary.primary01}
                    />
                  )}
                  {item.title}
                </StyledSwiperSlideText>
              </StyledSwiperSlideContent>
              <StyledImageWrapper>
                {item.image && (
                  <ResponsiveImageV2
                    alt={item.image.alt}
                    src={item.image.src}
                    crops={item.image.crops}
                    widths={{ desktop: 168, tablet: 168, mobile: 168 }}
                    ratios={{
                      desktop: '3_2',
                      tablet: '3_2',
                      mobile: '3_2',
                    }}
                  />
                )}
              </StyledImageWrapper>
            </StyledSwiperSlide>
          )
        })}
      />
    </>
  )
}

export default ChatbotArticleSlider
