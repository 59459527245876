import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import ScoreboardMatchResultSoccer from './ScoreboardMatchResultSoccer'
import ScoreboardMatchStatus from './ScoreboardMatchStatus'
import ScoreboardTeam from './ScoreboardTeam'
import useScoreboard from '@hooks/useScoreboard'

const StyledLiveIndicatorWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing4 },
    },
  }) => css`
    margin-left: ${spacing4};
    line-height: 0;
    min-width: ${spacing16};
    position: absolute;
    top: ${spacing16};
  `}
`

const StyledLiveIndicatorInnerCircle = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      spacing: { spacing8, spacing4 },
    },
  }) => css`
    position: absolute;
    top: ${spacing4};
    left: ${spacing4};
    background-color: ${blickRedColor};
    border-radius: 50%;
    height: ${spacing8};
    width: ${spacing8};
    line-height: 0;
  `}
`

const StyledLiveIndicatorOuterCircle = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      spacing: { spacing16 },
    },
  }) => css`
    background-color: ${blickRedColor};
    border-radius: 50%;
    display: inline-block;
    height: ${spacing16};
    line-height: 0;
    opacity: 1;
    width: ${spacing16};
    animation: pulsatingBorder 1.6s infinite;
    @keyframes pulsatingBorder {
      0% {
        opacity: 0.9;
        transform: scale(0);
      }
      100% {
        opacity: 0;
        transform: scale(1);
      }
    }
  `}
`

const StyledScoreboardMatchResult = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const StyledScoreboardWrapper = styled.div`
  width: 100%;
  display: grid;
  justify-items: stretch;
  align-items: center;
`

const MainContentWrapper = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr) auto minmax(0, 1fr);
  grid-auto-flow: column;
  align-items: center;
`
const CollapsibleSoccerScoreboard: FunctionComponent = () => {
  const {
    formattedData: { isLive, isScheduled },
  } = useScoreboard<'soccer'>()

  return (
    <StyledScoreboardWrapper>
      {isLive && (
        <StyledLiveIndicatorWrapper>
          <StyledLiveIndicatorInnerCircle />
          <StyledLiveIndicatorOuterCircle />
        </StyledLiveIndicatorWrapper>
      )}
      <MainContentWrapper>
        <ScoreboardTeam />
        <StyledScoreboardMatchResult>
          {isScheduled && <ScoreboardMatchStatus />}
          <ScoreboardMatchResultSoccer />
        </StyledScoreboardMatchResult>
        <ScoreboardTeam isAwayTeam />
      </MainContentWrapper>
    </StyledScoreboardWrapper>
  )
}

export default CollapsibleSoccerScoreboard
