import { mobileCSS, mobileAndTabletCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface PromoLabelProps {
  children: string
}

const Wrapper = styled.div`
  ${() => css`
    position: absolute;
    /* 21px is the distance between the image border and the label.
    12px (distance image border - line) + 1px (line thickness) + 8px (distance line - label) */
    top: 21px;
    left: 0;
    width: 100%;
    text-align: center;

    ${mobileAndTabletCSS(css`
      display: none;
    `)}
  `}
`

const Label = styled.span`
  ${({
    theme: {
      typography: {
        catchword: {
          small2: { bundledCSS: catchwordSmall2 },
        },
      },
      spacing: { spacing4, spacing32 },
      utils: { ellipsis },
      colors: { staticWhite, staticBlack },
    },
  }) => css`
    ${catchwordSmall2};
    text-transform: uppercase;
    color: ${staticBlack};
    background-color: ${staticWhite};
    padding: ${spacing4};

    ${ellipsis('100%', 1)};

    max-width: calc(100% - ${spacing32});
    box-sizing: border-box;

    ${mobileCSS(css`
      display: none;
    `)}
  `}
`

const PromoLabel: FunctionComponent<PromoLabelProps> = ({
  children: promoLabel,
}) => (
  <Wrapper>
    <Label>{promoLabel}</Label>
  </Wrapper>
)

export default PromoLabel
