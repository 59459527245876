import {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
  type FunctionComponent,
} from 'react'
import type { AnswersMessageProps } from '../types'
import styled, { css } from 'styled-components'
import AnswerParagraph from './AnswerParagraph'
import AnswerFooter from './AnswerFooter'
import AnswerParagraphCategory from './AnswerParagraphCategory'
import ReponsiveImageV2 from '@components/ResponsiveImageV2'
import { mobileCSS } from '@measures/responsive'
import { intent as intentConst } from '@utils/chatbot/types'
import SecondaryCTAButton from '@components/Buttons/SecondaryCTAButton'
import translate from '@i18n'

const StyledMessage = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    padding-bottom: ${spacing24};
    padding-top: ${spacing24};
  `}
`

const StyledResponsiveImageWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing32 },
    },
  }) => css`
    width: 326px;
    padding: ${spacing32} 0 ${spacing8} 0;
    max-width: 326px;

    &:first-child {
      padding: 0 0 ${spacing8} 0;
    }
    ${mobileCSS(css`
      width: 100%;
    `)};
  `}
`

const StyledSecondaryCTAButton = styled(SecondaryCTAButton)`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    margin-top: ${spacing16};
  `}
`

const AnswerMessage: FunctionComponent<AnswersMessageProps> = ({
  answer,
  intent,
  onGeneratingAnswerFinished,
  onSlideClick,
  showUserSnapRating = true,
  skipAnimation = false,
  onReloadAnswer,
  className,
}) => {
  const popoverParentRef = useRef<HTMLDivElement | null>(null)
  const [paragraphs, setParagraphs] = useState<ReactNode[]>([])
  const [generatorFinished, setGeneratorFinished] = useState(false)

  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const paragraphGenerator = useCallback(
    function* () {
      for (let index = 0; index < (answer.content?.length ?? 0); index++) {
        const contentItem = answer.content?.[index]
        const contentImage =
          answer.links?.[contentItem?.link ?? index + 1]?.image

        if (
          (contentImage && intent === intentConst.news) ||
          (contentImage &&
            intent === intentConst.generalKnowledge &&
            index === 0)
        ) {
          setParagraphs((prev) => [
            ...prev,
            <StyledResponsiveImageWrapper
              key={`content-image-${index}-${contentImage.alt}`}>
              <ReponsiveImageV2
                alt={contentImage.alt}
                src={contentImage.src}
                crops={contentImage.crops}
                widths={{ desktop: 326, tablet: 326, mobile: 326 }}
                ratios={{
                  desktop: '3_2',
                  tablet: '3_2',
                  mobile: '3_2',
                }}
              />
            </StyledResponsiveImageWrapper>,
          ])
        }

        if (contentItem) {
          if (contentItem.category) {
            setParagraphs((prev) => [
              ...prev,
              <AnswerParagraphCategory
                key={`${contentItem.category}-${index}`}
                categoryText={contentItem.category as string}
                afterTypingEffectEndCb={() => generatorRef.current.next()}
                skipAnimation={skipAnimation || answer.preventTyping}
              />,
            ])
            yield
          }
          const linkItem =
            answer.links && contentItem?.link && answer.links[contentItem.link]
          setParagraphs((prev) => [
            ...prev,
            <AnswerParagraph
              key={`answer-paragraph-${index}`}
              linkItem={typeof linkItem === 'string' ? undefined : linkItem}
              contentItem={contentItem}
              answer={answer}
              afterTypingEffectEndCb={() => generatorRef.current.next()}
              setActiveIndex={setActiveIndex}
              popoverParentRef={popoverParentRef}
              skipAnimation={skipAnimation || answer.preventTyping}
            />,
          ])
        }

        yield
      }
      setGeneratorFinished(true)
      if (onGeneratingAnswerFinished) {
        onGeneratingAnswerFinished()
      }
    },
    [answer, onGeneratingAnswerFinished, skipAnimation, intent]
  )
  const generatorRef = useRef(paragraphGenerator())
  useEffect(() => {
    generatorRef.current.next()
  }, [])

  return (
    <StyledMessage ref={popoverParentRef} className={className}>
      {paragraphs.map((paragraph) => paragraph)}
      {generatorFinished &&
        (answer.type === 'answer' ? (
          <AnswerFooter
            answer={answer}
            activeIndex={activeIndex}
            showUserSnapRating={showUserSnapRating}
            onSlideClick={onSlideClick}
          />
        ) : (
          <StyledSecondaryCTAButton
            colorVariant="black"
            size="small"
            iconName="arrows-rotate"
            onClick={() => onReloadAnswer?.(answer.id)}>
            {translate('chatbot.footer.reload')}
          </StyledSecondaryCTAButton>
        ))}
    </StyledMessage>
  )
}

export default AnswerMessage
