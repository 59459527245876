import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import translate from '@i18n'
import useSportsEvent, {
  UseSportsEventPropsHockey,
  UseSportsEventPropsSoccer,
} from '@hooks/useSportsEvent'
import {
  EnrichedLiveEventTransformedHockey,
  EnrichedLiveEventTransformedSoccer,
} from '@utils/sport'
import {
  TransformedDataHockey,
  TransformedDataSoccer,
  transformDataForSportsComponents,
} from '@components/SportsFooter/utils'
import TeamLogo from '@components/SportsFooter/TeamLogo'
import { RelatedMatchHockey, RelatedMatchSoccer } from '@widgets/RelatedMatches'
import { EntityRelatedMatches } from '@widgets/EntityRelatedMatches'
import useHasBlurryBackground from '@hooks/useHasBlurryBackground'
import TileWrapper from './TileWrapper'

type TileProps = RelatedMatchSoccer | RelatedMatchHockey | EntityRelatedMatches

type StatusType = {
  isLive?: boolean
  hasScoreExplanation?: boolean
  hasBlurryBackground: boolean
}
type TeamLogoType = { type: 'home' | 'away' }
type ScoreType = TeamLogoType & { hasBlurryBackground: boolean }
type TeamNameType = TeamLogoType & { hasBlurryBackground: boolean }

const TournamentName = styled.div<{
  hasBlurryBackground: boolean
}>`
  ${({
    theme: {
      colors: { textWeak, staticWhite },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: bodycopyXXSmall },
        },
      },
      utils: { ellipsis },
    },
    hasBlurryBackground,
  }) => css`
    grid-area: TN;
    ${bodycopyXXSmall};
    color: ${hasBlurryBackground ? staticWhite : textWeak};
    ${ellipsis('100%', 1)};
    display: inline-block;
  `}
`

const StyledTeamLogo = styled(TeamLogo)<TeamLogoType>`
  height: 32px;
  ${({ type }) => css`
    grid-area: ${type === 'home' ? 'L1' : 'L2'};
  `}
`

const TeamName = styled.div<TeamNameType>`
  ${({
    type,
    theme: {
      colors: { textDefault, staticWhite },
      typography: {
        catchword: {
          medium2: { bundledCSS: catchwordMedium2 },
        },
      },
      utils: { ellipsis },
    },
    hasBlurryBackground,
  }) => css`
    ${catchwordMedium2};
    color: ${hasBlurryBackground ? staticWhite : textDefault};
    text-transform: uppercase;
    ${ellipsis('100%', 1)};
    display: inline-block;
    grid-area: ${type === 'home' ? 'N1' : 'N2'};
  `}
`

const Score = styled.div<ScoreType>`
  ${({
    type,
    theme: {
      colors: { textDefault, staticWhite },
      typography: {
        headings: {
          small: { bundledCSS: headingsSmall },
        },
      },
    },
    hasBlurryBackground,
  }) => css`
    grid-area: ${type === 'home' ? 'SC1' : 'SC2'};
    color: ${hasBlurryBackground ? staticWhite : textDefault};
    ${headingsSmall};
  `}
`

const Separator = styled.div<{
  hasBlurryBackground: boolean
}>`
  ${({
    theme: {
      colors: { strokeWeak, staticNeutral400 },
      spacing: { spacing4 },
    },
    hasBlurryBackground,
  }) => css`
    grid-area: SP;
    width: 100%;
    height: 1px;
    background-color: ${hasBlurryBackground ? staticNeutral400 : strokeWeak};
    margin-top: ${spacing4};
    margin-bottom: ${spacing4};
  `}
`

const Status = styled.div<StatusType>`
  ${({
    isLive,
    hasScoreExplanation,
    theme: {
      colors: { textWeak, staticRed400, staticWhite, textBrand },
      typography: {
        catchword: {
          medium1: { bundledCSS: catchwordMedium1 },
          medium2: { bundledCSS: catchwordMedium2 },
        },
      },
    },
    hasBlurryBackground,
  }) => css`
    grid-area: ST;
    ${isLive ? catchwordMedium2 : catchwordMedium1};
    color: ${isLive
      ? hasBlurryBackground
        ? staticRed400
        : textBrand
      : hasBlurryBackground
        ? staticWhite
        : textWeak};
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;

    ${isLive &&
    css`
      &:before {
        content: '';
        display: inline;
        width: 6px;
        height: 6px;
        background-color: ${hasBlurryBackground ? staticRed400 : textBrand};
        border-radius: 50%;
        margin-right: 2px;
      }
    `}

    ${hasScoreExplanation &&
    css`
      display: flex;
      justify-content: space-between;
    `}
  `}
`

const ScoreExplanation = styled.div`
  text-transform: none;
`

const Tile: FunctionComponent<TileProps> = ({ matchId, typeOfSport, link }) => {
  const { data: sportsEventData } = useSportsEvent<
    UseSportsEventPropsHockey | UseSportsEventPropsSoccer
  >({
    matchId,
    typeOfSport,
  })

  const sportsData = transformDataForSportsComponents(
    sportsEventData ??
      ({} as
        | EnrichedLiveEventTransformedHockey
        | EnrichedLiveEventTransformedSoccer)
  )

  const {
    tournamentName,
    homeTeam,
    homeTeamScore,
    awayTeam,
    awayTeamScore,
    isLive,
    scoreExplanation,
    hasEnded,
    statusTitle,
    statusTitleRelatedMatches,
    isScoreAvailable,
  } =
    sportsData ??
    ({ homeTeam: {}, awayTeam: {} } as
      | TransformedDataSoccer
      | TransformedDataHockey)

  const hasScoreExplanation = !!scoreExplanation

  const hasBlurryBackground = useHasBlurryBackground()

  return (
    <TileWrapper
      link={link}
      isPlaceholder={!sportsData}
      hasBlurryBackground={hasBlurryBackground}>
      {!!sportsData && (
        <>
          <TournamentName hasBlurryBackground={hasBlurryBackground}>
            {tournamentName}
          </TournamentName>
          <StyledTeamLogo
            url={homeTeam.logoUrl}
            name={homeTeam.name}
            imageWidth={32}
            type="home"
          />
          <TeamName type="home" hasBlurryBackground={hasBlurryBackground}>
            {homeTeam.name}
          </TeamName>
          {isScoreAvailable && (
            <Score type="home" hasBlurryBackground={hasBlurryBackground}>
              {homeTeamScore}
            </Score>
          )}
          <StyledTeamLogo
            url={awayTeam.logoUrl}
            name={awayTeam.name}
            imageWidth={32}
            type="away"
          />
          <TeamName type="away" hasBlurryBackground={hasBlurryBackground}>
            {awayTeam.name}
          </TeamName>
          {isScoreAvailable && (
            <Score type="away" hasBlurryBackground={hasBlurryBackground}>
              {awayTeamScore}
            </Score>
          )}
          <Separator hasBlurryBackground={hasBlurryBackground} />
          {hasEnded && (
            <Status
              hasScoreExplanation={hasScoreExplanation}
              hasBlurryBackground={hasBlurryBackground}>
              {translate('liveEvent.finished')}
              {statusTitle}
              <ScoreExplanation>
                {hasScoreExplanation && scoreExplanation}
              </ScoreExplanation>
            </Status>
          )}
          {!hasEnded && (
            <Status isLive={isLive} hasBlurryBackground={hasBlurryBackground}>
              {statusTitleRelatedMatches}
            </Status>
          )}
        </>
      )}
    </TileWrapper>
  )
}

export default Tile
