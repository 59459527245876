import { ConfigFn } from '@config/types'

const CHATBOT_VERSION_PATH = '/api/v1'

const config = (({ chatbotChatUrl, chatbotQuestionsUrl }) =>
  ({
    chatbotChatEndpoint: `${chatbotChatUrl}${CHATBOT_VERSION_PATH}`,
    chatbotQuestionsEndpoint: `${chatbotQuestionsUrl}${CHATBOT_VERSION_PATH}`,
    chatbotFooterUserSnapFeedbackKey: '937d9bec-2615-4e79-abc3-363ffcad262a',
    moreInfoLink: 'https://chatbot.blick.ch/',
  }) as const) satisfies ConfigFn

export default config
