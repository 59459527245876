import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

type GrowthBookFeatures = {
  [key: string]: unknown
}

const useGrowthBookFeatures = (): GrowthBookFeatures => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()

  const [growthBookFeatures, setGrowthBookFeatures] =
    useState<GrowthBookFeatures>(
      () =>
        queryClient.getQueryData<GrowthBookFeatures>(['growthbook-features']) ??
        {}
    )

  const unsubscribeFnRef = useRef<() => void>(undefined)

  const updateGrowthBookFeatures = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'growthbook-features'
      ) {
        setGrowthBookFeatures(
          queryClient.getQueryData<GrowthBookFeatures>([
            'growthbook-features',
          ]) ?? {}
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setGrowthBookFeatures(
      queryClient.getQueryData<GrowthBookFeatures>(['growthbook-features']) ??
        {}
    )
    unsubscribeFnRef.current = queryCache.subscribe(updateGrowthBookFeatures)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateGrowthBookFeatures, queryCache, queryClient])

  return growthBookFeatures
}

export default useGrowthBookFeatures
