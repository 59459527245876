import { FunctionComponent, useState } from 'react'
import { QuestionSummaryProps } from './types'
import useTracking from '@hooks/useTracking'
import { trackClickHandler } from '@widgets/SectionSummary/utils'
import SummaryHeaderWrapper from '@components/Summary/SummaryHeaderWrapper'
import AnswerMessage from '@components/Chatbot/messages/AnswerMessage'
import { intent } from '@utils/chatbot/types'

const QuestionSummaryResultView: FunctionComponent<
  Omit<QuestionSummaryProps, 'kind' | 'state'> & {
    onCloseHandler: () => void
    children?: React.ReactNode
    onTypingAnimationFinishedHandler?: () => void
    bypassAnimation?: boolean
  }
> = ({
  title,
  content,
  links,
  question,
  children,
  bypassAnimation = false,
  onCloseHandler,
  onTypingAnimationFinishedHandler,
}) => {
  const trackClick = useTracking(trackClickHandler)
  const [shouldShowChildren, setShouldShowChildren] = useState(false)

  return (
    <>
      <SummaryHeaderWrapper
        title={question?.text ?? title}
        enabled={true}
        onCloseButtonClick={onCloseHandler}
        uppercase={false}
        showBetaTag={true}
        betaTagPullRight={true}
      />
      <AnswerMessage
        intent={intent.generalKnowledge}
        answer={{ content, links, type: 'answer' }}
        showUserSnapRating={false}
        onSlideClick={(url) =>
          trackClick({
            event: 'question_widget_click',
            label: 'article_source',
            linkUrl: url,
          })
        }
        onGeneratingAnswerFinished={() => {
          setShouldShowChildren(true)
          onTypingAnimationFinishedHandler?.()
        }}
        skipAnimation={bypassAnimation}
      />

      {shouldShowChildren && children}
    </>
  )
}

export default QuestionSummaryResultView
