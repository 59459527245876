import config from '@config'
import {
  Breadcrumbs,
  getPageMetadata,
  PageData,
  PageMetadata,
} from '@hooks/usePageMetadata'
import {
  areAdSlotsLoaded,
  deepCopy,
  getPageType,
  loadSlots,
  logAd,
  updateAreAdSlotsLoaded,
} from '@utils/ads'
import { getCookieValue } from '@utils/cookie'
import Script from 'next/script'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { getUser } from '@hooks/useUser'
import { getSubscriptionStatus } from '@hooks/useSubscriptionStatus'
import { getCurrentViewportType } from '@measures/responsive'
import nanoid from '@utils/random'
import useIsPianoSDKLoaded from '@hooks/useIsPianoSDKLoaded'
import useHasPianoSDKErrored from '@hooks/useHasPianoSDKErrored'
import { AdSkeleton } from 'pages/[[...pageUrl]]'
import useClientViewportType, {
  ClientViewportType,
} from '@hooks/useClientViewportType'
import useExecuteOnClientNavigation, {
  UseExecuteOnClientNavigation,
} from '@hooks/useExecuteOnClientNavigation'
import { getURLParam } from '@utils/urlParams'
import useIsAuthResolved from '@hooks/useIsAuthResolved'
import useExecuteBeforeClientNavigation from '@hooks/useExecuteBeforeClientNavigation'

const {
  ads: {
    sdk: {
      fallbackChannel,
      articleIdKeyName: adSdkArticleIdKeyName,
      channelMapping,
      publisherId,
      fallbackEnvironment,
      fallbackVersion,
    },
  },
} = config

const getChannel = (breadcrumbs: Breadcrumbs = []): string => {
  // If breadcrumbs are missing, return fallback channel
  if (!(breadcrumbs?.length > 0)) {
    return fallbackChannel
  } else if (breadcrumbs.length === 1) {
    // Catch if we are on home section
    return breadcrumbs[0].title
  } else {
    // Calculate the channel
    return [...breadcrumbs].reverse().reduce((acc, breadcrumb) => {
      const channel =
        channelMapping[breadcrumb.title as keyof typeof channelMapping]
      return channel && acc === fallbackChannel ? channel : acc
    }, fallbackChannel as string)
  }
}

const initAds = (queryClient: QueryClient): void => {
  const pageMetadata = getPageMetadata(queryClient)

  const {
    id,
    breadcrumbs,
    type,
    sectionIds,
    adChannel,
    syndication,
    targetContentType: contentType,
  } = pageMetadata

  const subscriptionStatus = getSubscriptionStatus(queryClient)

  const user = getUser(queryClient)
  const isUserLoggedIn = !!user
  const userEmail = user?.email

  const shouldAdmForce = [
    getCookieValue('BLICKFORCEADS'),
    getURLParam('admforce', queryClient)?.startsWith('qa'),
  ].some(Boolean)

  let olid = 0

  if (subscriptionStatus === 'subscribed') {
    olid = 3
  } else if (isUserLoggedIn) {
    olid = 2
  }

  const initObj = {
    platform: getCurrentViewportType() !== 'desktop' ? 'MobileWeb' : 'Desktop',
    channel: adChannel ?? getChannel(breadcrumbs),
    targeting: {
      pagetype: getPageType(type),
      ...(id ? { [adSdkArticleIdKeyName]: id } : {}),
      ...(contentType ? { contenttype: contentType } : {}),
      ...(syndication ? { syndication } : {}),
      subsectionid: sectionIds,
      ...(shouldAdmForce ? { admforce: 'qa' } : {}),
      olid,
    },
    ...(userEmail ? { oneId: userEmail } : {}),
  }

  const deepCopiedInitObj = deepCopy(initObj)

  window.admTagMan.q.push((admTagMan) => {
    admTagMan.init(initObj)
  })

  logAd(
    { queryClient },
    `SDK %cInitialized\n${' '.repeat(11)}%cconfig:`,
    'font-weight: bold;',
    '',
    deepCopiedInitObj
  )
}

const triggerAdRegistration = (queryClient: QueryClient): void => {
  const randomCode = `${getCurrentViewportType()}-${nanoid()}`
  const isHerotelling =
    (queryClient.getQueryData<PageData>(['page'])?.pageMetadata as PageMetadata)
      ?.targetContentSubType === 'hero'

  logAd(
    { queryClient },
    `Initialization Code Generated: %c${randomCode}\n${' '.repeat(11)}%cNow all the ad slots present will (re-)register.`,
    'font-weight: bold;',
    'font-weight: bold; color: #FFA500;'
  )

  queryClient.setQueryData(['RiAdTagManagerInitCode'], randomCode)
  queryClient.invalidateQueries({
    queryKey: ['RiAdTagManagerInitCode'],
    exact: true,
  })

  if (isHerotelling) {
    loadSlots(queryClient)
    logAd(
      { queryClient },
      `%cForced loaded slots immediately because of Herotelling\n${' '.repeat(11)}%cSlots that were registered until this point will now be batch loaded.\n${' '.repeat(11)}%cAds registered after this point in time will have to be loaded manually.`,
      'font-weight: bold; color: #FFA500;',
      '',
      'font-weight: bold; color: #FFA500;'
    )
  } else {
    setTimeout(() => {
      if (!areAdSlotsLoaded(queryClient)) {
        loadSlots(queryClient)
        logAd(
          { queryClient },
          `%cForced loaded slots due to timeout\n${' '.repeat(11)}%cSlots that were registered until this point will now be batch loaded.\n${' '.repeat(11)}%cAds registered after this point in time will have to be loaded manually.`,
          'font-weight: bold; color: #FFA500;',
          '',
          'font-weight: bold; color: #FFA500;'
        )
      }
    }, 300)
  }
}

const resetAreAdSlotsLoaded = (queryClient: QueryClient): void => {
  updateAreAdSlotsLoaded(queryClient, false)
  initAds(queryClient)
  triggerAdRegistration(queryClient)
}

const preparePageTransitionForAds = (queryClient: QueryClient): void => {
  const initCode = ''
  queryClient.setQueryData(['RiAdTagManagerInitCode'], initCode)
  queryClient.invalidateQueries({
    queryKey: ['RiAdTagManagerInitCode'],
    exact: true,
  })

  logAd(
    { queryClient },
    `Initialization Code Generated: %c${initCode}\n${' '.repeat(11)}%cNo new slots can register until page transition finishes.`,
    'font-weight: bold;',
    'font-weight: bold; color: #FFA500;'
  )
}

const updateAdSDKLoadedInfo = (queryClient: QueryClient): void => {
  logAd({ queryClient }, '%cSDK LOADED', 'font-weight: bold; color: #2E8B57;')
  queryClient.setQueryData(['RiAdTagManagerScriptLoaded'], true)
  queryClient.invalidateQueries({
    queryKey: ['RiAdTagManagerScriptLoaded'],
    exact: true,
  })
}

const updateAdSDKErroredInfo = (queryClient: QueryClient): void => {
  logAd(
    { queryClient, method: 'warn' },
    '%cSDK FAILED TO LOAD: Script fetch error',
    'font-weight: bold; color: #B22222;'
  )
  queryClient.setQueryData(['RiAdTagManagerScriptError'], true)
  queryClient.invalidateQueries({
    queryKey: ['RiAdTagManagerScriptError'],
    exact: true,
  })
}

const AdManager: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const isAuthResolved = useIsAuthResolved()
  const [isAdSDKLoaded, setIsAdSDKLoaded] = useState<boolean>(false)
  const clientViewportType = useClientViewportType()
  const isScriptInjectedRef = useRef<boolean>(false)
  const previousClientViewportTypeRef = useRef<ClientViewportType | undefined>(
    undefined
  )
  const previousIsAdSDKLoadedRef = useRef<boolean | undefined>(undefined)
  const previousIsAuthResolvedRef = useRef<boolean | undefined>(undefined)

  const [adConfig, setAdConfig] = useState<AdSkeleton>({
    environment: undefined,
    version: undefined,
  })

  const pianoSDKLoadedOrFailed = [
    useHasPianoSDKErrored(),
    useIsPianoSDKLoaded(),
  ].some(Boolean)

  const onAdSDKLoaded = useCallback(() => {
    updateAdSDKLoadedInfo(queryClient)
    setIsAdSDKLoaded(true)
  }, [queryClient])

  const onAdSDKErrored = useCallback(() => {
    updateAdSDKErroredInfo(queryClient)
  }, [queryClient])

  const resetAdSlotsAfterClientSideNavigation = useCallback<
    Parameters<UseExecuteOnClientNavigation>[0]
  >(() => {
    resetAreAdSlotsLoaded(queryClient)
  }, [queryClient])

  useEffect(() => {
    if (
      clientViewportType === previousClientViewportTypeRef.current &&
      isAdSDKLoaded === previousIsAdSDKLoadedRef.current &&
      isAuthResolved === previousIsAuthResolvedRef.current
    ) {
      return
    }

    previousClientViewportTypeRef.current = clientViewportType
    previousIsAdSDKLoadedRef.current = isAdSDKLoaded
    previousIsAuthResolvedRef.current = isAuthResolved

    if (clientViewportType !== 'server' && isAdSDKLoaded && isAuthResolved) {
      resetAreAdSlotsLoaded(queryClient)
    }
  }, [clientViewportType, isAdSDKLoaded, isAuthResolved, queryClient])

  useExecuteBeforeClientNavigation(preparePageTransitionForAds)

  useExecuteOnClientNavigation(resetAdSlotsAfterClientSideNavigation)

  useEffect(() => {
    if (!isScriptInjectedRef.current && pianoSDKLoadedOrFailed) {
      isScriptInjectedRef.current = true
      const atmEnv =
        (getCookieValue('ADM_TAG_MANAGER_ENV') as AdSkeleton['environment']) ||
        queryClient.getQueryData<AdSkeleton>(['adSkeleton'])?.environment ||
        fallbackEnvironment

      const atmVersion =
        (getCookieValue('ADM_TAG_MANAGER_VERSION') as AdSkeleton['version']) ||
        queryClient.getQueryData<AdSkeleton>(['adSkeleton'])?.version ||
        fallbackVersion

      if (!atmEnv) {
        logAd(
          { queryClient, method: 'warn' },
          `%cSDK FAILED TO LOAD: Missing atmEnv`,
          'font-weight: bold; color: #B22222;'
        )
      }

      if (!atmVersion) {
        logAd(
          { queryClient, method: 'warn' },
          `%cSDK FAILED TO LOAD: Missing atmVersion`,
          'font-weight: bold; color: #B22222;'
        )
      }

      setAdConfig({
        version:
          (getCookieValue(
            'ADM_TAG_MANAGER_VERSION'
          ) as AdSkeleton['version']) ||
          queryClient.getQueryData<AdSkeleton>(['adSkeleton'])?.version ||
          fallbackVersion,
        environment:
          (getCookieValue(
            'ADM_TAG_MANAGER_ENV'
          ) as AdSkeleton['environment']) ||
          queryClient.getQueryData<AdSkeleton>(['adSkeleton'])?.environment ||
          fallbackEnvironment,
      })
    }
  }, [pianoSDKLoadedOrFailed, queryClient])

  return adConfig.environment && adConfig.version ? (
    <Script
      id="adm-script"
      strategy="afterInteractive"
      defer={true}
      src={`https://cdn.ringier-advertising.ch/${adConfig.environment}/tagmanager/${publisherId}/${adConfig.version}/atm.js`}
      onLoad={onAdSDKLoaded}
      onError={onAdSDKErrored}
    />
  ) : null
}

export default AdManager
