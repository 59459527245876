import { ConfigFn } from '@config/types'

const config = (({ staticAssetBaseUrl, staticAssetDomain }) =>
  ({
    allowedOriginsToReceivePostMessage: [
      staticAssetDomain,
      'https://www.blick.ch',
      'https://next-stg.blick.ch',
      'https://next-test.blick.ch',
      'https://next-pre.blick.ch',
      'https://php.blick.ch',
      'https://storytelling.blick.ch',
      //* Sailthru
      'https://link-ssr.stg.blick.ch',
      'https://link.blick.ch',
      'https://link2.blick.ch',
    ],
    heightAdjustmentScript: `${staticAssetBaseUrl}/iframeHeightAdjustment.js`,
  }) as const) satisfies ConfigFn

export default config
