import styled, { useTheme, css } from 'styled-components'
import SummaryHeader from './SummaryHeader'
import { FunctionComponent, useMemo } from 'react'
import SvgIcon from '@components/SvgIcon'
import translate from '@i18n'
import ChatbotBetaTag from '@components/Chatbot/ChatbotBetaTag'

const StyledSummaryHeaderWrapper = styled.div<{ showBorder: boolean }>`
  ${({
    theme: {
      color: {
        tertiary: { grey400 },
      },
    },
    showBorder,
  }) => css`
    ${showBorder && `border-bottom: 1px solid ${grey400}`};
    width: 100%;
  `}
`

const StyledTextBetaTagWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${spacing24};
    & > div {
      margin: 0;
    }
  `}
`

const StyledButtonIconPullRight = styled.button`
  flex: 1;
  display: flex;
  justify-content: right;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
`

const StyledChatbotBetaTag = styled(ChatbotBetaTag)<{
  autoMarginLeft?: boolean
}>`
  ${({ autoMarginLeft = false }) => css`
    ${autoMarginLeft && 'margin-left: auto'}
  `}
`

const SummaryHeaderWrapper: FunctionComponent<{
  title: string
  enabled: boolean
  showBorder?: boolean
  showBetaTag?: boolean
  uppercase?: boolean
  betaTagPullRight?: boolean
  onCloseButtonClick?: () => void
}> = ({
  title,
  enabled,
  onCloseButtonClick,
  showBorder = true,
  uppercase = true,
  showBetaTag = false,
  betaTagPullRight = false,
}) => {
  const theme = useTheme()
  const afterTitle = useMemo<React.ReactNode>(
    () => (
      <>
        {showBetaTag && betaTagPullRight && (
          <StyledChatbotBetaTag autoMarginLeft={true} />
        )}
        {enabled && (
          <StyledButtonIconPullRight
            onClick={() => onCloseButtonClick && onCloseButtonClick()}
            aria-label={translate(
              `articleSummary.${enabled ? 'collapse' : 'expand'}Content`
            )}>
            <SvgIcon
              iconName="xmark-large"
              size={24}
              color={theme.color.primary.primary01}
            />
          </StyledButtonIconPullRight>
        )}
      </>
    ),
    [enabled, onCloseButtonClick, showBetaTag, betaTagPullRight, theme]
  )

  return (
    <StyledSummaryHeaderWrapper showBorder={showBorder}>
      <StyledTextBetaTagWrapper>
        <SummaryHeader
          prefixIconName="label-bliki-sparks"
          iconSize={47}
          after={afterTitle}
          uppercase={uppercase}>
          {title} {showBetaTag && !betaTagPullRight && <StyledChatbotBetaTag />}
        </SummaryHeader>
      </StyledTextBetaTagWrapper>
    </StyledSummaryHeaderWrapper>
  )
}

export default SummaryHeaderWrapper
