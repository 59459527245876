import { ConfigFn } from '@config/types'

const config = (({ growthBookEnv }) =>
  ({
    apiHost: 'https://cdn.growthbook.io',
    clientKey:
      growthBookEnv === 'prod' ? 'sdk-sKAcGRgPSEMDy' : 'sdk-pv3veaZkWss0R',
    localStorageUuidKey: 'gbSessionId',
  }) as const) satisfies ConfigFn

export default config
