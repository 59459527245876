import { ConfigFn } from '@config/types'

const config = (() => {
  return {
    moreInfoLink: 'https://www.blick.ch/id19711657',
    userSnapFeedbackKey: 'd65abfb0-c6af-416f-b2bd-0323125c08e1',
  } as const
}) satisfies ConfigFn

export default config
