import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import config from '@config'
import { CheckBoxProps } from './types'
import { writeToLocalStorage } from './utils'
import ControlledCheckbox from '@components/ControlledCheckbox'
import styled from 'styled-components'

const {
  abTest: { windowKey, featureFlagsEntries },
} = config

export type FeatureFlagVariantNames = keyof typeof featureFlagsEntries

const StyledControlledCheckbox = styled(ControlledCheckbox)`
  height: auto;

  > label > div {
    position: relative;
  }
`

const getCheckBoxValue = (variantName: FeatureFlagVariantNames): boolean => {
  try {
    const featureFlagStorageInfo = (JSON.parse(
      localStorage.getItem(windowKey) as any
    ) ?? {}) as Record<FeatureFlagVariantNames, unknown>

    return featureFlagStorageInfo.hasOwnProperty(variantName)
  } catch (err) {
    //! nothing we can do!
    console.error(err)
  }

  return false
}

const synchronizeCheckBoxLocalStorage = ({
  variantName,
  isChecked,
}: {
  variantName: FeatureFlagVariantNames
  isChecked: boolean
}) => {
  try {
    const featureFlagStorageInfo =
      JSON.parse(localStorage.getItem(windowKey) as any) ?? {}

    if (isChecked) {
      const newValue = featureFlagsEntries[variantName]?.values?.[0]?.name

      featureFlagStorageInfo[variantName] = newValue
    } else {
      delete featureFlagStorageInfo[variantName]
    }

    writeToLocalStorage(featureFlagStorageInfo)
  } catch (err) {
    //! nothing we can do!
    console.error(err)
  }
}

const CheckBox: FunctionComponent<CheckBoxProps> = ({
  variantName,
  updateKey,
  children,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const { name, description } = children

  const onChange = useCallback<(event: ChangeEvent<HTMLInputElement>) => void>(
    (event) => {
      setIsChecked(event.currentTarget.checked)
    },
    []
  )

  useEffect(() => {
    setIsChecked(getCheckBoxValue(variantName))
  }, [variantName, updateKey])

  useEffect(() => {
    synchronizeCheckBoxLocalStorage({ variantName, isChecked })
  }, [variantName, isChecked])

  return (
    <StyledControlledCheckbox
      name={`${variantName}.${name}`}
      checked={isChecked}
      onChange={onChange}>
      {description ?? `${name}`}
    </StyledControlledCheckbox>
  )
}

export default CheckBox
