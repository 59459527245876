import { css } from 'styled-components'
import typography from '@themes/default/typography'
import color from '@themes/default/color'

export const live = css`
  &.btv-live {
    .jw-text-live {
      color: #ffffff;
      box-shadow: none;
      height: 24px;
      width: auto;

      ${typography.catchword.small2.bundledCSS}

      &.jw-dvr-live {
        opacity: 0.2;
      }

      &::before {
        opacity: 1;
        background-color: ${color.primary.blickRed};
        margin-right: 2px;
      }

      &.jw-dvr-live::before {
        background-color: #ffffff;
      }
    }

    .jw-knob.btv-dvr-live {
      background-color: ${color.primary.blickRed};
    }

    .jw-text-elapsed {
      display: none;
    }
  }

  .jw-controlbar .jw-slider-time .jw-time-tip .jw-time-thumb {
    border: 0px;
  }
`
