import config from '@config'
import { PlaylistData } from '@widgets/Video/playlist/types'

export type FetchPlaylist = (options: {
  playlistSrc: string
  token?: string
  isServerRequest?: boolean
}) => Promise<PlaylistData | undefined>

const {
  backend: { versionedApiUrl },
} = config

const getIsPlaylistResource = (pageData: any) =>
  pageData?.children?.[0]?.kind?.[0] === 'widget' &&
  pageData?.children?.[0]?.kind?.[1] === 'playlist-videos-items'

const fetchPlaylist: FetchPlaylist = async ({
  playlistSrc,
  token,
  isServerRequest,
}) => {
  const playlistRequestURL = new URL(`${versionedApiUrl}${playlistSrc}`)
  const playlistRequestHeaders: Partial<Record<'User-Agent', 'next'>> = {}

  if (token) {
    playlistRequestURL.searchParams.set('token', token)
  }

  if (isServerRequest) {
    playlistRequestHeaders['User-Agent'] = 'next'
  }

  const result = await (
    await fetch(playlistRequestURL, { headers: playlistRequestHeaders })
  ).json()

  const playlistObj = result?.children?.find(
    (item: Record<string, any>) =>
      item?.kind?.[0] === 'widget' &&
      item?.kind?.[1] === 'playlist-videos-items'
  )

  const { items, title } = playlistObj ?? {}

  if (items?.length > 0) {
    return {
      title,
      items,
    } as PlaylistData
  } else {
    return
  }
}

export { fetchPlaylist, getIsPlaylistResource }
