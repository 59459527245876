import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { desktopCSS, tabletCSS } from '@measures/responsive'
import { Image } from '@utils/cook/types'

import BackgroundImage from './BackgroundImage'
import EntityImage from './Image'
import FollowYourEntity from './FollowYourEntity'

type CollapsibleEntityHeaderProps = {
  backgroundImage: Image<'16_9' | '1_1'>
  image: Image<'free'>
  isFollowingEnabled?: boolean
  shouldShowCollapsibleEntityHeader: boolean
}

const COLLAPSIBLE_ENTITY_HEADER_HEIGHT = 64

const StyledCollapsibleEntityHeader = styled.div<
  Pick<CollapsibleEntityHeaderProps, 'shouldShowCollapsibleEntityHeader'>
>`
  ${({
    theme: {
      measures: { stretchWidthOnMobile },
    },
    shouldShowCollapsibleEntityHeader,
  }) => {
    return css`
      background: linear-gradient(
        180deg,
        rgba(18, 16, 16, 0.64) -82.81%,
        rgba(18, 16, 16, 0) 79.69%
      );

      position: sticky;
      overflow: hidden;
      height: ${COLLAPSIBLE_ENTITY_HEADER_HEIGHT}px;
      top: 48px;
      right: 16px;
      z-index: 2;
      justify-content: center;
      display: ${shouldShowCollapsibleEntityHeader ? 'flex' : 'none'};
      opacity: ${shouldShowCollapsibleEntityHeader ? 1 : 0};

      @starting-style {
        opacity: 0;
      }
      transition: opacity 0.5s ease-in-out;

      ${desktopCSS(css`
        display: none;
      `)}

      ${tabletCSS(css`
        display: none;
      `)}

      ${stretchWidthOnMobile()};
    `
  }}
`

const StyledBackgroundImage = styled(BackgroundImage)`
  top: calc(-50% - ${COLLAPSIBLE_ENTITY_HEADER_HEIGHT}px);
`

const ImageWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    display: flex;
    gap: ${spacing12};
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
  `}
`

const StyledEntityImage = styled(EntityImage)`
  width: 48px;
  height: 48px;
`

const FollowYourEntityMobilePlaceholder = styled.div`
  width: 24px;
`

const CollapsibleEntityHeader: FunctionComponent<
  CollapsibleEntityHeaderProps
> = ({
  backgroundImage,
  image,
  isFollowingEnabled,
  shouldShowCollapsibleEntityHeader,
}) => {
  return (
    <StyledCollapsibleEntityHeader
      shouldShowCollapsibleEntityHeader={shouldShowCollapsibleEntityHeader}>
      <StyledBackgroundImage image={backgroundImage}>
        <ImageWrapper>
          {isFollowingEnabled && <FollowYourEntityMobilePlaceholder />}
          <StyledEntityImage image={image} />
          {isFollowingEnabled && <FollowYourEntity variant="mobile" />}
        </ImageWrapper>
      </StyledBackgroundImage>
    </StyledCollapsibleEntityHeader>
  )
}

export default CollapsibleEntityHeader
