import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import useScoreboard from '@hooks/useScoreboard'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'

const StyledScoreboardMatchPeriods = styled.div`
  ${({
    theme: {
      typography: {
        catchword: {
          small1: { bundledCSS: small1CatchwordCSS },
        },
      },
      color: {
        tertiary: { grey800: grey800Color },
      },
    },
  }) => css`
    ${small1CatchwordCSS};
    line-height: 1.6;
    align-items: center;
    color: ${grey800Color};
    display: flex;
    text-align: center;
  `}
`

const ScoreboardMatchPeriods: FunctionComponent = () => {
  const { formattedData } =
    useScoreboard<typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY>()

  const { periods } = formattedData

  return periods ? (
    <StyledScoreboardMatchPeriods>({periods})</StyledScoreboardMatchPeriods>
  ) : null
}

export default ScoreboardMatchPeriods
