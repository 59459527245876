import useIsInCollapsibleScoreboard from '@hooks/useIsInCollapsibleScoreboard'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const StyledMatchScoreContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-items: center;
`

const StyledMatchScore = styled.div<{ isInCollapsibleScoreboard?: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
      spacing: { spacing4, spacing8 },
      typography: {
        headings: {
          xxxlarge: { bundledCSS: xxxlargeHeadingCSS },
          small: { bundledCSS: smallHeadingCSS },
        },
      },
    },
    isInCollapsibleScoreboard,
  }) => {
    const collapsibleScoreboardCSS = css`
      ${smallHeadingCSS};
      margin: 0 ${spacing8};
      letter-spacing: ${spacing4};
    `
    return css`
      color: ${primary01Color};
      text-align: center;
      margin-bottom: ${spacing8};
      ${isInCollapsibleScoreboard
        ? collapsibleScoreboardCSS
        : xxxlargeHeadingCSS};
      letter-spacing: ${spacing4};
    `
  }}
`

const StyledMatchScoreExplanation = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey800: grey800color },
      },
      typography: {
        catchword: {
          medium2: { bundledCSS: medium2CatchwordCSS },
        },
      },
      spacing: { spacing12 },
    },
  }) => css`
    color: ${grey800color};
    ${medium2CatchwordCSS};
    text-align: center;
    margin-bottom: ${spacing12};
  `}
`

export type ScoreboardMatchScoreProps = {
  awayScore: number
  homeScore: number
  explanation?: string
}

const ScoreboardMatchScore: FunctionComponent<ScoreboardMatchScoreProps> = ({
  awayScore,
  homeScore,
  explanation,
}) => {
  const isInCollapsibleScoreboard = useIsInCollapsibleScoreboard()
  return (
    <StyledMatchScoreContainer>
      <StyledMatchScore isInCollapsibleScoreboard={isInCollapsibleScoreboard}>
        {homeScore}:{awayScore}
      </StyledMatchScore>
      {!!explanation && !isInCollapsibleScoreboard && (
        <StyledMatchScoreExplanation>{explanation}</StyledMatchScoreExplanation>
      )}
    </StyledMatchScoreContainer>
  )
}

export default ScoreboardMatchScore
