import { ConfigFn } from '@config/types'

const config = (({ publication, searchUrl }) =>
  ({
    apiUrl: `${searchUrl}/search`,
    sectionUrl: publication === 'romandie' ? '/fr/chercher/' : '/suche/',
    textHighlightClass: '@blick/search__highlighted-text',
    textHighlightColor: 'rgba(255,204,0,0.5)',
  }) as const) satisfies ConfigFn

export default config
