import { FunctionComponent, useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { OutPortal } from 'react-reverse-portal'

import { desktopCSS } from '@measures/responsive'
import { BlickBitesItem } from '@widgets/Video/BlickBites'
import { getJWPlayer } from '@utils/videoPlayer'

import PlayIcon from './PlayIcon'
import VolumeCTAButton from './VolumeCTAButton'
import CaptionsCTAButton from './CaptionsCTAButton'
import ShareCTAButton from './ShareCTAButton'
import ReadMoreCTAButton from './ReadMoreCTAButton'
import CloseButton from './CloseButton'
import VideoProgressBar from '../BlickBitesVideoPlayer/VideoProgressBar'
import Captions from '../BlickBitesVideoPlayer/Captions'
import useBlickBitesTracking from '../BlickBitesVideoPlayer/useBlickBitesTracking'
import useBlickBitesFastlaneContext from '@hooks/useBlickBitesFastlaneContext'
import LoadingSpinner from './LoadingSpinner'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { PLAYER_MODES, PLAYER_TYPES } from '@widgets/Video/utils'
import { triggerAureusClick, triggerAureusDeboosting } from '@utils/aureus'
import { useQueryClient } from '@tanstack/react-query'

export interface BlickBitesFastlaneItemProps {
  item: BlickBitesItem
  isCurrentVideo: boolean
  onClose: () => void
}

interface StyledTitleProps {
  isInViewport: boolean | null
}

const StyledTitle = styled.div<StyledTitleProps>`
  ${({
    theme: {
      typography: {
        headings: {
          medium: { bundledCSS: mediumHeadingsCSS },
        },
      },
    },
    isInViewport,
  }) => {
    return css`
      ${mediumHeadingsCSS};

      /* 
        Headline
        Disappears after 5000ms with a 200ms fade out animation
        Bottom gradient reduces height to 15% of the player height with a 200ms fade animation 
      */
      ${isInViewport &&
      css`
        animation: fadeOut 400ms forwards 5s;

        @keyframes fadeOut {
          0% {
            opacity: 1;
            visibility: visible;
          }
          50% {
            max-height: 500px;
            opacity: 0;
            visibility: hidden;
          }
          100% {
            max-height: 0;
            opacity: 0;
            visibility: hidden;
          }
        }
      `}
    `
  }}
`

const StyledVideoContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const BottomControlsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`
const LeftControlsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    display: flex;
    align-items: flex-start;
    gap: ${spacing12};
    flex: 1 0 0;
  `}
`

const StyledVideoInfoContainer = styled.div`
  position: absolute;
  z-index: 7;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledVideoOverlay = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  align-items: flex-end;
`

const StyledVideoInfoWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
      color: {
        primary: { primary02: primary02Color },
      },
    },
  }) => css`
    color: ${primary02Color};
    padding: ${spacing16};
    flex-direction: column;
    align-items: left;
    display: flex;
    gap: 18px;

    background: linear-gradient(
      180deg,
      rgba(17, 17, 17, 0) 0%,
      rgba(17, 17, 17, 0.75) 100%
    );
  `}
`

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  z-index: 8;
  top: 16px;
  right: 16px;

  ${desktopCSS(css`
    display: none;
  `)}
`

const getPageUrl = (): string =>
  window.location.href.replace(window.location.origin, '')

const BlickBitesFastlaneVideo: FunctionComponent<
  BlickBitesFastlaneItemProps
> = ({ isCurrentVideo, item, onClose }) => {
  const { link, title, video, articleId, aureusOfferId } = item

  const queryClient = useQueryClient()
  const {
    muted,
    captionsEnabled,
    setMuted,
    setCaptionsEnabled,
    widgetId,
    isPlayerReady,
    isLoading,
    isPaused,
    areCaptionsAvailable,
    VideoPlayerPortalNode,
    loadVideo,
  } = useBlickBitesFastlaneContext()

  useBlickBitesTracking({
    enabled: true,
    isPlayerReady,
    widgetId,
    biteId: articleId,
    isInViewport: true,
    videoId: video?.jwVideoId,
    videoTitle: video?.title ?? '',
    videoDuration: video?.duration ?? 0,
  })

  const onPlayChange = useCallback(() => {
    const player = getJWPlayer(widgetId)

    if (!player) {
      return
    }

    if (player.getState() === 'playing') {
      player.pause()
    } else {
      player.play()
    }
  }, [widgetId])

  const onMuteChangeHandler = useCallback(() => {
    const player = getJWPlayer(widgetId)

    if (player) {
      const currentMuteState = player?.getMute()

      player?.setMute(!currentMuteState)

      setMuted(!currentMuteState)
    }
  }, [setMuted, widgetId])

  const onCaptionsButtonClick = useCallback(() => {
    setCaptionsEnabled(!captionsEnabled)
  }, [captionsEnabled, setCaptionsEnabled])

  const readMoreCTAButtonClickHandler = useCallback<TrackingFnType>(
    () => ({
      event: 'mehr_dazu_click',
      element: 'blick_bites_widget',
      playerType: PLAYER_TYPES.BITE,
      playerMode: PLAYER_MODES.FASTLANE,
      videoTitle: video?.title,
      videoId: video?.jwVideoId,
      link_url: link?.href,
      biteId: articleId,
    }),
    [articleId, link?.href, video?.jwVideoId, video?.title]
  )

  const trackReadMoreCTAButtonClick = useTracking(readMoreCTAButtonClickHandler)

  const onReadMoreCTAButtonClick = useCallback(() => {
    trackReadMoreCTAButtonClick()
    const player = getJWPlayer(widgetId)

    if (!player) {
      return
    }

    if (player.getState() === 'playing') {
      player.pause()
    }
  }, [trackReadMoreCTAButtonClick, widgetId])

  useEffect(() => {
    loadVideo(video?.jwVideoId)
  }, [loadVideo, video?.jwVideoId])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (player?.getState() === 'paused') {
      player.play()
    }
  }, [widgetId])

  const onFirstFrame = useCallback(() => {
    const player = getJWPlayer(widgetId)

    if (!player || !isPlayerReady) {
      return
    }

    const wasDeboosted = queryClient
      .getQueryData<string[]>(['bites-deboosted-ids'])
      ?.includes(articleId)

    if (aureusOfferId && isCurrentVideo && !wasDeboosted) {
      triggerAureusClick({
        articleId,
        url: getPageUrl(),
        aureusOfferId,
        sectionId: 'blick-bites',
      })
      triggerAureusDeboosting({
        itemsToDeboost: [{ offerId: aureusOfferId, contentId: articleId }],
      })
      queryClient.setQueryData<string[]>(
        ['bites-deboosted-ids'],
        (bitesDeboostedIds = []) => [...bitesDeboostedIds, articleId]
      )
    }
  }, [
    widgetId,
    isPlayerReady,
    aureusOfferId,
    articleId,
    queryClient,
    isCurrentVideo,
  ])

  useEffect(() => {
    const player = getJWPlayer(widgetId)

    if (!isPlayerReady || !player) {
      return
    }

    player.on('firstFrame', onFirstFrame)

    return () => {
      player.off('firstFrame', onFirstFrame)
    }
  }, [isPlayerReady, onFirstFrame, widgetId])

  return (
    <>
      <StyledCloseButton onClick={onClose} />
      <StyledVideoContainerWrapper>
        <>
          {VideoPlayerPortalNode && <OutPortal node={VideoPlayerPortalNode} />}
          {isLoading ? <LoadingSpinner /> : <PlayIcon isVisible={isPaused} />}
        </>
      </StyledVideoContainerWrapper>
      <StyledVideoInfoContainer>
        <StyledVideoOverlay onClick={onPlayChange}>
          {isPlayerReady && areCaptionsAvailable && (
            <Captions widgetId={widgetId} captionsEnabled={captionsEnabled} />
          )}
        </StyledVideoOverlay>
        <StyledVideoInfoWrapper>
          <StyledTitle isInViewport={true}>{title}</StyledTitle>
          <VideoProgressBar widgetId={widgetId} isPlayerReady={isPlayerReady} />
          <BottomControlsWrapper>
            <LeftControlsWrapper>
              <VolumeCTAButton
                muted={muted}
                onVolumeButtonClick={onMuteChangeHandler}
              />
              <CaptionsCTAButton
                captionsAvailable={areCaptionsAvailable}
                captionsEnabled={captionsEnabled}
                onCaptionsButtonClick={onCaptionsButtonClick}
              />
              <ShareCTAButton title={title} articleId={articleId} />
            </LeftControlsWrapper>
            <ReadMoreCTAButton
              clickableProps={link}
              onClick={onReadMoreCTAButtonClick}
            />
          </BottomControlsWrapper>
        </StyledVideoInfoWrapper>
      </StyledVideoInfoContainer>
    </>
  )
}

export default BlickBitesFastlaneVideo
