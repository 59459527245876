import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import SummaryContainer from '@components/Summary/SummaryContainer'
import useTracking from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import {
  trackQuestionSummaryClickHandler,
  trackQuestionSummaryImpression,
} from './utils'
import { QuestionContainer } from './Question'
import QuestionSummaryRequestView from './ArticleQuestionRequestView'
import {
  SkeletonSeedValues,
  SkeletonView,
} from '@components/Summary/SummaryLoadingSkeleton'
import { ErrorView } from '@components/Summary/SummaryError'
import translate from '@i18n'
import QuestionSummaryResultView from './ArticleQuestionResultView'
import SummaryDisclaimer from '@components/Summary/SummaryDisclaimer'
import { skipToken, useQuery } from '@tanstack/react-query'
import { getAnswer, getQuestions, HTTP_METHOD_VALUES } from '@utils/chatbot'
import type { TQuestionData } from '@utils/chatbot/types'
import { intent, WidgetIdValues } from '@utils/chatbot/types'
import type { QuestionSummaryProps } from './types'
import usePageMetadata from '@hooks/usePageMetadata'
import useModalOverlayPortal from '@hooks/useModalOverlayPortal'
import UserSnapInlineForm from '@components/UserSnap/InlineForm'
import ChatbotInput from '@components/Chatbot/ChatbotInput'
import useChatbot from '@hooks/useChatbot'
import useIsUserLoggedIn from '@hooks/useIsUserLoggedIn'
import useAuthentication from '@hooks/useAuthentication'
import useURLParam from '@hooks/useURLParam'
import useFeatureFlagsSkeleton from '@hooks/useFeatureFlagsSkeleton'
import useIsCUIEnabled from '@hooks/useIsCUIEnabled'
import WidgetButton from '@components/Chatbot/buttons/WidgetButton'
import SummaryHeaderWrapper from '@components/Summary/SummaryHeaderWrapper'
import AnswerMessage from '@components/Chatbot/messages/AnswerMessage'
import QuestionLoading from '@components/Chatbot/loading/QuestionLoading'
import SecondaryCTAButton from '@components/Buttons/SecondaryCTAButton'

const {
  questionWidget: { moreInfoLink, userSnapFeedbackKey },
} = config

const StyledSecondaryCTAButton = styled(SecondaryCTAButton)<{
  hasMargin: boolean
}>`
  ${({
    hasMargin,
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    display: inline-block;
    width: 100%;
    ${hasMargin && `margin-top: ${spacing12};`}
  `}
`

const StyledChatbotInput = styled(ChatbotInput)`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    margin-top: ${spacing16};
  `}
`

const StyledAdditionQuestionsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing12, spacing24 },
      color: {
        tertiary: { grey400 },
      },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing12};
    margin-bottom: ${spacing12};
    padding-top: ${spacing24};
    border-top: 1px solid ${grey400};
  `}
`

const StyledSkeletonViewWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    max-width: 326px;
    padding: ${spacing16} 0 0 0;
    display: flex;
    flex-direction: column;
    gap: ${spacing16};

    & > div:last-child {
      width: 183px;
    }
  `}
`

const QuestionSummary: FunctionComponent<QuestionSummaryProps> = ({
  title = '',
  questions,
  question: initialQuestion,
  links,
  content,
}) => {
  const isChatbotEnabled = useIsCUIEnabled()
  const articleId = usePageMetadata().id ?? ''
  const isLoggedIn = useIsUserLoggedIn()
  const { login } = useAuthentication()
  const { onQuestionClick, isLastQuestion } = useChatbot()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { portalNode, enterModalOverlay, exitModalOverlay, InPortal } =
    useModalOverlayPortal()

  const trackWidgetImpression = useTracking(trackQuestionSummaryImpression)
  const handleTrackingOnClick = useTracking(trackQuestionSummaryClickHandler)
  const viewportRef = useViewportTracking({
    track: true,
    onImpression: trackWidgetImpression,
  })
  const [skipTypingEffect, setSkipTypingEffect] = useState<Record<
    string,
    boolean
  > | null>(null)
  const onLogin = () =>
    login({
      prefix: WidgetIdValues.ArticleQuestion,
      loginCase: 'email_only',
    })

  const [question, setQuestion] = useState<TQuestionData | undefined>(
    initialQuestion
  )

  const {
    data: questionsData,
    isLoading: questionsIsLoading,
    isError: questionsIsError,
    refetch: questionsRefetch,
  } = useQuery({
    queryKey: ['questionSummary.questions', articleId],
    queryFn: !!articleId
      ? async () => {
          const data = await getQuestions({
            widgetId: WidgetIdValues.ArticleQuestion,
            context: articleId,
          })()

          return data.questions
        }
      : skipToken,
    initialData: questions ? questions : undefined,
  })

  const {
    data: answersData,
    error: answersIsError,
    isLoading: answersIsLoading,
    isPending: answersIsPending,
    refetch: refetchAnswers,
  } = useQuery({
    queryKey: ['questionSummary.answer', articleId, question?.id],
    queryFn: !!question?.id // only fetch if question is set and no data is cached
      ? async () => {
          const data = await getAnswer({
            widgetId: WidgetIdValues.ArticleQuestion,
            context: articleId,
            questionId: question?.id,
          })()

          return {
            links: data.links,
            content: data.content,
          }
        }
      : skipToken,
    initialData: links && content ? { links, content } : undefined,
  })

  const cancelCallback = useCallback(() => {
    setQuestion(undefined)

    if (!questionsIsLoading && questionsIsError) {
      // refetch questions if there was an error
      questionsRefetch()
    }
  }, [setQuestion, questionsIsLoading, questionsIsError, questionsRefetch])

  const retryCallback = useCallback(() => {
    if (questionsIsError) {
      questionsRefetch()
    } else if (answersIsError) {
      refetchAnswers()
    }
  }, [refetchAnswers, answersIsError, questionsIsError, questionsRefetch])

  const openChatbot = (question: TQuestionData) => {
    if (!isLastQuestion(question.id)) {
      onQuestionClick({
        context: articleId,
        isSuggestedQuestion: true,
        id: question.id,
        isCached: false,
        text: question.text,
      })
    }
  }

  const isLoading = answersIsLoading
  const isError = answersIsError
  const isSummaryLoaded = questionsData && !question && !isError // only show summary if no question is selected

  const isAnswerLoaded =
    question && answersData && !answersIsPending && !answersIsError

  const text = answersData?.content
    ?.map((contentItem) => contentItem.text)
    ?.join(' ')
  const {
    data: nextQuestionData,
    isLoading: nextQuestionIsLoading,
    isError: nextQuestionIsError,
  } = useQuery({
    queryKey: ['questionSummary.nextQuestion', articleId, text],
    queryFn:
      !!text && isLoggedIn && isChatbotEnabled
        ? async () => {
            const data = await getQuestions({
              method: HTTP_METHOD_VALUES.POST,
              body: {
                text,
                context: articleId,
              },
            })()

            return data.questions
          }
        : skipToken,
  })
  const isNextQuestionLoaded =
    nextQuestionData && !nextQuestionIsLoading && !nextQuestionIsError

  const disclaimer = useMemo(
    () => ({
      title: translate('sectionSummary.disclaimerText'),
      links: [
        {
          label: translate('sectionSummary.disclaimerMoreInfo'),
          link: moreInfoLink,
          target: '_blank',
          onClickHandler: (label: string) => handleTrackingOnClick({ label }),
        },
        {
          label: translate('sectionSummary.disclaimerFeedback'),
          target: '_blank',
          onClickHandler: (label: string) => {
            event?.preventDefault()
            handleTrackingOnClick({ label })
            setIsModalVisible(true)
            enterModalOverlay()
          },
        },
      ],
    }),
    [handleTrackingOnClick, enterModalOverlay, setIsModalVisible]
  )

  if (questionsIsError || questionsIsLoading) {
    return null
  }

  return (
    <div id="question-widget">
      <SummaryContainer ref={viewportRef}>
        {portalNode && isModalVisible && (
          <InPortal node={portalNode}>
            <UserSnapInlineForm
              setIsVisible={(isVisible) => {
                if (!isVisible) {
                  exitModalOverlay()
                }
                enterModalOverlay()
              }}
              projectApiKey={userSnapFeedbackKey}
            />
          </InPortal>
        )}

        {isSummaryLoaded && (
          <QuestionSummaryRequestView title={title}>
            {questionsData.length ? (
              <QuestionContainer>
                {questionsData.map((data) => (
                  <WidgetButton
                    onClick={() => {
                      setQuestion(data)
                      handleTrackingOnClick({ label: data.text })
                    }}
                    key={data.id}
                    buttonText={data.text}
                    bypassTypingEffect={true}
                  />
                ))}
                {isChatbotEnabled && (
                  <>
                    {(isLoggedIn && (
                      <>
                        <StyledChatbotInput />
                        {disclaimer && <SummaryDisclaimer {...disclaimer} />}
                      </>
                    )) || (
                      <StyledSecondaryCTAButton
                        hasMargin={true}
                        size="small"
                        onClick={() => onLogin()}>
                        {translate('chatbot.footer.loginButton')}
                      </StyledSecondaryCTAButton>
                    )}
                  </>
                )}
              </QuestionContainer>
            ) : null}
          </QuestionSummaryRequestView>
        )}

        {isAnswerLoaded && (
          <QuestionSummaryResultView
            articleId={articleId}
            title={translate('summary.result.title')}
            question={question}
            links={answersData.links}
            content={answersData.content}
            onCloseHandler={cancelCallback}
            onTypingAnimationFinishedHandler={() => {
              setSkipTypingEffect((prevData) => ({
                ...(prevData ?? {}),
                [question.id]: true,
              }))
            }}
            bypassAnimation={skipTypingEffect?.[question.id] ?? false}>
            {isChatbotEnabled && (
              <StyledAdditionQuestionsWrapper>
                {(isNextQuestionLoaded && (
                  <QuestionContainer>
                    {nextQuestionData.slice(0, 2).map((data) => (
                      <WidgetButton
                        onClick={() => {
                          openChatbot(data)
                        }}
                        key={data.id}
                        buttonText={data.text}
                        bypassTypingEffect={
                          skipTypingEffect?.[question.id] ?? false
                        }></WidgetButton>
                    ))}
                  </QuestionContainer>
                )) ||
                  (isLoggedIn && nextQuestionIsLoading && (
                    <QuestionLoading buttonsCount={2} />
                  ))}
                {(isLoggedIn && <StyledChatbotInput />) || (
                  <StyledSecondaryCTAButton
                    hasMargin={false}
                    size="small"
                    onClick={() => onLogin()}>
                    {translate('chatbot.footer.loginButton')}
                  </StyledSecondaryCTAButton>
                )}
              </StyledAdditionQuestionsWrapper>
            )}

            {disclaimer && <SummaryDisclaimer {...disclaimer} />}
          </QuestionSummaryResultView>
        )}

        {isLoading && (
          <QuestionSummaryRequestView title={title} showBorder={true}>
            <StyledSkeletonViewWrapper>
              <SkeletonView
                seed={[{ type: SkeletonSeedValues.Image, multiplier: 1 }]}
              />
              <SkeletonView
                seed={[{ type: SkeletonSeedValues.Paragraph, multiplier: 1 }]}
              />
            </StyledSkeletonViewWrapper>
          </QuestionSummaryRequestView>
        )}

        {isError && !isLoading && (
          <ErrorView>
            <SummaryHeaderWrapper
              title={question ? question.text : title}
              enabled={true}
              showBorder={true}
              uppercase={!question}
              betaTagPullRight={true}
            />
            <AnswerMessage
              answer={{
                type: 'answerError',
                content: [
                  {
                    category: translate('chatbot.answerMessage.errorTitle'),
                    text: translate('sectionSummary.request.error'),
                  },
                ],
              }}
              onReloadAnswer={retryCallback}
              intent={intent.generalKnowledge}
            />
          </ErrorView>
        )}
      </SummaryContainer>
    </div>
  )
}

const ConditionallyEnabledQuestionSummary: FunctionComponent<
  QuestionSummaryProps
> = (props) =>
  [
    useFeatureFlagsSkeleton().isArticleQuestionsEnabled,
    useURLParam('eaq'),
  ].some((item) => item === true || item === 'true') ? (
    <QuestionSummary {...props} />
  ) : null

const widget = {
  kind: ['article-questions'],
  component: ConditionallyEnabledQuestionSummary,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
