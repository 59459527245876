import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const StyledQuestionTitle = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
      spacing: { spacing8, spacing16 },
      typography: {
        headings: { small: smallHeadingCSS },
      },
    },
  }) => css`
    ${smallHeadingCSS}
    border-left: 2px solid ${blickRed};
    padding-left: ${spacing8};
    margin-top: ${spacing16};
    margin-bottom: ${spacing16};
    text-transform: uppercase;
  `}
`

const QuestionTitle: FunctionComponent<{ title: string }> = ({ title }) => {
  return <StyledQuestionTitle>{title}</StyledQuestionTitle>
}

export default QuestionTitle
