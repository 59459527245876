import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { FeatureFlagEntryProps } from './types'
import CheckBox from './CheckBox'
import SelectBox from './SelectBox'
import { desktopCSS } from '@measures/responsive'

const StyledFeatureFlagEntry = styled.li`
  ${({
    theme: {
      colors: { fillBrand, fillWeak },
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing16};

    background-color: ${fillWeak};

    padding: ${spacing16};
    border-left: 2px solid ${fillBrand};

    ${desktopCSS(css`
      padding: ${spacing24};
      border-left: 4px solid ${fillBrand};
    `)}
  `}
`

const Title = styled.h3`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeading },
        },
      },
    },
  }) => css`
    margin: 0;
    padding: 0;

    ${smallHeading}
  `}
`

const Description = styled.p`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopy },
        },
      },
    },
  }) => css`
    margin: 0;
    padding: 0;

    ${small2BodyCopy}
  `}
`

const FeatureFlagEntry: FunctionComponent<FeatureFlagEntryProps> = ({
  name,
  description,
  values,
  updateKey,
}) => {
  return !values.length ? null : (
    <StyledFeatureFlagEntry>
      <Title>{name}</Title>
      <Description>{description}</Description>
      {values.length > 1 ? (
        <SelectBox variantName={name} updateKey={updateKey}>
          {values}
        </SelectBox>
      ) : (
        <CheckBox variantName={name} updateKey={updateKey}>
          {values[0]}
        </CheckBox>
      )}
    </StyledFeatureFlagEntry>
  )
}

export default FeatureFlagEntry

export { StyledFeatureFlagEntry, Title, Description }
