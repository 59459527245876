import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import translate from '@i18n'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'
import SummaryHeaderWrapper from '@components/Summary/SummaryHeaderWrapper'

const Paragraph = styled.p`
  ${({
    theme: {
      spacing: { spacing24 },
      typography: {
        subheads: { large2 },
      },
    },
  }) => css`
    ${large2}
    margin: ${spacing24} 0 0 0;
  `}
`

const SummaryRequestView: FunctionComponent<{
  title: string
  text: string
  onPrimaryClick: () => void
}> = ({ title, text, onPrimaryClick }) => {
  return (
    <>
      <SummaryHeaderWrapper
        title={title}
        enabled={false}
        showBetaTag={true}
        showBorder={false}
      />
      <Paragraph>{text}</Paragraph>
      <Paragraph>
        <PrimaryCTAButton
          type="button"
          size="large"
          onClick={onPrimaryClick}
          iconName="stars">
          {translate('sectionSummary.request.primaryButton')}
        </PrimaryCTAButton>
      </Paragraph>
    </>
  )
}

export default SummaryRequestView
