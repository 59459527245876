import { ValidAPIResponse } from '@hooks/useSportsStandings'
import {
  AllowedSports,
  CustomTitle,
  NumOfTeams,
} from '@widgets/SportTableInline/types'
import { ALLOWED_SPORTS } from '@widgets/SportTableInline/utils'
import config from '@config'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import {
  LiveEventDatas,
  LiveEventDataSportTypes,
} from '@utils/formatters/liveEvent'
import {
  LiveEventTransformedFormula1,
  transformLiveEvent as transformLiveEventFormula1,
} from '@utils/formatters/formula1'
import {
  LiveEventTransformedHockey,
  transformLiveEvent as transformLiveEventHockey,
} from '@utils/formatters/hockey'
import {
  LiveEventTransformedSki,
  transformLiveEvent as transformLiveEventSki,
} from '@utils/formatters/ski'
import {
  LiveEventTransformedSoccer,
  transformLiveEvent as transformLiveEventSoccer,
} from '@utils/formatters/soccer'
import {
  LiveEventTransformedTennis,
  transformLiveEvent as transformLiveEventTennis,
} from '@utils/formatters/tennis'

interface TransformDataProps {
  expectedTypeOfSport: LiveEventDataSportTypes
  expectedMatchId: string
  data: unknown
}

interface useSportsStandingsProps {
  customTitle: CustomTitle
  numOfTeams: NumOfTeams
  seasonId: string
  typeOfSport: AllowedSports
}

export type EnrichedLiveEventTransformed =
  | EnrichedLiveEventTransformedFormula1
  | EnrichedLiveEventTransformedTennis
  | EnrichedLiveEventTransformedSoccer
  | EnrichedLiveEventTransformedHockey
  | EnrichedLiveEventTransformedSki

export interface EnrichedLiveEventTransformedFormula1 {
  isPlaceholderData?: boolean
  sportsEventCacheDurationInMilliseconds?: number
  pollIntervalInMilliseconds?: number
  sportsEventId: string
  sportsEventType: typeof LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1
  formattedData: LiveEventTransformedFormula1
}

export interface EnrichedLiveEventTransformedTennis {
  isPlaceholderData?: boolean
  sportsEventCacheDurationInMilliseconds?: number
  pollIntervalInMilliseconds?: number
  sportsEventId: string
  sportsEventType: typeof LIVE_EVENT_DATA_SPORT_TYPES.TENNIS
  formattedData: LiveEventTransformedTennis
}

export interface EnrichedLiveEventTransformedSoccer {
  isPlaceholderData?: boolean
  sportsEventCacheDurationInMilliseconds?: number
  pollIntervalInMilliseconds?: number
  sportsEventId: string
  sportsEventType: typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
  formattedData: LiveEventTransformedSoccer
}

export interface EnrichedLiveEventTransformedHockey {
  isPlaceholderData?: boolean
  sportsEventCacheDurationInMilliseconds?: number
  pollIntervalInMilliseconds?: number
  sportsEventId: string
  sportsEventType: typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
  formattedData: LiveEventTransformedHockey
}

export interface EnrichedLiveEventTransformedSki {
  isPlaceholderData?: boolean
  sportsEventCacheDurationInMilliseconds?: number
  pollIntervalInMilliseconds?: number
  sportsEventId: string
  sportsEventType: typeof LIVE_EVENT_DATA_SPORT_TYPES.SKI
  formattedData: LiveEventTransformedSki
}

export interface EnrichedLiveEventTransformedSoccerHockey {
  isPlaceholderData?: boolean
  sportsEventCacheDurationInMilliseconds?: number
  pollIntervalInMilliseconds?: number
  sportsEventId: string
  sportsEventType:
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
  formattedData: LiveEventTransformedSoccer | LiveEventTransformedHockey
}

const {
  sports: {
    baseUrl: sportsBaseUrl,
    baseUrlV2: sportsBaseUrlV2,
    standingsUrlTemplate,
    standingsUrlTemplateV2,
    eventUrlTemplate,
    eventUrlTemplateV2,
  },
} = config

const composeRequestUrlSportStandings = ({
  seasonId,
  typeOfSport,
}: Pick<useSportsStandingsProps, 'seasonId' | 'typeOfSport'>) =>
  `${sportsBaseUrl}${standingsUrlTemplate}`
    .replace('{SPORT}', typeOfSport)
    .replace('{EVENT_ID}', seasonId)

const composeRequestUrlSportStandingsV2 = ({
  seasonId,
  typeOfSport,
}: Pick<useSportsStandingsProps, 'seasonId' | 'typeOfSport'>) =>
  `${sportsBaseUrlV2}${standingsUrlTemplateV2
    .replace('{SPORT}', typeOfSport)
    .replace('{API_VERSION}', 'v0')
    .replace('{EVENT_ID}', seasonId)}`

const isValidSportsTable = (
  sportsTableData: any
): sportsTableData is ValidAPIResponse =>
  Object.values(ALLOWED_SPORTS).includes(sportsTableData?.sportsTableType) &&
  !!sportsTableData?.sportsTableId &&
  !!sportsTableData?.tables?.[0] &&
  !!sportsTableData?.header?.[0] &&
  !!Object.keys(sportsTableData?.outcomeTypeColors)?.[0]

const getSportsStandingsData =
  ({
    expectedTypeOfSport,
    expectedSeasonId,
    isServerRequest,
  }: {
    expectedTypeOfSport: useSportsStandingsProps['typeOfSport']
    expectedSeasonId: useSportsStandingsProps['seasonId']
    isServerRequest?: boolean
  }) =>
  async (requestUrl: string) => {
    const requestHeaders: Partial<Record<'User-Agent', 'next'>> = {}

    if (isServerRequest) {
      requestHeaders['User-Agent'] = 'next'
    }

    const data = (await (
      await fetch(requestUrl, { headers: requestHeaders })
    ).json()) as ValidAPIResponse

    const isValid =
      isValidSportsTable(data) &&
      data.sportsTableType === expectedTypeOfSport &&
      data.sportsTableId === expectedSeasonId

    if (!isValid) {
      throw new Error(
        `Invalid sports standings data for sport: ${expectedTypeOfSport} with seasonId: ${expectedSeasonId}`
      )
    }

    if (isServerRequest) {
      // When we prefetch on the server, due to CDN caching, we'd like to make sure
      // that we immediately re-request the data on the client.
      data.pollIntervalInMilliseconds = 1000
    }

    return data
  }

const composeRequestUrlSportsEvent = ({
  matchId,
  typeOfSport,
}: {
  matchId: string
  typeOfSport: LiveEventDataSportTypes
}) =>
  `${sportsBaseUrl}${eventUrlTemplate
    .replace('{SPORT}', typeOfSport)
    .replace('{EVENT_ID}', matchId)}`

const composeRequestUrlSportsEventV2 = ({
  matchId,
  typeOfSport,
}: {
  matchId: string
  typeOfSport: LiveEventDataSportTypes
}) =>
  `${sportsBaseUrlV2}${eventUrlTemplateV2
    .replace('{SPORT}', typeOfSport)
    .replace('{API_VERSION}', 'v0')
    .replace('{EVENT_ID}', matchId)}`

const isValidSportsEvent = (
  sportsEventData: any
): sportsEventData is LiveEventDatas =>
  typeof sportsEventData === 'object' &&
  Object.values(LIVE_EVENT_DATA_SPORT_TYPES).includes(
    sportsEventData.sportsEventType
  ) &&
  sportsEventData.sportsEventType !== 'NO_SPORT'

const transformLiveEvent = (data: LiveEventDatas) => {
  switch (data.sportsEventType) {
    case LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1: {
      return transformLiveEventFormula1(data)
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY: {
      return transformLiveEventHockey(data)
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.SKI: {
      return transformLiveEventSki(data)
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.SOCCER: {
      return transformLiveEventSoccer(data)
    }

    case LIVE_EVENT_DATA_SPORT_TYPES.TENNIS: {
      return transformLiveEventTennis(data)
    }
  }
}

const transformDataSportsEvent = ({
  expectedTypeOfSport,
  expectedMatchId,
  data,
}: TransformDataProps) => {
  const isValid = isValidSportsEvent(data)

  if (!isValid) {
    throw new Error(
      `Invalid sports event data for sport: ${expectedTypeOfSport} with matchId: ${expectedMatchId}`
    )
  } else {
    const {
      sportsEventType,
      sportsEventId,
      sportsEventCacheDurationInMilliseconds,
    } = data
    return {
      sportsEventCacheDurationInMilliseconds,
      sportsEventType,
      sportsEventId,
      formattedData: transformLiveEvent(data),
    } as EnrichedLiveEventTransformed
  }
}

const getSportsEventData =
  ({
    expectedTypeOfSport,
    expectedMatchId,
    isServerRequest,
  }: Pick<TransformDataProps, 'expectedTypeOfSport' | 'expectedMatchId'> & {
    isServerRequest?: boolean
  }) =>
  async (requestUrl: string) => {
    const requestHeaders: Partial<Record<'User-Agent', 'next'>> = {}

    if (isServerRequest) {
      requestHeaders['User-Agent'] = 'next'
    }

    const transformedData = transformDataSportsEvent({
      expectedTypeOfSport,
      expectedMatchId,
      data: await (await fetch(requestUrl, { headers: requestHeaders })).json(),
    })

    if (isServerRequest) {
      // When we prefetch on the server, due to CDN caching, we'd like to make sure
      // that we immediately re-request the data on the client.
      transformedData.sportsEventCacheDurationInMilliseconds = 1000
      transformedData.pollIntervalInMilliseconds = 1000
    }

    return transformedData
  }

export {
  isValidSportsTable,
  getSportsStandingsData,
  composeRequestUrlSportStandings,
  composeRequestUrlSportStandingsV2,
  getSportsEventData,
  transformDataSportsEvent,
  composeRequestUrlSportsEvent,
  composeRequestUrlSportsEventV2,
}
