import { FunctionComponent, useCallback, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import useCueLiveTeaser from '@hooks/useCueLiveTeaser'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import Swiper, { ArrowsVisibilityType, SwiperClass } from '@components/Swiper'

import NewsTickerPost from './NewsTickerPost'
import SwiperArrows from './SwiperArrows'

const MAX_MOBILE_POSTS = 2

export interface NewsTickerProps {
  cueLiveId: string
  isInHeroModule?: boolean
}

const StyledSwiper = styled(Swiper)<{
  isInHeroModule?: boolean
}>`
  ${({
    theme: {
      colors: { staticBlack },
      spacing: { spacing16, spacing24 },
    },
    isInHeroModule,
  }) => {
    const mobileStyles = css`
      padding-left: ${spacing16};
      padding-right: ${spacing16};

      & > .swiper-items-container {
        overflow: hidden;
        grid-auto-flow: row;
        grid-auto-columns: auto;
      }
    `

    return css`
      min-height: 85px;
      box-sizing: border-box;

      background-color: ${staticBlack};

      padding-top: ${spacing16};
      padding-bottom: ${spacing16};

      ${isInHeroModule &&
      css`
        ${desktopCSS(css`
          & > .swiper-items-container {
            padding-left: ${spacing24};
            padding-right: ${spacing24};

            grid-template-columns: repeat(3, 1fr);
          }
        `)}
      `}

      ${mobileAndTabletCSS(mobileStyles)}
    `
  }}
`

const StyledNewsTickerPost = styled(NewsTickerPost)<{
  isInHeroModule?: boolean
}>`
  ${({
    theme: {
      spacing: { spacing24 },
    },
    isInHeroModule,
  }) => css`
    width: 100%;

    ${mobileAndTabletCSS(css`
      &:nth-child(n + ${MAX_MOBILE_POSTS + 1}) {
        display: none;
      }
    `)}

    ${desktopCSS(css`
      width: 240px;
      height: 54px;

      &:first-child {
        margin-left: ${spacing24};
        scroll-margin-left: ${spacing24};
      }

      &:last-child {
        margin-right: ${spacing24};
        scroll-margin-right: ${spacing24};
      }

      ${isInHeroModule &&
      css`
        width: auto;

        &:first-child {
          margin-left: 0;
          scroll-margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
          scroll-margin-right: 0;
        }
      `}
    `)}
  `}
`

const StyledSwiperArrows = styled(SwiperArrows)<{
  itemsCount: number
  isInHeroModule?: boolean
}>`
  ${({ itemsCount, isInHeroModule }) => css`
    ${(itemsCount <= 2 || isInHeroModule) &&
    css`
      display: none;
    `}
  `}
`

const NewsTicker: FunctionComponent<NewsTickerProps> = ({
  cueLiveId,
  isInHeroModule,
}) => {
  const posts = useCueLiveTeaser({ cueLiveId })

  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>()

  const onInit = useCallback(
    (swiper: any) => {
      if (swiperRef) {
        swiperRef.current = swiper
      }
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  return (
    <StyledSwiper
      spaceBetween={0}
      spaceTopBottom={0}
      onInit={onInit}
      onArrowsVisibility={onArrowsVisibility}
      isInHeroModule={isInHeroModule}
      slides={posts.map(({ id, dateInfo, text }, index) => (
        <StyledNewsTickerPost
          key={id}
          dateInfo={dateInfo}
          isInHeroModule={isInHeroModule}
          isLastChildMobile={
            index === (posts.length >= MAX_MOBILE_POSTS ? 1 : 0)
          }>
          {text}
        </StyledNewsTickerPost>
      ))}>
      <StyledSwiperArrows
        swiperRef={swiperRef}
        arrowsVisibility={arrowsVisibility}
        itemsCount={posts?.length ?? 0}
        isInHeroModule={isInHeroModule}
      />
    </StyledSwiper>
  )
}

export default NewsTicker
