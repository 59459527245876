import { ChatbotReloadQuestion } from '@components/Chatbot/types'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useRef, useState, useEffect } from 'react'

export const CHATBOT_RELOAD_QUESTION_KEY = 'chatbotReloadQuestion'

export const useChatbotReloadQuestion = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const [chatbotReloadQuestion, setChatbotReloadQuestion] = useState(
    queryClient.getQueryData<ChatbotReloadQuestion>([
      CHATBOT_RELOAD_QUESTION_KEY,
    ])
  )

  const updateChatbotReloadQuestion = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === CHATBOT_RELOAD_QUESTION_KEY
      ) {
        setChatbotReloadQuestion(
          queryClient.getQueryData<ChatbotReloadQuestion>([
            CHATBOT_RELOAD_QUESTION_KEY,
          ])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setChatbotReloadQuestion(
      queryClient.getQueryData<ChatbotReloadQuestion>([
        CHATBOT_RELOAD_QUESTION_KEY,
      ])
    )

    unsubscribeFnRef.current = queryCache.subscribe(updateChatbotReloadQuestion)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateChatbotReloadQuestion, queryClient])

  return chatbotReloadQuestion
}
