import { useCallback, useEffect, useRef, useState } from 'react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'

export type ClientViewportType = 'server' | 'desktop' | 'tablet' | 'mobile'

export type UseClientViewportType = () => ClientViewportType

type GetClientViewportType = (queryClient: QueryClient) => ClientViewportType

const getClientViewportType: GetClientViewportType = (queryClient) =>
  queryClient.getQueryData<ClientViewportType>([
    'clientViewportType',
  ]) as ClientViewportType

const useClientViewportType: UseClientViewportType = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const [clientViewportType, setClientViewportType] =
    useState<ClientViewportType>(() => getClientViewportType(queryClient))

  const updateType = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'clientViewportType'
      ) {
        setClientViewportType(getClientViewportType(queryClient))
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setClientViewportType(getClientViewportType(queryClient))

    unsubscribeFnRef.current = queryCache.subscribe(updateType)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, queryClient, updateType])

  return clientViewportType
}

export default useClientViewportType
