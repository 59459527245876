import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import ScoreboardTeamLogo from './ScoreboardTeamLogo'
import ScoreboardTeamContent from './ScoreboardTeamContent'
import useScoreboard from '@hooks/useScoreboard'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { desktopCSS } from '@measures/responsive'
import useIsInCollapsibleScoreboard from '@hooks/useIsInCollapsibleScoreboard'

export type ScoreboardTeamProps = {
  isAwayTeam?: boolean
}

type StyledTeamProps = ScoreboardTeamProps & {
  isScheduled: boolean
  isInCollapsibleScoreboard?: boolean
  isLive?: boolean
}

const StyledTeam = styled.div<StyledTeamProps>`
  ${({
    theme: {
      spacing: { spacing40, spacing32, spacing20 },
    },
    isScheduled,
    isInCollapsibleScoreboard,
    isAwayTeam,
    isLive,
  }) => {
    const collapsibleScoreboardCSS = css`
      margin-top: 0;
      ${isLive && !isAwayTeam && `padding-left: ${spacing20}`};
    `

    return css`
      margin-top: ${isScheduled ? spacing40 : spacing32};
      ${desktopCSS(css`
        align-self: ${isScheduled ? 'end' : 'center'};
      `)}
      ${isInCollapsibleScoreboard && collapsibleScoreboardCSS};
    `
  }}
`

const StyledTeamName = styled.div<Omit<StyledTeamProps, 'isScheduled'>>`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
      typography: {
        catchword: {
          small2: { bundledCSS: small2CatchwordCSS },
          medium2: { bundledCSS: medium2CatchwordCSS },
        },
      },
      spacing: { spacing8 },
      utils: { ellipsis },
    },
    isInCollapsibleScoreboard,
    isAwayTeam,
  }) => {
    const collapsibleScoreboardCSS = css`
      margin: 0 ${spacing8} 0 ${spacing8};
      text-align: ${isAwayTeam ? 'left' : 'right'};
    `
    return css`
      ${small2CatchwordCSS};
      ${desktopCSS(css`
        margin: 0 ${spacing8};
        ${medium2CatchwordCSS};
        line-height: 1.3;
      `)};
      color: ${primary01Color};
      text-transform: uppercase;
      margin-top: ${spacing8};
      line-height: 1.3;
      ${ellipsis('100%', 2)};
      text-align: center;
      ${isInCollapsibleScoreboard && collapsibleScoreboardCSS};
    `
  }}
`

const ScoreboardTeam: FunctionComponent<ScoreboardTeamProps> = ({
  isAwayTeam,
}) => {
  const { formattedData } = useScoreboard<
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.SOCCER
    | typeof LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY
  >()
  const isInCollapsibleScoreboard = useIsInCollapsibleScoreboard()

  const { teams, isScheduled, isLive } = formattedData

  const team = isAwayTeam ? teams[1] : teams[0]

  return (
    <StyledTeam
      isScheduled={isScheduled}
      isInCollapsibleScoreboard={isInCollapsibleScoreboard}
      isAwayTeam={isAwayTeam}
      isLive={isLive}>
      <ScoreboardTeamContent isAwayTeam={isAwayTeam}>
        {!!team.logoUrl && (
          <ScoreboardTeamLogo
            name={team.name}
            size={isInCollapsibleScoreboard ? 24 : 48}
            url={team.logoUrl}
          />
        )}
        <StyledTeamName
          isInCollapsibleScoreboard={isInCollapsibleScoreboard}
          isAwayTeam={isAwayTeam}>
          {team.name}
        </StyledTeamName>
      </ScoreboardTeamContent>
    </StyledTeam>
  )
}

export default ScoreboardTeam
