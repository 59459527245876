import dynamic from 'next/dynamic'
import ShowMoreButton from '@components/Buttons/ShowMoreButton'
import FadedExpandableTextWrapper from '@components/FadedExpandableTextWrapper'
import FadeOverlay from '@components/FadeOverlay'
import useIsInRecipeContent from '@hooks/useIsInRecipeContent'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { printCSS } from '@utils/style'
import {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import useHeightTransition from '@hooks/useHeightTransition'
import useViewportType from '@hooks/useViewport/useViewportType'

const HTMLView = dynamic(() => import('@components/HTMLView'))

// Line height is 27px, and we want to show max 6 lines on desktop and max 5 lines on mobile
// 162 because 27px x 3, 135 because 27px x 5
const maxHeight = 162
const maxHeightMobile = 135

const allowedHtmlTags = [
  'h1',
  'h2',
  'h3',
  'h4',
  'p',
  'ul',
  'ol',
  'li',
  'em',
  'strong',
  'a',
  'sub',
  'sup',
  'strike',
  'u',
  'br',
  'i',
]

interface TruncateCharactersProps {
  text: string
}

const Wrapper = styled.div`
  display: flex;
`

interface StyledContainerProps {
  isInRecipe: boolean
}

const StyledTruncateCharacters = styled.div<StyledContainerProps>`
  ${({
    isInRecipe,
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopyCSS },
        },
      },
    },
  }) => css`
    display: flex;
    flex-direction: column;

    ${small2BodyCopyCSS}
    p {
      ${!isInRecipe
        ? css`
            margin: 0;
          `
        : css`
            br {
              display: none;
            }
          `}
    }
  `}
`

const TextContainer = styled.div<StyledContainerProps>`
  ${({ isInRecipe }) => css`
    overflow: hidden;
    transition: height 0.5s ease-out;
    ${printCSS(css`
      overflow: visible;
      transition: unset;
      height: max-content;
    `)};

    ${isInRecipe &&
    css`
      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    `}
  `}
`

const TruncateCharacters: FunctionComponent<TruncateCharactersProps> = ({
  text,
}) => {
  const viewportType = useViewportType()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const isInRecipe = useIsInRecipeContent()
  const componentRef = useRef<HTMLDivElement | null>(null)

  const trackExpandClick = useCallback<TrackingFnType<{ buttonText: string }>>(
    ({ extraData }) => ({
      event: 'recipe_click',
      eventCategory: 'recipe',
      eventLabel: extraData.buttonText,
      eventAction: 'click',
    }),
    []
  )

  const handleTrackExpandClick = useTracking(trackExpandClick)

  const toggleCharacters = (buttonText: string) => {
    setIsExpanded(!isExpanded)
    if (isInRecipe) {
      handleTrackExpandClick({ buttonText })
    }
  }

  const getMaxHeightBasedOnViewport = useCallback(() => {
    return 'mobile' === viewportType ? maxHeightMobile : maxHeight
  }, [viewportType])

  const collapsedHeight = useMemo(
    () => getMaxHeightBasedOnViewport(),
    [getMaxHeightBasedOnViewport]
  )
  const getIsComponentExpanded = useCallback(() => isExpanded, [isExpanded])
  const { showMoreButton } = useHeightTransition({
    getIsComponentExpanded,
    collapsedHeight,
    componentRef,
  })

  return (
    <Wrapper>
      <StyledTruncateCharacters isInRecipe={isInRecipe}>
        <TextContainer ref={componentRef} isInRecipe={isInRecipe}>
          <HTMLView content={text} allowedHtmlTags={allowedHtmlTags} />
        </TextContainer>
        {showMoreButton && (
          <FadedExpandableTextWrapper>
            <FadeOverlay isExpanded={isExpanded} />
            <ShowMoreButton
              isMore={!isExpanded}
              onClick={toggleCharacters}
              size="large"
            />
          </FadedExpandableTextWrapper>
        )}
      </StyledTruncateCharacters>
    </Wrapper>
  )
}

export default TruncateCharacters
