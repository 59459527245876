import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import BlueSkyComponent from '@components/SocialEmbed/BlueSky'

interface BlueSkyProps {
  url: string
}

const BlueSky: FunctionComponent<BlueSkyProps> = ({ url }) => (
  <BlueSkyComponent url={url} />
)

const widget = {
  kind: ['widget', 'social-embed', 'bluesky'],
  component: BlueSky,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
