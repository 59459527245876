import ChatbotArticleSlider from '../ChatbotArticleSlider'
import AnswerFeedback from './AnswerFeedback'
import { FunctionComponent } from 'react'
import type { AnswerFooterProps } from '../types'
import translate from '@i18n'

const AnswerFooter: FunctionComponent<AnswerFooterProps> = ({
  answer,
  activeIndex,
  showUserSnapRating = true,
  onSlideClick,
}) => {
  return (
    <>
      {answer.links && !!Object.keys(answer.links).length && (
        <>
          <ChatbotArticleSlider
            links={answer.links}
            activeIndex={activeIndex}
            title={translate('summary.sources.title')}
            onSlideClick={onSlideClick}
          />
        </>
      )}

      {showUserSnapRating && <AnswerFeedback message={answer} />}
    </>
  )
}

export default AnswerFooter
