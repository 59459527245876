import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import {
  getAccountUrl,
  getLoginUrl,
  getLogoutUrl,
  setLoginCookie,
  setLogoutCookie,
} from './utils'

export type Login = (options: {
  prefix: string
  loginCase?: string
  omitPublicationInSource?: boolean
}) => void

export type LoginWithSource = (options: {
  source: string
  loginCase?: string
  omitPublicationInSource?: boolean
}) => void

export type DoLogin = (options: {
  loginCase?: string
  sourcePrefix?: string
  fullSource?: string
  omitPublicationInSource?: boolean
}) => void

export type Logout = () => void

export type VisitUserAccount = () => void

export type UseAuthentication = () => {
  login: Login
  loginWithSource: LoginWithSource
  logout: Logout
  visitUserAccount: VisitUserAccount
}

const useAuthentication: UseAuthentication = () => {
  const queryClient = useQueryClient()
  const router = useRouter()

  const trackLoginEvent = useCallback<TrackingFnType>(
    () => ({
      event: 'login',
      eventCategory: 'sso',
      eventLabel: 'profil',
      eventAction: 'login',
    }),
    []
  )

  const trackLogin = useTracking(trackLoginEvent)

  const trackLogoutEvent = useCallback<TrackingFnType>(
    () => ({
      event: 'logout',
      eventCategory: 'sso',
      eventLabel: 'profil',
      eventAction: 'logout',
    }),
    []
  )

  const trackLogout = useTracking(trackLogoutEvent)

  const doLogin = useCallback<DoLogin>(
    ({ sourcePrefix, fullSource, loginCase, omitPublicationInSource }) => {
      setLoginCookie()
      trackLogin()

      router.push(
        getLoginUrl({
          queryClient,
          sourcePrefix,
          fullSource,
          loginCase,
          omitPublicationInSource,
        })
      )
    },
    [queryClient, trackLogin, router]
  )

  const login = useCallback<Login>(
    ({ prefix, loginCase, omitPublicationInSource }) => {
      doLogin({ sourcePrefix: prefix, loginCase, omitPublicationInSource })
    },
    [doLogin]
  )

  const loginWithSource = useCallback<LoginWithSource>(
    ({ source, loginCase, omitPublicationInSource }) => {
      doLogin({ fullSource: source, loginCase, omitPublicationInSource })
    },
    [doLogin]
  )

  const logout = useCallback<Logout>(() => {
    setLogoutCookie()
    trackLogout()

    router.push(getLogoutUrl())
  }, [router, trackLogout])

  const visitUserAccount = useCallback<VisitUserAccount>(() => {
    window.open(getAccountUrl(), '_blank', 'noopener')
  }, [])

  return useMemo(
    () => ({ login, loginWithSource, logout, visitUserAccount }),
    [login, loginWithSource, logout, visitUserAccount]
  )
}

export default useAuthentication
