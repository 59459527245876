import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type HasAureusJsClientScriptInitializedType = boolean

export type UseHasAureusJsClientScriptInitialized =
  () => HasAureusJsClientScriptInitializedType

const useHasAureusJsClientScriptInitialized: UseHasAureusJsClientScriptInitialized =
  () => {
    const queryClient = useQueryClient()
    const queryCache = queryClient.getQueryCache()
    const unsubscribeFnRef = useRef<() => void>(undefined)

    const [
      hasAureusJsClientScriptInitialized,
      setHasAureusJsClientScriptInitialized,
    ] = useState<boolean>(
      () =>
        !!queryClient.getQueryData<HasAureusJsClientScriptInitializedType>([
          'has-aureus-js-client-script-initialized',
        ])
    )

    const updateHasAureusJsClientScriptInitialized = useCallback(
      (args: any) => {
        if (
          ['invalidate'].includes(args?.action?.type) &&
          args.query.queryKey[0] === 'has-aureus-js-client-script-initialized'
        ) {
          setHasAureusJsClientScriptInitialized(
            !!queryClient.getQueryData<HasAureusJsClientScriptInitializedType>([
              'has-aureus-js-client-script-initialized',
            ])
          )
        }
      },
      [queryClient]
    )

    useEffect(() => {
      setHasAureusJsClientScriptInitialized(
        !!queryClient.getQueryData<HasAureusJsClientScriptInitializedType>([
          'has-aureus-js-client-script-initialized',
        ])
      )

      unsubscribeFnRef.current = queryCache.subscribe(
        updateHasAureusJsClientScriptInitialized
      )

      return () => {
        if (unsubscribeFnRef.current) {
          unsubscribeFnRef.current()
        }
      }
    }, [queryCache, queryClient, updateHasAureusJsClientScriptInitialized])

    return hasAureusJsClientScriptInitialized
  }

export default useHasAureusJsClientScriptInitialized
