import React, { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import usePageMetadata from '@hooks/usePageMetadata'
import { isVideoOnlyArticle } from '@utils/cook'
import useIsInHerotellingPage from '@hooks/useIsInHerotellingPage'
import { mobileAndTabletCSS } from '@measures/responsive'

interface WrapperProps {
  isInHerotellingPage: boolean
  isVideoOnly: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey300 },
      },
      spacing: { spacing16 },
    },
    isVideoOnly,
    isInHerotellingPage,
  }) => {
    return css`
      width: 100%;
      display: flex;
      flex-direction: column;

      ${!isVideoOnly &&
      css`
        border-bottom: 1px solid ${grey300};
        ${isInHerotellingPage &&
        mobileAndTabletCSS(css`
          border-bottom: none;
        `)}
        padding-bottom: ${spacing16};
      `}
    `
  }}
`

const SharingAndTextToSpeechWrapper: FunctionComponent<{
  children?: ReactNode
}> = ({ children }) => {
  const { targetContentType } = usePageMetadata()
  const isVideoOnly = isVideoOnlyArticle(targetContentType)
  const isInHerotellingPage = useIsInHerotellingPage()

  return (
    <Wrapper
      isVideoOnly={isVideoOnly}
      isInHerotellingPage={isInHerotellingPage}>
      {children}
    </Wrapper>
  )
}

export default SharingAndTextToSpeechWrapper
