import {
  AllowedSports,
  CustomTitle,
  NumOfTeams,
} from '@widgets/SportTableInline/types'
import { ALLOWED_SPORTS, OUTCOME_TYPES } from '@widgets/SportTableInline/utils'
import { useQuery } from '@tanstack/react-query'
import translate from '@i18n'
import {
  composeRequestUrlSportStandings,
  composeRequestUrlSportStandingsV2,
  getSportsStandingsData,
  isValidSportsTable,
} from '@utils/sport'

interface useSportsStandingsProps {
  customTitle: CustomTitle
  numOfTeams: NumOfTeams
  seasonId: string
  typeOfSport: AllowedSports
}

export interface Team {
  name: string
  logoSrc: string
  sportradarId: string
}

export type OutcomeType = (typeof OUTCOME_TYPES)[keyof typeof OUTCOME_TYPES]

export interface DataRow {
  outcomeType?: OutcomeType
  rank: number
  team: Team
  gamesPlayed: number
  goalDifference: number
  points: number
}

type Legend = Record<string, OutcomeType>

export type Legends = Legend[]

export interface Table {
  title: string
  dataRows: DataRow[]
  legend: Legends
}

export type Header = HeaderItem[]

export interface HeaderItem {
  id: keyof DataRow
  name: string
}

export type OutcomeTypeColors = Record<OutcomeType, string>

export interface ValidAPIResponse {
  tables: Table[]
  header: Header
  sportsTableType: AllowedSports
  sportsTableId: string
  outcomeTypeColors: OutcomeTypeColors
  pollIntervalInMilliseconds?: number
}

type CreatePlaceholderDataForWeb = (args: {
  numOfTeams: number[]
  customTitle: string[]
  seasonId: ValidAPIResponse['sportsTableId']
  typeOfSport: ValidAPIResponse['sportsTableType']
}) => ValidAPIResponse | undefined

type CreateDataRows = (numOfTeamsForTable: number) => Table['dataRows']

const getPlaceholderHeader = (
  translation: typeof translate
): { id: keyof DataRow; name: string }[] => [
  { id: 'outcomeType', name: '' },
  { id: 'rank', name: '' },
  {
    id: 'team',
    name: translation('sportTable.name'),
  },
  { id: 'gamesPlayed', name: translation('sportTable.matchesTotal') },
  { id: 'goalDifference', name: translation('sportTable.goalDiffTotal') },
  { id: 'points', name: translation('sportTable.pointsTotal') },
]

const PLACEHOLDER_OUTCOME_TYPE_COLORS = Object.values(OUTCOME_TYPES).reduce(
  (acc, outcome) => {
    acc[outcome] = 'transparent'
    return acc
  },
  {} as OutcomeTypeColors
)

const PLACEHOLDER_DATA_ROW = (rank: number) => ({
  rank: rank,
  team: { name: '█ █ █ █', logoSrc: '', sportradarId: '' },
  gamesPlayed: 0,
  goalDifference: 0,
  points: 0,
})

const createDataRows: CreateDataRows = (numOfTeamsForTable) => {
  const dataRows = []

  for (let i = 0; i < numOfTeamsForTable; i++) {
    dataRows.push(PLACEHOLDER_DATA_ROW(i + 1))
  }

  return dataRows
}

const createPlaceholderDataForWeb: CreatePlaceholderDataForWeb = ({
  numOfTeams,
  customTitle,
  seasonId,
  typeOfSport,
}) => {
  if (!numOfTeams.length) {
    return
  }
  return {
    header: getPlaceholderHeader(translate),
    tables: numOfTeams
      .map((numOfTeamsForTable, index) => ({
        title:
          customTitle.length > 0 && customTitle.length === numOfTeams.length
            ? customTitle[index]
            : '█ █ █ █',
        dataRows: createDataRows(numOfTeamsForTable),
        legend: [],
      }))
      .filter((_table, index) => numOfTeams[index] > 0),
    sportsTableType: typeOfSport,
    sportsTableId: seasonId,
    outcomeTypeColors: PLACEHOLDER_OUTCOME_TYPE_COLORS,
  }
}

const validSeasonId = (seasonId: useSportsStandingsProps['seasonId']) =>
  !!seasonId && seasonId !== '0'

const validTypeOfSport = (
  typeOfSport: useSportsStandingsProps['typeOfSport']
) => Object.values(ALLOWED_SPORTS).includes(typeOfSport)

const transformData = ({
  customTitle,
  data,
  numOfTeams,
  seasonId,
  typeOfSport,
}: useSportsStandingsProps & { data: ValidAPIResponse }) => {
  const isValid =
    isValidSportsTable(data) &&
    data.sportsTableType === typeOfSport &&
    data.sportsTableId === seasonId

  if (!isValid) {
    throw new Error(
      `Invalid sports standings data for sport: ${typeOfSport} with seasonId: ${seasonId}`
    )
  }

  const tables =
    customTitle.length > 0 && customTitle.length === data.tables.length
      ? data.tables.map((table, index) => ({
          ...table,
          title: customTitle[index],
        }))
      : data.tables

  return {
    ...data,
    tables: tables.filter(
      (_standing, index) => numOfTeams.length === 0 || numOfTeams[index] > 0
    ),
  }
}

const useSportsStandings = ({
  customTitle,
  numOfTeams,
  seasonId,
  typeOfSport,
}: useSportsStandingsProps) => {
  const isEnabled = validSeasonId(seasonId) && validTypeOfSport(typeOfSport)
  const placeholderData = createPlaceholderDataForWeb({
    numOfTeams,
    customTitle,
    seasonId,
    typeOfSport,
  })

  const { data } = useQuery({
    queryKey: ['sportsStandings', typeOfSport, seasonId],
    queryFn: async () => {
      return getSportsStandingsData({
        expectedTypeOfSport: typeOfSport,
        expectedSeasonId: seasonId,
      })(
        typeOfSport === 'soccer'
          ? composeRequestUrlSportStandingsV2({ typeOfSport, seasonId })
          : composeRequestUrlSportStandings({ typeOfSport, seasonId })
      )
    },
    enabled: isEnabled,
    refetchInterval: (query) => {
      if (query.state.data?.pollIntervalInMilliseconds) {
        return query.state.data.pollIntervalInMilliseconds > 0
          ? query.state.data.pollIntervalInMilliseconds
          : false
      }

      return false
    },
    placeholderData,
  })

  return isEnabled && data
    ? transformData({
        customTitle,
        data,
        numOfTeams,
        seasonId,
        typeOfSport,
      })
    : placeholderData
}

export { transformData }

export default useSportsStandings
