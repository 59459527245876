import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'

import Blink from '@components/Blink'
import { LinkCarouselTile as LinkCarouselTileType } from '@widgets/LinkCarousel'
import LinkCarouselLogo from './LinkCarouselLogo'
import useTracking, { TrackingFnType } from '@hooks/useTracking'

const StyledLinkCarouselLink = styled(Blink)`
  display: flex;
  flex-direction: column;

  text-decoration: none;
  appearance: none;

  width: 104px;
  height: 86px;
`

const StyledTitle = styled.div`
  ${({
    theme: {
      colors: { textDefault },
      typography: {
        catchword: {
          small2: { bundledCSS: small2CSS },
        },
      },
      spacing: { spacing8 },
      utils: { ellipsis },
    },
  }) => css`
    ${small2CSS};
    color: ${textDefault};
    margin-top: ${spacing8};
    text-align: center;
    ${ellipsis('100%', 2)};
  `}
`

type LinkCarouselTileProps = LinkCarouselTileType & {
  mainTitle: string
}

const LinkCarouselTile: FunctionComponent<LinkCarouselTileProps> = ({
  link,
  image,
  mainTitle,
  title,
}) => {
  const onClickTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'linkcarousel_click',
      eventAction: 'click',
      eventCategory: 'linkcarousel_widget',
      eventLabel: title,
      linkcarousel_title: mainTitle,
    }),
    [mainTitle, title]
  )

  const onClick = useTracking(onClickTrackingFn)

  return (
    <StyledLinkCarouselLink {...link} ariaLabel={link.title} onClick={onClick}>
      <LinkCarouselLogo image={image} />
      <StyledTitle>{title}</StyledTitle>
    </StyledLinkCarouselLink>
  )
}

export default LinkCarouselTile
