import useDisableThirdPartyScripts from '@hooks/useDisableThirdPartyScripts'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import useCMPHasUserInteracted from '@hooks/useCMPHasUserInteracted'
import { User } from '@hooks/useUser'
import { FunctionComponent, useEffect } from 'react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { SubscriptionData } from '@components/AuthManager'
import config from '@config'
import { returnIfDefined } from '@hooks/useTracking/utils'
import { PageData, PageMetadata } from '@hooks/usePageMetadata'
import { paywallBypassReason } from '@components/Piano/utils'
import { getURLParam } from '@utils/urlParams'

const {
  abTest: { windowKey },
  backend: { deploymentEnv },
  tracking: { persistentTrackingFields },
} = config

const eventWithFlushedKeys = (
  queryClient: QueryClient,
  event: Record<string, unknown>
): Record<string, unknown> => {
  //! Every time we track an event, we not the event keys that were tracked.
  //! The next time we reset those keys, if they are not set to a new value.
  //! This way we flush the dataLayer keys so they are not persistent.
  const previouslyTrackedKeys =
    queryClient.getQueryData<string[]>(['previously-tracked-keys']) ?? []

  const flushedKeysObj: Record<string, undefined> = {}

  previouslyTrackedKeys.forEach((previouslyTrackedKey) => {
    if (
      !event.hasOwnProperty(previouslyTrackedKey) &&
      !(persistentTrackingFields as unknown as string[]).includes(
        previouslyTrackedKey
      )
    ) {
      flushedKeysObj[previouslyTrackedKey] = undefined
    }
  })

  queryClient.setQueryData(['previously-tracked-keys'], Object.keys(event))

  return { ...flushedKeysObj, ...event }
}

const enrichEvent = (
  queryClient: QueryClient,
  event: Record<string, unknown>
): Record<string, unknown> => {
  const user = queryClient.getQueryData<User>(['user'])
  const subscriptionData = queryClient.getQueryData<SubscriptionData>([
    'subscription-data',
  ])
  const { id: articleId } = queryClient.getQueryData<PageData>(['page'])
    ?.metadata as PageMetadata

  const isGiftedArticle = !!getURLParam('sharingPlus', queryClient)

  return {
    ...event,
    ...(!subscriptionData
      ? {}
      : {
          ...Object.entries(subscriptionData).reduce(
            (acc, [key, value]) => {
              return {
                ...acc,
                ...returnIfDefined({
                  key,
                  value: value as Partial<
                    number | boolean | string | unknown[]
                  >,
                }),
              }
            },
            {} as Record<string, number | boolean | string | unknown[]>
          ),
          ...returnIfDefined({
            key: 'user_status',
            value: subscriptionData?.user_status,
            deriveTrackingValue: (value) => {
              if (!user) {
                return 'notLoggedIn'
              } else if (typeof value === 'string') {
                return value
              }
            },
          }),
          ...returnIfDefined({
            key: 'paywall_bypass',
            value: paywallBypassReason({
              isGiftedArticle,
              subscriptionData,
              articleId,
            }),
            deriveTrackingValue: (value) => {
              if (event.event === 'article_page_view') {
                return value as string
              }
            },
          }),
        }),
    ...returnIfDefined({
      key: 'userId',
      value: user?.sub,
    }),
  }
}

const logEvent = (
  queryClient: QueryClient,
  event: Record<string, unknown>,
  autoIncrementId: number
): void => {
  const enableTrackingDebugLog =
    queryClient.getQueryData<'show'>([windowKey, 'showTrackingDebugView']) ===
    'show'

  if (deploymentEnv === 'dev' || enableTrackingDebugLog) {
    queryClient.setQueryData(
      ['event-debugger', (event as any).event, autoIncrementId],
      //! Because JSON doesn't support `undefined`, this will get rid of all the explicitly
      //! undefined variables. It will also create a deep copy of the object each time it's parsed.
      JSON.parse(JSON.stringify(event))
    )
  }
}

const enrichAndPushEvent = (
  event: Record<string, unknown>,
  queryClient: QueryClient,
  autoIncrementId: number
) => {
  const enrichedEvent = enrichEvent(queryClient, event)

  const enrichedEventWithFlushedKeys = eventWithFlushedKeys(
    queryClient,
    enrichedEvent
  )

  logEvent(queryClient, enrichedEventWithFlushedKeys, autoIncrementId)

  window.dataLayer.push(enrichedEventWithFlushedKeys)
}

const TrackingManager: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const disableThirdPartyScripts = useDisableThirdPartyScripts()
  const { subscriptionStatus } = useSubscriptionStatus()

  //! We need to wait until the user has interacted with the CMP popup,
  //! because of how GTM handles events.
  const hasUserInteracted = useCMPHasUserInteracted()

  useEffect(() => {
    if (!disableThirdPartyScripts && subscriptionStatus && hasUserInteracted) {
      if (window.eventQueueDataLayer) {
        window.eventQueueDataLayer.forEach((event, eventIndex) =>
          enrichAndPushEvent(event, queryClient, eventIndex)
        )
        ;(window as any).eventQueueDataLayer = {
          length: window.eventQueueDataLayer.length,
          push: (...events: Record<string, unknown>[]) => {
            length++
            events.forEach((event) =>
              enrichAndPushEvent(event, queryClient, length + 1)
            )
          },
          forEach: window.eventQueueDataLayer.forEach,
        }
      } else {
        console.error('eventQueueDataLayer is not defined! it should be!')
      }
    }
  }, [
    subscriptionStatus,
    hasUserInteracted,
    queryClient,
    disableThirdPartyScripts,
  ])

  return null
}

export default TrackingManager
