import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'
import SvgIcon from '../SvgIcon'
import { SvgIconName } from '../SvgIcon/types'
import { mobileAndTabletCSS } from '@measures/responsive'

const StyledSummaryHeader = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing24 },
    },
  }) => css`
    align-items: center;
    column-gap: ${spacing8};
    display: flex;
    margin: 0 0 ${spacing24} 0;
    text-transform: uppercase;
    width: 100%;
  `}
`

const SummaryTitleStyled = styled.h3<{
  isCollapsed: boolean
  uppercase: boolean
}>`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeading },
        },
      },
      spacing: { spacing8 },
    },
    uppercase,
  }) => css`
    display: flex;
    align-items: center;
    text-transform: none;
    gap: ${spacing8};
    line-height: 1;
    margin: 0;
    text-transform: ${uppercase ? 'uppercase' : 'initial'};
    ${mobileAndTabletCSS(css`
      word-break: break-word;
      hyphens: auto;
    `)}

    ${smallHeading}
    height: auto;
  `}
`

const StyledSvgIcon = styled(SvgIcon)<{ iconSize: number }>`
  ${({ iconSize }) => css`
    max-height: 35px;
    min-width: ${iconSize}px;
  `}
`

const SummaryHeader: FunctionComponent<{
  isCollapsed?: boolean
  prefixIconName?: SvgIconName
  after?: React.ReactNode
  children?: React.ReactNode
  iconSize?: number
  uppercase?: boolean
}> = ({
  prefixIconName,
  after,
  isCollapsed = false,
  children,
  iconSize = 24,
  uppercase = true,
}) => {
  const theme = useTheme()
  return (
    <StyledSummaryHeader>
      {prefixIconName && (
        <StyledSvgIcon
          iconName={prefixIconName}
          iconSize={iconSize}
          size={iconSize}
          color={theme.color.primary.primary01}
        />
      )}

      <SummaryTitleStyled isCollapsed={isCollapsed} uppercase={uppercase}>
        {children}
      </SummaryTitleStyled>
      {after}
    </StyledSummaryHeader>
  )
}

export default SummaryHeader
