import { getCSSVariable } from '@themes/utils'

const BASE_COLORS = {
  red400: '#ff0000',
  red500: '#e20000',
  red900: '#a70000',

  green100: '#eff5e8',
  green500: '#62a01a',
  green700: '#508216',
  green900: '#385912',
  green950: '#181b11',

  white: '#ffffff',
  black: '#121010',

  grey100: '#f5f5f5',
  grey200: '#e1e0e0',
  grey300: '#c8c6c6',
  grey400: '#a5a1a1',
  grey500: '#837c7c',
  grey600: '#6a6262',
  grey700: '#504949',
  grey800: '#3b3535',
  grey900: '#292424',
  grey950: '#1e1a1a',
} as const satisfies Record<string, string>

const colorConfig = {
  textDefault: getCSSVariable({
    light: BASE_COLORS.black,
    dark: BASE_COLORS.grey100,
  }),
  textDefaultHover: getCSSVariable({
    light: BASE_COLORS.grey900,
    dark: BASE_COLORS.grey200,
  }),
  textDefaultDisabled: getCSSVariable({
    light: BASE_COLORS.grey400,
    dark: BASE_COLORS.grey700,
  }),
  textWeak: getCSSVariable({
    light: BASE_COLORS.grey600,
    dark: BASE_COLORS.grey400,
  }),
  textInverse: getCSSVariable({
    light: BASE_COLORS.white,
    dark: BASE_COLORS.black,
  }),
  textBrand: getCSSVariable({
    light: BASE_COLORS.red500,
    dark: BASE_COLORS.red400,
  }),

  textBrandHover: getCSSVariable({
    light: BASE_COLORS.red900,
    dark: BASE_COLORS.red900,
  }),
  textSport: getCSSVariable({
    light: BASE_COLORS.green700,
    dark: BASE_COLORS.green500,
  }),
  strokeDefault: getCSSVariable({
    light: BASE_COLORS.grey600,
    dark: BASE_COLORS.grey400,
  }),
  strokeDefaultDisabled: getCSSVariable({
    light: BASE_COLORS.grey300,
    dark: BASE_COLORS.grey800,
  }),
  strokeWeak: getCSSVariable({
    light: BASE_COLORS.grey300,
    dark: BASE_COLORS.grey800,
  }),
  strokeStrong: getCSSVariable({
    light: BASE_COLORS.black,
    dark: BASE_COLORS.white,
  }),
  strokeStrongHover: getCSSVariable({
    light: BASE_COLORS.grey900,
    dark: BASE_COLORS.grey200,
  }),
  strokeInverse: getCSSVariable({
    light: BASE_COLORS.white,
    dark: BASE_COLORS.black,
  }),
  strokeBrand: getCSSVariable({
    light: BASE_COLORS.red500,
    dark: BASE_COLORS.red400,
  }),
  strokeBrandHover: getCSSVariable({
    light: BASE_COLORS.red900,
    dark: BASE_COLORS.red900,
  }),
  strokeSport: getCSSVariable({
    light: BASE_COLORS.green500,
    dark: BASE_COLORS.green500,
  }),
  strokeSportHover: getCSSVariable({
    light: BASE_COLORS.green900,
    dark: BASE_COLORS.green900,
  }),
  fillDefault: getCSSVariable({
    light: BASE_COLORS.white,
    dark: BASE_COLORS.black,
  }),
  fillDefaultDisabled: getCSSVariable({
    light: BASE_COLORS.grey200,
    dark: BASE_COLORS.grey900,
  }),
  fillWeak: getCSSVariable({
    light: BASE_COLORS.grey100,
    dark: BASE_COLORS.grey950,
  }),
  fillStrong: getCSSVariable({
    light: BASE_COLORS.grey200,
    dark: BASE_COLORS.grey800,
  }),
  fillStrongHover: getCSSVariable({
    light: BASE_COLORS.grey300,
    dark: BASE_COLORS.grey700,
  }),
  fillStrongest: getCSSVariable({
    light: BASE_COLORS.grey600,
    dark: BASE_COLORS.grey400,
  }),
  fillInverse: getCSSVariable({
    light: BASE_COLORS.black,
    dark: BASE_COLORS.white,
  }),
  fillInverseHover: getCSSVariable({
    light: BASE_COLORS.grey900,
    dark: BASE_COLORS.grey200,
  }),
  fillBrand: getCSSVariable({
    light: BASE_COLORS.red500,
    dark: BASE_COLORS.red500,
  }),
  fillBrandHover: getCSSVariable({
    light: BASE_COLORS.red900,
    dark: BASE_COLORS.red900,
  }),
  fillSport: getCSSVariable({
    light: BASE_COLORS.green700,
    dark: BASE_COLORS.green700,
  }),
  fillSportHover: getCSSVariable({
    light: BASE_COLORS.green900,
    dark: BASE_COLORS.green900,
  }),
  fillSportWeak: getCSSVariable({
    light: BASE_COLORS.green100,
    dark: BASE_COLORS.green950,
  }),
  fillSurface: getCSSVariable({
    light: BASE_COLORS.white,
    dark: BASE_COLORS.grey800,
  }),
  fillVideo: getCSSVariable({
    light: BASE_COLORS.black,
    dark: BASE_COLORS.grey900,
  }),
  iconDefault: getCSSVariable({
    light: BASE_COLORS.black,
    dark: BASE_COLORS.grey100,
  }),
  iconDefaultDisabled: getCSSVariable({
    light: BASE_COLORS.grey400,
    dark: BASE_COLORS.grey700,
  }),
  iconWeak: getCSSVariable({
    light: BASE_COLORS.grey600,
    dark: BASE_COLORS.grey400,
  }),
  iconInverse: getCSSVariable({
    light: BASE_COLORS.white,
    dark: BASE_COLORS.black,
  }),
  iconBrand: getCSSVariable({
    light: BASE_COLORS.red500,
    dark: BASE_COLORS.red400,
  }),
  iconBrandHover: getCSSVariable({
    light: BASE_COLORS.red900,
    dark: BASE_COLORS.red900,
  }),
  iconSport: getCSSVariable({
    light: BASE_COLORS.green700,
    dark: BASE_COLORS.green500,
  }),
  iconSportHover: getCSSVariable({
    light: BASE_COLORS.green900,
    dark: BASE_COLORS.green900,
  }),
} as const satisfies Record<string, string>

const colorStaticConfig = {
  staticWhite: BASE_COLORS.white,
  staticBlack: BASE_COLORS.black,
  staticRed400: BASE_COLORS.red400,
  staticRed500: BASE_COLORS.red500,
  staticRed900: BASE_COLORS.red900,
  staticGreen500: BASE_COLORS.green500,
  staticGreen700: BASE_COLORS.green700,
  staticGreen900: BASE_COLORS.green900,
  staticNeutral100: BASE_COLORS.grey100,
  staticNeutral200: BASE_COLORS.grey200,
  staticNeutral300: BASE_COLORS.grey300,
  staticNeutral400: BASE_COLORS.grey400,
  staticNeutral500: BASE_COLORS.grey500,
  staticNeutral600: BASE_COLORS.grey600,
  staticNeutral700: BASE_COLORS.grey700,
  staticNeutral800: BASE_COLORS.grey800,
  staticNeutral900: BASE_COLORS.grey900,
  staticNeutral950: BASE_COLORS.grey950,
} as const satisfies Record<string, string>

export { colorConfig, colorStaticConfig }
