import { createGlobalStyle, css } from 'styled-components'
import { printStyles } from '@utils/print'
import { globalAdStyles } from '@utils/ads'
import { globalPianoStyles } from '@components/Piano/utils'
import globalVideoPlayerStyles from '@components/Video/VideoPlayer/globalVideoPlayerStyles'

const GlobalStyle = createGlobalStyle`${({
  theme: {
    colors: { fillDefault, textDefault },
  },
}) => css`
  html {
    font-family: sans-serif;
    font-size: 1rem;
  }

  body {
    position: relative;
    background-color: ${fillDefault};
    margin: 0;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1;
    color: ${textDefault};
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  #__next {
    position: relative;
  }

  //! CMP Hide Floating Button
  #ot-sdk-btn-floating.ot-floating-button {
    display: none;
  }

  ${globalVideoPlayerStyles};

  ${printStyles};

  /* Used for overriding styles in Piano modal */
  ${globalPianoStyles};

  ${globalAdStyles};

  /* React Query Devtools */

  .tsqd-parent-container {
    z-index: 10000;
    position: relative;
  }
`}`

export default GlobalStyle
