import { ConfigFn } from '@config/types'

const config = (({ publication }) => {
  const isRomandie = publication === 'romandie'
  return {
    userSnapFeedbackKey: isRomandie
      ? '1cf40a31-46f6-477f-b8a2-701b28eb1d62'
      : '7af18277-7fb2-46ba-be82-3f1a20e3b2f7',
  } as const
}) satisfies ConfigFn

export default config
