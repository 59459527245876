import { ScoreboardProps } from '@widgets/Scoreboard/types'
import useSportsEvent, {
  UseSportsEventPropsHockey,
  UseSportsEventPropsSoccer,
  UseSportsEventPropsSki,
  UseSportsEventPropsFormula1,
  UseSportsEventPropsTennis,
} from '@hooks/useSportsEvent'
import ScoreboardHockeyOrSoccer from '@components/Scoreboard/ScoreboardHockeyOrSoccer'
import ScoreboardSkiOrFormula1 from '@components/Scoreboard/ScoreboardSkiOrFormula1'
import ScoreboardTennis from '@components/Scoreboard/ScoreboardTennis'
import { createPlaceholderData } from '@widgets/Scoreboard/utils'
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import ScoreboardMainSection from './ScoreboardMainSection'
import ScoreboardContext from '@contexts/scoreboard'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import ScoreboardStreamingLink from '@components/Scoreboard/ScoreboardStreamingLink'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'

const { SOCCER, HOCKEY, SKI, FORMULA1, TENNIS } = LIVE_EVENT_DATA_SPORT_TYPES

const StyledScoreboard = styled.div<{
  shouldShowScoreboard: boolean
}>`
  ${({
    theme: {
      color: {
        tertiary: { grey100 },
      },
      measures: { stretchWidthOnMobile },
    },
    shouldShowScoreboard,
  }) => css`
    background-color: ${grey100};
    display: flex;
    flex-direction: column;
    ${stretchWidthOnMobile()}
    opacity: ${shouldShowScoreboard ? 1 : 0};
    transition: opacity 0.3s ease-in-out;
  `}
`

const Scoreboard: FunctionComponent<ScoreboardProps> = (props) => {
  const {
    matchId,
    typeOfSport,
    streaming,
    updateDisplaySportsPartner,
    setShouldShowCollapsibleScoreboard,
    shouldShowScoreboard,
  } = props

  const placeholderData = useMemo(
    () => createPlaceholderData(typeOfSport),
    [typeOfSport]
  )

  const { data: sportsEventData } = useSportsEvent<
    | UseSportsEventPropsHockey
    | UseSportsEventPropsSoccer
    | UseSportsEventPropsSki
    | UseSportsEventPropsFormula1
    | UseSportsEventPropsTennis
  >({
    matchId,
    typeOfSport,
    placeholderData,
  })

  useEffect(() => {
    if (
      !sportsEventData?.isPlaceholderData &&
      sportsEventData?.formattedData?.isFinished !== undefined
    ) {
      updateDisplaySportsPartner(sportsEventData?.formattedData?.isFinished)
    }
  }, [
    sportsEventData?.formattedData?.isFinished,
    sportsEventData?.isPlaceholderData,
    updateDisplaySportsPartner,
  ])

  const onScoreboardInViewChangeHandler = useCallback(
    (inView: any) => {
      setShouldShowCollapsibleScoreboard(!inView)
    },
    [setShouldShowCollapsibleScoreboard]
  )

  const { ref } = useInView({
    rootMargin: '-150px 0px 0px 0px',
    onChange: onScoreboardInViewChangeHandler,
  })

  return (
    <ScoreboardContext.Provider value={sportsEventData ?? placeholderData}>
      <StyledScoreboard ref={ref} shouldShowScoreboard={shouldShowScoreboard}>
        <ScoreboardMainSection>
          {([HOCKEY, SOCCER] as ScoreboardProps['typeOfSport'][]).includes(
            typeOfSport
          ) && <ScoreboardHockeyOrSoccer />}
          {([FORMULA1, SKI] as ScoreboardProps['typeOfSport'][]).includes(
            typeOfSport
          ) && <ScoreboardSkiOrFormula1 />}
          {(TENNIS as ScoreboardProps['typeOfSport']) === typeOfSport && (
            <ScoreboardTennis />
          )}
        </ScoreboardMainSection>
        {!!streaming &&
          !sportsEventData?.isPlaceholderData &&
          !sportsEventData?.formattedData?.isFinished && (
            <ScoreboardStreamingLink streaming={streaming} />
          )}
      </StyledScoreboard>
    </ScoreboardContext.Provider>
  )
}

export default Scoreboard
