import { ConfigFn } from '@config/types'

const config = (() =>
  ({
    autoUpdateItemCount: 50,
    fetchingStep: 10,
    defaultRefetchInterval: 30,
  }) as const) satisfies ConfigFn

export default config
