import Blink from '@components/Blink'
import { FunctionComponent, ReactNode, SyntheticEvent } from 'react'
import styled, { css } from 'styled-components'

export interface CommonPaginationProps {
  direction?: 'prev' | 'next'
  children: number | string | ReactNode
}

export interface PaginationButtonProps extends CommonPaginationProps {
  type: 'button'
  onClick: (e: SyntheticEvent) => void
}

export interface PaginationLinkProps extends CommonPaginationProps {
  type: 'link'
  href: string
}

export interface PaginationInactiveProps extends CommonPaginationProps {
  type: 'inactive'
  isSelected?: boolean
}

export type PaginationItemProps =
  | PaginationButtonProps
  | PaginationLinkProps
  | PaginationInactiveProps

const commonCSS = css`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: xsmallHeadingCSS },
        },
      },
      colors: { textDefault },
    },
  }) => css`
    ${xsmallHeadingCSS};
    color: ${textDefault};
  `}
`

const numbersCSS = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
`

const selectedNumberCSS = css`
  ${({
    theme: {
      colors: { strokeDefault, textBrand },
      spacing: { spacing96 },
    },
  }) => css`
    color: ${textBrand};
    border: 1px solid ${strokeDefault};
    border-radius: ${spacing96};
  `}
`

const ButtonPaginationItem = styled.button<
  Pick<PaginationButtonProps, 'direction'>
>`
  ${({
    theme: {
      colors: { textBrand, textDefault, iconBrand },
    },
    direction,
  }) => css`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    color: ${direction ? textBrand : textDefault};
    ${commonCSS};
    ${!direction && numbersCSS};

    &:hover {
      ${!direction && selectedNumberCSS}
      color: ${textDefault};

      svg {
        fill: ${iconBrand};
      }
    }
  `}
`

const LinkPaginationItem = styled(Blink)<
  Pick<PaginationLinkProps, 'direction'>
>`
  ${({
    theme: {
      colors: { textDefault, textBrand },
    },
    direction,
  }) => css`
    text-decoration: none;
    ${commonCSS};
    color: ${direction ? textBrand : textDefault};

    &:hover {
      ${!direction && selectedNumberCSS}
      color: ${textDefault};

      svg {
        fill: ${textBrand};
      }
    }

    ${!direction && numbersCSS}
  `}
`

const InactivePaginationItem = styled.span<
  Pick<PaginationInactiveProps, 'isSelected' | 'direction'>
>`
  ${({
    theme: {
      colors: { iconDefaultDisabled },
    },
    isSelected,
    direction,
  }) => css`
    ${commonCSS};
    ${!direction &&
    css`
      ${numbersCSS};
      ${isSelected &&
      css`
        ${selectedNumberCSS};
      `}
    `};

    ${direction &&
    css`
      svg {
        fill: ${iconDefaultDisabled};
      }
    `}
  `}
`

const PaginationItem: FunctionComponent<PaginationItemProps> = (props) => {
  const { type, direction } = props

  if (type === 'button') {
    const { onClick, children } = props
    return (
      <ButtonPaginationItem
        {...(direction ? { direction } : {})}
        onClick={onClick}>
        {children}
      </ButtonPaginationItem>
    )
  } else if (type === 'link') {
    const { href, children } = props
    return (
      <LinkPaginationItem {...(direction ? { direction } : {})} href={href}>
        {children}
      </LinkPaginationItem>
    )
  } else {
    const { children, isSelected } = props
    return (
      <InactivePaginationItem
        {...(direction ? { direction } : {})}
        isSelected={!!isSelected}>
        {children}
      </InactivePaginationItem>
    )
  }
}

export default PaginationItem
