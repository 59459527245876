import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import ScoreboardTeamContent from './ScoreboardTeamContent'
import { LiveEventGoal } from '@utils/formatters/liveEvent'

export type ScoreboardGoalTeamContentProps = {
  isAwayTeam?: boolean
  goals: LiveEventGoal[]
}

const StyledGoal = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey700: grey700color },
      },
      typography: {
        catchword: {
          small1: { bundledCSS: small1CatchwordCSS },
          small2: { bundledCSS: small2CatchwordCSS },
        },
      },
      spacing: { spacing4 },
      utils: { ellipsis },
    },
  }) => css`
    color: ${grey700color};
    margin-bottom: ${spacing4};
    text-transform: uppercase;
    ${ellipsis('100%', 2)};
    ${small2CatchwordCSS};
    > span {
      ${small1CatchwordCSS};
    }
  `}
`

const StyledGoalTeamContent = styled.div<{ isAwayTeam?: boolean }>`
  ${({
    theme: {
      spacing: { spacing4 },
    },
    isAwayTeam,
  }) => css`
    display: grid;
    flex-basis: 100%;
    height: min-content;
    margin-top: ${spacing4};
    > div {
      flex-direction: column;
      align-items: ${isAwayTeam ? 'flex-end' : 'flex-start'};
    }
  `}
`

const ScoreboardGoalTeamContent: FunctionComponent<
  ScoreboardGoalTeamContentProps
> = ({ isAwayTeam, goals }) => {
  return (
    <StyledGoalTeamContent isAwayTeam={isAwayTeam}>
      <ScoreboardTeamContent isAwayTeam={isAwayTeam}>
        {goals?.map(({ minutes, name }) => (
          <StyledGoal key={`${name}${minutes}`}>
            {name}
            <span>{`  ${minutes}`}</span>
          </StyledGoal>
        ))}
      </ScoreboardTeamContent>
    </StyledGoalTeamContent>
  )
}

export default ScoreboardGoalTeamContent
