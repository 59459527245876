import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import SocialButton from './Button'
import config from '@config'
import usePageMetadata from '@hooks/usePageMetadata'
import ToastMessage from './ToastMessage'
import EndOfArticleContext from '@contexts/endOfArticle'
import { copyToClipboard, getUTMParameters } from './utils'

const {
  backend: { baseUrl },
  socialembeds: {
    share: {
      types: {
        copy: { utmSource },
      },
    },
  },
} = config

interface CopyProps {
  shareUrl?: string
  shareUrlHash?: string
  utmContent?: string
  label?: string
}

const Copy: FunctionComponent<CopyProps> = ({
  shareUrl: shareUrlProp,
  shareUrlHash,
  utmContent,
  label,
}) => {
  const endOfArticle = useContext(EndOfArticleContext)
  const pageMetadata = usePageMetadata()
  const [showToast, setShowToast] = useState(false)

  const shareUrl = shareUrlProp ?? `${baseUrl}${pageMetadata?.url}`

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(
        () => setShowToast((showToast) => !showToast),
        5000
      )
      return () => clearTimeout(timer)
    }
  }, [showToast])

  const trackingOnClick = useCallback<TrackingFnType>(
    () => ({
      event: 'element_click',
      element: `article_share_${endOfArticle ? 'bottom' : 'top'}`,
      method: 'copy',
      utm_content: utmContent,
    }),
    [endOfArticle, utmContent]
  )

  const trackingOnClickLegacy = useCallback<TrackingFnType>(
    ({ pageMetadata }) => ({
      event: 'article_share',
      eventCategory: 'article',
      eventAction: `share_copy_${endOfArticle ? 'bottom' : 'top'}`,
      eventLabel: `${pageMetadata.id}:${stripHtml(pageMetadata.title)}`,
      utm_content: utmContent,
    }),
    [endOfArticle, utmContent]
  )

  const trackingFunction = useTracking(trackingOnClick)
  // The *Legacy tracking functions will be deleted when the tracking team is ready to switch to the new events
  const trackingFunctionLegacy = useTracking(trackingOnClickLegacy)

  const onClick = useCallback(() => {
    const getFullShareUrlForCopy = () => {
      const utmSuffix = getUTMParameters({
        utmSource,
        utmContent,
        fullSuffix: true,
      })
      return `${shareUrl}${utmSuffix}${shareUrlHash ?? ''}`
    }

    trackingFunction()
    trackingFunctionLegacy()
    const fullShareUrl = getFullShareUrlForCopy()
    copyToClipboard(fullShareUrl).then(() => setShowToast(true))
  }, [
    trackingFunction,
    trackingFunctionLegacy,
    utmContent,
    shareUrl,
    shareUrlHash,
  ])

  return (
    <>
      <SocialButton type="copy" onClick={onClick}>
        {label}
      </SocialButton>
      {showToast && <ToastMessage />}
    </>
  )
}

export default Copy
