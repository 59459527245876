import { Link } from '@utils/cook/types'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, useCallback, useRef } from 'react'
import translate from '@i18n'
import styled, { css } from 'styled-components'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import usePageMetadata from '@hooks/usePageMetadata'
import PrimaryCTABlink from '@components/Buttons/PrimaryCTABlink'

interface LinkProps {
  text: string
  link: Link
}

interface TopThemenLinkProps {
  title?: string
  link: LinkProps
}

interface TopThemenProps {
  title: string
  links: LinkProps[]
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
`

const StyledTitle = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: headingSmallCSS },
        },
      },
      colors: { strokeBrand, textDefault },
      spacing: { spacing2, spacing8 },
    },
  }) => {
    return css`
      ${headingSmallCSS};
      text-align: left;
      text-transform: uppercase;
      border-left: ${spacing2} solid ${strokeBrand};
      color: ${textDefault};
      padding: 0 ${spacing8};
    `
  }};
`

const StyledTopThemenLink = styled(PrimaryCTABlink)`
  ${({
    theme: {
      spacing: { spacing12, spacing16 },
      utils: { ellipsis },
      colors: { fillStrong, textDefault, fillStrongHover },
    },
  }) => {
    return css`
      padding: ${spacing12} ${spacing16};
      background-color: ${fillStrong};
      color: ${textDefault};
      @media (hover: hover) {
        &:hover:not([disabled]) {
          background-color: ${fillStrongHover};
        }
      }
      ${ellipsis('300px', 1)};
    `
  }};
`

const LinksContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing8 },
    },
  }) => {
    return css`
      margin-top: ${spacing16};
      display: flex;
      flex-direction: row;
      gap: ${spacing8};
      flex-wrap: wrap;

      white-space: nowrap;
      text-overflow: ellipsis;

      min-width: 0;
      object-fit: contain;
    `
  }}
`

const TopthemenLink: FunctionComponent<TopThemenLinkProps> = ({
  link,
  title,
}) => {
  const linkClickTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'topthemen_click',
      eventAction: `${title}_click`,
      eventCategory: 'topThemen',
      eventLabel: link.text,
    }),
    [link.text, title]
  )

  const onLinkClick = useTracking(linkClickTrackingFn)

  return (
    <StyledTopThemenLink size="small" {...link.link} onClick={onLinkClick}>
      {link.text}
    </StyledTopThemenLink>
  )
}

const Topthemen: FunctionComponent<TopThemenProps> = ({ title, links }) => {
  const linksContainerElement = useRef<HTMLDivElement>(null)

  const { title: pageTitle } = usePageMetadata()

  const topthemenImpressionTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'topthemen_impression',
      eventAction: `${pageTitle}_impression`,
      eventCategory: 'topThemen',
      eventLabel: pageTitle,
    }),
    [pageTitle]
  )

  const handleImpressionTrack = useTracking(topthemenImpressionTrackingFn)

  const viewportRef = useViewportTracking({
    onImpression: handleImpressionTrack,
    track: true,
  })

  if (!links || links.length === 0) {
    return null
  }

  return (
    <Container ref={viewportRef}>
      <StyledTitle>{title ?? translate('topStories.defaultTitle')}</StyledTitle>
      <LinksContainer ref={linksContainerElement}>
        {links.map((link, index) => (
          <TopthemenLink
            key={link.link.href || index}
            link={link}
            title={pageTitle}
          />
        ))}
      </LinksContainer>
    </Container>
  )
}

const widget = {
  kind: ['widget', 'topthemen'],
  component: Topthemen,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
