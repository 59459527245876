import { ArticleSchemaData } from '@components/Schema/types'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

type UseArticleSchemaType = ({
  text,
  description,
}: {
  text?: string
  description?: string
}) => void

const useArticleSchema: UseArticleSchemaType = ({ text, description }) => {
  const [uniqueId] = useState(() => nanoid())
  const queryClient = useQueryClient()

  useEffect(() => {
    const oldData = queryClient.getQueryData<ArticleSchemaData>([
      'articleSchemaData',
    ])

    if (oldData) {
      queryClient.setQueryData(['articleSchemaData'], {
        ...oldData,
        articleBody: text
          ? [
              ...oldData.articleBody,
              {
                id: uniqueId,
                text,
              },
            ]
          : oldData.articleBody,
        ...(description ? { description } : {}),
      })
    }
  }, [queryClient, text, description, uniqueId])
}

export default useArticleSchema
