import styled, { css } from 'styled-components'
import { trackHandler } from '../utils'
import TypingAnimationWrapper from './TypingEffectWrapper'
import type { FunctionComponent } from 'react'
import { AnswerParagraphProps, ChatbotMessage } from '../types'
import useViewportType from '@hooks/useViewport/useViewportType'
import useTracking from '@hooks/useTracking'
import SummaryPill from '@components/Summary/SummaryPill'
import Blink from '@components/Blink'

const StyledParagraph = styled.p`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodycopyCSS },
        },
      },
      spacing: { spacing8 },
    },
  }) => css`
    ${small2BodycopyCSS}
    margin: 0;
    min-height: 16px;
    padding: ${spacing8} 0 ${spacing8} 0;
    &:first-child {
      padding: 0 0 ${spacing8} 0;
    }
  `}
`

const StyledPillWrapper = styled(Blink)`
  ${({
    theme: {
      color: {
        secondary: { darkRed },
      },
    },
  }) => css`
    position: relative;
    top: -3px;
    cursor: pointer;

    &:hover {
      span {
        background-color: ${darkRed};
      }
    }
  `}
`

const AnswerParagraph: FunctionComponent<AnswerParagraphProps> = ({
  linkItem,
  contentItem,
  answer,
  popoverParentRef,
  skipAnimation,
  setActiveIndex,
  afterTypingEffectEndCb,
}) => {
  const handleTracking = useTracking(trackHandler)
  const viewportType = useViewportType()
  const isMobile = viewportType === 'mobile'

  const trackClick = (url: string, message: ChatbotMessage) => {
    handleTracking({
      event: 'chatbot_article_click',
      messageId: message.traceId,
      conversationId: message.conversationId,
      url,
    })
  }

  const onPillClick = (url: string, message: ChatbotMessage) => {
    if (isMobile) {
      const activeIndex = parseInt(contentItem.link ?? '')

      if (isNaN(activeIndex) || activeIndex === null) {
        setActiveIndex(null)
        return
      }

      setActiveIndex(activeIndex - 1)
    } else {
      trackClick(url, message)
    }
  }

  return (
    <StyledParagraph key={contentItem.text}>
      <TypingAnimationWrapper
        text={contentItem.text}
        skipAnimation={skipAnimation}
        afterText={
          <>
            {linkItem && (
              <>
                &nbsp;
                <StyledPillWrapper
                  onClick={() => onPillClick(linkItem.url, answer)}
                  href={new URL(linkItem.url).pathname}>
                  <SummaryPill
                    tooltipItem={linkItem}
                    onTooltipClick={() => trackClick(linkItem.url, answer)}
                    popoverParentRef={popoverParentRef}>
                    {contentItem.link}
                  </SummaryPill>
                </StyledPillWrapper>
              </>
            )}
          </>
        }
        afterTypingEffectEndCb={afterTypingEffectEndCb}
      />
    </StyledParagraph>
  )
}

export default AnswerParagraph
