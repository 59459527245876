import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

import { Image } from '@utils/cook/types'

import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'

export interface BackgroundImageProps {
  image: Image<'16_9' | '1_1'>
  className?: string
  children?: ReactNode
}

const RATIOS = {
  desktop: '16_9',
  tablet: '16_9',
  mobile: '1_1',
} as const satisfies ImageRatios

const WIDTHS = {
  desktop: 964,
  tablet: 964,
  mobile: 'viewport-width',
} as const satisfies ImageWidths

const StyledResponsiveImageV2 = styled(ResponsiveImageV2)`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(39, 38, 38, 0.8) 0%,
    rgba(94, 93, 93, 0.8) 101.09%
  );
`

const BackgroundImage: FunctionComponent<BackgroundImageProps> = ({
  className,
  image,
  children,
}) => {
  return (
    <>
      <StyledResponsiveImageV2
        src={image?.src}
        alt={image?.alt}
        crops={image?.crops ?? {}}
        ratios={RATIOS}
        widths={WIDTHS}
        className={className}
      />
      {children}
    </>
  )
}

export default BackgroundImage
