import JSONRenderer from '@components/JSONRenderer'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, useMemo } from 'react'
import styled, { css } from 'styled-components'
import ArticleBodyContentContext from '@contexts/articleBodyContent'
import { bodyStyle } from './bodyStyle'
import PlusContentWrapper from '@components/PlusContentWrapper'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

export type GenericWidget = Record<string, unknown> & {
  kind: string[]
}

interface BodyProps {
  content: GenericWidget[]
}

const StyledContainer = styled.article<{ hasInvertedColors: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary02 },
      },
      colors: { textDefault },
    },
    hasInvertedColors,
  }) => css`
    ${bodyStyle};

    color: ${hasInvertedColors ? primary02 : textDefault};
  `}
`

const Body: FunctionComponent<BodyProps> = ({ content }) => {
  const hasInvertedColors = useHasInvertedColors()

  const firstPlusItemIndex = useMemo(
    () => content.findIndex((item) => item?.isPlusContent === true),
    [content]
  )

  const isWithPlusWidgets = useMemo(
    () => firstPlusItemIndex !== -1,
    [firstPlusItemIndex]
  )

  const showablePart = isWithPlusWidgets
    ? content.slice(0, firstPlusItemIndex)
    : content

  const hideablePart = isWithPlusWidgets
    ? content.slice(firstPlusItemIndex)
    : null

  return (
    <ArticleBodyContentContext.Provider value={true}>
      <StyledContainer hasInvertedColors={hasInvertedColors}>
        <JSONRenderer>{showablePart}</JSONRenderer>
        <PlusContentWrapper>{hideablePart}</PlusContentWrapper>
      </StyledContainer>
    </ArticleBodyContentContext.Provider>
  )
}

export { StyledContainer }

const widget = {
  kind: ['widget', 'body'],
  component: Body,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
