import SvgIcon from '@components/SvgIcon'
import { SvgIconName } from '@components/SvgIcon/types'
import { desktopCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const StyledSvgIcon = styled(SvgIcon)`
  ${desktopCSS(css`
    max-width: 80px;
  `)}
`

const MessageBoxWrapper = styled.div`
  ${({
    theme: {
      colors: { textDefault, fillWeak },
      spacing: { spacing12, spacing16, spacing8 },
    },
  }) => css`
    color: ${textDefault};
    background-color: ${fillWeak};
    border-radius: ${spacing8};
    box-sizing: border-box;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: ${spacing8};

    ${desktopCSS(css`
      padding: ${spacing12} ${spacing16};
    `)}
  `}
`

const MessageBoxContent = styled.div`
  ${({
    theme: {
      spacing: { spacing8, spacing24 },
    },
  }) => css`
    display: flex;
    gap: ${spacing8};

    ${desktopCSS(css`
      gap: ${spacing24};
    `)}
  `}
`

const MessageBoxTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const MessageBoxTitle = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: headingSmall },
          xsmall: { bundledCSS: headingXSmall },
        },
      },
      spacing: { spacing8 },
    },
  }) => css`
    ${headingXSmall};
    margin-bottom: ${spacing8};

    ${desktopCSS(css`
      ${headingSmall};
    `)}
  `}
`

const MessageBoxText = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: bodycopySmall2 },
          xsmall: { bundledCSS: bodycopyXSmall },
        },
      },
    },
  }) => css`
    ${bodycopyXSmall};

    ${desktopCSS(css`
      ${bodycopySmall2};
    `)}
  `}
`

interface MessageBoxProps {
  iconName: SvgIconName
  title: string
  text: string
}

const MessageBox: FunctionComponent<MessageBoxProps> = ({
  iconName,
  title,
  text,
}) => {
  return (
    <MessageBoxWrapper>
      <MessageBoxContent>
        <StyledSvgIcon iconName={iconName} size={40} />
        <MessageBoxTextWrapper>
          <MessageBoxTitle>{title}</MessageBoxTitle>
          <MessageBoxText>{text}</MessageBoxText>
        </MessageBoxTextWrapper>
      </MessageBoxContent>
    </MessageBoxWrapper>
  )
}

export default MessageBox
