import SchemaHTMLElement from '@components/SchemaHTMLElement'
import { FunctionComponent } from 'react'
import config from '@config'
import OrganizationSchema from '@components/Schema/OrganizationSchema'
import { EducationSchema } from '@components/Schema/types'

const {
  schema: { organizationSchemaUrl, educationalOccupationalCredentialSchemaUrl },
} = config

const EducationalOccupationalCredentialSchema: FunctionComponent<
  EducationSchema
> = (props) => {
  const {
    name,
    credentialCategory,
    description,
    organizationName,
    organizationAlternateName,
    organizationUrl,
    organizationWikipedia,
    organizationKnowledgeGraphId,
  } = props

  return (
    <>
      {!!organizationName && (
        <OrganizationSchema
          id={organizationName}
          name={organizationName}
          alternateName={organizationAlternateName}
          url={organizationUrl}
          sameAs={[
            ...(organizationKnowledgeGraphId
              ? [
                  `https://www.google.com/search?kgmid=/g/${organizationKnowledgeGraphId}`,
                ]
              : []),
            ...(Array.isArray(organizationWikipedia)
              ? organizationWikipedia
              : organizationWikipedia
                ? [organizationWikipedia]
                : []),
          ]}
        />
      )}
      <SchemaHTMLElement
        scriptKey={`educationalOccupationalCredential-${name}`}
        schemaObject={{
          '@type': 'EducationalOccupationalCredential',
          '@id': `${educationalOccupationalCredentialSchemaUrl}${name}`,
          ...(name ? { name } : {}),
          ...(description ? { description } : {}),
          ...(credentialCategory ? { credentialCategory } : {}),
          ...(organizationName
            ? { recognizedBy: `${organizationSchemaUrl}${organizationName}` }
            : {}),
          '@context': 'https://schema.org',
        }}
      />
    </>
  )
}

export default EducationalOccupationalCredentialSchema
