import useGrowthBookFeatures from './useGrowthBookFeatures'

type UseGrowthBookFeatureValue = (featureKey: string) => unknown

const useGrowthBookFeatureValue: UseGrowthBookFeatureValue = (featureKey) => {
  const growthBookFeatures = useGrowthBookFeatures()

  return growthBookFeatures?.[featureKey]
}

export default useGrowthBookFeatureValue
