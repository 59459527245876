import { FunctionComponent, useCallback } from 'react'
import { TeaserStandardHorizontalAPIProps } from '@widgets/TeaserStandardHorizontal'
import TeaserMiddleToiWrapper from '@components/TeaserMiddleToi/Wrapper'
import TextWrapper from '@components/TeaserMiddleToi/TextWrapper'
import Label from '@components/TeaserMiddleToi/Label'
import ImageComponent from '@components/TeaserMiddleToi/Image'
import Catchword from '@components/TeaserMiddleToi/Catchword'
import BlickPlusLogo from '@components/TeaserMiddleToi/BlickPlusLogo'
import Title from '@components/TeaserMiddleToi/Title'
import TeaserAnimatedPreview from '@components/TeaserAnimatedPreview'
import VideoLabel from '@components/VideoLabel'
import SportLabelContent from '@components/SportsFooter/SportLabelContent'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useRefDelegate from '@hooks/useRefDelegate'
import useAnimatedPreview from '@hooks/useAnimatedPreview'
import useViewportTracking from '@hooks/useViewportTracking'
import useSportsEvent from '@hooks/useSportsEvent'
import { useQueryClient } from '@tanstack/react-query'
import { stripHtml } from '@hooks/useTracking/utils'
import { triggerAureusImpression } from '@utils/aureus'
import styled, { css, useTheme } from 'styled-components'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { LiveEventDataSportTypes } from '@utils/formatters/liveEvent'
import { transformDataForSportsComponents } from '@components/SportsFooter/utils'

type TeaserMiddleToiProps = TeaserStandardHorizontalAPIProps

const StyledVideoLabel = styled(VideoLabel)`
  ${({
    theme: {
      spacing: { spacing8, spacing16 },
    },
  }) => css`
    position: absolute;
    bottom: ${spacing16};
    left: ${spacing8};
  `}
`

const TeaserMiddleToi: FunctionComponent<TeaserMiddleToiProps> = (props) => {
  const {
    accentColor,
    title,
    link,
    image,
    isPlus,
    catchword,
    targetContentType,
    typeOfSport,
    articleId,
    contentOrigin,
    track,
    video,
    aureusOfferId,
    commercialType,
  } = props
  const queryClient = useQueryClient()
  const isValidSport =
    !!typeOfSport &&
    (
      [
        LIVE_EVENT_DATA_SPORT_TYPES.SOCCER,
        LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY,
      ] as LiveEventDataSportTypes[]
    ).includes(typeOfSport)
  const showCatchword = !!isPlus || !!catchword
  const isCommercial = !!commercialType
  const hasLabelText = !isCommercial && !!props.labelText

  const { data: sportsEventData } = useSportsEvent({
    matchId: isValidSport ? props.matchId : '',
    typeOfSport: isValidSport ? typeOfSport : 'NO_SPORT',
    shouldRefetch: true,
  })

  const sportsData = sportsEventData
    ? transformDataForSportsComponents(sportsEventData)
    : undefined
  const shouldShowSportsComponents =
    sportsData?.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.SOCCER ||
    sportsData?.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY

  const {
    color: {
      secondary: { greenAccessible },
    },
  } = useTheme()

  const labelColor = sportsData?.isLive
    ? undefined
    : isValidSport
      ? greenAccessible
      : accentColor
  const onImpression = useCallback<TrackingFnType>(() => {
    return {
      event: 'teaser_impression',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'impression',
      id: articleId,
      is_branded: !!contentOrigin,
      link_url: link.href,
      teaser_type: 'variantB',
    }
  }, [video, articleId, title, contentOrigin, link.href])

  const onClick = useCallback<TrackingFnType>(() => {
    return {
      event: 'teaser_click',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'click',
      id: articleId,
      is_branded: !!contentOrigin,
      link_url: link.href,
      teaser_type: 'variantB',
    }
  }, [articleId, contentOrigin, title, video, link.href])

  const trackedOnImpression = useTracking(onImpression)
  const trackedOnClick = useTracking(onClick)

  const finalOnImpression = useCallback(() => {
    triggerAureusImpression({
      aureusOfferId,
      queryClient,
    })

    if (track) {
      trackedOnImpression()
    }
  }, [aureusOfferId, queryClient, trackedOnImpression, track])

  const clickableProps = {
    aureusOfferId,
    ...(track
      ? {
          onClick: trackedOnClick,
        }
      : {}),
    ...link,
  }

  const ref = useRefDelegate(
    useAnimatedPreview({
      video,
      articleUrl: clickableProps.href,
    }),
    useViewportTracking({
      onImpression: finalOnImpression,
      track: true,
    })
  )
  return (
    <TeaserMiddleToiWrapper {...clickableProps} ref={ref}>
      <ImageComponent {...image}>
        {!!video && (
          <TeaserAnimatedPreview video={video} articleUrl={link.href} />
        )}
        {!!props.video && (
          <StyledVideoLabel
            duration={props.video.duration}
            targetContentType={targetContentType}
          />
        )}
        <TextWrapper>
          {(hasLabelText || isValidSport) && (
            <Label labelColor={labelColor}>
              {shouldShowSportsComponents ? (
                <SportLabelContent sportsData={sportsData} />
              ) : hasLabelText ? (
                props.labelText
              ) : null}
            </Label>
          )}
          {showCatchword && (
            <Catchword hasPlusLogo={!!isPlus}>
              {isPlus && <BlickPlusLogo />}
              {catchword}
            </Catchword>
          )}
          <Title>{title}</Title>
        </TextWrapper>
      </ImageComponent>
    </TeaserMiddleToiWrapper>
  )
}

export default TeaserMiddleToi
