import SummaryHeaderWrapper from '@components/Summary/SummaryHeaderWrapper'
import { FunctionComponent, ReactNode } from 'react'

const QuestionSummaryRequestView: FunctionComponent<{
  title: string
  children: ReactNode
  showBorder?: boolean
}> = ({ title, children, showBorder = false }) => {
  return (
    <>
      <SummaryHeaderWrapper
        enabled={false}
        title={title}
        showBorder={showBorder}
        showBetaTag={true}
        betaTagPullRight={true}
      />
      {children}
    </>
  )
}

export default QuestionSummaryRequestView
