import { createContext } from 'react'

import {
  EnrichedLiveEventTransformedFormula1,
  EnrichedLiveEventTransformedHockey,
  EnrichedLiveEventTransformedSki,
  EnrichedLiveEventTransformedSoccer,
  EnrichedLiveEventTransformedTennis,
} from '@utils/sport'

const ScoreboardContext = createContext<
  | EnrichedLiveEventTransformedSoccer
  | EnrichedLiveEventTransformedHockey
  | EnrichedLiveEventTransformedSki
  | EnrichedLiveEventTransformedFormula1
  | EnrichedLiveEventTransformedTennis
  | undefined
>(undefined)

export default ScoreboardContext
