import FooterItemComponents from '@components/Footer/FooterItem'
import {
  FillerItem,
  FooterProps,
  StyledFooterColumnsProps,
  StyledFooterContentWrapperProps,
} from '@components/Footer/types'
import { FooterSkeletonLinkItem } from '@hooks/useFooter'
import {
  desktopCSS,
  mobileCSS,
  mobileAndTabletCSS,
  tabletCSS,
} from '@measures/responsive'
import { FunctionComponent, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import FooterExpandBar from './FooterExpandBar'
import StoreIcons from './StoreIcons'

const { Default: Item, Filler, Logo } = FooterItemComponents

const StyledFooter = styled.div`
  ${({
    theme: {
      colors: { fillDefault },
    },
  }) => css`
    position: relative;
    z-index: 10;
    background-color: ${fillDefault};
  `}
`

const StyledFooterContentWrapper = styled.div<StyledFooterContentWrapperProps>`
  ${({
    isExpanded,
    logoType,
    theme: {
      colors: { strokeBrand },
      spacing: { spacing24, spacing40 },
    },
  }) =>
    !isExpanded
      ? css`
          display: none;
        `
      : css`
          display: grid;
          box-sizing: border-box;
          width: 100%;
          ${logoType === 'blick' && `border-top: 4px solid ${strokeBrand};`}
          grid-template-columns: auto 1fr;
          grid-template-rows: auto;
          grid-column-gap: 60px;
          align-items: flex-start;
          padding-top: ${spacing24};

          ${desktopCSS(css`
            &:nth-child(2) {
              padding-top: ${spacing40};
            }
          `)}

          ${mobileAndTabletCSS(css`
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-row-gap: 20px;
          `)};
        `}
`

const StyledFooterTitle = styled.div`
  ${({
    theme: {
      colors: { textDefault },
      typography: {
        subheads: {
          small1: { bundledCSS: subheadsSmall1 },
        },
      },
    },
  }) => css`
    ${subheadsSmall1};
    color: ${textDefault};
  `}
`

const StyledTextWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing32, spacing28 },
    },
  }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    ${desktopCSS(css`
      grid-gap: ${spacing28};
    `)}
    ${mobileAndTabletCSS(css`
      grid-gap: ${spacing32};
    `)};
  `}
`

const StyledFooterColumns = styled.div<StyledFooterColumnsProps>`
  ${({
    showOnMobile,
    dataColumns,
    dataRows,
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(${dataColumns}, 1fr);
    grid-template-rows: repeat(${dataRows}, auto);
    grid-column-gap: ${spacing24};

    ${mobileCSS(
      showOnMobile
        ? css``
        : css`
            display: none;
          `
    )}
    ${tabletCSS(
      !showOnMobile
        ? css``
        : css`
            display: none;
          `
    )};
    ${desktopCSS(
      !showOnMobile
        ? css``
        : css`
            display: none;
          `
    )}
  `}
`

const createItem = (
  item: FooterSkeletonLinkItem | FillerItem,
  index: number,
  array: (FooterSkeletonLinkItem | FillerItem)[]
) => {
  const key = `column-${index / array.length + 1}-row-${
    (index % array.length) + 1
  }`
  return item === 'filler' ? <Filler key={key} /> : <Item {...item} key={key} />
}

const Footer: FunctionComponent<FooterProps> = ({
  isExpandable,
  initialExpandedState,
  title,
  logoType,
  blinkProps,
  dataForMobile,
  columnsForMobile,
  rowsForMobile,
  dataForNonMobile,
  columnsForNonMobile,
  rowsForNonMobile,
}) => {
  const [isExpanded, setIsExpanded] = useState(
    !isExpandable ? true : initialExpandedState === 'expanded'
  )

  const toggleExpand = useCallback(() => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded)
  }, [])

  return (
    <StyledFooter>
      {!!isExpandable && (
        <FooterExpandBar isExpanded={isExpanded} toggleExpand={toggleExpand} />
      )}
      <StyledFooterContentWrapper isExpanded={isExpanded} logoType={logoType}>
        <Logo logoType={logoType} blinkProps={blinkProps} />
        <StyledTextWrapper>
          {!!title && <StyledFooterTitle>{title}</StyledFooterTitle>}
          <StoreIcons logoType={logoType} />
          <StyledFooterColumns
            showOnMobile={true}
            dataRows={rowsForMobile}
            dataColumns={columnsForMobile}>
            {dataForMobile.map(createItem)}
          </StyledFooterColumns>
          <StyledFooterColumns
            showOnMobile={false}
            dataRows={rowsForNonMobile}
            dataColumns={columnsForNonMobile}>
            {dataForNonMobile.map(createItem)}
          </StyledFooterColumns>
        </StyledTextWrapper>
      </StyledFooterContentWrapper>
    </StyledFooter>
  )
}

export default Footer
