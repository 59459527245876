import { ConfigFn } from '@config/types'

const config = (() => {
  const prolitterisBaseUrl = 'https://pl02.owen.prolitteris.ch'
  return {
    normalArticlesUrlPrefix: `${prolitterisBaseUrl}/na/vzm.13447-`,
    blickPlusArticlesUrlPrefix: `${prolitterisBaseUrl}/pw/vzm.13447-`,
  } as const
}) satisfies ConfigFn

export default config
