import { FunctionComponent, useCallback, useMemo } from 'react'
import styled, { css, useTheme } from 'styled-components'
import translate from '@i18n'
import useScoreboard from '@hooks/useScoreboard'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import SvgIcon from '@components/SvgIcon'
import useIsInCollapsibleScoreboard from '@hooks/useIsInCollapsibleScoreboard'

const StyledLiveDot = styled.div`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
      spacing: { spacing4 },
    },
  }) => css`
    background-color: ${primary02Color};
    border-radius: 50%;
    display: inline-block;
    height: 0.6em;
    line-height: 0;
    margin-right: ${spacing4};
    margin-bottom: 1px;
    opacity: 1;
    width: 0.6em;
    animation: liveFadeInOut 1.5s ease-in-out 0s infinite alternate-reverse;
    @keyframes liveFadeInOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  `}
`

const StyledIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing4 },
      typography: {
        catchword: {
          small2: { fontSize, lineHeight },
        },
      },
    },
  }) => css`
    vertical-align: top;
    margin-right: ${spacing4};
    height: calc(${fontSize} * ${lineHeight});
    width: calc(${fontSize} * ${lineHeight});
  `}
`

const StyledMatchStatus = styled.div<{
  isFinished: boolean
  isLive: boolean
  isScheduled: boolean
  isInCollapsibleScoreboard?: boolean
}>`
  ${({
    theme: {
      color: {
        secondary: { greenAccessible: greenAccessibleColor },
        primary: { primary02: primary02Color, blickRed: blickRedColor },
      },
      spacing: { spacing4, spacing8 },
      typography: {
        catchword: {
          small2: { bundledCSS: small2CatchwordCSS },
        },
      },
      utils: { ellipsis },
    },
    isFinished,
    isLive,
    isScheduled,
    isInCollapsibleScoreboard,
  }) => css`
    ${small2CatchwordCSS};
    align-items: center;
    align-self: center;
    background-color: ${isLive ? blickRedColor : greenAccessibleColor};
    display: ${isLive || isScheduled || isFinished ? 'flex' : 'none'};
    color: ${primary02Color};
    padding: ${spacing4};
    text-align: center;
    text-transform: uppercase;
    margin-bottom: ${isInCollapsibleScoreboard ? '0' : spacing8};
    ${isInCollapsibleScoreboard && 'margin: 0 8px'};
    ${ellipsis('95%')};
  `}
`

const StyledMatchInfo = styled.div`
  ${({
    theme: {
      spacing: { spacing4, spacing8 },
      color: {
        primary: { primary02: primary02Color },
      },
    },
  }) => css`
    border-bottom-right-radius: 2px;
    display: inline !important;
    margin-left: ${spacing8};
    margin-right: -${spacing4};
    padding: 0 ${spacing4} 0 ${spacing8};
    border-left: 1px solid ${primary02Color};
  `}
`

const ScoreboardMatchStatus: FunctionComponent = () => {
  const { formattedData, sportsEventType } = useScoreboard()
  const isInCollapsibleScoreboard = useIsInCollapsibleScoreboard()
  const {
    color: {
      primary: { primary02: primary02Color },
    },
  } = useTheme()
  const { info, isFinished, isLive, isScheduled, title } = useMemo(() => {
    if (sportsEventType === LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1) {
      const {
        willStart,
        isCancelled,
        isLive,
        raceTypeName,
        statusInfo,
        statusTitle,
      } = formattedData

      const isFinished = !willStart && !isLive

      return {
        info: isLive ? statusInfo : (raceTypeName ?? ''),
        isFinished: !willStart && !isLive,
        isLive,
        isScheduled: willStart,
        title: isFinished
          ? translate('liveEvent.finished')
          : isCancelled
            ? translate('liveEvent.cancelled')
            : statusTitle,
      }
    } else if (sportsEventType === LIVE_EVENT_DATA_SPORT_TYPES.SKI) {
      const {
        isBetweenRuns,
        isCancelled,
        isFinished,
        isLive,
        isPending,
        raceTypeName,
        statusInfo,
        statusTitle,
      } = formattedData

      return {
        info:
          (isLive || isBetweenRuns) && !!statusInfo
            ? statusInfo
            : !isLive && !isFinished
              ? raceTypeName
              : '',
        isFinished,
        isLive,
        isScheduled: isPending,
        title:
          isFinished || isBetweenRuns
            ? translate('liveEvent.finished')
            : isCancelled
              ? translate('liveEvent.cancelled')
              : statusTitle,
      }
    } else if (sportsEventType === LIVE_EVENT_DATA_SPORT_TYPES.TENNIS) {
      const { isLive, statusTitle, isScheduled, isCancelled, isFinished } =
        formattedData

      const isStarted = !isScheduled

      return {
        info: '',
        isFinished,
        isLive,
        isScheduled,
        title:
          !isStarted || isCancelled
            ? statusTitle
            : isFinished
              ? translate('liveEvent.finished')
              : statusTitle,
      }
    } else {
      const { isLive, statusTitle, isScheduled, isFinished } = formattedData

      return {
        info: '',
        isFinished,
        isLive,
        isScheduled,
        title: isFinished ? translate('liveEvent.finished') : statusTitle,
      }
    }
  }, [formattedData, sportsEventType])

  const getCollapsibleScoreboardTitle = useCallback((title: string): string => {
    return title.replace(/\bAb\b|\bUhr\b/g, '').trim()
  }, [])

  const shouldShowIcon = isScheduled && !isInCollapsibleScoreboard

  const collapsibleScoreboardTitle = getCollapsibleScoreboardTitle(title)

  return (
    <StyledMatchStatus
      isFinished={isFinished}
      isLive={isLive}
      isScheduled={isScheduled}
      isInCollapsibleScoreboard={isInCollapsibleScoreboard}>
      {isLive && <StyledLiveDot />}
      {shouldShowIcon && (
        <StyledIcon iconName="clock-three" size={12} color={primary02Color} />
      )}
      {isInCollapsibleScoreboard ? collapsibleScoreboardTitle : title}
      {info && <StyledMatchInfo>{info}</StyledMatchInfo>}
    </StyledMatchStatus>
  )
}

export default ScoreboardMatchStatus
