import { FunctionComponent } from 'react'
import { createPortal } from 'react-dom'
import { BlickBitesAd, BlickBitesItem } from '@widgets/Video/BlickBites'
import BlickBitesFastlane from './BlickBitesFastlane'

export interface FullScreenVideoPortalProps {
  onClose: () => void
  data: BlickBitesItem[]
  activeIndex: number
  biteId?: string
  adData: BlickBitesAd
}

const FullScreenVideoPortal: FunctionComponent<FullScreenVideoPortalProps> = ({
  data,
  activeIndex,
  biteId,
  onClose,
  adData,
}) => {
  return createPortal(
    <BlickBitesFastlane
      data={data}
      activeIndex={activeIndex}
      biteId={biteId}
      onClose={onClose}
      adData={adData}
    />,
    document.body
  )
}

export default FullScreenVideoPortal
