import { FunctionComponent, useCallback, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { WidgetKind } from '@utils/registry'
import { TeaserStandardHorizontalAPIProps } from '@widgets/TeaserStandardHorizontal'
import { TeaserToiVideoAPIProps } from '@widgets/TeaserToiVideo'
import { Link } from '@utils/cook/types'

import { desktopCSS } from '@measures/responsive'
import InvertedColorsContext from '@contexts/invertedColors'

import SectionHeader from '@components/SectionHeader'
import JSONRenderer from '@components/JSONRenderer'
import Swiper, { SwiperClass, ArrowsVisibilityType } from '@components/Swiper'
import NavigationArrows from '@components/Swiper/NavigationArrows'

type FeaturedVideosMainItem = TeaserToiVideoAPIProps & {
  kind: WidgetKind
}

type FeaturedVideosItem = TeaserStandardHorizontalAPIProps & {
  kind: WidgetKind
}

export interface FeaturedVideosWidgetAPIProps {
  title: string
  accentColor: string
  mainItem: FeaturedVideosMainItem
  items: FeaturedVideosItem[]
  itemsTitle: string
  link?: Link
  ctaText?: string
}

const FeaturedVideosWrapper = styled.div`
  ${({
    theme: {
      colors: { staticWhite, staticBlack },
      spacing: { spacing16, spacing32, spacing56 },
      measures: { stretchBackground },
    },
  }) => css`
    background-color: ${staticBlack};

    ${stretchBackground()};

    padding-top: ${spacing32};
    padding-bottom: ${spacing32};

    display: grid;
    align-items: flex-start;
    grid-template-columns: minmax(0, 1fr);
    grid-column-gap: ${spacing16};
    grid-row-gap: ${spacing16};

    ${desktopCSS(css`
      padding: ${spacing56};

      grid-template-columns: minmax(0, 1fr) 300px;
      grid-column-gap: ${spacing32};
      grid-row-gap: ${spacing32};
    `)}
    > :nth-child(2) {
      border: 1px solid ${staticWhite};
      box-sizing: border-box;
    }
  `}
`

const StyledSectionHeader = styled(SectionHeader)`
  ${desktopCSS(css`
    grid-column: 1 / -1;
  `)}
`

const ItemsListWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    display: grid;
    grid-row-gap: ${spacing16};

    ${desktopCSS(css`
      grid-row-gap: ${spacing24};
    `)}
  `}
`

const StyledNavigationArrows = styled(NavigationArrows)<{ itemsCount: number }>`
  ${({ itemsCount }) => css`
    ${itemsCount <= 2 &&
    css`
      display: none;
    `}
  `}
`

const StyledSwiper = styled(Swiper)`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    > .swiper-items-container {
      grid-auto-flow: column;
      grid-template-rows: repeat(2, 1fr);
      grid-auto-columns: 100%;
      grid-row-gap: ${spacing16};
    }
  `}
`

const SwiperSlide = styled.div`
  width: 100%;
`

const FeaturedVideos: FunctionComponent<FeaturedVideosWidgetAPIProps> = ({
  title,
  accentColor,
  link,
  items,
  mainItem,
  itemsTitle,
  ctaText,
}) => {
  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>()

  const onInit = useCallback(
    (swiper: SwiperClass) => {
      swiperRef.current = swiper
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  if (!title) {
    return null
  }

  return (
    <InvertedColorsContext.Provider value={true}>
      <FeaturedVideosWrapper>
        <StyledSectionHeader
          title={title}
          link={link}
          accentColor={accentColor}
          ctaText={ctaText}
        />
        <JSONRenderer>{mainItem}</JSONRenderer>
        <ItemsListWrapper>
          <SectionHeader
            title={itemsTitle}
            accentColor={accentColor}
            isSubheader={true}>
            <StyledNavigationArrows
              swiperRef={swiperRef}
              arrowsVisibility={arrowsVisibility}
              itemsCount={items?.length ?? 0}
            />
          </SectionHeader>
          <StyledSwiper
            initialSlide={0}
            slidesPerGroup={2}
            spaceBetween={16}
            spaceTopBottom={0}
            fullWidthSlides={true}
            onInit={onInit}
            onArrowsVisibility={onArrowsVisibility}
            slides={items?.map((item) => (
              <SwiperSlide key={item.articleId}>
                <JSONRenderer>{item}</JSONRenderer>
              </SwiperSlide>
            ))}
          />
        </ItemsListWrapper>
      </FeaturedVideosWrapper>
    </InvertedColorsContext.Provider>
  )
}

const widget = {
  kind: ['featured-videos'],
  component: FeaturedVideos,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
