import config from '@config'

const {
  publication: { publication },
} = config

const formatNumberByPublication = (num: number): string =>
  num.toLocaleString(`${publication === 'romandie' ? 'fr' : 'de'}-CH`)

export { formatNumberByPublication }
