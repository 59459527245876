import { ConfigFn } from '@config/types'

const config = (() => {
  return {
    clientScript: 'https://ocdn.eu/aureus-js-client/7.0.1/aureusjsclient.js',
    endpoint:
      'https://recommendations-ringier-ch.aureus.ringpublishing.com/v1/recommend',
    fallbackEndpoint:
      'https://fallback-ringier-ch.aureus.ringpublishing.com/v1/recommend',
    clientUUID: '5af519bf-788a-4c94-b65b-54e7cd74efd7',
    apiKey: 'ced893d5-8441-4696-9450-276ec0431fd7',
  } as const
}) satisfies ConfigFn

export default config
