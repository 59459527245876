import SvgIcon from '@components/SvgIcon'
import translate from '@i18n'
import { FunctionComponent } from 'react'
import styled, { css, useTheme } from 'styled-components'

interface FooterExpandBarProps {
  isExpanded: boolean
  toggleExpand: () => void
}

const StyledFooterExpandBar = styled.button`
  ${({
    theme: {
      colors: { strokeDefault },
      spacing: { spacing8 },
    },
  }) => css`
    width: 100%;
    display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid ${strokeDefault};
    border-radius: ${spacing8};
    background-color: transparent;
    padding: 0;

    cursor: pointer;
  `}
`

const StyledFooterExpandBarContentWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    min-height: 56px;
    padding: 0 ${spacing24};
  `}
`

const StyledFooterExpandBarText = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xxsmall: { bundledCSS: headingXXSmall },
        },
      },
      colors: { textDefault },
    },
  }) => css`
    display: inline-block;
    ${headingXXSmall};
    color: ${textDefault};
  `}
`

const FooterExpandBar: FunctionComponent<FooterExpandBarProps> = ({
  isExpanded,
  toggleExpand,
}) => {
  const theme = useTheme()
  const iconName = isExpanded ? 'chevron-up' : 'chevron-down'
  const iconColor = theme.colors.iconDefault

  return (
    <StyledFooterExpandBar onClick={toggleExpand}>
      <StyledFooterExpandBarContentWrapper>
        <StyledFooterExpandBarText>
          {translate('footer.ringierFooterTitle')}
        </StyledFooterExpandBarText>
        <SvgIcon iconName={iconName} size={26} color={iconColor} />
      </StyledFooterExpandBarContentWrapper>
    </StyledFooterExpandBar>
  )
}

export default FooterExpandBar
