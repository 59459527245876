import { FunctionComponent, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import { Image, Link, TargetContentType, TeaserVideo } from '@utils/cook/types'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import VideoLabel from '@components/VideoLabel'
import ImageComponent from '@components/TeaserToiCommercial/Image'
import Label from '@components/TeaserToiCommercial/Label'
import Catchword from '@components/TeaserToiCommercial/Catchword'
import Title from '@components/TeaserToiCommercial/Title'
import BlickPlusLogo from '@components/TeaserToiCommercial/BlickPlusLogo'
import Subtitle from '@components/TeaserToiCommercial/Subtitle'
import BulletPoints from '@components/TeaserToiCommercial/BulletPoints'
import VideoBackground from '@components/TeaserToiCommercial/VideoBackground'
import PromoLabel from '@components/TeaserToiCommercial/PromoLabel'
import SponsoredLabel from '@components/TeaserToiCommercial/SponsoredLabel'
import SponsoredLogo from '@components/TeaserToiCommercial/SponsoredLogo'
import TeaserBackgroundVideo from '@components/TeaserBackgroundVideo'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import { triggerAureusImpression } from '@utils/aureus'
import useViewportTracking from '@hooks/useViewportTracking'
import { useQueryClient } from '@tanstack/react-query'
import TeaserToiCommercialWrapper from '@components/TeaserToiCommercial/Wrapper'
import useDeviceType from '@hooks/useDeviceType'
import TeaserAnimatedPreview from '@components/TeaserAnimatedPreview'
import useRefDelegate from '@hooks/useRefDelegate'
import useAnimatedPreview from '@hooks/useAnimatedPreview'

interface StyledImageComponentProps {
  hasBorder: boolean
  isCompact: boolean
}

interface EditorialProps {
  isEditorial: boolean
}

export interface TeaserToiCommercialNoBulletPointProps {
  bulletpoints?: undefined
  subtitle?: string
}

export interface TeaserToiCommercialBulletPointProps {
  bulletpoints: string[]
}

export type TeaserToiCommercialMaybeBulletPointProps =
  | TeaserToiCommercialNoBulletPointProps
  | TeaserToiCommercialBulletPointProps

export interface TeaserToiCommercialLayoutTemplateBigProps {
  layoutTemplate: 'big'
  image: Image<'1_1' | '3_4'>
}

export interface TeaserToiCommercialLayoutTemplateCompactProps {
  layoutTemplate: 'compact'
  image: Image<'1_1' | '3_2'>
}

export type TeaserToiCommercialLayoutTemplateProps =
  | TeaserToiCommercialLayoutTemplateBigProps
  | TeaserToiCommercialLayoutTemplateCompactProps

export interface TeaserToiCommercialPromoCommercialProps {
  commercialType: 'promo'
  commercial: {
    label: string
  }
}

export interface TeaserToiCommercialSponsoredCommercialProps {
  commercialType: 'sponsored' | 'editorial'
  commercial: {
    label: string
    logo?: Image<'free'>
  }
}

export type TeaserToiCommercialCommercialProps =
  | TeaserToiCommercialPromoCommercialProps
  | TeaserToiCommercialSponsoredCommercialProps

export interface TeaserToiCommercialCommonProps {
  accentColor: string
  title: string
  catchword?: string
  labelText?: string
  video?: TeaserVideo
  link: Link
  section: {
    name: string
    uniqueName: string
  }
  contentOrigin?: string
  track?: boolean
  isPlus?: boolean
  articleId?: string
  targetContentType: TargetContentType
  teaserVideo?: TeaserVideo
  aureusOfferId?: string
}

interface StyledSponsoredLabelProps {
  isEditorial: boolean
}

export type TeaserToiCommercialAPIProps = TeaserToiCommercialCommonProps &
  TeaserToiCommercialMaybeBulletPointProps &
  TeaserToiCommercialLayoutTemplateProps &
  TeaserToiCommercialCommercialProps

const TextStack = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: flex-start;
  justify-items: flex-start;
  position: relative;
  z-index: 1;
`

const GradientStack = styled.div<EditorialProps>`
  ${({
    theme: {
      colors: { staticBlack },
      spacing: { spacing24, spacing32, spacing16 },
    },
    isEditorial,
  }) => {
    const paddingDesktop = isEditorial ? spacing24 : spacing32
    const paddingMobile = isEditorial ? spacing16 : spacing24
    return css`
      position: relative;
      box-sizing: border-box;
      width: 100%;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      align-items: flex-start;
      justify-items: flex-start;
      padding: 0 ${paddingMobile} ${spacing24};

      ${desktopCSS(css`
        padding: 0 ${paddingDesktop} ${paddingDesktop};
      `)}
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: -80px;
        left: 0;
        width: 100%;
        height: calc(100% + 80px);
        background: linear-gradient(
          2deg,
          ${staticBlack}99 60%,
          ${staticBlack}00 90%
        );
      }
    `
  }}
`

const GridWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  align-items: flex-end;
`

const StyledImageComponent = styled(ImageComponent)<StyledImageComponentProps>`
  ${({
    theme: {
      spacing: { spacing12, spacing16, spacing56 },
      colors: { staticBlack, staticWhite },
    },
    hasBorder,
    isCompact,
  }) => css`
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 60px;

      ${isCompact &&
      mobileAndTabletCSS(css`
        height: ${spacing56};
      `)};

      background: linear-gradient(
        180deg,
        ${staticBlack}BF 0%,
        ${staticBlack}00 100%
      );
    }

    ${hasBorder &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: ${spacing12};
        right: ${spacing12};
        bottom: ${spacing12};
        left: ${spacing12};
        ${desktopCSS(css`
          top: ${spacing16};
          right: ${spacing16};
          bottom: ${spacing16};
          left: ${spacing16};
        `)}
        border: 1px solid ${staticWhite};
        z-index: 2;
      }
    `};
  `}
`

const StyledVideoBackground = styled(VideoBackground)<{ isVisible: boolean }>`
  ${({ isVisible }) => css`
    opacity: ${isVisible ? 1 : 0};
    transition: opacity 0.3s ease-in-out;
  `}
`

const StyledLabel = styled(Label)<EditorialProps>`
  ${({
    theme: {
      spacing: { spacing8, spacing16, spacing24, spacing32 },
    },
    isEditorial,
  }) => {
    const marginDesktop = isEditorial ? spacing24 : spacing32
    const marginMobile = isEditorial ? spacing16 : spacing24
    return css`
      margin-bottom: ${spacing8};
      margin-left: ${marginMobile};
      max-width: calc(100% - 2 * ${marginMobile});
      ${desktopCSS(css`
        margin-left: ${marginDesktop};
        max-width: calc(100% - 2 * ${marginDesktop});
      `)};
    `
  }}
`

const StyledVideoLabel = styled(VideoLabel)<EditorialProps>`
  ${({
    theme: {
      spacing: { spacing8, spacing16, spacing24, spacing32 },
    },
    isEditorial,
  }) => css`
    margin-bottom: ${spacing8};
    margin-left: ${isEditorial ? spacing16 : spacing24};
    ${desktopCSS(css`
      margin-left: ${isEditorial ? spacing24 : spacing32};
    `)};
  `}
`

const StyledBulletPoints = styled(BulletPoints)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    margin-top: ${spacing8};
  `}
`

const StyledPromoLabel = styled(PromoLabel)`
  ${({
    theme: {
      spacing: { spacing24, spacing32 },
    },
  }) => css`
    position: absolute;
    top: ${spacing24};
    ${desktopCSS(css`
      top: ${spacing32};
    `)};
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  `}
`

const StyledSponsoredLabel = styled(SponsoredLabel)<StyledSponsoredLabelProps>`
  ${({
    theme: {
      spacing: { spacing24, spacing32, spacing16 },
    },
    isEditorial,
  }) => css`
    position: absolute;
    top: ${spacing24};
    right: ${isEditorial ? spacing16 : spacing24};
    ${desktopCSS(css`
      top: ${isEditorial ? spacing24 : spacing32};
      right: ${isEditorial ? spacing24 : spacing32};
    `)};
    z-index: 2;
  `}
`

const TeaserToiCommercial: FunctionComponent<TeaserToiCommercialAPIProps> = (
  props
) => {
  const {
    title,
    link,
    image,
    targetContentType,
    accentColor,
    catchword,
    isPlus,
    video,
    labelText,
    bulletpoints,
    layoutTemplate,
    commercialType,
    commercial,
    teaserVideo,
    articleId,
    contentOrigin,
    track,
    aureusOfferId,
  } = props
  const queryClient = useQueryClient()

  const showCatchword = !!isPlus || !!catchword
  const isCompact = layoutTemplate === 'compact'
  const imageProps =
    layoutTemplate === 'big'
      ? { layoutTemplate, image }
      : { layoutTemplate, image }

  const deviceType = useDeviceType()
  const shouldShowBackgroundVideo = !!teaserVideo && deviceType !== 'ios'

  const [hasBackgroundVideoLoaded, setHasBackgroundVideoLoaded] =
    useState<boolean>(false)

  const onVideoPlay = useCallback(() => {
    setHasBackgroundVideoLoaded(true)
  }, [])

  const onVideoStop = useCallback(() => {
    setHasBackgroundVideoLoaded(false)
  }, [])

  const onImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'teaser_impression',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'impression',
      is_branded: !!contentOrigin,
      link_url: link.href,
    }),
    [video, articleId, title, contentOrigin, link.href]
  )

  const onPromoImpression = useCallback(
    () => ({
      event: 'contentcommerce_impression',
      eventCategory: 'BrandStudio',
      eventAction: 'Impression / Brandstudio',
    }),
    []
  )

  const onClick = useCallback<TrackingFnType>(
    () => ({
      event: 'teaser_click',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'click',
      id: articleId,
      is_branded: !!contentOrigin,
      link_url: link.href,
    }),
    [video, articleId, title, contentOrigin, link.href]
  )

  const trackedOnImpression = useTracking(onImpression)
  const trackedOnPromoImpression = useTracking(onPromoImpression)
  const trackedOnClick = useTracking(onClick)

  const shouldBeTracked = contentOrigin || !!track

  const finalOnImpression = useCallback(() => {
    triggerAureusImpression({
      aureusOfferId,
      queryClient,
    })

    if (shouldBeTracked) {
      trackedOnImpression()
      trackedOnPromoImpression()
    }
  }, [
    aureusOfferId,
    shouldBeTracked,
    trackedOnImpression,
    trackedOnPromoImpression,
    queryClient,
  ])

  const clickableProps = {
    aureusOfferId,
    ...(shouldBeTracked ? { onClick: trackedOnClick } : {}),
    ...link,
  }
  const isEditorial = commercialType === 'editorial'
  const isPromo = commercialType === 'promo'

  const ref = useRefDelegate(
    useAnimatedPreview({
      video,
      articleUrl: clickableProps.href,
    }),
    useViewportTracking({
      onImpression: finalOnImpression,
      track: true,
    })
  )

  return (
    <TeaserToiCommercialWrapper {...clickableProps} ref={ref}>
      <StyledImageComponent
        {...imageProps}
        hasBorder={!isEditorial}
        isCompact={isCompact}>
        {shouldShowBackgroundVideo && (
          <StyledVideoBackground isVisible={hasBackgroundVideoLoaded}>
            <TeaserBackgroundVideo
              videoId={teaserVideo.jwVideoId}
              onPlay={onVideoPlay}
              onStop={onVideoStop}
            />
          </StyledVideoBackground>
        )}
        {!!video && !shouldShowBackgroundVideo && (
          <TeaserAnimatedPreview video={video} articleUrl={link?.href} />
        )}
        <GridWrapper>
          {!isPromo ? (
            <StyledSponsoredLabel isEditorial={isEditorial}>
              {commercial.label}
              {!!commercial.logo && <SponsoredLogo {...commercial.logo} />}
            </StyledSponsoredLabel>
          ) : (
            <StyledPromoLabel>{commercial.label}</StyledPromoLabel>
          )}
          <TextStack>
            {!!video && (
              <StyledVideoLabel
                duration={video.duration}
                targetContentType={targetContentType}
                isEditorial={isEditorial}
              />
            )}
            {!!labelText && (
              <StyledLabel labelColor={accentColor} isEditorial={isEditorial}>
                {labelText}
              </StyledLabel>
            )}
            <GradientStack isEditorial={isEditorial}>
              {showCatchword && (
                <Catchword>
                  {isPlus && <BlickPlusLogo />}
                  {catchword}
                </Catchword>
              )}
              <Title layoutTemplate={layoutTemplate}>{title}</Title>
              {!!bulletpoints && (
                <StyledBulletPoints accentColor={accentColor}>
                  {bulletpoints}
                </StyledBulletPoints>
              )}
              {!bulletpoints && !!props.subtitle && (
                <Subtitle>{props.subtitle}</Subtitle>
              )}
            </GradientStack>
          </TextStack>
        </GridWrapper>
      </StyledImageComponent>
    </TeaserToiCommercialWrapper>
  )
}

const widget = {
  kind: ['teaser-toi-commercial'],
  component: TeaserToiCommercial,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
