import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export interface BadgeProps {
  children: ReactNode
  className?: string
  backgroundColor: string
}

export type StyledBadgeProps = Pick<BadgeProps, 'backgroundColor'>

const StyledBadge = styled.div<StyledBadgeProps>`
  ${({
    theme: {
      spacing: { spacing2 },
      colors: { staticWhite },
      typography: {
        catchword: {
          small2: { bundledCSS: small2CatchwordCSS },
        },
      },
    },
    backgroundColor,
  }) => css`
    position: relative;
    white-space: nowrap;
    display: inline-flex;
    column-gap: ${spacing2};
    ${small2CatchwordCSS};
    color: ${staticWhite};
    background-color: ${backgroundColor};
    padding: 0.2em 0.4em 0.12em;
    text-transform: uppercase;
  `}
`

const Badge: FunctionComponent<BadgeProps> = ({
  children,
  className,
  backgroundColor,
}) => {
  return (
    <StyledBadge className={className} backgroundColor={backgroundColor}>
      {children}
    </StyledBadge>
  )
}

export default Badge
