import { ConfigFn } from '@config/types'

const config = (({ publication, deploymentEnv }) =>
  ({
    onesignalSdkUrl: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
    appId: `${
      deploymentEnv === 'prod'
        ? (`${
            publication === 'romandie'
              ? '83880ab2-4a11-4a59-9bb5-045960c1c59c'
              : '1b9a4377-47f7-4ca7-8389-decf07e188ce'
          }` as const)
        : (`${
            publication === 'romandie'
              ? '6d22a720-6171-4a5f-bb19-824799ae8ec8'
              : 'd3d22d82-38a1-4613-9b70-ae1c94f9477b'
          }` as const)
    }` as const,
    playerIdStorageKey: 'oneSignalPlayerId',
  }) as const) satisfies ConfigFn

export default config
