import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface LabelProps {
  labelColor: string | undefined
  children: ReactNode
}

type StyledLabelProps = Pick<LabelProps, 'labelColor'>

const StyledLabel = styled.div<StyledLabelProps>`
  ${({
    theme: {
      spacing: { spacing8 },
      color: {
        primary: { primary02: primary02Color, blickRed },
      },
      typography: {
        catchword: {
          small2: { bundledCSS: small2CatchwordCSS },
        },
      },
      utils: { ellipsis },
    },
    labelColor,
  }) => css`
    box-sizing: border-box;
    ${ellipsis('100%', 1)};
    display: inline-block;
    ${small2CatchwordCSS};
    color: ${primary02Color};
    background-color: ${labelColor ?? blickRed};
    padding: 0.28em ${spacing8} 0.2em;
    text-transform: uppercase;

    &:empty {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;

      &:before {
        content: '​';
        width: 0;
      }
    }
  `}
`

const Label: FunctionComponent<LabelProps> = ({ labelColor, children }) => {
  return <StyledLabel labelColor={labelColor}>{children}</StyledLabel>
}

export default Label
