import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'

export interface CatchwordProps {
  children?: ReactNode
  hasPlusLogo: boolean
}

export type StyledCatchwordProps = Pick<CatchwordProps, 'hasPlusLogo'>
const StyledCatchword = styled.div<StyledCatchwordProps>`
  ${({
    theme: {
      spacing: { spacing4, spacing8 },
      colors: { textBrand, staticWhite },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      utils: { ellipsis },
    },
    hasPlusLogo,
  }) => css`
    ${small1SubheadingCSS};
    color: ${textBrand};
    background-color: ${staticWhite};
    padding: ${spacing4} ${spacing8};
    ${ellipsis('100%', 1)};
    display: block;
    box-sizing: border-box;
    ${desktopCSS(css`
      ${ellipsis('100%', 2)};
      ${hasPlusLogo &&
      css`
        line-height: 1.3;
      `};
    `)}
  `}
`

const Catchword: FunctionComponent<CatchwordProps> = ({
  children,
  hasPlusLogo,
}) => {
  return <StyledCatchword hasPlusLogo={hasPlusLogo}>{children}</StyledCatchword>
}

export default Catchword
