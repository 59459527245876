import { desktopCSS } from '@measures/responsive'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import useIsInCollapsibleScoreboard from '@hooks/useIsInCollapsibleScoreboard'

export type ScoreboardTeamContentProps = {
  children: ReactNode
  isAwayTeam?: boolean
}

type StyledScoreboardTeamContentProps = {
  isAwayTeam?: boolean
  isInCollapsibleScoreboard?: boolean
}

const StyledScoreboardTeamContent = styled.div<StyledScoreboardTeamContentProps>`
  ${({ isAwayTeam, isInCollapsibleScoreboard }) => {
    const collapsibleScoreboardCSS = css`
      justify-content: flex-start;
      flex-direction: ${isAwayTeam ? `row` : `row-reverse`};
    `
    return css`
      display: flex;
      align-items: center;
      flex-direction: column;
      ${desktopCSS(css`
        flex-direction: row;
        ${isAwayTeam &&
        css`
          flex-direction: row-reverse;
        `}
      `)}
      ${isAwayTeam && `text-align: right;`};
      ${isInCollapsibleScoreboard && collapsibleScoreboardCSS}
    `
  }}
`

const ScoreboardTeamContent: FunctionComponent<ScoreboardTeamContentProps> = ({
  children,
  isAwayTeam,
}) => {
  const isInCollapsibleScoreboard = useIsInCollapsibleScoreboard()
  return (
    <StyledScoreboardTeamContent
      isAwayTeam={isAwayTeam}
      isInCollapsibleScoreboard={isInCollapsibleScoreboard}>
      {children}
    </StyledScoreboardTeamContent>
  )
}

export default ScoreboardTeamContent
