import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { desktopCSS } from '@measures/responsive'
import { Image } from '@utils/cook/types'
import FreeRatioImage from '@components/FreeRatioImage'
import { ImageConstraints } from '@components/FreeRatioImage/types'

export interface EntityImageProps {
  image: Image<'free'>
  className?: string
}

const IMAGE_CONSTRAINTS = {
  desktop: {
    width: 80,
    height: 80,
  },
  tablet: {
    width: 64,
    height: 64,
  },
  mobile: {
    width: 64,
    height: 64,
  },
  mobileSmall: {
    width: 64,
    height: 64,
  },
} as const satisfies ImageConstraints

const EntityImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${IMAGE_CONSTRAINTS.mobile.width}px;
  height: ${IMAGE_CONSTRAINTS.mobile.height}px;

  ${desktopCSS(css`
    width: ${IMAGE_CONSTRAINTS.desktop.width}px;
    height: ${IMAGE_CONSTRAINTS.desktop.height}px;
  `)}
`

const EntityImage: FunctionComponent<EntityImageProps> = ({
  className,
  image,
}) => {
  return (
    <EntityImageContainer>
      {image ? (
        <FreeRatioImage
          className={className}
          src={image.src}
          alt={image.alt}
          crops={image.crops ?? {}}
          originalDimensions={{
            width: image.width,
            height: image.height,
          }}
          constraints={IMAGE_CONSTRAINTS}
        />
      ) : null}
    </EntityImageContainer>
  )
}

export default EntityImage
