import styled, { css } from 'styled-components'

const Separator = styled.div`
  ${({
    theme: {
      colors: { strokeWeak },
    },
  }) => css`
    width: 100%;
    height: 1px;
    background-color: ${strokeWeak};
  `}
`

export default Separator
