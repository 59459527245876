import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const StyledBetaTag = styled.span`
  ${({
    theme: {
      color: {
        primary: { primary02, blickRed },
      },
      typography: {
        subheads: {
          xsmall2: { bundledCSS: xsmall2 },
        },
      },
      spacing: { spacing4 },
    },
  }) => css`
    color: ${primary02};
    background-color: ${blickRed};
    text-align: center;
    padding: 0 ${spacing4};
    text-transform: none;
    border-radius: ${spacing4};
    height: fit-content;
    ${xsmall2}
    width: 34px;
    height: 14px;
    box-sizing: border-box;
  `}
`

const ChatbotBetaTag: FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  return <StyledBetaTag className={className}>Beta</StyledBetaTag>
}

export default ChatbotBetaTag
