import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { getPageViewType, getTabTypeEnrichedTargetContentType } from './utils'
import config from '@config'
import useExecuteOnClientNavigation from '@hooks/useExecuteOnClientNavigation'
import { useQueryClient } from '@tanstack/react-query'

const {
  subscriptions: { offerPagePath },
} = config

const TrackPageView: FunctionComponent = () => {
  const queryClient = useQueryClient()

  const onImpression = useCallback<TrackingFnType>(({ pageMetadata }) => {
    const {
      sectionUniqueNames,
      ressort,
      url,
      personalizationInfo,
      targetContentType,
      defaultTabType,
    } = pageMetadata

    const type = getPageViewType(pageMetadata)

    let eventData: ReturnType<TrackingFnType>

    switch (type) {
      case 'home': {
        eventData = { event: 'home_page_view' }
        break
      }
      case 'section': {
        eventData = {
          ...(url === offerPagePath ? { isPlus: 1 } : {}),
          event: 'section_page_view',
        }
        break
      }
      case 'article': {
        eventData = {
          ...{
            pageRessort: sectionUniqueNames?.[sectionUniqueNames.length - 1],
          },
          analyticsRessort: ressort,
          event: 'article_page_view',
          targetContentType: getTabTypeEnrichedTargetContentType(
            defaultTabType,
            targetContentType
          ),
        }
        break
      }
      case 'dossier': {
        eventData = { event: 'dossier_page_view' }
        break
      }
      default: {
        return
      }
    }

    return {
      ...eventData,
      ...(personalizationInfo?.experimentVariant
        ? { experimentVariant: personalizationInfo.experimentVariant }
        : {}),
    }
  }, [])

  const trackOnImpression = useTracking(onImpression)

  useExecuteOnClientNavigation(
    useCallback(() => {
      trackOnImpression()
    }, [trackOnImpression])
  )

  useEffect(() => {
    if (!queryClient.getQueryData<boolean>(['initial-page-view-tracked'])) {
      queryClient.setQueryData(['initial-page-view-tracked'], true)
      trackOnImpression()
    }
  }, [queryClient, trackOnImpression])

  return null
}

export default TrackPageView
