import config from '@config'
import useIsContentRestricted from '@hooks/useIsContentRestricted'
import { useQueryClient } from '@tanstack/react-query'
import router from 'next/router'
import { FunctionComponent, useCallback, useEffect } from 'react'
import styled from 'styled-components'

const {
  subscriptions: { restrictedDrawerClassname },
} = config

const StyledRestrictedBackground = styled.div`
  position: fixed;
  top: 40px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background: linear-gradient(transparent, rgb(0, 0, 0));
  z-index: 1;
`

const StyledPianoRestrictedDrawer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10000000;
  width: 100dvw;
  top: 100dvh;
  transform: translate(0px, -30dvh);
`

const PianoRestrictedDrawer: FunctionComponent = () => {
  const isContentRestricted = useIsContentRestricted()
  const queryClient = useQueryClient()

  /**
   * Resets and invalidates the `isContentRestricted` query when a route change starts.
   *
   * ## Why is this needed?
   * - Ensures `isContentRestricted` is set to `false` before any new page is rendered.
   * - Prevents a race condition caused by setting the `WrapperOfAll` component to a fixed position, which impacts
   * the scrollRestoration functionality of Next.js
   * - Uses `routeChangeStart` instead of `routeChangeComplete` to guarantee
   *   the state is reset early in the navigation cycle.
   *
   */
  const resetContentRestriction = useCallback(() => {
    queryClient.setQueryData(['isContentRestricted'], false)
    queryClient.invalidateQueries({
      queryKey: ['isContentRestricted'],
      exact: true,
    })
  }, [queryClient])

  useEffect(() => {
    router.events.on('routeChangeStart', resetContentRestriction)

    return () => {
      router.events.off('routeChangeStart', resetContentRestriction)
    }
  }, [resetContentRestriction])

  return isContentRestricted ? (
    <>
      <StyledPianoRestrictedDrawer className={`${restrictedDrawerClassname}`} />
      <StyledRestrictedBackground />
    </>
  ) : null
}

export default PianoRestrictedDrawer
