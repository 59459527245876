import { css } from 'styled-components'

export interface TypographyDefinition {
  fontFamily: string
  fontWeight: number
  letterSpacing: string | number
  fontSize: string
  lineHeight: number
}

export interface TypographyDefinitionWithBundledCSS
  extends TypographyDefinition {
  bundledCSS: ReturnType<typeof bundledCSS>
}

export type TypographyType = {
  headings: {
    xxxlarge: TypographyDefinitionWithBundledCSS
    xxlarge: TypographyDefinitionWithBundledCSS
    xlarge: TypographyDefinitionWithBundledCSS
    large: TypographyDefinitionWithBundledCSS
    medium: TypographyDefinitionWithBundledCSS
    small: TypographyDefinitionWithBundledCSS
    xsmall: TypographyDefinitionWithBundledCSS
    xxsmall: TypographyDefinitionWithBundledCSS
  }
  subheads: {
    xlarge: TypographyDefinitionWithBundledCSS
    large1: TypographyDefinitionWithBundledCSS
    large2: TypographyDefinitionWithBundledCSS
    medium1: TypographyDefinitionWithBundledCSS
    medium2: TypographyDefinitionWithBundledCSS
    small1: TypographyDefinitionWithBundledCSS
    small2: TypographyDefinitionWithBundledCSS
    xsmall1: TypographyDefinitionWithBundledCSS
    xsmall2: TypographyDefinitionWithBundledCSS
  }
  bodycopy: {
    xlarge: TypographyDefinitionWithBundledCSS
    large: TypographyDefinitionWithBundledCSS
    medium1: TypographyDefinitionWithBundledCSS
    medium2: TypographyDefinitionWithBundledCSS
    small1: TypographyDefinitionWithBundledCSS
    small2: TypographyDefinitionWithBundledCSS
    xsmall: TypographyDefinitionWithBundledCSS
    xxsmall: TypographyDefinitionWithBundledCSS
  }
  catchword: {
    large1: TypographyDefinitionWithBundledCSS
    large2: TypographyDefinitionWithBundledCSS
    medium1: TypographyDefinitionWithBundledCSS
    medium2: TypographyDefinitionWithBundledCSS
    small1: TypographyDefinitionWithBundledCSS
    small2: TypographyDefinitionWithBundledCSS
  }
  labels: {
    label1: TypographyDefinitionWithBundledCSS
    label2: TypographyDefinitionWithBundledCSS
    label3: TypographyDefinitionWithBundledCSS
  }
}

const bundledCSS = ({
  fontFamily,
  fontWeight,
  letterSpacing,
  fontSize,
  lineHeight,
}: TypographyDefinition) => css`
  font-family: ${fontFamily};
  font-weight: ${fontWeight};
  letter-spacing: ${letterSpacing};
  font-size: ${fontSize};
  line-height: ${lineHeight};
`

/* HEADINGS */

const xxxlargeHeading = {
  fontFamily: 'BlickVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '44px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const xxlargeHeading = {
  fontFamily: 'BlickVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '36px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const xlargeHeading = {
  fontFamily: 'BlickVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '32px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const largeHeading = {
  fontFamily: 'BlickVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '28px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const mediumHeading = {
  fontFamily: 'BlickVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '22px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const smallHeading = {
  fontFamily: 'BlickVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '19px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const xsmallHeading = {
  fontFamily: 'BlickVariable',
  fontWeight: 600,
  letterSpacing: 0,
  fontSize: '17px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const xxsmallHeading = {
  fontFamily: 'BlickVariable',
  fontWeight: 600,
  letterSpacing: 0,
  fontSize: '14px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

/* SUBHEADS */

const xlargeSubhead = {
  fontFamily: 'InterVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '20px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const large1Subhead = {
  fontFamily: 'InterVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '18px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const large2Subhead = {
  fontFamily: 'InterVariable',
  fontWeight: 500,
  letterSpacing: 0,
  fontSize: '18px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const medium1Subhead = {
  fontFamily: 'InterVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '16px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const medium2Subhead = {
  fontFamily: 'InterVariable',
  fontWeight: 500,
  letterSpacing: 0,
  fontSize: '16px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const small1Subhead = {
  fontFamily: 'InterVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '14px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const small2Subhead = {
  fontFamily: 'InterVariable',
  fontWeight: 400,
  letterSpacing: 0,
  fontSize: '14px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const xsmall1Subhead = {
  fontFamily: 'InterVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '12px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const xsmall2Subhead = {
  fontFamily: 'InterVariable',
  fontWeight: 400,
  letterSpacing: 0,
  fontSize: '12px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

/* BODYCOPY */
const xlargeBodyCopy = {
  fontFamily: 'InterVariable',
  fontWeight: 400,
  letterSpacing: 0,
  fontSize: '24px',
  lineHeight: 1.6,
} as const satisfies TypographyDefinition

const largeBodyCopy = {
  fontFamily: 'InterVariable',
  fontWeight: 400,
  letterSpacing: 0,
  fontSize: '20px',
  lineHeight: 1.6,
} as const satisfies TypographyDefinition

const medium1BodyCopy = {
  fontFamily: 'InterVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '18px',
  lineHeight: 1.6,
} as const satisfies TypographyDefinition

const medium2BodyCopy = {
  fontFamily: 'InterVariable',
  fontWeight: 400,
  letterSpacing: 0,
  fontSize: '18px',
  lineHeight: 1.6,
} as const satisfies TypographyDefinition

const small1BodyCopy = {
  fontFamily: 'InterVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '16px',
  lineHeight: 1.6,
} as const satisfies TypographyDefinition

const small2BodyCopy = {
  fontFamily: 'InterVariable',
  fontWeight: 400,
  letterSpacing: 0,
  fontSize: '16px',
  lineHeight: 1.6,
} as const satisfies TypographyDefinition

const xsmallBodyCopy = {
  fontFamily: 'InterVariable',
  fontWeight: 400,
  letterSpacing: 0,
  fontSize: '14px',
  lineHeight: 1.6,
} as const satisfies TypographyDefinition

const xxsmallBodyCopy = {
  fontFamily: 'InterVariable',
  fontWeight: 400,
  letterSpacing: 0,
  fontSize: '12px',
  lineHeight: 1.6,
} as const satisfies TypographyDefinition

/* CATCHWORD */

const large1Catchword = {
  fontFamily: 'InterVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '16px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const large2Catchword = {
  fontFamily: 'InterVariable',
  fontWeight: 500,
  letterSpacing: 0,
  fontSize: '16px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const medium1Catchword = {
  fontFamily: 'InterVariable',
  fontWeight: 500,
  letterSpacing: 0,
  fontSize: '14px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const medium2Catchword = {
  fontFamily: 'InterVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '14px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const small1Catchword = {
  fontFamily: 'InterVariable',
  fontWeight: 500,
  letterSpacing: 0,
  fontSize: '12px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const small2Catchword = {
  fontFamily: 'InterVariable',
  fontWeight: 700,
  letterSpacing: 0,
  fontSize: '12px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

/* LABELS */

const label1Label = {
  fontFamily: 'BlickVariable',
  fontWeight: 400,
  letterSpacing: 0,
  fontSize: '14px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const label2Label = {
  fontFamily: 'BlickVariable',
  fontWeight: 200,
  letterSpacing: 0,
  fontSize: '14px',
  lineHeight: 1.2,
} as const satisfies TypographyDefinition

const label3Label = {
  fontFamily: 'BlickVariable',
  fontWeight: 400,
  letterSpacing: 0,
  fontSize: '12px',
  lineHeight: 1.0,
} as const satisfies TypographyDefinition

const typography = {
  headings: {
    xxxlarge: {
      ...xxxlargeHeading,
      bundledCSS: bundledCSS(xxxlargeHeading),
    },
    xxlarge: {
      ...xxlargeHeading,
      bundledCSS: bundledCSS(xxlargeHeading),
    },
    xlarge: {
      ...xlargeHeading,
      bundledCSS: bundledCSS(xlargeHeading),
    },
    large: {
      ...largeHeading,
      bundledCSS: bundledCSS(largeHeading),
    },
    medium: {
      ...mediumHeading,
      bundledCSS: bundledCSS(mediumHeading),
    },
    small: {
      ...smallHeading,
      bundledCSS: bundledCSS(smallHeading),
    },
    xsmall: {
      ...xsmallHeading,
      bundledCSS: bundledCSS(xsmallHeading),
    },
    xxsmall: {
      ...xxsmallHeading,
      bundledCSS: bundledCSS(xxsmallHeading),
    },
  },
  subheads: {
    xlarge: {
      ...xlargeSubhead,
      bundledCSS: bundledCSS(xlargeSubhead),
    },
    large1: {
      ...large1Subhead,
      bundledCSS: bundledCSS(large1Subhead),
    },
    large2: {
      ...large2Subhead,
      bundledCSS: bundledCSS(large2Subhead),
    },
    medium1: {
      ...medium1Subhead,
      bundledCSS: bundledCSS(medium1Subhead),
    },
    medium2: {
      ...medium2Subhead,
      bundledCSS: bundledCSS(medium2Subhead),
    },
    small1: {
      ...small1Subhead,
      bundledCSS: bundledCSS(small1Subhead),
    },
    small2: {
      ...small2Subhead,
      bundledCSS: bundledCSS(small2Subhead),
    },
    xsmall1: {
      ...xsmall1Subhead,
      bundledCSS: bundledCSS(xsmall1Subhead),
    },
    xsmall2: {
      ...xsmall2Subhead,
      bundledCSS: bundledCSS(xsmall2Subhead),
    },
  },
  bodycopy: {
    xlarge: {
      ...xlargeBodyCopy,
      bundledCSS: bundledCSS(xlargeBodyCopy),
    },
    large: {
      ...largeBodyCopy,
      bundledCSS: bundledCSS(largeBodyCopy),
    },
    medium1: {
      ...medium1BodyCopy,
      bundledCSS: bundledCSS(medium1BodyCopy),
    },
    medium2: {
      ...medium2BodyCopy,
      bundledCSS: bundledCSS(medium2BodyCopy),
    },
    small1: {
      ...small1BodyCopy,
      bundledCSS: bundledCSS(small1BodyCopy),
    },
    small2: {
      ...small2BodyCopy,
      bundledCSS: bundledCSS(small2BodyCopy),
    },
    xsmall: {
      ...xsmallBodyCopy,
      bundledCSS: bundledCSS(xsmallBodyCopy),
    },
    xxsmall: {
      ...xxsmallBodyCopy,
      bundledCSS: bundledCSS(xxsmallBodyCopy),
    },
  },
  catchword: {
    large1: {
      ...large1Catchword,
      bundledCSS: bundledCSS(large1Catchword),
    },
    large2: {
      ...large2Catchword,
      bundledCSS: bundledCSS(large2Catchword),
    },
    medium1: {
      ...medium1Catchword,
      bundledCSS: bundledCSS(medium1Catchword),
    },
    medium2: {
      ...medium2Catchword,
      bundledCSS: bundledCSS(medium2Catchword),
    },
    small1: {
      ...small1Catchword,
      bundledCSS: bundledCSS(small1Catchword),
    },
    small2: {
      ...small2Catchword,
      bundledCSS: bundledCSS(small2Catchword),
    },
  },
  labels: {
    label1: {
      ...label1Label,
      bundledCSS: bundledCSS(label1Label),
    },
    label2: {
      ...label2Label,
      bundledCSS: bundledCSS(label2Label),
    },
    label3: {
      ...label3Label,
      bundledCSS: bundledCSS(label3Label),
    },
  },
} as const satisfies TypographyType

export default typography
