import config from '@config'
import useIsErrorPage from '@hooks/useIsErrorPage'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import Link from 'next/link'
import {
  Ref,
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useMemo,
  FunctionComponent,
} from 'react'
import styled from 'styled-components'
import { classifyUrl } from './utils'
import { getRedirectToRSSInfo, getRSSContentInfo } from '@utils/cook'

export interface BlinkProps {
  href: string
  onClick?: () => void
  title?: string
  ariaLabel?: string
  target?: string
  rel?: string
  inTextBox?: boolean
  brandedBox?: boolean
  inlineArticle?: boolean
  targetArticleId?: string
  aureusOfferId?: string
  className?: string
  ref?: Ref<HTMLAnchorElement>
}

const {
  backend: { baseUrl },
  publication: { publication },
} = config

const StyledLink = styled.a``

const StyledNextLink = styled(Link)``

type GetFinalRel = (args: { target?: string; rel?: string }) => string

const getFinalRel: GetFinalRel = ({ target, rel }) => {
  const finalRel = new Set()

  ;(rel ? rel?.split(' ') : []).forEach((relValue) =>
    finalRel.add(relValue.toLowerCase())
  )

  //! Reason: https://www.chromestatus.com/feature/6140064063029248
  //! Details: https://web.dev/external-anchors-use-rel-noopener/#how_to_improve_your_sites_performance_and_prevent_security_vulnerabilities
  if (target === '_blank') {
    finalRel.add('noopener')
  }

  return Array.from(finalRel.values()).join(' ')
}

const Blink: FunctionComponent<PropsWithChildren<BlinkProps>> = ({
  children,
  href,
  target,
  rel,
  title,
  ariaLabel,
  brandedBox,
  inTextBox,
  inlineArticle,
  aureusOfferId,
  targetArticleId,
  onClick,
  className,
  ref = null,
}) => {
  const isErrorPage = useIsErrorPage()

  const { hostname, isExternal, isOutbound, isCMPOpener } = useMemo(
    () => classifyUrl(href),
    [href]
  )

  const externalUrlTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'external_link',
      eventCategory: 'external link',
      eventLabel: href,
      eventAction: 'true',
    }),
    [href]
  )

  const onExternalUrlClicked = useTracking(externalUrlTrackingFn)

  const outboundLinkTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'outbound_link',
      eventCategory: 'outbound_link',
      eventLabel: href,
      eventAction: 'clickout',
      hostname,
      isTextBox: !!inTextBox,
      brandedBox: !!brandedBox,
    }),
    [href, hostname, inTextBox, brandedBox]
  )

  const onOutboundLinkClicked = useTracking(outboundLinkTrackingFn)

  const targetArticleIdLinkTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'click_inlineArticle',
      eventCategory: 'related content',
      eventLabel: `${baseUrl}/id${targetArticleId}.html`,
      eventAction: 'inlineArticle',
    }),
    [targetArticleId]
  )

  const onTargetArticleIdLinkClicked = useTracking(
    targetArticleIdLinkTrackingFn
  )

  const enhancedOnClick = useCallback<(e: SyntheticEvent) => void>(
    (e) => {
      if (isCMPOpener) {
        e.preventDefault()
        window?.OneTrust?.ToggleInfoDisplay?.()
        return
      }

      if (isExternal) {
        onExternalUrlClicked()
      }

      if (isOutbound) {
        onOutboundLinkClicked()
      }

      if (inlineArticle && !!targetArticleId) {
        onTargetArticleIdLinkClicked()
      }

      onClick?.()
    },
    [
      isExternal,
      isOutbound,
      isCMPOpener,
      onExternalUrlClicked,
      onOutboundLinkClicked,
      onTargetArticleIdLinkClicked,
      onClick,
      targetArticleId,
      inlineArticle,
    ]
  )

  if (!href) {
    return null
  }

  const finalRel = getFinalRel({ target, rel })

  const linkProps = {
    ref,
    href,
    ...(target ? { target } : {}),
    onClick: enhancedOnClick,
    ...(className ? { className } : {}),
    ...(targetArticleId ? { 'data-uuid-ui': targetArticleId } : {}),
    ...(aureusOfferId ? { 'data-ei': aureusOfferId } : {}),
    ...(ariaLabel
      ? {
          'aria-label':
            ariaLabel ||
            title ||
            (typeof children === 'string' ? children : ''),
        }
      : {}),
    ...(finalRel ? { rel: finalRel } : {}),
  }

  if (isErrorPage) {
    return <StyledLink {...linkProps}>{children}</StyledLink>
  }

  if (
    (publication === 'romandie' && href === '/') ||
    (publication === 'blick' && href === '/fr/') ||
    //! RSS links should never use client-side routing
    //! because it always assumes Content-Type: application/json
    //! and cannot serve properly RSS content.
    //! So instead we'll use a normal link.
    (!isOutbound &&
      (getRedirectToRSSInfo(href).shouldRedirect ||
        getRSSContentInfo(href).shouldServeRSSCONTENT))
  ) {
    return <StyledLink {...linkProps}>{children}</StyledLink>
  }

  return (
    <StyledNextLink {...linkProps} prefetch={false}>
      {children}
    </StyledNextLink>
  )
}

export default Blink
