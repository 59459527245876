import { TrackingFnType } from '@hooks/useTracking'
import { TrackingEventType } from './types'
import { PageMetadata } from '@hooks/usePageMetadata'
import { extractPageType } from '@utils/cook'
import config from '@config'
import { TAnswerData, TQuestionData } from '@utils/chatbot/types'

const {
  publication: { publication },
} = config

export const trackHandler: TrackingFnType<{
  event: TrackingEventType
  messageId?: string
  conversationId?: string | null
  isCached?: boolean
  isSuggestedQuestion?: boolean
  questionAnswered?: boolean | string | null
  url?: string
  rating?: string
}> = ({
  extraData: {
    event,
    messageId,
    conversationId,
    isCached,
    isSuggestedQuestion,
    questionAnswered,
    url,
    rating,
  },
}) => {
  const data = {
    event: event,
    ...(typeof conversationId !== 'undefined' && {
      chatbot_conversationId: conversationId,
    }),
    ...(typeof messageId !== 'undefined' && {
      chatbot_messageId: messageId,
    }),
    ...(typeof conversationId !== 'undefined' && {
      chatbot_conversationId: conversationId,
    }),
    ...(typeof isSuggestedQuestion !== 'undefined' && {
      chatbot_entrypoint: getEntryPoint(isSuggestedQuestion, isCached),
    }),
    ...(typeof questionAnswered !== 'undefined' && {
      chatbot_question_answered: questionAnswered,
    }),
    ...(typeof url !== 'undefined' && {
      link_url: url,
    }),
    ...(typeof rating !== 'undefined' && {
      button_label: rating,
    }),
  }

  return data
}

export const getEntryPoint = (
  isSuggestedQuestion?: boolean,
  isCached?: boolean
) =>
  isSuggestedQuestion
    ? `${isCached ? 'cached ' : ''}suggested question`
    : 'custom question'

export const removeLastDot = (text: string) => {
  if (text.endsWith('.')) {
    return text.slice(0, -1)
  }
  return text
}

export const enrichQuestions = (
  questions: TQuestionData[],
  isSuggestedQuestion: boolean,
  context?: string
) =>
  questions.map((question) => ({
    context,
    isSuggestedQuestion,
    ...question,
  }))

export const extractContext = (pageMetadata: PageMetadata) => {
  const type = extractPageType(pageMetadata)

  if (type === 'article') {
    return pageMetadata.contextId
  }

  if (type === 'section' || type === 'dossier') {
    const splitUrl = pageMetadata.url.split('/')
    const sectionIndex = publication === 'blick' ? 1 : 2
    if (splitUrl[sectionIndex]) {
      return splitUrl[sectionIndex]
    }
  }

  return 'home'
}

export const getAnswerText = (content?: TAnswerData[]) => {
  if (!content) {
    return
  }

  return content?.map((contentItem) => contentItem.text).join(' ')
}
