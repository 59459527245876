import { FunctionComponent, useState, useCallback } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { desktopCSS } from '@measures/responsive'
import SvgIcon from '@components/SvgIcon'
import SecondaryCTAButton from '@components/Buttons/SecondaryCTAButton'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'
import translate from '@i18n'

type FollowYourEntityProps = {
  variant: 'mobile' | 'desktop'
}

const IconWrapper = styled.button`
  ${() => css`
    background: transparent;
    display: grid;
    align-items: center;
    justify-items: center;
    border: 0;
    cursor: pointer;
    padding: 0;
    line-height: 0;

    ${desktopCSS(css`
      display: none;
    `)}
  `}
`

const StyledPrimaryCTAButton = styled(PrimaryCTAButton)`
  ${() => css`
    display: none;

    ${desktopCSS(css`
      display: flex;
    `)}
  `}
`

const StyledSecondaryCTAButton = styled(SecondaryCTAButton)`
  ${() => css`
    display: none;

    ${desktopCSS(css`
      display: flex;
    `)}
  `}
`

const FollowYourEntity: FunctionComponent<FollowYourEntityProps> = ({
  variant = 'desktop',
}) => {
  const theme = useTheme()

  const [isFollowing, setIsFollowing] = useState<boolean>(false)

  const handleFollow = useCallback(() => {
    setIsFollowing(!isFollowing)
  }, [isFollowing])

  if (variant === 'mobile') {
    return (
      <IconWrapper aria-label="Follow" onClick={handleFollow}>
        <SvgIcon
          size={24}
          iconName={isFollowing ? 'check-circle-solid' : 'plus-circle'}
          color={theme.colors.staticWhite}
        />
      </IconWrapper>
    )
  }

  return isFollowing ? (
    <StyledPrimaryCTAButton
      colorVariant="white"
      size="small"
      iconName="check-circle-solid"
      onClick={handleFollow}>
      {translate('entityHeader.following')}
    </StyledPrimaryCTAButton>
  ) : (
    <StyledSecondaryCTAButton
      colorVariant="white"
      size="small"
      iconName="plus-circle"
      onClick={handleFollow}>
      {translate('entityHeader.follow')}
    </StyledSecondaryCTAButton>
  )
}

export default FollowYourEntity
