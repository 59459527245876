import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import Blink from '@components/Blink'
import { Link } from '@utils/cook/types'

type TileWrapperProps = {
  isPlaceholder: boolean
  hasBlurryBackground: boolean
  children: React.ReactNode
  link?: Link
}

const commonWrapperCSS = ({
  isPlaceholder,
  hasBlurryBackground,
}: {
  isPlaceholder: boolean
  hasBlurryBackground: boolean
}) => css`
  ${({
    theme: {
      spacing: { spacing4, spacing8, spacing12, spacing32 },
    },
  }) => css`
    appearance: none;
    text-decoration: none;

    border-radius: ${spacing8};
    height: 143px;
    padding: ${spacing12};
    box-sizing: border-box;

    ${desktopCSS(css`
      width: 300px;
      ${hasBlurryBackground &&
      css`
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        border: none;
      `};
    `)};

    ${mobileAndTabletCSS(css`
      width: 200px;
    `)};

    ${isPlaceholder &&
    css`
      border-color: transparent;
    `}

    /*
    * TN: Tournament Name
    * L1: Logo 1
    * N1: Team Name 1
    * SC1: Score 1
    * N2: Team Name 2
    * L2: Logo 2
    * SC2: Score 2
    * SP: Separator
    * ST: Status
    */
    display: grid;
    grid-template-areas:
      'TN TN TN'
      'L1 N1 SC1'
      'L2 N2 SC2'
      'SP SP SP'
      'ST ST ST';

    grid-template-rows: repeat(5, auto);
    grid-template-columns: ${spacing32} minmax(0, 1fr) auto;
    grid-row-gap: ${spacing4};
    grid-column-gap: ${spacing8};
    align-items: center;
  `}
`

const StyledBlink = styled(Blink)<{
  isPlaceholder: boolean
  hasBlurryBackground: boolean
}>`
  ${({
    theme: {
      colors: { strokeDefault },
    },
    isPlaceholder,
    hasBlurryBackground,
  }) => css`
    ${commonWrapperCSS({ isPlaceholder, hasBlurryBackground })}

    border: 1px solid ${strokeDefault};
  `}
`

const StyledDiv = styled.div<{
  isPlaceholder: boolean
  hasBlurryBackground: boolean
}>`
  ${({
    theme: {
      colors: { strokeDefaultDisabled },
    },
    isPlaceholder,
    hasBlurryBackground,
  }) => css`
    ${commonWrapperCSS({ isPlaceholder, hasBlurryBackground })}

    border: 1px solid ${strokeDefaultDisabled};
  `}
`

const TileWrapper: FunctionComponent<TileWrapperProps> = ({
  isPlaceholder,
  hasBlurryBackground,
  link,
  children,
}) => {
  return link ? (
    <StyledBlink
      isPlaceholder={isPlaceholder}
      hasBlurryBackground={hasBlurryBackground}
      {...link}>
      {children}
    </StyledBlink>
  ) : (
    <StyledDiv
      isPlaceholder={isPlaceholder}
      hasBlurryBackground={hasBlurryBackground}>
      {children}
    </StyledDiv>
  )
}

export default TileWrapper
