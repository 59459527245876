import { Image } from '@utils/cook/types'

import { Vtype } from '@components/Ad/types'
import { PlayerType } from './types'
import { createCropParams } from '@components/Schema/images'
import { dayjs } from '@utils/date'
import { DurationUnitType } from 'dayjs/plugin/duration'
import config from '@config'
import { VIDEO_TYPES as AD_VIDEO_TYPES } from '@components/Ad/utils'

interface GetVideoTypeParamProps {
  duration: number
  shortVideoLengthThresholdInSeconds: number
}

const ORIENTATIONS = {
  PORTRAIT: 'portrait',
  HORIZONTAL: 'horizontal',
} as const

const PLAYER_TYPES = {
  VOD: 'vod',
  LIVE: 'live',
  LIVE_IN_ARTICLE: 'live_in_article',
  BITE: 'bite',
} as const

const PLAYER_MODES = {
  OVERLAY: 'overlay',
  INLINE: 'inline',
  FASTLANE: 'fastlane',
} as const

const VIDEO_TYPES = {
  YOUTUBE: 'youtube',
  BLICKTV: 'blicktv',
  VOD: 'vod',
  LIVE: 'live',
} as const

const {
  date: { timeInputFormat: dateTimeInputFormat },
  video: {
    durationFormat: videoDurationFormat,
    durationFormatWithHours: videoDurationFormatWithHours,
  },
} = config

const isLiveStream = (duration: number): boolean =>
  duration === -1 || duration === Infinity

const shouldAutoplay = (duration: number, isInVideoOnlyArticle: boolean) => {
  const isLiveStreamVideo = isLiveStream(duration)
  const autoplay = isInVideoOnlyArticle || isLiveStreamVideo

  return autoplay
}

const getVideoTypeParam = ({
  duration,
  shortVideoLengthThresholdInSeconds,
}: GetVideoTypeParamProps): Vtype => {
  if (isLiveStream(duration)) {
    return AD_VIDEO_TYPES.LIVESTREAM
  }

  switch (duration) {
    case undefined:
      return AD_VIDEO_TYPES.DEFAULT
    default:
      if (Number.isInteger(duration)) {
        return duration >= shortVideoLengthThresholdInSeconds
          ? AD_VIDEO_TYPES.LONGFORM
          : AD_VIDEO_TYPES.SHORTFORM
      }
      return AD_VIDEO_TYPES.DEFAULT
  }
}

const getPlayerType = ({
  isBlickTV,
  duration,
}: {
  isBlickTV: boolean
  duration: number
}): PlayerType =>
  isBlickTV
    ? PLAYER_TYPES.LIVE
    : duration === -1
      ? PLAYER_TYPES.LIVE_IN_ARTICLE
      : PLAYER_TYPES.VOD

const getPosterImageUrl = (
  baseUrl: string,
  image?: Omit<Image<'16_9'>, 'width' | 'height'>
): string | undefined =>
  (image?.src && !image?.crops && `${baseUrl}${image.src}?ratio=16_9`) ||
  (image?.src &&
    image?.crops &&
    `${baseUrl}${image.src}?ratio=16_9${createCropParams({
      crops: image.crops,
      ratio: {
        name: '16_9',
        width: 16,
        height: 9,
      },
    })}`) ||
  undefined

const getDurationType = (duration: number) =>
  (duration === -1 && 'LIVE') || (duration > 0 && 'VOD') || 'INVALID'

const isExtraColonAllowed = (text: string) =>
  [':', '!', '?', '.'].some((punctuation) => text.endsWith(punctuation))

const getTextWithExtraColon = (text: string) =>
  `${text}${isExtraColonAllowed(text) ? '' : ':'}`

const formatDuration = (duration?: number | null, format?: string): string => {
  if (typeof duration === 'undefined' || duration === null || duration < 0) {
    return '--:--'
  }

  return dayjs
    .duration(Number(duration), dateTimeInputFormat as DurationUnitType)
    .format(
      format ||
        (duration >= 60 * 60 // 1 hour
          ? videoDurationFormatWithHours
          : videoDurationFormat)
    )
}

export {
  isLiveStream,
  getVideoTypeParam,
  getPlayerType,
  getPosterImageUrl,
  getDurationType,
  shouldAutoplay,
  isExtraColonAllowed,
  getTextWithExtraColon,
  formatDuration,
  PLAYER_TYPES,
  PLAYER_MODES,
  VIDEO_TYPES,
  ORIENTATIONS,
}
