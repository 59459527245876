import usePageMetadata from '@hooks/usePageMetadata'
import config from '@config'
import blickSchemaOrgLogo from '@assets/images/logo_schemaorg.png'
import { getSameAsInfo } from '@components/Schema/utils'
import SchemaHTMLElement from '@components/SchemaHTMLElement'
import AddressSchema from '@components/Schema/AddressSchema'
import { useMemo } from 'react'
import PersonSchema from '@components/Schema/PersonSchema'
import { ORGANIZATIONS } from '@components/Schema/constants'
import { getPreparedAssociations } from '@components/Schema/utils'
import OrganizationSchema from '@components/Schema/OrganizationSchema'

const {
  backend: { baseUrl },
  schema: {
    organizationId,
    organizationAddressId,
    parentOrganizationId,
    personSchemaUrl,
  },
} = config

const NewsMediaOrganizationSchema = () => {
  const pageMetadata = usePageMetadata()
  const { url, metaDescription, schema: { keyEmployees } = {} } = pageMetadata
  const keyEmployeesIds = useMemo(
    () =>
      keyEmployees?.map((employee) => ({
        '@id': `${personSchemaUrl}${employee?.url || ''}`,
      })),
    [keyEmployees]
  )
  const organizationsIds = ORGANIZATIONS.map((organization) => ({
    '@id': `${organizationId}${organization.organizationId}`,
  }))
  const preparedOrganizations = getPreparedAssociations(ORGANIZATIONS)
  const blickEmail = 'redaktion@blick.ch'
  const blickPhone = '+41442596262'
  const addressProps = {
    id: organizationAddressId,
    streetAddress: 'Dufourstrasse 23',
    addressLocality: 'Zürich',
    addressRegion: 'ZH',
    postalCode: '8008',
    addressCountry: 'Schweiz',
  }
  return (
    <>
      {!!keyEmployees?.length &&
        keyEmployees.map((employee) => (
          <PersonSchema key={employee.url} {...employee} />
        ))}
      {preparedOrganizations?.map((organization) => (
        <OrganizationSchema key={organization.id} {...organization} />
      ))}
      <AddressSchema {...addressProps} />
      <SchemaHTMLElement
        scriptKey="newsMediaOrganization"
        schemaObject={{
          '@type': 'NewsMediaOrganization',
          id: organizationId,
          url: `${baseUrl}${url}`,
          name: 'Blick',
          legalName: 'Blick',
          alternateName: 'blick.ch',
          description: metaDescription,
          logo: {
            '@id': `${baseUrl}${blickSchemaOrgLogo}`,
          },
          image: [{ '@id': `${baseUrl}${blickSchemaOrgLogo}` }],
          email: blickEmail,
          telephone: blickPhone,
          address: {
            '@id': organizationAddressId,
          },
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: blickPhone,
            contactType: 'Customer support',
            email: blickEmail,
          },
          parentOrganization: { '@id': parentOrganizationId },
          ...(keyEmployeesIds?.length ? { employee: keyEmployeesIds } : {}),
          memberOf: organizationsIds,
          ...getSameAsInfo(),
        }}
      />
    </>
  )
}

export default NewsMediaOrganizationSchema
