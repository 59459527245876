import { FunctionComponent } from 'react'
import { WidgetButtonProps } from '../types'
import styled, { css, useTheme } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import SvgIcon from '@components/SvgIcon'
import TypingAnimationWrapper from '../messages/TypingEffectWrapper'

const StyledWidgetButton = styled.button`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02 },
        tertiary: { grey400 },
      },
      spacing: { spacing16 },
      typography: {
        subheads: {
          medium1: { bundledCSS: medium1HeadingCSS },
        },
      },
    },
  }) => css`
    ${medium1HeadingCSS}
    background: ${primary02};
    color: ${primary01};
    border: 1px solid ${grey400};
    max-width: 100%;
    border-radius: 4px;
    text-align: left;
    padding: ${spacing16};
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (hover: hover) {
      &:hover {
        border-color: ${primary01};
      }
    }

    &:disabled {
      pointer-events: none;
    }

    ${mobileCSS(css`
      width: 100%;
    `)}
  `}
`

const StyledSvgIcon = styled(SvgIcon)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    min-width: 24px;
    min-height: 24px;
    margin-left: ${spacing8};
  `}
`

const WidgetButton: FunctionComponent<WidgetButtonProps> = ({
  children,
  buttonText,
  onClick,
  disabled,
  bypassTypingEffect = false,
}) => {
  const theme = useTheme()

  return (
    <StyledWidgetButton
      onClick={() => onClick && onClick()}
      disabled={disabled}>
      {buttonText ? (
        <TypingAnimationWrapper
          text={buttonText}
          skipAnimation={bypassTypingEffect}
        />
      ) : (
        children
      )}
      <StyledSvgIcon
        iconName="stars"
        size={24}
        color={theme.color.primary.blickRed}
      />
    </StyledWidgetButton>
  )
}

export default WidgetButton
