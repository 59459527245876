import { ConfigFn } from '@config/types'

const config = (() =>
  ({
    conversation: 'none',
    cards: 'visible',
    theme: 'light',
    width: 'auto',
    dnt: false,
  }) as const) satisfies ConfigFn

export default config
