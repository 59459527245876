import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import useIsInCueLiveContent from '@hooks/useIsInCueLiveContent'

interface QuoteProps {
  text: string
  source?: string
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Source = styled.span`
  ${({
    theme: {
      typography: {
        catchword: {
          medium1: { bundledCSS: mediumCatchwordCSS },
        },
      },
      color: {
        tertiary: { grey800 },
      },
    },
  }) => css`
    color: ${grey800};
    font-style: normal;
    text-transform: uppercase;
    margin-right: auto;
    ${mediumCatchwordCSS}
  `}
`

const Text = styled.span<{ isInCueLive: boolean }>`
  ${({
    theme: {
      typography: {
        headings: {
          medium: { bundledCSS: mediumHeadingCSS },
        },
      },
      spacing: { spacing2, spacing4, spacing16, spacing24 },
      color: {
        primary: { primary01, blickRed },
      },
    },
    isInCueLive,
  }) => css`
    border-left: ${spacing2} solid ${blickRed};
    padding-left: ${spacing16};
    color: ${primary01};
    ${mediumHeadingCSS};
    ${!isInCueLive &&
    desktopCSS(css`
      border-left: ${spacing4} solid ${blickRed};
      padding-left: ${spacing24};
    `)};
    ${isInCueLive &&
    css`
      border-left: none;
      padding-left: 0;
    `};
  `}
`

const StyledQuotation = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
    },
  }) => css`
    color: ${blickRed};
    font-family: BlickVariable;
    font-size: 60px;
    font-style: normal;
    font-weight: 850;
    line-height: 60px;
  `}
`

const StyledSourceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const Quote: FunctionComponent<QuoteProps> = ({ text, source }) => {
  const isInCueLive = useIsInCueLiveContent()

  return (
    <StyledWrapper>
      <StyledQuotation>&laquo;</StyledQuotation>
      <Text isInCueLive={isInCueLive}>{text}</Text>
      <StyledSourceWrapper>
        {source && <Source>{source}</Source>}
        <StyledQuotation>&raquo;</StyledQuotation>
      </StyledSourceWrapper>
    </StyledWrapper>
  )
}

const widget = {
  kind: ['widget', 'quote'],
  component: Quote,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
