import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import { FunctionComponent, ReactNode, Ref } from 'react'

interface ItemContainerProps {
  children?: ReactNode
  className?: string
  ref?: Ref<HTMLDivElement>
}

const ItemContainerWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing32, spacing64 },
    },
  }) => css`
    position: relative;
    background-color: #000000;

    display: flex;
    justify-content: center;

    height: 100%;
    width: 100%;
    min-height: 560px;
    min-width: 315px;

    scroll-snap-align: start;
    scroll-snap-stop: always;

    ${desktopCSS(css`
      height: calc(100vh - 96px);
      width: calc(56.25vh - 54px);

      margin-top: ${spacing32};
      scroll-margin-top: ${spacing32};

      overflow: hidden;
      border-radius: 8px;

      &:last-child {
        margin-bottom: ${spacing64};
      }
    `)}
  `}
`
const ItemContainer: FunctionComponent<ItemContainerProps> = ({
  children,
  className,
  ref,
}) => (
  <ItemContainerWrapper className={className} ref={ref}>
    {children}
  </ItemContainerWrapper>
)
export default ItemContainer
