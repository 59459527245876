import config from '@config'
import blickSchemaOrgLogo from '@assets/images/logo_schemaorg.png'

const {
  backend: { baseUrl },
  schema: { organizationAddressId },
} = config

const ORGANIZATIONS = [
  {
    organizationId: '11f08b7b0h',
    organizationName: 'Verband Schweizer Medien',
    organizationUrl: 'https://www.schweizermedien.ch/',
    organizationKnowledgeGraphId: '11f08b7b0h',
    organizationWikipedia:
      'https://de.wikipedia.org/wiki/Verband_Schweizer_Medien',
  },
  {
    organizationId: '11fhql5vgx',
    organizationName: 'Verein GS1 Schweiz',
    organizationUrl: 'https://www.gs1.ch/de',
    organizationKnowledgeGraphId: '11fhql5vgx',
  },
  {
    organizationId: '11s232rqwb',
    organizationName: 'MVFP Medienverband der freien Presse',
    organizationUrl: 'https://www.mvfp.de/',
    organizationKnowledgeGraphId: '11s232rqwb',
    organizationWikipedia:
      'https://de.wikipedia.org/wiki/MVFP_Medienverband_der_freien_Presse',
  },
  {
    organizationId: '120l2j70',
    organizationName: 'WEMF AG für Werbemedienforschung',
    organizationUrl: 'https://wemf.ch/',
    organizationKnowledgeGraphId: '120l2j70',
    organizationWikipedia: [
      'https://de.wikipedia.org/wiki/WEMF',
      'https://en.wikipedia.org/wiki/WEMF_AG_f%C3%BCr_Werbemedienforschung',
    ],
  },
  {
    organizationId: '120z_3f9',
    organizationName: 'Börsenverein des Deutschen Buchhandels',
    organizationUrl: 'https://www.boersenverein.de/',
    organizationKnowledgeGraphId: '120z_3f9',
    organizationWikipedia: [
      'https://de.wikipedia.org/wiki/B%C3%B6rsenverein_des_Deutschen_Buchhandels',
      'https://en.wikipedia.org/wiki/B%C3%B6rsenverein_des_Deutschen_Buchhandels',
    ],
  },
  {
    organizationId: '1q6fvtgt4',
    organizationName: 'Handelskammer Deutschland-Schweiz',
    organizationUrl: 'https://www.handelskammer-d-ch.ch/de',
    organizationKnowledgeGraphId: '1q6fvtgt4',
    organizationWikipedia:
      'https://de.wikipedia.org/wiki/Handelskammer_Deutschland-Schweiz',
  },
  {
    organizationId: '0174w5',
    organizationName: 'World Economic Forum',
    organizationUrl: 'https://www.weforum.org/',
    organizationKnowledgeGraphId: '0174w5',
    organizationWikipedia: [
      'https://de.wikipedia.org/wiki/World_Economic_Forum',
      'https://en.wikipedia.org/wiki/World_Economic_Forum',
    ],
  },
  {
    organizationId: '02ln61',
    organizationName: 'Reporters sans frontières',
    organizationUrl: 'https://rsf.org/fr',
    organizationKnowledgeGraphId: '02ln61',
    organizationWikipedia: [
      'https://fr.wikipedia.org/wiki/Reporters_sans_fronti%C3%A8res',
      'https://de.wikipedia.org/wiki/Reporter_ohne_Grenzen',
      'https://en.wikipedia.org/wiki/Reporters_Without_Borders',
    ],
  },
  {
    organizationId: '02p9x00',
    organizationName: 'INMA',
    organizationUrl: 'https://www.inma.org/',
    organizationKnowledgeGraphId: '02p9x00',
  },
  {
    organizationId: '0dpl5n',
    organizationName: 'WAN-IFRA',
    organizationUrl: 'https://wan-ifra.org/',
    organizationKnowledgeGraphId: '0dpl5n',
    organizationWikipedia: [
      'https://de.wikipedia.org/wiki/WAN-IFRA',
      'https://en.wikipedia.org/wiki/World_Association_of_Newspapers_and_News_Publishers',
    ],
  },
]

const ORGANIZATION_DATA = {
  id: '2',
  url: 'https://www.ringier.com/',
  name: 'Ringier',
  legalName: 'Ringier AG',
  alternateName: 'ringier.com',
  description:
    'Ringier is a family-owned media group with brands in Europe and Africa that focus on media, e-commerce, marketplaces and entertainment.',
  email: 'info@ringier.ch',
  telephone: '+41442596111',
  address: organizationAddressId,
  sameAs: [
    'https://www.linkedin.com/company/ringier/',
    'https://twitter.com/ringier_ag',
    'https://www.facebook.com/ringierag/',
    'https://www.youtube.com/user/RingierComm',
    'https://www.instagram.com/ringier_ag',
  ],
}

const DEFAULT_AUTHOR = 'Blick.ch'

const BLICK_FACEBOOK_PAGE = 'https://www.facebook.com/blick'
const BLICK_TWITTER_PAGE = 'https://twitter.com/blickch'
const BLICK_INSTAGRAM_PAGE = 'https://instagram.com/blick'
const BLICK_WIKIPEDIA_PAGE = 'https://de.wikipedia.org/wiki/Blick.ch'
const BLICK_TIKTOK_PAGE = 'https://www.tiktok.com/@blick'
const BLICK_YOUTUBE_PAGE = 'https://www.youtube.com/@BlickTube'
const BLICK_BLUE_SKY_PAGE = 'https://bsky.app/profile/blick.ch'

const BLICK_LOGO_INFO = {
  //! The `src` needs to be absolute, so the request can be run as is by search engines
  url: `${baseUrl}${blickSchemaOrgLogo}`,
  width: 137,
  height: 60,
}

const IMAGE_CONFIG: Record<string, any> = {
  ratios: {
    '1_1': {
      name: '1_1',
      width: 1,
      height: 1,
    },
    '4_3': {
      name: '4_3',
      width: 4,
      height: 3,
    },
    '16_9': {
      name: '16_9',
      width: 16,
      height: 9,
    },
    free: {
      name: 'free',
      width: 'auto',
      height: 'auto',
    },
  },
  sizes: {
    XS: 'XS',
    S: 'S',
    M: 'M',
    L: 'L',
    XL: 'XL',
    XLL: 'XLL',
    XXL: 'XXL',
    retina: {
      XS: 'XSR',
      S: 'SR',
      M: 'MR',
      L: 'LR',
      XL: 'XLR',
      XLL: 'XLL',
      XXL: 'XXLR',
    },
  },
  widths: {
    XS: 112,
    S: 300,
    M: 375,
    L: 500,
    XL: 1000,
    XLL: 1200,
    XXL: 2000,
  },
}

const SCHEMAORG_IMAGE_RATIOS = [
  IMAGE_CONFIG.ratios['16_9'],
  IMAGE_CONFIG.ratios['4_3'],
  IMAGE_CONFIG.ratios['1_1'],
]

export {
  ORGANIZATIONS,
  ORGANIZATION_DATA,
  DEFAULT_AUTHOR,
  BLICK_TWITTER_PAGE,
  BLICK_TIKTOK_PAGE,
  BLICK_YOUTUBE_PAGE,
  BLICK_WIKIPEDIA_PAGE,
  BLICK_FACEBOOK_PAGE,
  BLICK_INSTAGRAM_PAGE,
  BLICK_BLUE_SKY_PAGE,
  BLICK_LOGO_INFO,
  IMAGE_CONFIG,
  SCHEMAORG_IMAGE_RATIOS,
}
