import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { Link, Logo } from '@utils/cook/types'
import JSONRenderer from '@components/JSONRenderer'
import { JSONWidgetType as TeaserStandardHorizontalJSONWidgetType } from '@widgets/TeaserStandardHorizontal'
import { JSONWidgetType as TeaserStandardVerticalJSONWidgetType } from '@widgets/TeaserStandardVertical'
import SectionHeader from '@components/SectionHeader'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'

export type SectionalAPIProps = {
  title: string
  accentColor: string
  link?: Link
  adSlideIndex?: number
  belongsTo?: string
  mainItem: TeaserStandardVerticalJSONWidgetType
  items: TeaserStandardHorizontalJSONWidgetType[]
  logo?: Logo
  ctaText?: string
}

const Separator = styled.div`
  ${({
    theme: {
      colors: { strokeWeak },
    },
  }) => css`
    width: 100%;
    height: 1px;
    background-color: ${strokeWeak};
  `}
`

const StyledSectional = styled.div`
  ${({
    theme: {
      spacing: { spacing16, spacing24, spacing32 },
    },
  }) => css`
    width: 100%;
    display: grid;
    grid-column-gap: ${spacing32};
    grid-row-gap: ${spacing16};
    align-items: flex-start;

    > :nth-child(1) {
      margin-bottom: 8px;
    }

    ${desktopCSS(css`
      grid-row-gap: ${spacing24};
      grid-template-rows: repeat(7, auto);
      grid-template-columns: repeat(12, minmax(0, 1fr));

      /*
       * SH: Section Header
       * MI: Main Item
       * I1: Item 1
       * I2: Item 2
       * I3: Item 3
       * S1: Separator 1
       * S2: Separator 2
       * ER: Empty Row
       */
      grid-template-areas:
        'SH SH SH SH SH SH SH SH SH SH SH SH'
        'MI MI MI MI MI MI MI MI I1 I1 I1 I1'
        'MI MI MI MI MI MI MI MI S1 S1 S1 S1'
        'MI MI MI MI MI MI MI MI I2 I2 I2 I2'
        'MI MI MI MI MI MI MI MI S2 S2 S2 S2'
        'MI MI MI MI MI MI MI MI I3 I3 I3 I3'
        'MI MI MI MI MI MI MI MI ER ER ER ER';

      > :nth-child(1) {
        grid-area: SH;
      }

      > :nth-child(2) {
        grid-area: MI;
      }

      > :nth-child(3) {
        display: none;
      }

      > :nth-child(4) {
        grid-area: I1;
      }

      > :nth-child(5) {
        grid-area: S1;
        align-self: center;
      }

      > :nth-child(6) {
        grid-area: I2;
      }

      > :nth-child(7) {
        grid-area: S2;
        align-self: center;
      }

      > :nth-child(8) {
        grid-area: I3;
      }

      > :nth-child(9) {
        display: none;
      }
    `)}

    ${mobileAndTabletCSS(css`
      grid-template-columns: minmax(0, 1fr);
    `)};
  `}
`

const Sectional: FunctionComponent<SectionalAPIProps> = ({
  accentColor,
  mainItem,
  items,
  title,
  link,
  logo,
  ctaText,
}) => {
  return (
    <StyledSectional>
      <SectionHeader
        title={title}
        link={link}
        accentColor={accentColor}
        logo={logo}
        ctaText={ctaText}
      />
      <JSONRenderer>{mainItem}</JSONRenderer>
      <Separator />
      <JSONRenderer>{items[0]}</JSONRenderer>
      <Separator />
      <JSONRenderer>{items[1]}</JSONRenderer>
      <Separator />
      <JSONRenderer>{items[2]}</JSONRenderer>
      <Separator />
    </StyledSectional>
  )
}

const widget = {
  kind: ['sectional'],
  component: Sectional,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
