import config from '@config'
import translate from '@i18n'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/de-ch'
import 'dayjs/locale/fr-ch'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import utc from 'dayjs/plugin/utc'

const {
  publication: { publication },
  date: { dateFormat },
} = config

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)
dayjs.extend(updateLocale)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)

if (publication === 'romandie') {
  dayjs.locale('fr-ch')
  dayjs.updateLocale('fr-ch', {
    formats: {
      LT: 'HH:mm [heures]',
      LTS: 'HH:mm:ss',
      L: 'DD.MM.YYYY',
      LL: 'D. MMMM YYYY',
      LLL: 'D. MMMM YYYY HH:mm',
      LLLL: 'dddd, D. MMMM YYYY HH:mm',
    },
  })
} else {
  dayjs.locale('de-ch')
  dayjs.updateLocale('de-ch', {
    formats: {
      LT: 'HH:mm [Uhr]',
      LTS: 'HH:mm:ss',
      L: 'DD.MM.YYYY',
      LL: 'D. MMMM YYYY',
      LLL: 'D. MMMM YYYY HH:mm',
      LLLL: 'dddd, D. MMMM YYYY HH:mm',
    },
  })
}

type FormatByLocaleFn = (date: any, dateFormat: any) => string

const formatByLocale: FormatByLocaleFn = (date, dateFormat) =>
  dayjs(date).tz('Europe/Zurich').format(dateFormat)

export interface FormattedDate {
  date: string
  time: string
  dateOrTime: string
  sameDateTime: string | null
}

type FormatDateSimpleFn = (
  rawDate?: any
) => Record<string, unknown> | { date: string; time: string }

const formatDateSimple: FormatDateSimpleFn = (rawDate) => {
  if (!rawDate) {
    return {}
  }

  if (!dayjs(rawDate).isValid()) {
    return {}
  }
  const date = formatByLocale(rawDate, 'L')
  const time = formatByLocale(rawDate, 'LT')
  return { date, time }
}

type IsSameDateFn = (timestamp1: any, timestamp2: any, units?: any) => boolean

const isSameDate: IsSameDateFn = (
  timestamp1,
  timestamp2,
  units = 'millisecond'
) => dayjs(timestamp1).isSame(dayjs(timestamp2), units)

type IsOlderThanFn = (date: any, minutes: any) => boolean

const isOlderThan: IsOlderThanFn = (date, minutes) => {
  const now = dayjs()
  const diffMinutes = now.diff(date, 'minutes')
  return diffMinutes > minutes
}

type IsDateWithinDaysRangeFn = (date: string, minutes: number) => boolean

const isDateWithinDaysRange: IsDateWithinDaysRangeFn = (date, days) => {
  const now = dayjs()
  const dayDate = dayjs(date)
  const diffDays = dayDate.diff(now, 'days')
  return diffDays < days
}

type ConvertToDaysObjFn = (date: any) => Dayjs

const convertToDayjsObj: ConvertToDaysObjFn = (date) =>
  dayjs(date).startOf('day')

type GetNextMonthFn = (date: any) => Dayjs

const getNextMonth: GetNextMonthFn = (date) =>
  date.clone().add(1, 'months').startOf('month')

type GetPreviousMonthFn = (date: any) => Dayjs

const getPreviousMonth: GetPreviousMonthFn = (date) =>
  date.clone().subtract(1, 'months').startOf('month')

type IsBeforeTomorrowFn = (date: any) => boolean

const isBeforeTomorrow: IsBeforeTomorrowFn = (date) => {
  const tomorrow = dayjs().add(1, 'days').startOf('day')
  return date < tomorrow
}

type GetTodayFn = () => Dayjs

const getToday: GetTodayFn = () => {
  return dayjs().startOf('day')
}

const getIsToday = (date: any): boolean => dayjs(date).isToday()

const getIsTomorrow = (date: any): boolean => dayjs(date).isTomorrow()

type GetNextDayFn = (date: any) => Dayjs

const getNextDay: GetNextDayFn = (date) =>
  date.clone().add(1, 'days').startOf('day')

type GetCalendarStartEndDateFn = (date: Dayjs) => {
  calendarStart: Dayjs
  calendarEnd: Dayjs
}

const getCalendarStartEndDate: GetCalendarStartEndDateFn = (date) => {
  const calendarStart = date
    .clone()
    .startOf('month')
    .subtract(1, 'weeks')
    .endOf('week')
    .add(1, 'days')
    .startOf('day')
  const calendarEnd = date
    .clone()
    .endOf('month')
    .add(1, 'weeks')
    .startOf('week')
    .subtract(1, 'days')
    .endOf('day')
  return {
    calendarStart,
    calendarEnd,
  }
}

const monthsNameWebarchive = [
  'januar',
  'februar',
  'maerz',
  'april',
  'mai',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'dezember',
]

type GetFormattedDateWebarchiveFn = (date: any) => string

const getFormattedDateWebarchive: GetFormattedDateWebarchiveFn = (date) => {
  return `${formatByLocale(date, 'DD')}_${
    monthsNameWebarchive[date.month()]
  }_${formatByLocale(date, 'YYYY')}`
}

export interface FormattedDate {
  date: string
  time: string
  dateOrTime: string
  sameDateTime: string | null
}

type FormatDateFn = (
  rawDate?: any,
  inputDateFormat?: string | null
) => '' | FormattedDate

const formatDate: FormatDateFn = (rawDate, inputDateFormat) => {
  if (!rawDate) {
    return ''
  }

  if (!inputDateFormat) {
    inputDateFormat = dateFormat
  }

  const parsedDate = dayjs(rawDate, inputDateFormat)
  if (!parsedDate.isValid()) {
    return ''
  }

  const now = dayjs()
  const diffMinutes = now.diff(parsedDate, 'minutes')
  const date = formatByLocale(parsedDate, 'L')
  let time = formatByLocale(parsedDate, 'LT')

  if (diffMinutes <= 1) {
    // If under a minute: 'one minute ago'
    time = translate('lessThanMinute')
  } else if (now.diff(parsedDate, 'hours') < 1) {
    // If under an hour 'xx minutes ago'
    time = translate('lessThanNMinutes', { diffMinutes: `${diffMinutes}` })
  }
  const sameDate = isSameDate(now, parsedDate, 'day')
  const dateOrTime = sameDate ? time : `${date}, ${time}`
  const sameDateTime = sameDate ? time : null

  return { date, time, dateOrTime, sameDateTime }
}

export {
  dayjs,
  Dayjs,
  formatDate,
  formatDateSimple,
  formatByLocale,
  isSameDate,
  isOlderThan,
  isDateWithinDaysRange,
  getIsToday,
  getIsTomorrow,
  getCalendarStartEndDate,
  isBeforeTomorrow,
  getNextDay,
  getNextMonth,
  getPreviousMonth,
  getToday,
  convertToDayjsObj,
  getFormattedDateWebarchive,
}
