import { FunctionComponent, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import Blink, { BlinkProps } from '@components/Blink'
import {
  getColorVariantsMapForPrimaryCTAButton,
  PrimaryCTAButtonProps,
  primaryCTAButtonStyles,
  StyledPrimaryCTAButtonType,
} from './PrimaryCTAButton'
import {
  CommonCTAProps,
  commonCTAStyles,
  TextWithIconWrapper,
} from './CommonCTA'

export type PrimaryCTABlinkProps = CommonCTAProps &
  BlinkProps & {
    colorVariant?: PrimaryCTAButtonProps['colorVariant']
  }

const StyledPrimaryCTABlink = styled(Blink)<StyledPrimaryCTAButtonType>`
  ${commonCTAStyles}
  ${primaryCTAButtonStyles}
`

const PrimaryCTABlink: FunctionComponent<PrimaryCTABlinkProps> = ({
  colorVariant = 'red',
  size = 'large',
  disabled = false,
  iconName,
  iconLeft = false,
  className,
  children,
  ...BlinkProps
}) => {
  const theme = useTheme()

  const buttonStateColors = useMemo(
    () => getColorVariantsMapForPrimaryCTAButton(theme.colors)[colorVariant],
    [theme.colors, colorVariant]
  )

  return (
    <StyledPrimaryCTABlink
      className={className}
      size={size}
      disabled={disabled}
      buttonStateColors={buttonStateColors}
      iconLeft={iconLeft}
      {...(BlinkProps satisfies BlinkProps)}>
      {iconName ? (
        <TextWithIconWrapper iconName={iconName} size={size}>
          {children}
        </TextWithIconWrapper>
      ) : (
        children
      )}
    </StyledPrimaryCTABlink>
  )
}

export default PrimaryCTABlink
