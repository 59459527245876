import { FunctionComponent, useCallback, useRef, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'

import SectionHeader from '@components/SectionHeader'
import Swiper, { ArrowsVisibilityType, SwiperClass } from '@components/Swiper'

import Tile from '@components/RelatedMatches/Tile'
import RelatedMatchesWrapper from '@components/RelatedMatches/Wrapper'
import NavigationArrows from '@components/RelatedMatches/NavigationArrows'
import useEntityRelatedMatches, {
  EntityRelatedMatchesAPIResponse,
  AllowedTypeOfSport,
} from '@hooks/useEntityRelatedMatches'
import { mobileCSS } from '@measures/responsive'
import MessageBox from '@components/RelatedMatches/MessageBox'
import translate from '@i18n'
import { SvgIconName } from '@components/SvgIcon/types'

export interface EntityRelatedMatchesWidgetApiProps {
  title?: string
  accentColor?: string
  seasonId: string
  typeOfSport: AllowedTypeOfSport
}

export type EntityRelatedMatches =
  EntityRelatedMatchesAPIResponse['items'][number]

const StyledSwiperWrapper = styled.div`
  height: 143px;
  display: grid;
`

const StyledSwiper = styled(Swiper)`
  ${({
    theme: {
      measures: { outerPadding },
    },
  }) => css`
    ${mobileCSS(css`
      margin-right: -${outerPadding.mobile}; // + stretch to right for mobile
      width: auto;

      > .swiper-items-container > *:last-child {
        margin-right: ${outerPadding.mobile};
        scroll-margin-right: ${outerPadding.mobile};
      }
    `)}
  `}
`

const StyledSectionHeader = styled(SectionHeader)`
  min-height: 32px;
`

const EntityRelatedMatches: FunctionComponent<
  EntityRelatedMatchesWidgetApiProps
> = ({ title, accentColor, seasonId, typeOfSport }) => {
  const theme = useTheme()

  const { data, error, isSuccess, isEnabled } = useEntityRelatedMatches({
    seasonId,
    typeOfSport,
  })

  const items = data?.items
  const isDataEmpty = isSuccess && !items?.length

  const errorMessage = error
    ? {
        iconName: 'user-robot-xmarks-solid' as SvgIconName,
        title: translate('relatedMatches.error.title'),
        text: translate('relatedMatches.error.text'),
      }
    : isDataEmpty
      ? {
          iconName: 'calendar-clock' as SvgIconName,
          title: translate('relatedMatches.emptyData.title'),
          text: translate('relatedMatches.emptyData.text'),
        }
      : undefined

  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>()

  const onInit = useCallback(
    (swiper: SwiperClass) => {
      swiperRef.current = swiper
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  if (!title || !isEnabled) {
    return null
  }

  return (
    <RelatedMatchesWrapper>
      <StyledSectionHeader
        title={title}
        accentColor={accentColor ?? theme.color.secondary.green}
        isSubheader={true}>
        <NavigationArrows
          swiperRef={swiperRef}
          arrowsVisibility={arrowsVisibility}
          itemsCount={items?.length ?? 0}
          amountOfTilesToShow={3}
        />
      </StyledSectionHeader>
      <StyledSwiperWrapper>
        {errorMessage ? (
          <MessageBox
            iconName={errorMessage.iconName}
            title={errorMessage.title}
            text={errorMessage.text}
          />
        ) : (
          <StyledSwiper
            spaceBetween={8}
            spaceTopBottom={0}
            slidesPerGroup={1}
            onInit={onInit}
            onArrowsVisibility={onArrowsVisibility}
            slides={items?.map((item) => (
              <Tile
                key={`${item.typeOfSport}-${item.matchId}`}
                matchId={item.matchId}
                typeOfSport={item.typeOfSport}
                link={item.link}
              />
            ))}
          />
        )}
      </StyledSwiperWrapper>
    </RelatedMatchesWrapper>
  )
}

const widget = {
  kind: ['entity-related-matches'],
  component: EntityRelatedMatches,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
