import usePageMetadata from '@hooks/usePageMetadata'
import { FunctionComponent } from 'react'
import { generateInitialSchema } from '@components/Schema/utils'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const SchemaInjector: FunctionComponent = () => {
  const pageMetadata = usePageMetadata()

  return (
    <>
      {Object.entries(generateInitialSchema(pageMetadata))
        .filter(([, schemaValue]) => !!schemaValue)
        .map(([key, value]) => (
          <SchemaHTMLElement key={key} scriptKey={key} schemaObject={value} />
        ))}
    </>
  )
}

export default SchemaInjector
