import SvgIcon from '@components/SvgIcon'
import useChatbot from '@hooks/useChatbot'
import translate from '@i18n'
import {
  ChangeEvent,
  FormEventHandler,
  FunctionComponent,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

const MAX_TEXTAREA_HEIGHT = 90
const MIN_TEXTAREA_HEIGHT = 42

const StyledChatbotForm = styled.form`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    position: relative;
    width: 100%;
    display: flex;
    gap: ${spacing16};
    align-items: flex-end;
  `}
`

const StyledInputWrapper = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey400 },
      },
      spacing: { spacing16, spacing24 },
    },
  }) => css`
    display: flex;
    align-items: center;
    border: 1px solid ${grey400};
    border-radius: ${spacing24};
    padding: 0 ${spacing16} 0 ${spacing24};
    min-height: calc(48px - 2px);
    flex: 1;
    background-color: white;
  `}
`

const StyledTextArea = styled.textarea`
  ${({
    theme: {
      typography: {
        bodycopy: {
          small2: { bundledCSS: small2BodyCopyCSS },
        },
      },
      color: {
        primary: { primary02 },
      },
      spacing: { spacing8 },
    },
  }) => css`
    height: 32px;
    width: 100%;
    border: 0;
    padding: 0;
    background: ${primary02};
    box-sizing: border-box;
    resize: none;
    border-radius: 5px;
    ${small2BodyCopyCSS}
    padding: ${spacing8} 0 ${spacing8} 0;
    margin: 0;
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 transparent;
    &::placeholder {
      ${small2BodyCopyCSS}
    }
    &:focus {
      outline: none;
    }
  `}
`

const StyledSubmitButton = styled.button`
  ${({
    theme: {
      color: {
        primary: { blickRed },
        secondary: { darkRed },
      },
    },
  }) => css`
    background: ${blickRed};
    border: 0;
    cursor: pointer;
    height: 48px;
    width: 48px;
    min-width: 48px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: ${darkRed};
    }

    &:disabled {
      pointer-events: none;
    }
  `}
`

const ChatbotInput: FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  const {
    onQuestionSubmit,
    chatbotAnswerLoading,
    chatbotInitialLoading,
    chatbotConversationId,
  } = useChatbot()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const submitRef = useRef<HTMLFormElement>(null)
  const [value, setValue] = useState('')

  const onInputValueChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setValue(event.target.value)

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    const question = textAreaRef?.current?.value
    if (question) {
      onQuestionSubmit(question, chatbotConversationId)
      if (textAreaRef.current) {
        setValue('')
      }
    }
  }

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `${MIN_TEXTAREA_HEIGHT}px`
      const scrollHeight = textAreaRef.current.scrollHeight
      textAreaRef.current.style.height = `${Math.min(Math.max(scrollHeight, MIN_TEXTAREA_HEIGHT), MAX_TEXTAREA_HEIGHT)}px`
    }
  }, [value])

  const onTextAreaKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    if (chatbotAnswerLoading || chatbotInitialLoading) {
      return
    }

    if (
      (event?.key === 'Enter' && !event.shiftKey) ||
      (event?.keyCode === 13 && !event.shiftKey)
    ) {
      event.preventDefault()
      submitRef.current?.requestSubmit()
    }
  }

  return (
    <StyledChatbotForm
      ref={submitRef}
      onSubmit={onSubmit}
      className={className}>
      <StyledInputWrapper>
        <StyledTextArea
          placeholder={translate('chatbot.footer.inputPlaceholder')}
          ref={textAreaRef}
          value={value}
          onChange={onInputValueChange}
          onKeyDown={onTextAreaKeyDown}
        />
      </StyledInputWrapper>
      <StyledSubmitButton
        type="submit"
        disabled={chatbotAnswerLoading || chatbotInitialLoading}>
        <SvgIcon iconName="chatbot-paper-plane-stars" size="24px" />
      </StyledSubmitButton>
    </StyledChatbotForm>
  )
}

export default ChatbotInput
