import { useQueryClient } from '@tanstack/react-query'
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import {
  ChatbotLastQuestion,
  ChatbotMessage,
  ChatbotQuestionData,
  ChatbotReloadQuestion,
} from './types'
import useChatbotVisible from '@hooks/useChatbot/useChatbotVisible'
import useChatbotLastQuestion from '@hooks/useChatbot/useChatbotLastQuestion'
import translate from '@i18n'
import useTracking from '@hooks/useTracking'
import { enrichQuestions, trackHandler } from './utils'
import { TLinkData, WidgetIdValues } from '@utils/chatbot/types'
import { getChat, getAnswer, getQuestions } from '@utils/chatbot'
import useChatbotContext from '@hooks/useChatbot/useChatbotContext'
import useIsCUIEnabled from '@hooks/useIsCUIEnabled'
import {
  CHATBOT_RELOAD_QUESTION_KEY,
  useChatbotReloadQuestion,
} from '@hooks/useChatbot/useChatbotReloadQuestion'
import useURLParam from '@hooks/useURLParam'
import config from '@config'

const {
  abTest: { windowKey },
} = config

const ChatbotManager: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const chatbotVisible = useChatbotVisible()
  const lastQuestion = useChatbotLastQuestion()
  const reloadQuestion = useChatbotReloadQuestion()
  const handleTracking = useTracking(trackHandler)
  const isCUIEnabled = useIsCUIEnabled()
  const chatbotContext = useChatbotContext()
  const cuiUrlParam = useURLParam('cui') === 'true'

  useEffect(() => {
    if (cuiUrlParam) {
      if (window[windowKey]['showCUI'] !== 'show') {
        window[windowKey]['showCUI'] = 'show'
      }
    }
  }, [cuiUrlParam])

  const setNewMessage = useCallback(
    (newMessage: ChatbotMessage) => {
      queryClient.setQueryData(
        ['chatbotMessages'],
        (prevData: ChatbotMessage[]) => {
          const index = (prevData ?? []).findIndex(
            (message) => message.id === newMessage.id
          )
          if (index < 0) {
            return prevData ? [...prevData, newMessage] : [newMessage]
          }

          return prevData.map((item, i) => (i === index ? newMessage : item))
        }
      )
      queryClient.invalidateQueries({
        queryKey: ['chatbotMessages'],
      })
    },
    [queryClient]
  )

  const setInitialQuestionLoadingState = useCallback(
    (isLoading: boolean) => {
      queryClient.setQueryData(['chatbotInitialLoading'], isLoading)
      queryClient.invalidateQueries({
        queryKey: ['chatbotInitialLoading'],
      })
    },
    [queryClient]
  )

  const getInitialSummary = useCallback(async () => {
    const { questions } = await getQuestions({
      widgetId: WidgetIdValues.SectionSummary,
      context: 'home',
    })()

    if (questions.length === 0) {
      throw new Error('No data found')
    }

    const questionId = questions[0].id

    const { links } = await getAnswer({
      widgetId: WidgetIdValues.SectionSummary,
      context: 'home',
      questionId: questionId,
    })()

    queryClient.setQueryData<TLinkData>(['chatbotInitialLinks'], links)
    queryClient.invalidateQueries({
      queryKey: ['chatbotInitialLinks'],
    })
  }, [queryClient])

  const getInitialQuestions = useCallback(async () => {
    const result = await getQuestions({
      widgetId: WidgetIdValues.QuestionWidget,
      context: chatbotContext,
    })()

    if (result) {
      const { questions } = result
      const enrichedQuestions: ChatbotQuestionData[] = enrichQuestions(
        questions,
        true,
        chatbotContext
      )
      queryClient.setQueryData<ChatbotQuestionData[]>(
        ['chatbotInitialQuestions'],
        enrichedQuestions
      )
      queryClient.invalidateQueries({
        queryKey: ['chatbotInitialQuestions'],
      })
    }
  }, [queryClient, chatbotContext])

  const getInitialData = useCallback(async () => {
    try {
      setInitialQuestionLoadingState(true)
      await getInitialQuestions()
      await getInitialSummary()
      setInitialQuestionLoadingState(false)
    } catch {
      setInitialQuestionLoadingState(false)
    }
  }, [getInitialQuestions, getInitialSummary, setInitialQuestionLoadingState])

  const addErrorMessageIntoMessages = useCallback(
    (answerId: string) => {
      setNewMessage({
        type: 'answerError',
        content: [
          {
            category: translate('chatbot.answerMessage.errorTitle'),
            text: translate('chatbot.answerMessage.error'),
          },
        ],
        id: answerId,
      })
    },
    [setNewMessage]
  )

  const getQuestionAnswer = useMemo(
    () => async (question: ChatbotLastQuestion | ChatbotReloadQuestion) => {
      setNewMessage({
        type: 'question',
        text: question.data.text,
        id: question.data.id,
        preventTyping: !!question.preventTyping,
      })
      queryClient.setQueryData(['chatbotAnswerLoading'], true)
      queryClient.invalidateQueries({
        queryKey: ['chatbotAnswerLoading'],
      })
      const answerId =
        'chatbotAnswerId' in question && question.chatbotAnswerId
          ? question.chatbotAnswerId
          : `user-answer-${new Date().getTime()}`
      try {
        const answerResult = question.data.isCached
          ? await getAnswer({
              widgetId: WidgetIdValues.QuestionWidget,
              questionId: question.data.id,
              context: question.data.context,
            })()
          : await getChat({
              text: question.data.text,
              isSuggestedQuestion: question.data.isSuggestedQuestion,
              context: question.data.context,
              conversationId: question.chatbotConversationId,
            })()

        if (answerResult) {
          const { content, links, traceId, spanId, conversationId, intent } =
            answerResult

          queryClient.setQueryData(['chatbotConversationId'], conversationId)
          queryClient.invalidateQueries({
            queryKey: ['chatbotConversationId'],
          })

          handleTracking({
            event: question.data.isCached
              ? 'chatbot_first_question'
              : 'chatbot_select_question',
            messageId: traceId,
            conversationId,
            isSuggestedQuestion: question.data.isSuggestedQuestion,
            isCached: question.data.isCached,
            questionAnswered: null,
          })

          setNewMessage({
            type: 'answer',
            content,
            links,
            traceId,
            spanId,
            conversationId,
            questionData: question.data,
            id: answerId,
            intent: intent,
            preventTyping: !!question.preventTyping,
          })
        } else {
          addErrorMessageIntoMessages(answerId)
        }
      } catch {
        addErrorMessageIntoMessages(answerId)
      } finally {
        queryClient.setQueryData(['chatbotAnswerLoading'], false)
        queryClient.invalidateQueries({
          queryKey: ['chatbotAnswerLoading'],
        })
      }
    },
    [queryClient, setNewMessage, handleTracking, addErrorMessageIntoMessages]
  )

  useEffect(() => {
    if (
      isCUIEnabled &&
      !lastQuestion &&
      chatbotVisible &&
      !process.env.STORYBOOK
    ) {
      getInitialData()
    }
  }, [getInitialData, chatbotVisible, lastQuestion, isCUIEnabled])

  useEffect(() => {
    if (isCUIEnabled && lastQuestion && !process.env.STORYBOOK) {
      getQuestionAnswer(lastQuestion)
    }
  }, [lastQuestion, getQuestionAnswer, isCUIEnabled])
  useEffect(() => {
    if (isCUIEnabled && reloadQuestion && !process.env.STORYBOOK) {
      getQuestionAnswer(reloadQuestion)
      queryClient.setQueryData([CHATBOT_RELOAD_QUESTION_KEY], null)
      queryClient.invalidateQueries({
        queryKey: [CHATBOT_RELOAD_QUESTION_KEY],
      })
    }
  }, [getQuestionAnswer, isCUIEnabled, reloadQuestion, queryClient])

  useEffect(() => {
    queryClient.setQueryData(['chatbotConversationId'], null)
    queryClient.invalidateQueries({
      queryKey: ['chatbotConversationId'],
    })
  }, [chatbotContext, queryClient])

  return null
}

export default ChatbotManager
