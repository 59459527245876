import styled, { css } from 'styled-components'
import { FunctionComponent } from 'react'
import { ItemSuperUserIndicatorProps } from './types'

const SuperUserFlag = styled.div`
  ${({
    theme: {
      spacing: { spacing4, spacing8 },
      color: {
        primary: { blickRed, primary02 },
      },
      typography: {
        catchword: {
          small2: { bundledCSS: catchwordSmall2CSS },
        },
      },
    },
  }) => css`
    background-color: ${blickRed};
    color: ${primary02};
    width: fit-content;
    padding: ${spacing4};

    text-transform: uppercase;
    ${catchwordSmall2CSS};
    margin-bottom: ${spacing8};
  `}
`

const ItemSuperUserIndicator: FunctionComponent<
  ItemSuperUserIndicatorProps
> = ({ userTitle }) => <SuperUserFlag>{userTitle}</SuperUserFlag>

export default ItemSuperUserIndicator
