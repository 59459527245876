//* https://cdn.ringier-advertising.ch/doc/docs/tagman-api.html

import config from '@config'
import usePageMetadata from '@hooks/usePageMetadata'
import useRiAdTagManagerInit from '@hooks/useRiAdTagManagerInit'
import { getCurrentViewportType } from '@measures/responsive'
import {
  areAdSlotsLoaded,
  debouncedLoadSlots,
  deepCopy,
  getPageType,
  isLastHighPriorityPlacement,
  loadSlots,
  logAd,
  showSlot,
} from '@utils/ads'
import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import styled from 'styled-components'
import Wrapper from './Wrapper'
import { PlainAdEvents, PlainAdOptions } from './types'
import { registerSlot, updateADMClass } from './utils'
import { useQueryClient } from '@tanstack/react-query'
import { AdRingierProps } from '@widgets/AdRingier'

export interface Targeting {
  articleid?: string
  articleid_fr?: string
  subsectionid: string[]
  pagetype: 'article' | 'overview'
  syndication?: string
}

export interface AdProps extends AdRingierProps {
  onAdLoaded?: Required<PlainAdEvents>['adLoaded']
  onAdEmpty?: Required<PlainAdEvents>['adEmpty']
  onAdError?: Required<PlainAdEvents>['adError']
}

const {
  ads: {
    sdk: { articleIdKeyName: adSDKArticleIdKeyName },
  },
} = config

const StyledAdContainer = styled.div``

const Ad: FunctionComponent<AdProps> = ({
  placement,
  isAdDisabled,
  slotCodeDesktop,
  slotCodeTablet,
  slotCodeMobile,
  containerId,
  autoLoadSlot,
  className,
  onAdLoaded,
  onAdEmpty,
  onAdError,
}) => {
  const previousTagManagerInitCodeRef = useRef<string>('')
  const wrapperRef = useRef<HTMLDivElement>(null)
  const queryClient = useQueryClient()
  const tagManagerInitCode = useRiAdTagManagerInit()
  const pageMetadata = usePageMetadata()
  const {
    syndication,
    targetContentType: contentType,
    type,
    id,
    sectionIds,
  } = pageMetadata

  const getCurrentSlot = useCallback(() => {
    const currentViewportType = getCurrentViewportType()
    switch (currentViewportType) {
      case 'desktop': {
        return slotCodeDesktop
      }

      case 'tablet': {
        return slotCodeTablet
      }

      case 'mobile': {
        return slotCodeMobile
      }

      default: {
        return ''
      }
    }
  }, [slotCodeDesktop, slotCodeTablet, slotCodeMobile])

  const onLoaded = useCallback<Required<PlainAdEvents>['adLoaded']>(
    (...args) => {
      updateADMClass('next-adm-loaded', wrapperRef)
      onAdLoaded?.(...args)
    },
    [onAdLoaded]
  )

  const onEmpty = useCallback<Required<PlainAdEvents>['adEmpty']>(
    (...args) => {
      updateADMClass('next-adm-empty', wrapperRef)
      onAdEmpty?.(...args)
    },
    [onAdEmpty]
  )

  const onError = useCallback<Required<PlainAdEvents>['adError']>(
    (...args) => {
      updateADMClass('next-adm-error', wrapperRef)
      onAdError?.(...args)
    },
    [onAdError]
  )

  const container = useMemo(
    () =>
      `ad-placement-${placement}${containerId ? `-${containerId}` : ''}-${tagManagerInitCode}`,
    [containerId, placement, tagManagerInitCode]
  )

  const registrationOptions = useMemo<Omit<PlainAdOptions, 'slot'>>(
    () => ({
      container,
      targeting: {
        placement,
        pagetype: getPageType(type),
        ...(id ? { [adSDKArticleIdKeyName]: id } : {}),
        ...(contentType ? { contenttype: contentType } : {}),
        ...(syndication ? { syndication } : {}),
        subsectionid: sectionIds,
      },
      events: {
        adLoaded: onLoaded,
        adEmpty: onEmpty,
        adError: onError,
      },
    }),
    [
      container,
      contentType,
      id,
      onEmpty,
      onError,
      onLoaded,
      placement,
      sectionIds,
      syndication,
      type,
    ]
  )

  useEffect(() => {
    if (!tagManagerInitCode) {
      return
    }

    if (tagManagerInitCode === previousTagManagerInitCodeRef.current) {
      return
    }

    previousTagManagerInitCodeRef.current = tagManagerInitCode

    //! If this component mounts due to a change in viewport, and
    //! the current viewport initialized is not supporting the slot,
    //! we need to not do anything until the new init() with the right viewport.
    if (!tagManagerInitCode.startsWith(getCurrentViewportType())) {
      return
    }

    const slot = getCurrentSlot()

    if (!slot) {
      return
    }

    const deepCopiedRegistrationOptions = deepCopy(registrationOptions)

    registerSlot({ ...registrationOptions, slot })

    const singleLogAd = (loadingBehaviorDescription?: string) => {
      logAd(
        { queryClient },
        `Slot Registered: %c${slot}\n${' '.repeat(11)}%cinitCode:${' '.repeat(8)}%c${tagManagerInitCode}\n${' '.repeat(11)}%cplacement:${' '.repeat(7)}%c${placement}\n${' '.repeat(11)}%ccontainer:${' '.repeat(7)}%c${container}\n${' '.repeat(11)}%cloading state:${' '.repeat(3)}%c${loadingBehaviorDescription}\n${' '.repeat(11)}%cconfig:${' '.repeat(8)}`,
        'font-weight: bold;',
        '',
        'font-weight: bold;',
        '',
        'font-weight: bold;',
        '',
        'font-weight: bold;',
        '',
        'font-weight: bold;',
        '',
        { ...deepCopiedRegistrationOptions, slot }
      )
    }

    if (autoLoadSlot) {
      //! Some ads have to be loaded & shown manually,
      //! because they are part of galleries etc, where
      //! the ad is not shown until the user performs
      //! an action (e.g. sliding to the ad slide).
      loadSlots(queryClient)
      showSlot(container)
      singleLogAd(`loaded and shown manually because of "autoLoadSlot"`)
    } else if (!autoLoadSlot && areAdSlotsLoaded(queryClient)) {
      debouncedLoadSlots(queryClient)
      singleLogAd(`loaded manually, as batch loading has already happened`)
    } else if (!autoLoadSlot && isLastHighPriorityPlacement(placement)) {
      loadSlots(queryClient)
      singleLogAd(
        `loaded manually, as it is the last high priority placement in the page`
      )
      logAd(
        { queryClient },
        `%cLoadAds called\n${' '.repeat(11)}%cSlots that were registered until this point will now be batch loaded.\n${' '.repeat(11)}%cAds registered after this point in time will have to be loaded manually.`,
        'font-weight: bold; color: #FFA500;',
        '',
        'font-weight: bold; color: #FFA500;'
      )
    } else {
      singleLogAd(`not loaded yet, as batch loading is pending`)
    }
  }, [
    autoLoadSlot,
    container,
    getCurrentSlot,
    placement,
    queryClient,
    registrationOptions,
    tagManagerInitCode,
  ])

  const unmountAd =
    isAdDisabled === true || (!getCurrentSlot() && tagManagerInitCode)

  useEffect(() => {
    if (unmountAd) {
      updateADMClass('', wrapperRef)
    }
  }, [unmountAd])

  const generatedSlotCodeClassNames = useMemo(
    () =>
      Object.entries({
        'slot-code-desktop': slotCodeDesktop ?? '',
        'slot-code-tablet': slotCodeTablet ?? '',
        'slot-code-mobile': slotCodeMobile ?? '',
      }).reduce(
        (acc, [platformKey, platformValue]) =>
          platformValue ? `${acc} ${platformKey}-${platformValue}` : acc,
        ''
      ),
    [slotCodeDesktop, slotCodeMobile, slotCodeTablet]
  )

  return (
    <Wrapper
      ref={wrapperRef}
      slotCodeDesktop={slotCodeDesktop}
      slotCodeTablet={slotCodeTablet}
      slotCodeMobile={slotCodeMobile}
      className={`${className ? `${className} ` : ''}${generatedSlotCodeClassNames}`}>
      {unmountAd ? null : (
        <StyledAdContainer
          key={`${getCurrentSlot()}-${getCurrentViewportType()}`}
          id={container}
        />
      )}
    </Wrapper>
  )
}

const MemoizedAd = memo(Ad)

MemoizedAd.displayName = 'MemoizedAd'

export default MemoizedAd

export { StyledAdContainer }
