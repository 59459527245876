//! WARNING: GlobalStyle has to be imported first, so styled-components places it as a first css definition inside the <style /> tag.
import GlobalStyle from '@components/GlobalStyle'
import dynamic from 'next/dynamic'
import { FunctionComponent, ReactNode } from 'react'
import {
  DehydratedState,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ThemeProvider } from 'styled-components'
import { DefaultTheme } from '@themes'
import { Hydrate } from '@components/Hydrate'
import ThemeColorMetaTag from '@components/MetaTags/ThemeColorMetaTag'
import CustomStyleSheetManager from '@components/CustomStyleSheetManager'
import ErrorPageContext from '@contexts/errorPageContext'
import { FontFacesWeb } from 'helpers/fonts'
import Head from 'next/head'
import config from '@config'

import apple180_180 from '@assets/icons/apple/apple-touch-icon-180x180.png'
import appleFr180_180 from '@assets/icons/apple/apple-touch-icon-fr-180x180.png'

import favicon from '@assets/images/favicon.png'
import faviconFr from '@assets/images/favicon-fr.png'

const {
  publication: { publication },
} = config

export interface AppWrapperProps {
  queryClient: QueryClient
  dehydratedQueryStateWithAdjustedTimestamp: DehydratedState | undefined
  isErrorPage: boolean
  preCSSComponents: ReactNode
  children: ReactNode
  isStorybook?: boolean
}

const TanstackQueryDevtools = dynamic(
  () => import('@components/TanstackQueryDevtools'),
  { ssr: false }
)

const AppWrapper: FunctionComponent<AppWrapperProps> = ({
  queryClient,
  dehydratedQueryStateWithAdjustedTimestamp,
  isErrorPage,
  preCSSComponents,
  children,
  isStorybook,
}) => (
  <ErrorPageContext.Provider value={isErrorPage}>
    {!isStorybook && (
      <Head>
        {/*
          //! NOTE: It is very important that we import the font faces here in _app
          //! and not in _document, otherwise they will not be a part of the client bundle.
          //! This will result in fonts failing to load from the proper path.
         */}
        <style
          key="font-faces"
          dangerouslySetInnerHTML={{
            __html: FontFacesWeb,
          }}
        />
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0"
        />
        <link
          key="apple-touch-icon"
          rel="apple-touch-icon"
          href={publication === 'romandie' ? appleFr180_180 : apple180_180}
        />
        <link
          key="icon"
          rel="shortcut icon"
          type="image/png"
          sizes="192x192"
          href={publication === 'romandie' ? faviconFr : favicon}
        />
      </Head>
    )}
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedQueryStateWithAdjustedTimestamp}>
        {preCSSComponents}
        <CustomStyleSheetManager>
          <ThemeProvider theme={DefaultTheme}>
            <ThemeColorMetaTag />
            <GlobalStyle />
            {children}
          </ThemeProvider>
        </CustomStyleSheetManager>
      </Hydrate>
      {(!isStorybook ||
        process.env.NEXT_PUBLIC_STORYBOOK_DEVTOOLS === 'true') && (
        <TanstackQueryDevtools />
      )}
    </QueryClientProvider>
  </ErrorPageContext.Provider>
)

export default AppWrapper
