import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { LeadImageProps, TextWrapperProps, TitleProps } from './types'
import { desktopCSS } from '@measures/responsive'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { IMAGE_RATIOS, IMAGE_WIDTHS } from './utils'
import Credit from '@widgets/LeadImage/Credit'

const Wrapper = styled.div`
  position: relative;
`

type StyledTextWrapperProps = Pick<TextWrapperProps, 'isWithImage'>

const TextWrapper = styled.div<StyledTextWrapperProps>`
  ${({
    theme: {
      spacing: { spacing24, spacing8, spacing32 },
    },
    isWithImage,
  }) => css`
    width: 100%;
    box-sizing: border-box;
    padding: ${spacing24} 0 ${spacing8} 0;
    ${desktopCSS(css`
      padding-bottom: ${spacing32};
      ${isWithImage &&
      css`
        position: absolute;
        left: 0;
        bottom: 0;
        padding: ${spacing32};
      `};
    `)}
  `}
`

type StyledHeadingProps = Pick<TitleProps, 'isWithImage'>

const Heading = styled.div<StyledHeadingProps>`
  ${({
    theme: {
      color: {
        primary: { primary02 },
        tertiary: { grey700 },
      },
      typography: {
        catchword: {
          large1: { bundledCSS: catchwordLarge1 },
        },
      },
      spacing: { spacing8 },
    },
    isWithImage,
  }) => css`
    color: ${grey700};
    margin-bottom: ${spacing8};
    ${catchwordLarge1};

    ${desktopCSS(css`
      color: ${isWithImage ? primary02 : grey700};
    `)}
    text-transform: uppercase;
  `}
`

type StyledTitleProps = Pick<TitleProps, 'isWithImage'>

const Title = styled.h2<StyledTitleProps>`
  ${({
    isWithImage,
    theme: {
      color: {
        primary: { primary01, primary02 },
      },
      typography: {
        headings: {
          xlarge: { bundledCSS: xlargeHeadings },
          xxxlarge: { bundledCSS: xxxlargeHeadings },
        },
      },
    },
  }) => css`
    margin: 0;
    display: block;
    ${xlargeHeadings};

    ${desktopCSS(css`
      color: ${isWithImage ? primary02 : primary01};
      ${xxxlargeHeadings};
    `)}
  `}
`

const LeadImage: FunctionComponent<LeadImageProps> = ({
  image,
  credit,
  title,
  catchword,
}) => (
  <Wrapper>
    {image ? (
      <ResponsiveImageV2
        src={image.src ?? ''}
        alt={image.alt ?? ''}
        ratios={IMAGE_RATIOS}
        crops={image.crops ?? {}}
        widths={IMAGE_WIDTHS}
        loading="eager"
      />
    ) : null}
    {!!credit && <Credit {...credit} />}
    {(!!catchword || !!title) && (
      <TextWrapper isWithImage={!!image}>
        {!!catchword && <Heading isWithImage={!!image}>{catchword}</Heading>}
        {!!title && <Title isWithImage={!!image}>{title}</Title>}
      </TextWrapper>
    )}
  </Wrapper>
)

const widget = {
  kind: ['lead-image'],
  component: LeadImage,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
