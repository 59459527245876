import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { ProcessedPost } from '@hooks/useCueLiveTeaser/types'
import { desktopCSS } from '@measures/responsive'

export interface NewsTickerPostProps {
  className?: string
  dateInfo: ProcessedPost['dateInfo']
  children: ProcessedPost['text']
  isLastChildMobile: boolean
}

const StyledNewsTickerPost = styled.div`
  position: relative;
`

const StyledLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: 4px;
  width: 8px;
  height: 100%;

  ${desktopCSS(css`
    flex-direction: row;
    top: 0;
    width: 100%;
    height: 14px;
  `)}
`

const StyledDot = styled.div`
  ${({
    theme: {
      colors: { staticRed400 },
    },
  }) => css`
    background-color: ${staticRed400};
    width: 8px;
    height: 8px;
    border-radius: 50%;
  `};
`

const StyledLine = styled.div`
  ${({
    theme: {
      colors: { staticRed400 },
    },
  }) => css`
    flex: 1;
    background-color: ${staticRed400};

    height: 2px;
    width: 2px;
  `};
`

const StyledTextWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    position: relative;
    overflow: hidden;
    margin-left: ${spacing24};

    ${desktopCSS(css`
      margin-left: 0;
    `)}
  `};
`

const StyledPostDate = styled.div`
  ${({
    theme: {
      typography: {
        subheads: {
          xsmall1: { bundledCSS: xsmall1SubheadsCSS },
        },
      },
      colors: { staticRed400, staticBlack },
      spacing: { spacing4, spacing8 },
    },
  }) => css`
    ${xsmall1SubheadsCSS};
    color: ${staticRed400};

    ${desktopCSS(css`
      display: inline-block;
      vertical-align: top;

      margin-left: ${spacing8};
      padding-left: ${spacing4};
      padding-right: ${spacing4};

      background-color: ${staticBlack};
    `)}
  `};
`

const StyledPostText = styled.div<
  Pick<NewsTickerPostProps, 'isLastChildMobile'>
>`
  ${({
    theme: {
      typography: {
        headings: {
          xxsmall: { bundledCSS: xxsmallHeadingsCSS },
          xsmall: { bundledCSS: xsmallHeadingsCSS },
        },
      },
      colors: { staticWhite },
      spacing: { spacing4, spacing16 },
      utils: { ellipsis },
    },
    isLastChildMobile,
  }) => css`
    ${xsmallHeadingsCSS};
    color: ${staticWhite};

    padding-top: ${spacing4};
    margin-bottom: ${isLastChildMobile ? 0 : spacing16};

    ${ellipsis('100%', 2)};

    ${desktopCSS(css`
      ${xxsmallHeadingsCSS};
      padding-right: ${spacing16};
      margin-bottom: 0;
    `)}
  `};
`

const NewsTickerPost: FunctionComponent<NewsTickerPostProps> = ({
  className,
  dateInfo,
  children,
  isLastChildMobile,
}) => {
  return (
    <StyledNewsTickerPost className={className}>
      <StyledLineWrapper>
        <StyledDot />
        <StyledLine />
      </StyledLineWrapper>
      <StyledTextWrapper>
        <StyledPostDate suppressHydrationWarning={true}>
          {dateInfo.dateOrTime}
        </StyledPostDate>
        <StyledPostText isLastChildMobile={isLastChildMobile}>
          {children}
        </StyledPostText>
      </StyledTextWrapper>
    </StyledNewsTickerPost>
  )
}

export default NewsTickerPost
