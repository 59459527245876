import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

const useIsAuthResolved = (): boolean => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()

  const [isAuthResolved, setIsAuthResolved] = useState<boolean>(
    () => queryClient.getQueryData<boolean>(['auth-resolved']) ?? false
  )

  const unsubscribeFnRef = useRef<() => void>(undefined)

  const updateUserData = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'auth-resolved'
      ) {
        setIsAuthResolved(
          queryClient.getQueryData<boolean>(['auth-resolved']) ?? false
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setIsAuthResolved(!!queryClient.getQueryData<boolean>(['auth-resolved']))
    unsubscribeFnRef.current = queryCache.subscribe(updateUserData)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateUserData, queryCache, queryClient])

  return isAuthResolved
}

export default useIsAuthResolved
