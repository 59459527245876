//! WARNING: AppWrapper includes GlobalStyle and has to be imported first, so styled-components places it as a first css definition inside the <style /> tag.
import AppWrapper from '@components/AppWrapper'
import { FunctionComponent, useMemo, useState } from 'react'
import { QueryClient } from '@tanstack/react-query'
import { AppProps } from 'next/app'
import {
  defaultConfig as queryConfig,
  getDehydratedQueryStateWithAdjustedTimestamp,
  disableStructuralSharingForQueries,
} from '@utils/react-query'
import { CatchAllPageServerProps } from './[[...pageUrl]]'
import DefaultPage from '@components/Page/DefaultPage'
import ErrorPage from '@components/Page/ErrorPage'
import BlickPlusOfferPage from '@components/Page/BlickPlusOfferPage'
import NonErrorPagePreCSSComponents from '@components/Page/NonErrorPagePreCSSComponents'
import { initRegistry } from '@utils/registry'
import { useRouter } from 'next/router'

interface MyAppProps extends AppProps {
  pageProps: CatchAllPageServerProps
}

initRegistry()

const MyApp: FunctionComponent<MyAppProps> = ({ Component, pageProps }) => {
  //! Force disable prefetching for all pages (this can be removed when migrating to App Router)
  useRouter().prefetch = () => Promise.resolve()

  const [queryClient] = useState(() => new QueryClient(queryConfig))

  disableStructuralSharingForQueries(queryClient)

  const {
    dehydratedQueryState,
    isError,
    isBlickPlusOfferPage,
    keysThatShouldNotOverwriteClientCacheIfExists,
    ...restOfPageProps
  } = pageProps

  const isErrorPage = isError !== false

  const dehydratedQueryStateWithAdjustedTimestamp = useMemo(
    () =>
      isErrorPage
        ? dehydratedQueryState
        : getDehydratedQueryStateWithAdjustedTimestamp({
            queryClient,
            dehydratedQueryState,
            keysThatShouldNotOverwriteClientCacheIfExists,
          }),
    [
      queryClient,
      isErrorPage,
      dehydratedQueryState,
      keysThatShouldNotOverwriteClientCacheIfExists,
    ]
  )

  const PageComponent = isErrorPage
    ? ErrorPage
    : isBlickPlusOfferPage
      ? BlickPlusOfferPage
      : DefaultPage

  return (
    <AppWrapper
      dehydratedQueryStateWithAdjustedTimestamp={
        dehydratedQueryStateWithAdjustedTimestamp
      }
      queryClient={queryClient}
      isErrorPage={isErrorPage}
      preCSSComponents={
        isErrorPage ? null : (
          <NonErrorPagePreCSSComponents
            isBlickPlusOfferPage={isBlickPlusOfferPage}
          />
        )
      }>
      <PageComponent Component={Component} restOfPageProps={restOfPageProps} />
    </AppWrapper>
  )
}

export default MyApp
