import { FunctionComponent } from 'react'
import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'

const {
  socialembeds: { blueSkyEmbedStaticPageUrl },
} = config

interface BlueSkyComponentProps {
  url: string
}

const BlueSkyComponent: FunctionComponent<BlueSkyComponentProps> = ({
  url,
}) => <EmbeddedContent url={`${blueSkyEmbedStaticPageUrl}${url}`} />

export default BlueSkyComponent
