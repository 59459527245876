import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'

import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { Image, TeaserVideo, TargetContentType, Link } from '@utils/cook/types'

import VideoLabel from '@components/VideoLabel'
import Title from '@components/TeaserBite/Title'
import TeaserImage from '@components/TeaserBite/Image'
import TeaserAnimatedPreview from '@components/TeaserAnimatedPreview'
import useAnimatedPreview from '@hooks/useAnimatedPreview'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import useRefDelegate from '@hooks/useRefDelegate'

interface TeaserBiteVideo extends TeaserVideo {
  title: string
}

export interface TeaserBiteAPIProps {
  accentColor: string
  title: string
  section: {
    name: string
    uniqueName: string
  }
  targetContentType: TargetContentType
  video: TeaserBiteVideo
  articleId: string
  trackTeaser?: boolean
  isPromo?: boolean
  image: Image<'9_16'>
  link?: Link
  updatedDate: {
    value: string
    format: string
  }
  aureusOfferId?: string
}

const StyledTeaserBite = styled.div`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    display: grid;
    grid-row-gap: ${spacing12};

    position: relative;
    cursor: pointer;
  `}
`

const StyledVideoLabel = styled(VideoLabel)`
  ${({
    theme: {
      spacing: { spacing12 },
    },
  }) => css`
    position: absolute;
    z-index: 1;
    bottom: ${spacing12};
    left: ${spacing12};
  `}
`

const TeaserBite: FunctionComponent<TeaserBiteAPIProps> = ({
  image,
  targetContentType,
  link,
  title,
  video,
  articleId,
  trackTeaser,
  isPromo,
}) => {
  const onImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'bite_teaser_impression',
      eventCategory: !!isPromo,
      eventLabel: articleId,
      eventAction: title,
    }),
    [articleId, isPromo, title]
  )

  const onClick = useCallback<TrackingFnType>(
    () => ({
      event: 'bite_teaser_click',
      eventCategory: !!isPromo,
      eventLabel: articleId,
      eventAction: title,
    }),
    [articleId, isPromo, title]
  )

  const trackOnImpression = useTracking(onImpression)
  const trackOnClick = useTracking(onClick)

  const handleClick = useCallback(() => {
    if (trackTeaser) {
      trackOnClick()
    }
  }, [trackOnClick, trackTeaser])

  const ref = useRefDelegate(
    useAnimatedPreview({
      video,
      articleUrl: link?.href ?? '',
    }),
    useViewportTracking({
      onImpression: trackOnImpression,
      track: !!trackTeaser,
    })
  )

  return (
    <StyledTeaserBite ref={ref} onClick={handleClick}>
      <TeaserImage {...image}>
        {!!video && (
          <TeaserAnimatedPreview video={video} articleUrl={link?.href ?? ''} />
        )}
        <StyledVideoLabel
          duration={video?.duration}
          targetContentType={targetContentType}
        />
      </TeaserImage>
      <Title>{title}</Title>
    </StyledTeaserBite>
  )
}

const widget = {
  kind: ['teaser-bite'],
  component: TeaserBite,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
