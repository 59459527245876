import React, { FunctionComponent, useCallback, useRef, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { mobileCSS } from '@measures/responsive'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { Image, Link } from '@utils/cook/types'

import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import { ArrowsVisibilityType, SwiperClass } from '@hooks/useSwiper'

import SectionHeader from '@components/SectionHeader'
import Swiper from '@components/Swiper'
import NavigationArrows from '@components/Swiper/NavigationArrows'
import LinkCarouselLabel from '@components/LinkCarousel//LinkCarouselLabel'
import LinkCarouselTile from '@components/LinkCarousel/LinkCarouselTile'
import SwiperArrows from '@components/LinkCarousel/SwiperArrows'

export interface LinkCarouselTile {
  image: Image<'free'>
  link: Link
  title: string
}

export interface LinkCarouselProps {
  hideTitle: boolean
  label: string
  link?: Link
  tiles: LinkCarouselTile[]
  title: string
}

const StyledLinkCarousel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const StyledSwiper = styled(Swiper)`
  ${({
    theme: {
      measures: { outerPadding },
      spacing: { spacing16 },
    },
  }) => css`
    margin-top: ${spacing16};

    ${mobileCSS(css`
      margin-right: -${outerPadding.mobile}; // + stretch to right for mobile
      width: auto;

      > .swiper-items-container > *:last-child {
        margin-right: ${outerPadding.mobile};
        scroll-margin-right: ${outerPadding.mobile};
      }
    `)}
  `}
`

const StyledSwiperArrows = styled(SwiperArrows)<{
  itemsCount: number
}>`
  ${({ itemsCount }) => css`
    ${itemsCount <= 3 &&
    css`
      display: none;
    `}
  `}
`

const LinkCarousel: FunctionComponent<LinkCarouselProps> = ({
  hideTitle,
  label,
  link,
  tiles,
  title,
}) => {
  const theme = useTheme()
  const swiperRef = useRef<SwiperClass | null>(null)
  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>()

  const onInit = useCallback(
    (swiper: any) => {
      if (swiperRef) {
        swiperRef.current = swiper
      }
    },
    [swiperRef]
  )

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  const onImpressionTrackingFn = useCallback<TrackingFnType>(
    () => ({
      event: 'linkcarousel_impression',
      eventAction: 'impression',
      eventCategory: 'linkcarousel_widget',
      eventLabel: title,
      linkcarousel_title: title,
    }),
    [title]
  )

  const onImpression = useTracking(onImpressionTrackingFn)

  const viewportRef = useViewportTracking({
    onImpression,
    track: true,
  })

  return (
    <StyledLinkCarousel aria-label={title} ref={viewportRef}>
      {!hideTitle && (
        <SectionHeader title={title} accentColor={theme.colors.strokeSport}>
          {arrowsVisibility && arrowsVisibility !== 'none' && (
            <NavigationArrows
              swiperRef={swiperRef}
              arrowsVisibility={arrowsVisibility}
            />
          )}
        </SectionHeader>
      )}
      <StyledSwiper
        initialSlide={0}
        spaceTopBottom={0}
        slidesPerGroup={1}
        spaceBetween={8}
        onInit={onInit}
        onArrowsVisibility={onArrowsVisibility}
        slides={tiles.map((tile, index) => (
          <LinkCarouselTile
            key={`${tile.title}-${index}`}
            mainTitle={title}
            {...tile}
          />
        ))}>
        {hideTitle && arrowsVisibility && arrowsVisibility !== 'none' && (
          <StyledSwiperArrows
            swiperRef={swiperRef}
            arrowsVisibility={arrowsVisibility}
            itemsCount={tiles?.length ?? 0}
          />
        )}
      </StyledSwiper>
      {link && <LinkCarouselLabel label={label} link={link} />}
    </StyledLinkCarousel>
  )
}

const widget = {
  kind: ['widget', 'link-carousel'],
  component: LinkCarousel,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
