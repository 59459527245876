import { QueryClient } from '@tanstack/react-query'

export const DEBOOSTING_STRATEGY = {
  CLICK: 'click',
  VIEWABILITY: 'viewability',
  VIDEO_VIEWS: 'video_views',
}

type DeboostingStrategyKeys = keyof typeof DEBOOSTING_STRATEGY
type DeboostingStrategyValues =
  (typeof DEBOOSTING_STRATEGY)[DeboostingStrategyKeys]

type TriggerAureusImpression = (args: {
  aureusOfferId: string | undefined
  queryClient: QueryClient
}) => void

type TriggerAureusClick = (args: {
  url: string
  aureusOfferId: string
  articleId: string
  sectionId: string
}) => void

type TriggerAureusDeboosting = (args: {
  itemsToDeboost: { offerId: string; contentId: string }[]
  strategy?: DeboostingStrategyValues
}) => void

type AureusBatchRecommendationsResponseData<T = Record<string, unknown>> = {
  results: {
    [queryName: string]: { recommendations: T[] | undefined } | undefined
  }
}

export type GetAureusBatchRecommendationsResponse<T = Record<string, unknown>> =
  Promise<AureusBatchRecommendationsResponseData<T>>

type GetAureusBatchRecommendations = <T>(
  query: Record<string, unknown>,
  options?: Record<string, unknown>
) => GetAureusBatchRecommendationsResponse<T>

const triggerAureusImpression: TriggerAureusImpression = ({
  aureusOfferId,
  queryClient,
}) => {
  if (!aureusOfferId) {
    return
  }

  //! We should only track one impression per offerId (per Page)
  //! Resetting the impressions so it triggers on a new page happens
  //! in getServerSideProps().
  if (
    (queryClient.getQueryData<Record<string, unknown>>([
      'aureusOfferTracked',
    ]) ?? {})[aureusOfferId]
  ) {
    return
  }

  queryClient.setQueryData(['aureusOfferTracked'], {
    ...(queryClient.getQueryData<Record<string, unknown>>([
      'aureusOfferTracked',
    ]) ?? {}),
    [aureusOfferId]: true,
  })

  window.dlApi.cmd.push((dlApi: any) => {
    dlApi.registerAureusOffers([aureusOfferId])
  })
}

const triggerAureusClick: TriggerAureusClick = ({
  articleId,
  url,
  aureusOfferId,
  sectionId,
}) => {
  if (!window.dlApi || !url || !sectionId || !aureusOfferId || !articleId) {
    return
  }

  window.dlApi.cmd.push((dlApi: any) => {
    dlApi.clkEvent?.(
      url,
      'keep watching',
      sectionId,
      '',
      '',
      '',
      '',
      '',
      aureusOfferId,
      articleId,
      ''
    )
  })
}

const triggerAureusDeboosting: TriggerAureusDeboosting = ({
  strategy = DEBOOSTING_STRATEGY.CLICK,
  itemsToDeboost,
}) => {
  if (!itemsToDeboost?.length) {
    return
  }

  window.aureusClient?.sendDeboostingEvents(itemsToDeboost, strategy)
}

const getAureusBatchRecommendations: GetAureusBatchRecommendations = (
  query,
  options
) =>
  new Promise((resolve, reject) => {
    if (!window.aureusClient) {
      reject(new Error('Aureus Client is not available'))
    }

    window.aureusClient.getBatchRecommendations(
      query,
      (error, data) => {
        if (error) {
          reject(error)
        }
        resolve(data)
      },
      options
    )
  })

export {
  triggerAureusImpression,
  triggerAureusClick,
  triggerAureusDeboosting,
  getAureusBatchRecommendations,
}
