import useIsInCollapsibleScoreboard from '@hooks/useIsInCollapsibleScoreboard'
import { desktopCSS, mobileCSS } from '@measures/responsive'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const StyledScoreboardMainSection = styled.div<{
  isInCollapsibleScoreboard?: boolean
}>`
  ${({
    theme: {
      spacing: { spacing8, spacing16, spacing24 },
    },
    isInCollapsibleScoreboard,
  }) => {
    const collapsibleScoreboardCSS = css`
      ${mobileCSS(css`
        padding: ${spacing8};
        width: 100%;
      `)}
    `

    return css`
      display: flex;
      justify-content: space-between;
      padding: ${spacing16};
      ${desktopCSS(css`
        padding: ${spacing16} ${spacing24};
      `)}
      ${isInCollapsibleScoreboard && collapsibleScoreboardCSS}
    `
  }}
`

export type ScoreboardMainSectionProps = {
  children: ReactNode
}

const ScoreboardMainSection: FunctionComponent<ScoreboardMainSectionProps> = ({
  children,
}) => {
  const isInCollapsibleScoreboard = useIsInCollapsibleScoreboard()
  return (
    <StyledScoreboardMainSection
      isInCollapsibleScoreboard={isInCollapsibleScoreboard}>
      {children}
    </StyledScoreboardMainSection>
  )
}

export default ScoreboardMainSection
