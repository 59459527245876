import JSONRenderer from '@components/JSONRenderer'
import nanoid from '@utils/random'
import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import { BlickBitesAd } from '@widgets/Video/BlickBites'
import useInViewChange from '@hooks/useInViewChange'
import translate from '@i18n'
import { desktopCSS } from '@measures/responsive'
import CloseButton from '@components/Video/BlickBites/BlickBitesFastlane/BlickBitesFastlaneItem/CloseButton'
import ItemContainer from '@components/Video/BlickBites/BlickBitesFastlane/BlickBitesFastlaneItem/ItemContainer'

interface BlickBitesAdProps {
  adItem: BlickBitesAd
  isActive: boolean
  index: number
  onImpression: (index: number) => void
  onClose: () => void
}

const BlickBitesAdWrapper = styled(ItemContainer)`
  ${({
    theme: {
      color: {
        tertiary: { grey900 },
      },
    },
  }) => css`
    background-color: ${grey900};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`
const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  z-index: 8;
  top: 16px;
  right: 16px;

  ${desktopCSS(css`
    display: none;
  `)}
`

const StyledLabel = styled.div`
  ${({
    theme: {
      spacing: { spacing4, spacing24 },
      color: {
        primary: { primary02, primary01 },
      },
      typography: {
        catchword: {
          small2: { bundledCSS: catchwordSmall2CSS },
        },
      },
    },
  }) => css`
    background-color: ${primary02};
    padding: ${spacing4};
    position: absolute;
    top: ${spacing24};
    color: ${primary01};
    ${catchwordSmall2CSS};
    text-transform: uppercase;
  `}
`

const BlickBitesAdItem: FunctionComponent<BlickBitesAdProps> = ({
  adItem,
  isActive,
  onImpression,
  index,
  onClose,
}) => {
  const [adKey, setAdKey] = useState<string>('')
  const [isInViewport, setIsInViewport] = useState<boolean | null>(null)
  const onInViewChangeHandler = useCallback((inView: any) => {
    setIsInViewport(inView)
  }, [])

  const viewportRef = useInViewChange({
    track: true,
    threshold: 0.5,
    delay: 0,
    onInViewChange: onInViewChangeHandler,
  })

  useEffect(() => {
    if (isInViewport) {
      onImpression(index)
    }
  }, [index, isInViewport, onImpression])

  useEffect(() => {
    if (isActive) {
      setAdKey(nanoid())
    } else {
      setAdKey('')
    }
  }, [isActive])

  return (
    <BlickBitesAdWrapper ref={viewportRef}>
      {isActive && adKey && (
        <>
          <StyledCloseButton onClick={onClose} />
          <StyledLabel>{translate('adsTitle')}</StyledLabel>
          <JSONRenderer key={adKey}>
            {{
              ...adItem,
              autoLoadSlot: true,
              containerId: `${index}-${adKey}`,
            }}
          </JSONRenderer>
        </>
      )}
    </BlickBitesAdWrapper>
  )
}

const MemoizedBlickBitesAdItem = memo(BlickBitesAdItem)

MemoizedBlickBitesAdItem.displayName = 'MemoizedBlickBitesAdItem'

export default MemoizedBlickBitesAdItem
