import SportsFooter from '@components/SportsFooter/toi'
import SportLabelContent from '@components/SportsFooter/SportLabelContent'
import { transformDataForSportsComponents } from '@components/SportsFooter/utils'
import Badge from '@components/TeaserToi/Badge'
import BlickPlusLogo from '@components/TeaserToi/BlickPlusLogo'
import BulletPoints from '@components/TeaserToi/BulletPoints'
import Catchword from '@components/TeaserToi/Catchword'
import ImageComponent from '@components/TeaserToi/Image'
import Label from '@components/TeaserToi/Label'
import Pop from '@components/TeaserToi/Pop'
import Subtitle from '@components/TeaserToi/Subtitle'
import Title from '@components/TeaserToi/Title'
import VideoBackground from '@components/TeaserToi/VideoBackground'
import VideoLabel from '@components/VideoLabel'
import useSportsEvent from '@hooks/useSportsEvent'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import { Image, Link, TargetContentType, TeaserVideo } from '@utils/cook/types'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { LiveEventDataSportTypes } from '@utils/formatters/liveEvent'
import { isLiveStream } from '@widgets/Video/utils'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, ReactNode, useCallback, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import BlinkingDot from '@components/BlinkingDot'
import TeaserBackgroundVideo from '@components/TeaserBackgroundVideo'
import NewsTicker from '@components/TeaserToi/NewsTicker'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import { triggerAureusImpression } from '@utils/aureus'
import { useQueryClient } from '@tanstack/react-query'
import TeaserAnimatedPreview from '@components/TeaserAnimatedPreview'
import useAnimatedPreview from '@hooks/useAnimatedPreview'
import useRefDelegate from '@hooks/useRefDelegate'
import TeaserToiWrapper from '@components/TeaserToi/Wrapper'
import useDeviceType from '@hooks/useDeviceType'
import useGrowthBookFeatureValue from '@hooks/useGrowthBookFeatureValue'

export interface POP {
  image: Image<'1_1'>
  position: {
    v: 'top' | 'bottom'
    h: 'left' | 'right'
  }
}

export interface TeaserToiNoBulletPointProps {
  bulletpoints?: undefined
  subtitle?: string
}

export interface TeaserToiBulletPointProps {
  bulletpoints: string[]
}

export type TeaserToiMaybeBulletPointProps =
  | TeaserToiNoBulletPointProps
  | TeaserToiBulletPointProps

export interface TeaserToiCommonProps {
  accentColor: string
  title: string
  catchword?: string
  labelText?: string
  pop?: POP
  video?: TeaserVideo
  image: Image<'1_1' | '3_4'>
  link: Link
  section: {
    name: string
    uniqueName: string
  }
  contentOrigin?: string
  track?: boolean
  isPlus?: boolean
  articleId?: string
  targetContentType: TargetContentType
  teaserVideo?: TeaserVideo
  cueLiveId?: string
  aureusOfferId?: string
}

export interface TeaserToiNoSportProps {
  typeOfSport?: undefined
  badge?: {
    text: string
    isLive?: boolean
    backgroundColor: string
  }
}

export interface TeaserToiSportProps {
  typeOfSport: Exclude<LiveEventDataSportTypes, 'NO_SPORT'>
  matchId: string
}

export type TeaserToiMaybeSportProps =
  | TeaserToiNoSportProps
  | TeaserToiSportProps

export type TeaserToiAPIProps = TeaserToiCommonProps &
  TeaserToiMaybeBulletPointProps &
  TeaserToiMaybeSportProps

export type StyledPopProps = Required<TeaserToiAPIProps>['pop']['position']

export interface StyledImageComponentProps {
  hasSport: boolean
}

export type BackgroundVideoType =
  | 'fit-to-width'
  | 'stretched-to-fill'
  | undefined

export interface GradientStackProps {
  shouldShowDarkGradient: boolean
}

const TextStack = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: flex-start;
  justify-items: flex-start;
  position: relative;
  z-index: 1;
`

const GradientStack = styled.div<GradientStackProps>`
  ${({
    theme: {
      colors: { staticBlack },
      spacing: { spacing16, spacing24 },
    },
    shouldShowDarkGradient,
  }) => css`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    align-items: flex-start;
    justify-items: flex-start;
    padding: 0 ${spacing16} ${spacing16};

    ${desktopCSS(css`
      padding: 0 ${spacing24} ${spacing24};
    `)};

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: -80px;
      left: 0;
      width: 100%;
      height: calc(100% + 80px);
      background: ${
        shouldShowDarkGradient
          ? `linear-gradient(2deg, ${staticBlack} 10%, ${staticBlack}99 60%,${staticBlack}00 90%)`
          : `linear-gradient(2deg, ${staticBlack}99 60%, ${staticBlack}00 90%)`
      }
  `}
`

const PopStack = styled.div`
  position: relative;
  z-index: 2;
`

const StyledBadge = styled(Badge)`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    position: absolute;
    top: ${spacing24};
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  `}
`

const StyledVideoBackground = styled(VideoBackground)<{ isVisible: boolean }>`
  ${({ isVisible }) => css`
    opacity: ${isVisible ? 1 : 0};
    transition: opacity 0.3s ease-in-out;
  `}
`

const StyledSportBadge: FunctionComponent<{
  isLive: boolean
  children: ReactNode
}> = ({ isLive, children }) => {
  const theme = useTheme()
  return (
    <StyledBadge
      backgroundColor={
        isLive ? theme.colors.fillBrand : theme.colors.fillSport
      }>
      {children}
    </StyledBadge>
  )
}

const StyledPop = styled(Pop)<StyledPopProps>`
  ${({
    theme: {
      spacing: { spacing16, spacing20, spacing24, spacing32 },
    },
    v,
    h,
  }) => css`
    position: absolute;
    ${v === 'bottom'
      ? css`
          bottom: ${spacing20};
        `
      : css`
          top: ${spacing24};
        `}
    ${h === 'left' ? 'left' : 'right'}: ${spacing16};
    ${desktopCSS(css`
      ${h === 'left' ? 'left' : 'right'}: ${spacing24};
      ${v === 'bottom'
        ? css`
            bottom: ${spacing32};
          `
        : css`
            top: ${spacing24};
          `}
    `)}
  `}
`

const StyledBlinkingDot = styled(BlinkingDot)`
  ${({
    theme: {
      spacing: { spacing2, spacing4 },
    },
  }) => css`
    margin-right: calc(${spacing4} + ${spacing2});
    margin-left: ${spacing2};
    height: 14px;
  `}
`

const GridWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  grid-template-columns: minmax(0, 1fr);
`

const DynamicContentWrapper = styled.div<{
  typeOfSport: TeaserToiAPIProps['typeOfSport']
  isSportDataLoaded: boolean
}>`
  ${({
    typeOfSport,
    isSportDataLoaded,
    theme: {
      colors: { staticBlack },
    },
  }) => {
    if (!typeOfSport || isSportDataLoaded) {
      return css``
    }
    return css`
      background-color: ${staticBlack};
      ${typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.TENNIS
        ? css`
            ${desktopCSS(css`
              min-height: 94px;
            `)};
            ${mobileAndTabletCSS(css`
              min-height: 86px;
            `)};
          `
        : typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.FORMULA1 ||
            typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.SKI
          ? css`
              ${desktopCSS(css`
                min-height: 72px;
              `)};
              ${mobileAndTabletCSS(css`
                min-height: 138px;
              `)};
            `
          : css`
              ${desktopCSS(css`
                min-height: 94px;
              `)};
              ${mobileAndTabletCSS(css`
                min-height: 98px;
              `)};
            `};
    `
  }}
`

const StyledImageComponent = styled(ImageComponent)<StyledImageComponentProps>`
  ${({
    theme: {
      colors: { staticBlack },
    },
    hasSport,
  }) => css`
    ${hasSport &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 60px;
        background: linear-gradient(
          180deg,
          ${staticBlack}BF 0%,
          ${staticBlack}00 100%
        );
      }
    `}
  `}
`

const StyledLabel = styled(Label)`
  ${({
    theme: {
      spacing: { spacing8, spacing16, spacing24 },
    },
  }) => css`
    margin-bottom: ${spacing8};
    margin-left: ${spacing16};
    ${desktopCSS(css`
      margin-left: ${spacing24};
    `)};
  `}
`

const StyledVideoLabel = styled(VideoLabel)`
  ${({
    theme: {
      spacing: { spacing8, spacing16, spacing24 },
    },
  }) => css`
    margin-bottom: ${spacing8};
    margin-left: ${spacing16};
    ${desktopCSS(css`
      margin-left: ${spacing24};
    `)};
  `}
`

const StyledBulletPoints = styled(BulletPoints)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    margin-top: ${spacing8};
  `}
`

const TeaserToi: FunctionComponent<TeaserToiAPIProps> = (props) => {
  const {
    link,
    image,
    targetContentType,
    pop,
    accentColor,
    isPlus,
    typeOfSport,
    video,
    labelText,
    teaserVideo,
    bulletpoints,
    cueLiveId,
    articleId,
    contentOrigin,
    track,
    aureusOfferId,
  } = props

  const growthBookTeaserTestData = useGrowthBookFeatureValue(
    `teaserTest-${articleId}`
  )
  const propsFromGrowthBook = (
    typeof growthBookTeaserTestData === 'object' &&
    growthBookTeaserTestData !== null
      ? growthBookTeaserTestData
      : {}
  ) as TeaserToiAPIProps

  const { title, catchword } = {
    ...props,
    ...propsFromGrowthBook,
  }

  const queryClient = useQueryClient()

  const isValidSport = !!typeOfSport

  const { data: sportsEventData } = useSportsEvent({
    matchId: isValidSport ? props.matchId : '',
    typeOfSport: isValidSport ? typeOfSport : 'NO_SPORT',
  })

  const sportsData = sportsEventData
    ? transformDataForSportsComponents(sportsEventData)
    : undefined

  const shouldShowSportsComponents = isValidSport && !!sportsData

  const showCatchword = !!isPlus || !!catchword

  const deviceType = useDeviceType()
  const shouldShowBackgroundVideo = !!teaserVideo && deviceType !== 'ios'
  const isBackgroundVideoLiveStream =
    shouldShowBackgroundVideo && isLiveStream(teaserVideo?.duration)
  const backgroundVideoType = isBackgroundVideoLiveStream
    ? 'fit-to-width'
    : 'stretched-to-fill'

  const [hasBackgroundVideoLoaded, setHasBackgroundVideoLoaded] =
    useState<boolean>(false)

  const onVideoPlay = useCallback(() => {
    setHasBackgroundVideoLoaded(true)
  }, [])

  const onVideoStop = useCallback(() => {
    setHasBackgroundVideoLoaded(false)
  }, [])

  const hasNewsTicker = !!cueLiveId

  const onImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'teaser_impression',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'impression',
      is_branded: !!contentOrigin,
      link_url: link.href,
    }),
    [video, articleId, title, contentOrigin, link.href]
  )

  const onClick = useCallback<TrackingFnType>(
    () => ({
      event: 'teaser_click',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'click',
      id: articleId,
      is_branded: !!contentOrigin,
      link_url: link.href,
    }),
    [video, articleId, title, contentOrigin, link.href]
  )

  const trackedOnImpression = useTracking(onImpression)
  const trackedOnClick = useTracking(onClick)

  const shouldBeTracked = contentOrigin || !!track

  const finalOnImpression = useCallback(() => {
    triggerAureusImpression({
      aureusOfferId,
      queryClient,
    })

    if (shouldBeTracked) {
      trackedOnImpression()
    }
  }, [aureusOfferId, shouldBeTracked, trackedOnImpression, queryClient])

  const clickableProps = {
    aureusOfferId,
    ...(shouldBeTracked ? { onClick: trackedOnClick } : {}),
    ...link,
  }

  const ref = useRefDelegate(
    useAnimatedPreview({
      video,
      articleUrl: clickableProps.href,
    }),
    useViewportTracking({
      onImpression: finalOnImpression,
      track: true,
    })
  )

  const shouldShowDarkGradient = shouldShowSportsComponents || hasNewsTicker
  return (
    <TeaserToiWrapper {...clickableProps} ref={ref}>
      <StyledImageComponent {...image} hasSport={isValidSport}>
        {shouldShowBackgroundVideo && (
          <StyledVideoBackground
            videoType={backgroundVideoType}
            isVisible={hasBackgroundVideoLoaded}>
            <TeaserBackgroundVideo
              videoId={teaserVideo.jwVideoId}
              isLiveStream={isBackgroundVideoLiveStream}
              onPlay={onVideoPlay}
              onStop={onVideoStop}
            />
          </StyledVideoBackground>
        )}
        {!!video && !shouldShowBackgroundVideo && (
          <TeaserAnimatedPreview video={video} articleUrl={link?.href} />
        )}
        <GridWrapper>
          {!isValidSport && !!props.badge && (
            <StyledBadge backgroundColor={props.badge.backgroundColor}>
              {!!props.badge.isLive && <StyledBlinkingDot />}
              {props.badge.text}
            </StyledBadge>
          )}
          {shouldShowSportsComponents && (
            <StyledSportBadge isLive={sportsData?.isLive}>
              <SportLabelContent sportsData={sportsData} />
            </StyledSportBadge>
          )}
          <PopStack>
            {!!pop && <StyledPop {...pop.position} {...pop.image} />}
          </PopStack>
          <TextStack>
            {!!video && (
              <StyledVideoLabel
                duration={video.duration}
                targetContentType={targetContentType}
              />
            )}
            {!!labelText && (
              <StyledLabel labelColor={accentColor}>{labelText}</StyledLabel>
            )}
            <GradientStack shouldShowDarkGradient={shouldShowDarkGradient}>
              {showCatchword && (
                <Catchword>
                  {isPlus && <BlickPlusLogo />}
                  {catchword}
                </Catchword>
              )}
              <Title>{title}</Title>
              {!!bulletpoints && (
                <StyledBulletPoints accentColor={accentColor}>
                  {bulletpoints}
                </StyledBulletPoints>
              )}
              {!bulletpoints && !!props.subtitle && (
                <Subtitle>{props.subtitle}</Subtitle>
              )}
            </GradientStack>
          </TextStack>
          <DynamicContentWrapper
            typeOfSport={typeOfSport}
            isSportDataLoaded={!!sportsData}>
            {shouldShowSportsComponents && (
              <SportsFooter sportsData={sportsData} />
            )}
            {hasNewsTicker && <NewsTicker cueLiveId={cueLiveId} />}
          </DynamicContentWrapper>
        </GridWrapper>
      </StyledImageComponent>
    </TeaserToiWrapper>
  )
}

const widget = {
  kind: ['teaser-toi'],
  component: TeaserToi,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
