import { ConfigFn } from '@config/types'

const config = (({ publication }) =>
  ({
    android:
      publication === 'romandie'
        ? 'https://play.google.com/store/apps/details?id=ch.blick.news.fr'
        : 'https://play.google.com/store/apps/details?id=ch.blick.news',
    ios:
      publication === 'romandie'
        ? 'https://apps.apple.com/ch/app/blick-fr/id1596317983'
        : 'https://apps.apple.com/ch/app/blick-news-sport/id375173494',
  }) as const) satisfies ConfigFn

export default config
