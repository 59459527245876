import translate from '@i18n'
import { desktopCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

type ScoreboardOpponentSeparatorProps = {
  isTennis?: boolean
}

const StyledScoreboardOpponentSeparator = styled.div<ScoreboardOpponentSeparatorProps>`
  ${({
    theme: {
      typography: {
        catchword: {
          small1: { bundledCSS: small1CatchwordCSS },
        },
      },
      color: {
        primary: { primary02: primary02Color },
        tertiary: { grey700: grey700Color },
      },
      spacing: { spacing32, spacing20, spacing28, spacing8 },
    },
    isTennis,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${primary02Color};
    width: ${spacing32};
    height: ${spacing32};
    border-radius: 50%;
    background-color: ${grey700Color};
    margin: ${isTennis ? 0 : spacing28} ${spacing8} 0 ${spacing8};
    ${desktopCSS(css`
      margin-top: ${isTennis ? 0 : spacing20};
    `)}
    text-transform: uppercase;
    justify-self: center;
    ${small1CatchwordCSS};
  `}
`

const ScoreboardOpponentSeparator: FunctionComponent<
  ScoreboardOpponentSeparatorProps
> = ({ isTennis }) => {
  return (
    <StyledScoreboardOpponentSeparator isTennis={isTennis}>
      {translate('scoreboard.versus')}
    </StyledScoreboardOpponentSeparator>
  )
}

export default ScoreboardOpponentSeparator
