import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import Blink from '@components/Blink'

const TeaserMiddleToiWrapper = styled(Blink)`
  ${desktopCSS(css`
    display: none;
  `)}
`

export default TeaserMiddleToiWrapper
