import { FunctionComponent, Ref } from 'react'
import styled, { css } from 'styled-components'
import ScoreboardMainSection from './ScoreboardMainSection'
import CollapsibleSoccerScoreboard from './CollapsibleSoccerScoreboard'

import { desktopCSS, tabletCSS } from '@measures/responsive'
import useSportsEvent from '@hooks/useSportsEvent'
import ScoreboardContext from '@contexts/scoreboard'
import CollapsibleScoreboardContext from '@contexts/collapsibleScoreboardContext'

type CollapsibleScoreboardProps = {
  ref?: Ref<HTMLDivElement | null>
  typeOfSport: 'soccer'
  matchId: string
  shouldShowCollapsibleScoreboard: boolean
}

const StyledCollapsibleScoreboard = styled.div<
  Pick<CollapsibleScoreboardProps, 'shouldShowCollapsibleScoreboard'>
>`
  ${({
    theme: {
      color: {
        tertiary: { grey100 },
      },
      measures: { stretchWidthOnMobile },
    },
    shouldShowCollapsibleScoreboard,
  }) => {
    return css`
      background-color: ${grey100};
      height: 48px;
      position: sticky;
      top: 48px;
      right: 16px;
      z-index: 1;
      display: flex;
      justify-content: center;
      ${stretchWidthOnMobile()};
      opacity: ${shouldShowCollapsibleScoreboard ? 1 : 0};
      transition: opacity 0.2s ease-in-out;
      ${desktopCSS(css`
        display: none;
      `)}
      ${tabletCSS(css`
        display: none;
      `)}
    `
  }}
`

const CollapsibleScoreboard: FunctionComponent<CollapsibleScoreboardProps> = ({
  matchId,
  typeOfSport,
  shouldShowCollapsibleScoreboard,
  ref,
}) => {
  const { data } = useSportsEvent({ typeOfSport, matchId })

  if (!data) {
    return null
  }

  return (
    <ScoreboardContext.Provider value={data}>
      <CollapsibleScoreboardContext.Provider value={true}>
        <StyledCollapsibleScoreboard
          ref={ref}
          shouldShowCollapsibleScoreboard={shouldShowCollapsibleScoreboard}>
          <ScoreboardMainSection>
            <CollapsibleSoccerScoreboard />
          </ScoreboardMainSection>
        </StyledCollapsibleScoreboard>
      </CollapsibleScoreboardContext.Provider>
    </ScoreboardContext.Provider>
  )
}

export default CollapsibleScoreboard
