import SvgIcon from '@components/SvgIcon'
import { formatDate } from '@utils/date'
import { FunctionComponent, useMemo } from 'react'
import styled, { css, useTheme } from 'styled-components'

const StyledDate = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: bodycopyXXSmall },
        },
      },
      colors: { textWeak },
    },
  }) => css`
    flex-grow: 1;
    display: grid;
    align-items: end;
    grid-template-columns: 20px 1fr;
    grid-column-gap: 4px;
    ${bodycopyXXSmall};
    color: ${textWeak};
  `};
`

const Date: FunctionComponent<{ children: string }> = ({ children: date }) => {
  const {
    colors: { textWeak },
  } = useTheme()

  const finalDate = useMemo(() => {
    const formattedDateObj = formatDate(date)
    return formattedDateObj !== '' ? formattedDateObj.dateOrTime : ''
  }, [date])

  return (
    <StyledDate suppressHydrationWarning={true}>
      <SvgIcon iconName="arrows-rotate" size={20} color={textWeak} />
      <span suppressHydrationWarning={true}>{finalDate}</span>
    </StyledDate>
  )
}

export default Date
