import { ConfigFn } from '@config/types'

const config = (({
  publication,
  deploymentEnv,
  authUrl,
  ssoDomain,
  myServicesUrl,
  pianoEnv,
}) =>
  ({
    authURL: authUrl,
    accountPath: '/profile',
    domain: ssoDomain,
    audience: 'https://api.onelog.ch/profile/v1',
    scope: 'openid email fields admeira:id offline_access',
    ssoSourceSuffix: `Blick-${publication === 'romandie' ? 'FR' : 'DE'}`,
    loginCookie: 'loginRedirect',
    logoutCookie: 'logoutRedirect',
    loginPath: '/login',
    logoutPath: '/logout',
    metadataAndSubscriptionsPath: '/user/metadata',
    heartbeatPath: '/heartbeat',
    responseType: 'code',
    state: publication === 'romandie' ? 'blickromandiech' : 'blickch',
    cookieDomain: 'blick.ch',
    clientID:
      deploymentEnv === 'prod'
        ? '6d8dcfe7824c089600ba85267a6f5ec4'
        : '0a41bc5ff18efc3851032dfe24c1408f',
    lang: publication === 'romandie' ? 'fr' : 'de',
    myServicesUrl: `${myServicesUrl}${
      pianoEnv === 'pre-production' ? '/digital-subscriptions?pianoPreProd' : ''
    }`,
  }) as const) satisfies ConfigFn

export default config
