import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { GrowthBook, TrackingCallback } from '@growthbook/growthbook-react'
import nanoid from '@utils/random'

import config from '@config'
import { User } from '@hooks/useUser'
import { getLocalStorageItem, setLocalStorageItem } from '@utils/localStorage'
import useIsGrowthbookDevModeEnabled from '@hooks/useIsGrowthbookDevModeEnabled'
import useExecuteOnClientNavigation from '@hooks/useExecuteOnClientNavigation'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import { FeatureFlagsEntry } from '@config/items/abTest'

const {
  backend: { hotModuleReloading },
  growthBook: {
    apiHost: growthBookApiHost,
    clientKey: growthBookClientKey,
    localStorageUuidKey: growthBookLocalStorageUuidKey,
  },
  abTest: { featureFlagsEntries, windowKey: abTestWindowKey },
} = config

type FeatureFlagsEntriesKeys = keyof typeof featureFlagsEntries

type GrowthBookFeatures = {
  [key: string]: unknown
}

const getSessionId = (): string => {
  let sessionId = getLocalStorageItem<string>(growthBookLocalStorageUuidKey)

  if (!sessionId) {
    sessionId = nanoid()
    setLocalStorageItem(growthBookLocalStorageUuidKey, sessionId)
  }

  return sessionId
}

const GrowthBookManager: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const { subscriptionStatus } = useSubscriptionStatus()

  const isGrowthbookDevModeEnabled = useIsGrowthbookDevModeEnabled()
  const growthbookInstanceRef = useRef<GrowthBook | null>(null)

  const trackingCallbackHandler = useCallback<
    TrackingFnType<{
      experiment: Parameters<TrackingCallback>[0]
      result: Parameters<TrackingCallback>[1]
    }>
  >(
    ({ extraData }) => ({
      event: 'Viewed Experiment',
      experiment_id: extraData.experiment.key,
      variation_id: extraData.result.key,
    }),
    []
  )

  const onExperimentView = useTracking(trackingCallbackHandler)

  const checkAndDestroyPreviousInstance = useCallback(() => {
    if (growthbookInstanceRef.current) {
      growthbookInstanceRef.current.destroy()
    }
  }, [])

  const growthbook = useMemo(() => {
    checkAndDestroyPreviousInstance()

    const growthbook = new GrowthBook({
      apiHost: growthBookApiHost,
      clientKey: growthBookClientKey,
      // There is a GrowthBook Chrome DevTools Extension that can help
      // you debug and test feature flags in development.
      // To avoid exposing all of internal feature flags and experiments
      // to users - It should be false in production in most cases.
      // But you can enable it for development through Feature Flags Dashboard.
      enableDevMode: hotModuleReloading || isGrowthbookDevModeEnabled,
      trackingCallback: (experiment, result) => {
        onExperimentView({ experiment, result })
      },
    })
    growthbookInstanceRef.current = growthbook

    return growthbook
  }, [
    isGrowthbookDevModeEnabled,
    checkAndDestroyPreviousInstance,
    onExperimentView,
  ])

  useEffect(() => {
    return () => {
      // Cleanup GrowthBook instance when the component is unmounted
      checkAndDestroyPreviousInstance()
    }
  }, [checkAndDestroyPreviousInstance])

  useEffect(() => {
    growthbook.setRenderer(() => {
      // go through the feature flags which can be enabled in GrowthBook
      Object.keys(featureFlagsEntries)
        .filter(
          (featureFlag) =>
            (
              featureFlagsEntries[
                featureFlag as FeatureFlagsEntriesKeys
              ] as FeatureFlagsEntry
            )?.canBeEnabledFromGrowthbook
        )
        .forEach((featureFlag) => {
          // Check if feature flag exists in GrowthBook features
          if (growthbook.getFeatures()?.hasOwnProperty(featureFlag)) {
            const featureValue = growthbook.getFeatureValue(
              featureFlag,
              undefined
            )

            window[abTestWindowKey][featureFlag] = featureValue
          }
        })

      queryClient.setQueryData<GrowthBookFeatures>(
        ['growthbook-features'],
        Object.fromEntries(
          Object.entries(growthbook.getFeatures()).map(([key]) => [
            key,
            growthbook.getFeatureValue(key, undefined),
          ])
        )
      )
      queryClient.invalidateQueries({ queryKey: ['growthbook-features'] })
    })
  }, [growthbook, queryClient])

  useEffect(() => {
    // Fetch feature payload from GrowthBook
    growthbook.init({
      // If the network request takes longer than this (in milliseconds) -
      // render the page without the feature flags.
      // This is useful for preventing performance
      // issues if the GrowthBook API is slow to respond.
      timeout: 1000,
      // The GrowthBook SDK supports streaming with Server-Sent Events (SSE).
      // When enabled, changes to features within GrowthBook will be streamed
      // to the SDK in realtime as they are published.
      // We dont need it for now so disable streaming to reduce load on the server.
      streaming: false,
    })
  }, [growthbook])

  useEffect(() => {
    if (growthbook && subscriptionStatus) {
      const userData = queryClient.getQueryData<User>(['user'])
      // Attributes about the current user and request.
      // These are used for two things: Feature targeting and
      // Assigning persistent variations in A/B tests (e.g. user id "123" always gets variation B).
      growthbook.setAttributes({
        //! NOTE: "Id" is used as "Assignment Attribute" in experiments to assign variations.
        //! This value is required for GrowthBook to run experiments and cant be null.
        //! If the user is not logged in or without id, we use the "sessionId" for this attribute
        //! which is random anonymous hash, persisted in a cookie or local storage.
        id: userData?.sub || getSessionId(),
        // Other attributes
        userId: userData?.sub || null,
        loggedIn: !!userData,
        subscriptionStatus,
      })
    }
  }, [growthbook, subscriptionStatus, queryClient])

  const updateGrowthBookURL = useCallback(() => {
    growthbook?.setURL(window.location.href)
  }, [growthbook])

  // For the SPA we need to let the GrowthBook instance know when
  // the URL changes so the active experiments can update accordingly.
  useExecuteOnClientNavigation(updateGrowthBookURL)

  return null
}

export default GrowthBookManager
