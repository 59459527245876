import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, useCallback, useState } from 'react'
import { ScoreboardAPIProps } from './types'
import { isValidScoreboardProps } from './utils'
import styled, { css } from 'styled-components'
import JSONRenderer from '@components/JSONRenderer'
import Scoreboard from '@components/Scoreboard'
import SportsPartner from '@components/SportsPartner'
import ScoreboardLink from '@components/Scoreboard/ScoreboardLink'
import { useQueryClient } from '@tanstack/react-query'
import {
  EnrichedLiveEventTransformedFormula1,
  EnrichedLiveEventTransformedHockey,
  EnrichedLiveEventTransformedSki,
  EnrichedLiveEventTransformedSoccer,
  EnrichedLiveEventTransformedTennis,
} from '@utils/sport'
import config from '@config'
import CollapsibleScoreboard from '@components/Scoreboard/CollapsibleScoreboard'
import { mobileCSS } from '@measures/responsive'
import { StyledVideoContainer } from '@components/Video/VideoPlayer/VideoContainer'
import { StyledWrapper as ZattooStyledWrapper } from '@components/Video/Zattoo'
import useGetPageIdentity from '@hooks/useGetPageIdentity'

const {
  publication: { publication },
} = config

const Wrapper = styled.div<{
  shouldPullUpContent: boolean
}>`
  ${({
    shouldPullUpContent,
    theme: {
      measures: { stretchWidthOnMobile },
      spacing: { spacing32 },
    },
  }) => css`
    ${shouldPullUpContent &&
    css`
      ${mobileCSS(css`
        margin-top: -64px;
      `)}
    `};

    > ${StyledVideoContainer},
      > ${StyledSportsPartner},
      > ${ZattooStyledWrapper} {
      ${stretchWidthOnMobile()};
      ${mobileCSS(css`
        width: calc(100% + ${spacing32});
      `)}
    }
  `}
`

const StyledSportsPartner = styled(SportsPartner)``

const ScoreboardWidget: FunctionComponent<ScoreboardAPIProps> = (props) => {
  const { link, video, zattoo, streaming, partner } = props
  const queryClient = useQueryClient()
  const { hasScoreboard } = useGetPageIdentity()

  const [shouldShowCollapsibleScoreboard, setShouldShowCollapsibleScoreboard] =
    useState(false)

  const [displaySportsPartner, setDisplaySportsPartner] = useState(() => {
    if (publication === 'romandie') {
      return true
    }
    const sportsEventData = queryClient.getQueryData<
      | EnrichedLiveEventTransformedSoccer
      | EnrichedLiveEventTransformedHockey
      | EnrichedLiveEventTransformedSki
      | EnrichedLiveEventTransformedFormula1
      | EnrichedLiveEventTransformedTennis
    >(['sportsEvent', props.typeOfSport, props.matchId])
    return !sportsEventData?.formattedData?.isFinished
  })

  const updateDisplaySportsPartner = useCallback<
    (matchFinished: boolean) => void
  >((matchFinished) => {
    if (publication !== 'romandie') {
      setDisplaySportsPartner(!matchFinished)
    }
  }, [])

  const videoComponent = !!video?.videoId
    ? { ...video, isInScoreboardContent: true }
    : null

  const zattooComponent = zattoo
    ? { ...zattoo, isInScoreboardContent: true }
    : null

  if (!isValidScoreboardProps(props)) {
    return null
  }

  const { matchId, typeOfSport } = props

  const showScoreboard = matchId !== '0'
  const shouldPullUpContent = typeOfSport === 'soccer' && hasScoreboard

  return (
    <>
      {showScoreboard && typeOfSport === 'soccer' && (
        <CollapsibleScoreboard
          matchId={matchId}
          typeOfSport={typeOfSport}
          shouldShowCollapsibleScoreboard={shouldShowCollapsibleScoreboard}
        />
      )}
      <Wrapper shouldPullUpContent={shouldPullUpContent}>
        {link && <ScoreboardLink link={link} />}
        {showScoreboard && (
          <Scoreboard
            matchId={matchId}
            streaming={streaming}
            typeOfSport={typeOfSport}
            updateDisplaySportsPartner={updateDisplaySportsPartner}
            shouldShowScoreboard={!shouldShowCollapsibleScoreboard}
            setShouldShowCollapsibleScoreboard={
              setShouldShowCollapsibleScoreboard
            }
          />
        )}
        <JSONRenderer>{videoComponent}</JSONRenderer>
        <JSONRenderer>{zattooComponent}</JSONRenderer>
        {displaySportsPartner && <StyledSportsPartner partner={partner} />}
      </Wrapper>
    </>
  )
}

const widget = {
  kind: ['widget', 'sport', 'scoreboard'],
  component: ScoreboardWidget,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
