import {
  DehydratedState,
  QueryClient,
  QueryClientConfig,
  QueryKey,
} from '@tanstack/react-query'
import { CatchAllPageServerProps } from 'pages/[[...pageUrl]]'

const STRUCTURAL_SHARING_DISABLED_QUERIES: QueryKey[] = [
  ['sidebar-ad-ref'],
  ['active-animated-preview'],
  ['animated-previews-list'],
  ['full-screen-portal-rendered-node-ref'],
  ['modal-overlay-portal-rendered-node-ref'],
  ['commenting-widget-ref'],
  ['cliffhanger-widget-ref'],
  ['trigger-jwplayer-script-loading-func'],
] as const

const defaultConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      staleTime: Infinity,
      retry: false,
      networkMode: 'offlineFirst',
    },
    mutations: {
      gcTime: Infinity,
      networkMode: 'offlineFirst',
    },
  },
}

const dehydratedQueryWithOldTimestamp = ({
  currentTimestamp,
  query,
}: {
  currentTimestamp: number
  query: DehydratedState['queries'][number]
}): DehydratedState['queries'][number] => ({
  ...query,
  state: { ...query.state, dataUpdatedAt: currentTimestamp - 31556952000 }, // 1 year
})

const dehydratedQueryWithNowTimestamp = (
  query: DehydratedState['queries'][number]
): DehydratedState['queries'][number] => ({
  ...query,
  state: { ...query.state, dataUpdatedAt: Date.now() },
})

//! Because on certain environments (test, stg, prod) we are caching
//! the server response of the getServerSideProps() method, depending
//! on the page visited, the timestamp of the query data generated at
//! that point in time might be earlier than the timestamp of a previous
//! url cached request, even if the current request from the client is newer.
//! This is causing problems when react-query is trying to merge the query data
//! provide from the server after the cache hydration on the client, as it in
//! some cases ignores the data of the newer request in favor of the existing data
//! (because the cached timestamp is older that the one on the client).
//! We also follow the opposite approach when we have data that we need to only be set
//! when it doesn't exist already on the client cache, by setting the timestamp to be
//! one year older than existing data updatedAt timestamp.
const getDehydratedQueryStateWithAdjustedTimestamp = ({
  queryClient,
  dehydratedQueryState,
  keysThatShouldNotOverwriteClientCacheIfExists,
}: {
  queryClient: QueryClient
  dehydratedQueryState: DehydratedState
  keysThatShouldNotOverwriteClientCacheIfExists: CatchAllPageServerProps['keysThatShouldNotOverwriteClientCacheIfExists']
}): DehydratedState => ({
  ...dehydratedQueryState,
  queries: dehydratedQueryState.queries.reduce(
    (acc, query) => {
      if (
        keysThatShouldNotOverwriteClientCacheIfExists?.[
          JSON.stringify(query.queryKey)
        ]
      ) {
        const queryStateOfExistingKey = queryClient.getQueryState(
          query.queryKey
        )
        if (queryStateOfExistingKey) {
          acc.push(
            dehydratedQueryWithOldTimestamp({
              query,
              currentTimestamp: queryStateOfExistingKey.dataUpdatedAt,
            })
          )
        } else {
          acc.push(dehydratedQueryWithNowTimestamp(query))
        }
      } else {
        acc.push(dehydratedQueryWithNowTimestamp(query))
      }

      return acc
    },
    [] as DehydratedState['queries']
  ),
})

const disableStructuralSharingForQueries = (queryClient: QueryClient) => {
  STRUCTURAL_SHARING_DISABLED_QUERIES.forEach((query) => {
    queryClient.setQueryDefaults(query, {
      structuralSharing: false,
    })
  })
}

export {
  defaultConfig,
  getDehydratedQueryStateWithAdjustedTimestamp,
  disableStructuralSharingForQueries,
}
