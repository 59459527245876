import { useQueryClient } from '@tanstack/react-query'

export interface FeatureFlagsSkeleton {
  isArticleQuestionsEnabled: boolean
  isChatbotEnabled: boolean
}

type UseFeatureFlagsSkeleton = () => FeatureFlagsSkeleton

const useFeatureFlagsSkeleton: UseFeatureFlagsSkeleton = () =>
  useQueryClient().getQueryData<FeatureFlagsSkeleton>([
    'featureFlagsSkeleton',
  ]) as FeatureFlagsSkeleton

export default useFeatureFlagsSkeleton
