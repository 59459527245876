import { default as abTest } from './items/abTest'
import { default as ads } from './items/ads'
import { default as appLinks } from './items/appLinks'
import { default as auth } from './items/auth'
import { default as backend } from './items/backend'
import { default as cmp } from './items/cmp'
import { default as community } from './items/commenting'
import { default as cueLive } from './items/cueLive'
import { default as datawrapper } from './items/datawrapper'
import { default as date } from './items/date'
import { default as debug } from './items/debug'
import { default as dlApi } from './items/dlApi'
import { default as feedback } from './items/feedback'
import { default as formio } from './items/formio'
import { default as gamification } from './items/gamification'
import { default as gdpr } from './items/gdpr'
import { default as googleNews } from './items/googleNews'
import { default as hearken } from './items/hearken'
import { default as heimspiel } from './items/heimspiel'
import { default as iframe } from './items/iframe'
import { default as image } from './items/image'
import { default as jwplayer } from './items/jwplayer'
import { default as lotto } from './items/lotto'
import { default as metatags } from './items/metatags'
import { default as notifications } from './items/notifications'
import { default as oneSignal } from './items/oneSignal'
import { default as piano } from './items/piano'
import { default as plusLinkShare } from './items/plusLinkShare'
import { default as prolitteris } from './items/prolitteris'
import { default as podigee } from './items/podigee'
import { default as publication } from './items/publication'
import { default as recommendation } from './items/recommendation'
import { default as riddle } from './items/riddle'
import { default as schema } from './items/schema'
import { default as search } from './items/search'
import { default as sentry } from './items/sentry'
import { default as socialembeds } from './items/socialembeds'
import { default as sports } from './items/sports'
import { default as subscriptions } from './items/subscriptions'
import { default as tracking } from './items/tracking'
import { default as twitter } from './items/twitter'
import { default as ugc } from './items/ugc'
import { default as urlParams } from './items/urlParams'
import { default as usersnap } from './items/usersnap'
import { default as video } from './items/video'
import { default as vimeo } from './items/vimeo'
import { default as weather } from './items/weather'
import { default as webarchive } from './items/webarchive'
import { default as zattoo } from './items/zattoo'
import { default as leserreporter } from './items/leserreporter'
import { default as textToSpeech } from './items/textToSpeech'
import { default as articleSummary } from './items/articleSummary'
import { default as sectionSummary } from './items/sectionSummary'
import { default as questionWidget } from './items/questionWidget'
import { default as blickBites } from './items/blickBites'
import { default as chatbot } from './items/chatbot'
import { default as growthBook } from './items/growthBook'
import { default as aureus } from './items/aureus'

import { ConfigFn, EnvVariables } from './types'

const localhostHTTPS = process.env.NEXT_PUBLIC_DEV_SERVER_HTTPS === 'true'
const useLocalhostStaticPages =
  process.env.NEXT_PUBLIC_USE_LOCALHOST_STATIC_PAGES === 'true'

const rawEnvVariables = {
  releaseVersion: process.env.NEXT_PUBLIC_RELEASE_VERSION ?? '',
  deploymentEnv: process.env.NEXT_PUBLIC_DEPLOYMENT_ENV,
  teamcityEnv: process.env.NEXT_PUBLIC_TEAMCITY_ENV,
  publication: process.env.NEXT_PUBLIC_PUBLICATION,
  useLocalhostApiUrl: process.env.NEXT_PUBLIC_USE_LOCALHOST_API_URL === 'true',
  sentryDebugMode: process.env.NEXT_PUBLIC_SENTRY_DEBUG_MODE === 'true',
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  authUrl: process.env.NEXT_PUBLIC_AUTH_URL,
  sportApiUrl: process.env.NEXT_PUBLIC_SPORT_API_URL,
  sportApiUrlV2: process.env.NEXT_PUBLIC_SPORT_API_URL_V2,
  lottoUrl: process.env.NEXT_PUBLIC_LOTTO_URL,
  imageUrl: process.env.NEXT_PUBLIC_IMAGE_URL,
  baseUrl: localhostHTTPS
    ? process.env.NEXT_PUBLIC_BASE_URL_HTTPS
    : process.env.NEXT_PUBLIC_BASE_URL,
  staticAssetDomain: useLocalhostStaticPages
    ? process.env.NEXT_PUBLIC_STATIC_ASSET_DOMAIN_LOCALHOST
    : process.env.NEXT_PUBLIC_STATIC_ASSET_DOMAIN,
  staticAssetBaseUrl:
    process.env.NEXT_PUBLIC_DEPLOYMENT_ENV === 'pr' &&
    !process.env.NEXT_PUBLIC_STATIC_PAGE_PR_NUMBER
      ? process.env.NEXT_PUBLIC_FALLBACK_STATIC_ASSET_BASE_URL
      : useLocalhostStaticPages
        ? process.env.NEXT_PUBLIC_STATIC_ASSET_BASE_URL_LOCALHOST
        : process.env.NEXT_PUBLIC_STATIC_ASSET_BASE_URL,
  staticAssetPageUrl:
    process.env.NEXT_PUBLIC_DEPLOYMENT_ENV === 'pr' &&
    !process.env.NEXT_PUBLIC_STATIC_PAGE_PR_NUMBER
      ? process.env.NEXT_PUBLIC_FALLBACK_STATIC_ASSET_PAGE_URL
      : useLocalhostStaticPages
        ? process.env.NEXT_PUBLIC_STATIC_ASSET_PAGE_URL_LOCALHOST
        : process.env.NEXT_PUBLIC_STATIC_ASSET_PAGE_URL,
  staticPageUrlSuffix: useLocalhostStaticPages ? '' : '.html',
  blickTvPlayerAssetUrlPrefix:
    process.env.NEXT_PUBLIC_BLICK_TV_PLAYER_ASSET_URL_PREFIX,
  weatherUrl: process.env.NEXT_PUBLIC_WEATHER_URL,
  chaptersApiUrl: process.env.NEXT_PUBLIC_CHAPTERS_API_URL,
  commentingUrl: process.env.NEXT_PUBLIC_COMMENTING_URL,
  gamificationTrophyUrl: process.env.NEXT_PUBLIC_GAMIFICATION_TROPHY_URL,
  searchUrl: process.env.NEXT_PUBLIC_SEARCH_URL,
  recommendationsUrl: process.env.NEXT_PUBLIC_RECOMMENDATIONS_URL,
  ssoDomain: process.env.NEXT_PUBLIC_SSO_DOMAIN,
  videoMappingUrl: process.env.NEXT_PUBLIC_VIDEO_MAPPING_URL,
  subscriptionCheckerUrl: process.env.NEXT_PUBLIC_SUBSCRIPTION_CHECKER_URL,
  myServicesUrl: process.env.NEXT_PUBLIC_MY_SERVICES_URL,
  ugcUrl: process.env.NEXT_PUBLIC_UGC_URL,
  cueLiveUrl: process.env.NEXT_PUBLIC_CUE_LIVE_URL,
  pianoEnv: process.env.NEXT_PUBLIC_PIANO_ENV,
  plusLinkShareUrl: process.env.NEXT_PUBLIC_PLUS_LINK_SHARE_URL,
  plusSubscriptionServiceUrl:
    process.env.NEXT_PUBLIC_PLUS_SUBSCRIPTION_SERVICE_URL,
  hotModuleReloading: process.env.NEXT_PUBLIC_DEV_SERVER === 'true',
  chatbotChatUrl: process.env.NEXT_PUBLIC_CHATBOT_CHAT_URL,
  chatbotQuestionsUrl: process.env.NEXT_PUBLIC_CHATBOT_QUESTIONS_URL,
  growthBookEnv: process.env.NEXT_PUBLIC_GROWTHBOOK_ENV,
} as EnvVariables

const configItems = {
  abTest,
  appLinks,
  ads,
  auth,
  backend,
  chatbot,
  cmp,
  community,
  cueLive,
  datawrapper,
  date,
  debug,
  dlApi,
  feedback,
  formio,
  gamification,
  gdpr,
  googleNews,
  hearken,
  heimspiel,
  image,
  iframe,
  jwplayer,
  lotto,
  metatags,
  notifications,
  oneSignal,
  piano,
  plusLinkShare,
  prolitteris,
  podigee,
  publication,
  recommendation,
  riddle,
  schema,
  search,
  sentry,
  socialembeds,
  sports,
  subscriptions,
  tracking,
  twitter,
  ugc,
  urlParams,
  usersnap,
  video,
  vimeo,
  weather,
  webarchive,
  zattoo,
  leserreporter,
  textToSpeech,
  articleSummary,
  sectionSummary,
  questionWidget,
  blickBites,
  growthBook,
  aureus,
} as const

export type ConfigType = {
  [key in keyof typeof configItems]: ReturnType<(typeof configItems)[key]>
}

const generatedConfig = Object.entries(configItems).reduce(
  (acc, [configName, configFn]) => {
    acc[configName as keyof typeof configItems] = configFn(rawEnvVariables)
    return acc
  },
  {} as Record<keyof typeof configItems, ReturnType<ConfigFn>>
)

export default generatedConfig as ConfigType
