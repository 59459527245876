import styled, { css } from 'styled-components'
import TypingAnimationWrapper from './TypingEffectWrapper'
import { FunctionComponent } from 'react'
import { AnswerParagraphCategoryProps } from '../types'

const StyledAnswerCategory = styled.h2`
  ${({
    theme: {
      typography: {
        headings: { small: smallHeadings },
      },
      spacing: { spacing8 },
    },
  }) => css`
    ${smallHeadings}
    margin: 0;
    padding-top: ${spacing8};
    &:first-child {
      padding: 0 0 ${spacing8} 0;
    }
  `}
`
const AnswerParagraphCategory: FunctionComponent<
  AnswerParagraphCategoryProps
> = ({ categoryText, skipAnimation, afterTypingEffectEndCb }) => {
  return (
    <StyledAnswerCategory>
      <TypingAnimationWrapper
        text={categoryText}
        skipAnimation={skipAnimation}
        afterTypingEffectEndCb={afterTypingEffectEndCb}
      />
    </StyledAnswerCategory>
  )
}

export default AnswerParagraphCategory
